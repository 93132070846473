// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareAddressFields() {
    val props: Array<SerializableProperty<Address, *>> = arrayOf(Address_street, Address_city, Address_zip, Address_state, Address_geoCoordinate)
    Address.serializer().properties { props }
}
val <K> DataClassPath<K, Address>.street: DataClassPath<K, String> get() = this[Address_street]
val <K> DataClassPath<K, Address>.city: DataClassPath<K, String> get() = this[Address_city]
val <K> DataClassPath<K, Address>.zip: DataClassPath<K, String?> get() = this[Address_zip]
val <K> DataClassPath<K, Address>.state: DataClassPath<K, UsState> get() = this[Address_state]
val <K> DataClassPath<K, Address>.geoCoordinate: DataClassPath<K, GeoCoordinate> get() = this[Address_geoCoordinate]
inline val Address.Companion.path: DataClassPath<Address, Address> get() = path<Address>()


object Address_street: SerializableProperty<Address, String> {
    override val name: String = "street"
    override fun get(receiver: Address): String = receiver.street
    override fun setCopy(receiver: Address, value: String) = receiver.copy(street = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Address.serializer().tryFindAnnotations("street")
}
object Address_city: SerializableProperty<Address, String> {
    override val name: String = "city"
    override fun get(receiver: Address): String = receiver.city
    override fun setCopy(receiver: Address, value: String) = receiver.copy(city = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Address.serializer().tryFindAnnotations("city")
}
object Address_zip: SerializableProperty<Address, String?> {
    override val name: String = "zip"
    override fun get(receiver: Address): String? = receiver.zip
    override fun setCopy(receiver: Address, value: String?) = receiver.copy(zip = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = Address.serializer().tryFindAnnotations("zip")
    override val default: String? = null
}
object Address_state: SerializableProperty<Address, UsState> {
    override val name: String = "state"
    override fun get(receiver: Address): UsState = receiver.state
    override fun setCopy(receiver: Address, value: UsState) = receiver.copy(state = value)
    override val serializer: KSerializer<UsState> = UsState.serializer()
    override val annotations: List<Annotation> = Address.serializer().tryFindAnnotations("state")
}
object Address_geoCoordinate: SerializableProperty<Address, GeoCoordinate> {
    override val name: String = "geoCoordinate"
    override fun get(receiver: Address): GeoCoordinate = receiver.geoCoordinate
    override fun setCopy(receiver: Address, value: GeoCoordinate) = receiver.copy(geoCoordinate = value)
    override val serializer: KSerializer<GeoCoordinate> = GeoCoordinate.serializer()
    override val annotations: List<Annotation> = Address.serializer().tryFindAnnotations("geoCoordinate")
}
