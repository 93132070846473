// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareUserVehiclePairFields() {
    val props: Array<SerializableProperty<UserVehiclePair, *>> = arrayOf(UserVehiclePair_user, UserVehiclePair_vehicle)
    UserVehiclePair.serializer().properties { props }
}
val <K> DataClassPath<K, UserVehiclePair>.user: DataClassPath<K, UUID> get() = this[UserVehiclePair_user]
val <K> DataClassPath<K, UserVehiclePair>.vehicle: DataClassPath<K, UUID> get() = this[UserVehiclePair_vehicle]
inline val UserVehiclePair.Companion.path: DataClassPath<UserVehiclePair, UserVehiclePair> get() = path<UserVehiclePair>()


object UserVehiclePair_user: SerializableProperty<UserVehiclePair, UUID> {
    override val name: String = "user"
    override fun get(receiver: UserVehiclePair): UUID = receiver.user
    override fun setCopy(receiver: UserVehiclePair, value: UUID) = receiver.copy(user = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = UserVehiclePair.serializer().tryFindAnnotations("user")
}
object UserVehiclePair_vehicle: SerializableProperty<UserVehiclePair, UUID> {
    override val name: String = "vehicle"
    override fun get(receiver: UserVehiclePair): UUID = receiver.vehicle
    override fun setCopy(receiver: UserVehiclePair, value: UUID) = receiver.copy(vehicle = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = UserVehiclePair.serializer().tryFindAnnotations("vehicle")
}
