package com.ilussobsa.views.dialogs

import com.ilussobsa.Strings
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.SizeConstraints
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.KiteUiScreen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.card
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.*

class GenericDialog(val message: String, val onDismiss: (suspend () -> Unit)?) :
    KiteUiScreen {
    override fun ViewWriter.render() {
        dismissBackground {
            centered - sizedBox(SizeConstraints(maxWidth = 20.rem, minWidth = 10.rem)) - card - col {
                text {
                    align = Align.Center
                    content = message
                }
//                        sizedBox(SizeConstraints(maxWidth = 20.rem, minWidth = 10.rem))

                card - button {
                    text {
                        align = Align.Center
                        content = Strings.ok
                    }
                    onClick {
                        onDismiss?.invoke()
                        dialogScreenNavigator.dismiss()
                    }
                }
            }
        }
    }
}