package com.ilussobsa.sdk

import com.ilussobsa.*
import com.ilussobsa.views.LogInScreen
import com.lightningkite.lightningserver.db.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.reactive.*
import kotlinx.datetime.Clock.System.now
import com.lightningkite.kiteui.navigation.mainScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.db.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.lightningserver.websocket.*
import com.lightningkite.serialization.*
import kotlin.time.Duration.Companion.minutes
import kotlinx.coroutines.*
import kotlinx.datetime.Clock.System.now
import kotlinx.datetime.Instant
import kotlinx.serialization.builtins.nullable

class UserSession(
    override val api: Api,
    override val userToken: String,
    override val userAccessToken: suspend () -> String,
    override val masquerade: String?
) : AbstractUserSession(api, userToken, userAccessToken, masquerade) {
    fun refresh() {
        users.totallyInvalidate()
        notifications.totallyInvalidate()
        auctions.totallyInvalidate()
        liveAuctionDataCache.totallyInvalidate()
        bids.totallyInvalidate()
        vehicleRelationships.totallyInvalidate()
        vehicles.totallyInvalidate()
        savedSearches.totallyInvalidate()
        sellerTalkingPoints.totallyInvalidate()
        makes.totallyInvalidate()
        models.totallyInvalidate()
        earnestPayments.totallyInvalidate()
//        dealershipJoinRequests.totallyInvalidate()
    }

    val userId = asyncGlobal { userAuth.getSelf()._id }
    val me = shared { users.get(userId()) }.flatten()
    val users = ModelCache(user, User.serializer(), cacheTime = 1.minutes)
    val notifications = ModelCache(notification, Notification.serializer(), cacheTime = 1.minutes)
    val auctions = ModelCache(auctionLane, AuctionLane.serializer(), cacheTime = 1.minutes)
    val liveAuctionDataCache = ModelCache(liveAuctionData, LiveAuctionData.serializer(), cacheTime = 1.minutes)
    val bids = ModelCache(bid, Bid.serializer(), cacheTime = 1.minutes)
    val vehicleRelationships = ModelCache(vehicleRelationship, VehicleRelationship.serializer(), cacheTime = 1.minutes)
    val vehicles = ModelCache(vehicle, Vehicle.serializer(), cacheTime = 1.minutes) { v ->
        vehicleRelationships.localSignalUpdate(
            matching = { it._id.vehicle == v._id },
            modify = { it.copy(vehicleDenormalizedInfo = v.short()) }
        )
    }
    val savedSearches = ModelCache(savedSearch, SavedSearch.serializer(), cacheTime = 1.minutes)
    val sellerTalkingPoints = ModelCache(sellerTalkingPoint, SellerTalkingPoint.serializer(), cacheTime = 1.minutes)
    val makes = ModelCache(make, Make.serializer(), cacheTime = 1.minutes)
    val models = ModelCache(model, Model.serializer(), cacheTime = 1.minutes)
    val earnestPayments = ModelCache(earnestPayment, EarnestPayment.serializer(), cacheTime = 1.minutes)
    val externalFinancingForms = ModelCache(externalFinancingForm, ExternalFinancingForm.serializer(), cacheTime = 1.minutes)
//    val dealershipJoinRequests =
//        ModelCache(this.join, DealershipJoinRequest.serializer(), cacheTime = 1.minutes)
}

val currentSessionNullable = shared<UserSession?> {
    val refresh = sessionToken() ?: run {
        println("Session token not found!!")
        return@shared null
    }
    val api = selectedApi().api

    var lastRefresh: Instant = now()

    fun tokenGetter() = asyncGlobal {
        try {
            api.userAuth.getTokenSimple(refresh)
        } catch (e: LsErrorException) {
            if (e.status == 400.toShort()) {
                println("Got a 400; we think we've messed up our auth")
                sessionToken.set(null)
                mainNavigatorGlobalIsBad.navigate(LogInScreen())
            }
            throw e
        }
    }

    var token: Async<String> = tokenGetter()

    val generateToken = suspend {
        if (lastRefresh <= now().minus(4.minutes)) {
            lastRefresh = now()
            token = tokenGetter()
        }
        token.await()
    }

    val masqueradeHeader = masqueradeUserId()?.let { "User/$it" }

    UserSession(
        api = api,
        userToken = refresh,
        userAccessToken = generateToken,
        masquerade = masqueradeHeader
    )
}

val ViewWriter.currentSession get() = shared {
    val result = currentSessionNullable()
    if (result == null) {
        if (mainScreenNavigator.stack.value.lastOrNull() !is LogInScreen) {
            println("Kicking ya out!")
            mainScreenNavigator.reset(LogInScreen())
        }
        throw CancelledException("Failed")
    }
    result
}

//suspend fun ViewWriter.clearSession() {
//    try {
//        currentSession.await()?.userSession?.terminateSession()
//    } catch (e: Exception) {
//        /*squish*/
//    }
//    println("Session cleared")
//    sessionToken set null
//}


val sessionToken = PersistentProperty<String?>("sessionToken", null)
val masqueradeUserId = PersistentProperty<String?>("masqueradeUserId", null)

val currentUser = shared { currentSessionNullable.awaitNotNull().me }.flatten()
val isSeller = shared { currentUser()?.role?.let { it >= UserRole.Manager } ?: false }
val participatesInAuctions = shared { (currentUser()?.role ?: UserRole.Anonymous) == UserRole.Customer }
val managesAuctions = shared { (currentUser()?.role ?: UserRole.Anonymous) >= UserRole.Manager }
