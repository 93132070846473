// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareStripeWebhookSecretFields() {
    val props: Array<SerializableProperty<StripeWebhookSecret, *>> = arrayOf(StripeWebhookSecret__id, StripeWebhookSecret_secret, StripeWebhookSecret_stripeId, StripeWebhookSecret_events)
    StripeWebhookSecret.serializer().properties { props }
}
val <K> DataClassPath<K, StripeWebhookSecret>._id: DataClassPath<K, String> get() = this[StripeWebhookSecret__id]
val <K> DataClassPath<K, StripeWebhookSecret>.secret: DataClassPath<K, String> get() = this[StripeWebhookSecret_secret]
val <K> DataClassPath<K, StripeWebhookSecret>.stripeId: DataClassPath<K, String> get() = this[StripeWebhookSecret_stripeId]
val <K> DataClassPath<K, StripeWebhookSecret>.events: DataClassPath<K, Set<String>> get() = this[StripeWebhookSecret_events]
inline val StripeWebhookSecret.Companion.path: DataClassPath<StripeWebhookSecret, StripeWebhookSecret> get() = path<StripeWebhookSecret>()


object StripeWebhookSecret__id: SerializableProperty<StripeWebhookSecret, String> {
    override val name: String = "_id"
    override fun get(receiver: StripeWebhookSecret): String = receiver._id
    override fun setCopy(receiver: StripeWebhookSecret, value: String) = receiver.copy(_id = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = StripeWebhookSecret.serializer().tryFindAnnotations("_id")
}
object StripeWebhookSecret_secret: SerializableProperty<StripeWebhookSecret, String> {
    override val name: String = "secret"
    override fun get(receiver: StripeWebhookSecret): String = receiver.secret
    override fun setCopy(receiver: StripeWebhookSecret, value: String) = receiver.copy(secret = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = StripeWebhookSecret.serializer().tryFindAnnotations("secret")
}
object StripeWebhookSecret_stripeId: SerializableProperty<StripeWebhookSecret, String> {
    override val name: String = "stripeId"
    override fun get(receiver: StripeWebhookSecret): String = receiver.stripeId
    override fun setCopy(receiver: StripeWebhookSecret, value: String) = receiver.copy(stripeId = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = StripeWebhookSecret.serializer().tryFindAnnotations("stripeId")
    override val default: String = ""
}
object StripeWebhookSecret_events: SerializableProperty<StripeWebhookSecret, Set<String>> {
    override val name: String = "events"
    override fun get(receiver: StripeWebhookSecret): Set<String> = receiver.events
    override fun setCopy(receiver: StripeWebhookSecret, value: Set<String>) = receiver.copy(events = value)
    override val serializer: KSerializer<Set<String>> = SetSerializer(String.serializer())
    override val annotations: List<Annotation> = StripeWebhookSecret.serializer().tryFindAnnotations("events")
    override val default: Set<String> = setOf()
}
