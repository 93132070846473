package com.ilussobsa.utils

import com.ilussobsa.fcmToken
import com.lightningkite.kiteui.ConsoleRoot
import com.lightningkite.kiteui.launchGlobal
import com.lightningkite.kiteui.navigation.PlatformNavigator
import com.lightningkite.kiteui.navigation.basePath
import com.lightningkite.kiteui.suspendCoroutineCancellable
import com.lightningkite.lightningdb.condition
import kotlin.coroutines.resume
import kotlin.js.json
import kotlinx.browser.window
import org.w3c.dom.MODULE
import org.w3c.dom.WorkerType
import org.w3c.notifications.*
import org.w3c.workers.*

actual suspend fun fcmSetup(): Unit {
    try {
        val firebaseAppOptions: dynamic = object {}
        firebaseAppOptions["apiKey"] = "AIzaSyBXwN_6zldIISLI-AHfPJgMlUIyqkkeDQA"
        firebaseAppOptions["authDomain"] = "ilusso-auctions.firebaseapp.com"
        firebaseAppOptions["projectId"] = "ilusso-auctions"
        firebaseAppOptions["storageBucket"] = "ilusso-auctions.firebasestorage.app"
        firebaseAppOptions["messagingSenderId"] = "527981634545"
        firebaseAppOptions["appId"] = "1:527981634545:web:5e43acfbb8984617b41a50"

        val app = initializeApp(firebaseAppOptions.unsafeCast<FirebaseOptions>())
        val messaging = getMessaging(app)

        window.navigator.serviceWorker
            .register(basePath + "firebase-messaging-sw.js", RegistrationOptions(scope = "./"))
            .then { serviceWorkerReg ->

                val serviceWorker = serviceWorkerReg.installing ?: serviceWorkerReg.waiting ?: serviceWorkerReg.active
                ConsoleRoot.log("Service worker state: ${serviceWorker?.state}")
                if(serviceWorker?.state == ServiceWorkerState.ACTIVATED) {
                    finishSetup(serviceWorkerReg, messaging)
                } else {
                    serviceWorker?.addEventListener("statechange", { e ->
                        val target = e.target as ServiceWorker
                        ConsoleRoot.log("Service worker state: ${target.state}")
                        if(target.state == ServiceWorkerState.ACTIVATED)
                            finishSetup(serviceWorkerReg, messaging)
                    })
                }


            }.catch {
                it.printStackTrace()
            }

    } catch (e: Exception) {
        e.printStackTrace()
    }
}

private fun finishSetup(
    serviceWorker: ServiceWorkerRegistration,
    messaging: Messaging
) {
    val messagingOptions: dynamic = object {}
    messagingOptions["vapidKey"] =
        "BJcLhCdURFxQUWpjm8Jhn_Ft2_QGZBQ-5miwKkh-f9AGUxXV9t6ARY-JjXzUCyHj0zEOTFG7N4VSutADawJRWw0"
    messagingOptions["serviceWorkerRegistration"] = serviceWorker

    getToken(
        messaging,
        messagingOptions.unsafeCast<GetTokenOptions>()
    ).then {
        fcmToken.value = it
    }.catch {
        it.printStackTrace()
    }

    onMessage(messaging) { notification ->
        serviceWorker.showNotification(
            notification.notification?.title ?: "Notification",
            NotificationOptions(
                body = notification.notification?.body,
                image = notification.notification?.image,
                icon = notification.notification?.icon,
            )
        )
            .catch { e ->
                console.error("Error sending notificaiton to user", e);
            }
        serviceWorker.update()
    }
    ConsoleRoot.info("FCM Registration complete.")
}

actual suspend fun requestNotificationPermissions(): Unit {
    val result = suspendCoroutineCancellable { cont ->
        Notification.requestPermission { result ->
            cont.resume(result)
        }
        return@suspendCoroutineCancellable {}
    }
    if (result == NotificationPermission.GRANTED) fcmSetup()
}

actual suspend fun notificationPermissions(): Boolean? {
    return if (js("'Notification' in window").unsafeCast<Boolean>())
        when (Notification.permission) {
            NotificationPermission.GRANTED -> true
            NotificationPermission.DENIED -> false
            else -> null
        } else false
}