// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareModelFields() {
    val props: Array<SerializableProperty<Model, *>> = arrayOf(Model_make, Model_model, Model__id)
    Model.serializer().properties { props }
}
val <K> DataClassPath<K, Model>.make: DataClassPath<K, String> get() = this[Model_make]
val <K> DataClassPath<K, Model>.model: DataClassPath<K, String> get() = this[Model_model]
val <K> DataClassPath<K, Model>._id: DataClassPath<K, String> get() = this[Model__id]
inline val Model.Companion.path: DataClassPath<Model, Model> get() = path<Model>()


object Model_make: SerializableProperty<Model, String> {
    override val name: String = "make"
    override fun get(receiver: Model): String = receiver.make
    override fun setCopy(receiver: Model, value: String) = receiver.copy(make = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Model.serializer().tryFindAnnotations("make")
}
object Model_model: SerializableProperty<Model, String> {
    override val name: String = "model"
    override fun get(receiver: Model): String = receiver.model
    override fun setCopy(receiver: Model, value: String) = receiver.copy(model = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Model.serializer().tryFindAnnotations("model")
}
object Model__id: SerializableProperty<Model, String> {
    override val name: String = "_id"
    override fun get(receiver: Model): String = receiver._id
    override fun setCopy(receiver: Model, value: String) = receiver.copy(_id = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Model.serializer().tryFindAnnotations("_id")
}
