
package com.ilussobsa.sdk

import com.lightningkite.*
import com.lightningkite.kiteui.*
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.db.*
import com.lightningkite.lightningserver.auth.*
import com.lightningkite.serialization.*
import kotlinx.datetime.*
import kotlin.String
import com.lightningkite.lightningserver.files.UploadInformation
import com.lightningkite.lightningserver.typed.BulkRequest
import com.lightningkite.lightningserver.typed.BulkResponse
import kotlin.Boolean
import com.lightningkite.lightningserver.auth.proof.FinishProof
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.auth.proof.IdentificationAndPassword
import com.lightningkite.UUID
import com.lightningkite.lightningserver.auth.subject.IdAndAuthMethods
import com.lightningkite.lightningserver.auth.subject.LogInRequest
import com.lightningkite.lightningserver.auth.subject.ProofsCheckResult
import com.lightningkite.lightningserver.auth.oauth.OauthTokenRequest
import com.lightningkite.lightningserver.auth.oauth.OauthResponse
import com.ilussobsa.Make
import com.lightningkite.lightningdb.Query
import com.lightningkite.lightningdb.QueryPartial
import com.lightningkite.serialization.Partial
import com.lightningkite.lightningdb.MassModification
import kotlin.Int
import com.lightningkite.lightningdb.Modification
import com.lightningkite.lightningdb.EntryChange
import com.lightningkite.lightningdb.Condition
import kotlin.Unit
import com.lightningkite.lightningdb.GroupCountQuery
import com.lightningkite.lightningdb.AggregateQuery
import kotlin.Double
import com.lightningkite.lightningdb.GroupAggregateQuery
import com.lightningkite.lightningserver.serverhealth.ServerHealth
import com.ilussobsa.PaymentMethod
import com.ilussobsa.FinancingApplication
import com.ilussobsa.User
import com.lightningkite.lightningdb.ListChange
import com.lightningkite.lightningserver.auth.subject.SubSessionRequest
import com.lightningkite.lightningserver.auth.subject.Session
import com.ilussobsa.FcmToken
import com.ilussobsa.VehicleRelationship
import com.ilussobsa.UserVehiclePair
import com.ilussobsa.Vehicle
import com.lightningkite.lightningdb.CollectionUpdates
import com.ilussobsa.AuctionLane
import com.ilussobsa.Bid
import com.ilussobsa.LiveAuctionData
import com.ilussobsa.SellerTalkingPoint
import com.ilussobsa.SavedSearch
import com.ilussobsa.Notification
import com.ilussobsa.ResetDataRequest
import com.ilussobsa.Model
import com.ilussobsa.EarnestPayment
import com.ilussobsa.ExternalFinancingForm
import com.lightningkite.lightningserver.auth.proof.OtpSecret
import com.lightningkite.lightningserver.auth.proof.EstablishOtp
import com.lightningkite.lightningserver.auth.proof.PasswordSecret
import com.lightningkite.lightningserver.auth.proof.EstablishPassword

open class AbstractAnonymousSession(val api: Api) {
    val user: AbstractAnonymousSessionUserApi = AbstractAnonymousSessionUserApi(api.user)
    val emailProof: AbstractAnonymousSessionEmailProofApi = AbstractAnonymousSessionEmailProofApi(api.emailProof)
    val otpSecret: AbstractAnonymousSessionOtpSecretApi = AbstractAnonymousSessionOtpSecretApi(api.otpSecret)
    val oneTimePasswordProof: AbstractAnonymousSessionOneTimePasswordProofApi = AbstractAnonymousSessionOneTimePasswordProofApi(api.oneTimePasswordProof)
    val passwordSecret: AbstractAnonymousSessionPasswordSecretApi = AbstractAnonymousSessionPasswordSecretApi(api.passwordSecret)
    val passwordProof: AbstractAnonymousSessionPasswordProofApi = AbstractAnonymousSessionPasswordProofApi(api.passwordProof)
    val userAuth: AbstractAnonymousSessionUserAuthApi = AbstractAnonymousSessionUserAuthApi(api.userAuth)
    val userSession: AbstractAnonymousSessionUserSessionApi = AbstractAnonymousSessionUserSessionApi(api.userSession)
    val fcmToken: AbstractAnonymousSessionFcmTokenApi = AbstractAnonymousSessionFcmTokenApi(api.fcmToken)
    val vehicleRelationship: AbstractAnonymousSessionVehicleRelationshipApi = AbstractAnonymousSessionVehicleRelationshipApi(api.vehicleRelationship)
    val vehicle: AbstractAnonymousSessionVehicleApi = AbstractAnonymousSessionVehicleApi(api.vehicle)
    val auctionLane: AbstractAnonymousSessionAuctionLaneApi = AbstractAnonymousSessionAuctionLaneApi(api.auctionLane)
    val bid: AbstractAnonymousSessionBidApi = AbstractAnonymousSessionBidApi(api.bid)
    val liveAuctionData: AbstractAnonymousSessionLiveAuctionDataApi = AbstractAnonymousSessionLiveAuctionDataApi(api.liveAuctionData)
    val sellerTalkingPoint: AbstractAnonymousSessionSellerTalkingPointApi = AbstractAnonymousSessionSellerTalkingPointApi(api.sellerTalkingPoint)
    val savedSearch: AbstractAnonymousSessionSavedSearchApi = AbstractAnonymousSessionSavedSearchApi(api.savedSearch)
    val notification: AbstractAnonymousSessionNotificationApi = AbstractAnonymousSessionNotificationApi(api.notification)
    val faker: AbstractAnonymousSessionFakerApi = AbstractAnonymousSessionFakerApi(api.faker)
    val make: AbstractAnonymousSessionMakeApi = AbstractAnonymousSessionMakeApi(api.make)
    val model: AbstractAnonymousSessionModelApi = AbstractAnonymousSessionModelApi(api.model)
    val earnestPayment: AbstractAnonymousSessionEarnestPaymentApi = AbstractAnonymousSessionEarnestPaymentApi(api.earnestPayment)
    val externalFinancingForm: AbstractAnonymousSessionExternalFinancingFormApi = AbstractAnonymousSessionExternalFinancingFormApi(api.externalFinancingForm)
    suspend fun logInViaApple(): String = api.logInViaApple()
    suspend fun logInViaGoogle(): String = api.logInViaGoogle()
    suspend fun logInViaMicrosoft(): String = api.logInViaMicrosoft()
    suspend fun uploadFileForRequest(): UploadInformation = api.uploadFileForRequest()
    suspend fun bulkRequest(input: Map<String, BulkRequest>): Map<String, BulkResponse> = api.bulkRequest(input)
    suspend fun checkIfUserExistsByEmail(input: String): Boolean = api.checkIfUserExistsByEmail(input)
    open class AbstractAnonymousSessionUserApi(val api: Api.UserApi) {
    }
    open class AbstractAnonymousSessionEmailProofApi(val api: Api.EmailProofApi): EmailProofClientEndpoints {
        override suspend fun beginEmailOwnershipProof(input: String): String = api.beginEmailOwnershipProof(input)
        override suspend fun proveEmailOwnership(input: FinishProof): Proof = api.proveEmailOwnership(input)
    }
    open class AbstractAnonymousSessionOtpSecretApi(val api: Api.OtpSecretApi) {
    }
    open class AbstractAnonymousSessionOneTimePasswordProofApi(val api: Api.OneTimePasswordProofApi): OneTimePasswordProofClientEndpoints {
        override suspend fun proveOTP(input: IdentificationAndPassword): Proof = api.proveOTP(input)
    }
    open class AbstractAnonymousSessionPasswordSecretApi(val api: Api.PasswordSecretApi) {
    }
    open class AbstractAnonymousSessionPasswordProofApi(val api: Api.PasswordProofApi): PasswordProofClientEndpoints {
        override suspend fun provePasswordOwnership(input: IdentificationAndPassword): Proof = api.provePasswordOwnership(input)
    }
    open class AbstractAnonymousSessionUserAuthApi(val api: Api.UserAuthApi): UserAuthClientEndpoints<UUID> {
        override suspend fun logIn(input: List<Proof>): IdAndAuthMethods<UUID> = api.logIn(input)
        override suspend fun logInV2(input: LogInRequest): IdAndAuthMethods<UUID> = api.logInV2(input)
        override suspend fun checkProofs(input: List<Proof>): ProofsCheckResult<UUID> = api.checkProofs(input)
        override suspend fun openSession(input: String): String = api.openSession(input)
        override suspend fun getToken(input: OauthTokenRequest): OauthResponse = api.getToken(input)
        override suspend fun getTokenSimple(input: String): String = api.getTokenSimple(input)
    }
    open class AbstractAnonymousSessionUserSessionApi(val api: Api.UserSessionApi) {
    }
    open class AbstractAnonymousSessionFcmTokenApi(val api: Api.FcmTokenApi) {
    }
    open class AbstractAnonymousSessionVehicleRelationshipApi(val api: Api.VehicleRelationshipApi) {
    }
    open class AbstractAnonymousSessionVehicleApi(val api: Api.VehicleApi) {
    }
    open class AbstractAnonymousSessionAuctionLaneApi(val api: Api.AuctionLaneApi) {
    }
    open class AbstractAnonymousSessionBidApi(val api: Api.BidApi) {
    }
    open class AbstractAnonymousSessionLiveAuctionDataApi(val api: Api.LiveAuctionDataApi) {
    }
    open class AbstractAnonymousSessionSellerTalkingPointApi(val api: Api.SellerTalkingPointApi) {
    }
    open class AbstractAnonymousSessionSavedSearchApi(val api: Api.SavedSearchApi) {
    }
    open class AbstractAnonymousSessionNotificationApi(val api: Api.NotificationApi) {
    }
    open class AbstractAnonymousSessionFakerApi(val api: Api.FakerApi) {
    }
    open class AbstractAnonymousSessionMakeApi(val api: Api.MakeApi): ClientModelRestEndpoints<Make, String> {
        override suspend fun default(): Make = api.default(null, null)
        override suspend fun query(input: Query<Make>): List<Make> = api.query(input, null, null)
        override suspend fun queryPartial(input: QueryPartial<Make>): List<Partial<Make>> = api.queryPartial(input, null, null)
        override suspend fun detail(id: String): Make = api.detail(id, null, null)
        override suspend fun insertBulk(input: List<Make>): List<Make> = api.insertBulk(input, null, null)
        override suspend fun insert(input: Make): Make = api.insert(input, null, null)
        override suspend fun upsert(id: String, input: Make): Make = api.upsert(id, input, null, null)
        override suspend fun bulkReplace(input: List<Make>): List<Make> = api.bulkReplace(input, null, null)
        override suspend fun replace(id: String, input: Make): Make = api.replace(id, input, null, null)
        override suspend fun bulkModify(input: MassModification<Make>): Int = api.bulkModify(input, null, null)
        override suspend fun modifyWithDiff(id: String, input: Modification<Make>): EntryChange<Make> = api.modifyWithDiff(id, input, null, null)
        override suspend fun modify(id: String, input: Modification<Make>): Make = api.modify(id, input, null, null)
        override suspend fun bulkDelete(input: Condition<Make>): Int = api.bulkDelete(input, null, null)
        override suspend fun delete(id: String): Unit = api.delete(id, null, null)
        override suspend fun count(input: Condition<Make>): Int = api.count(input, null, null)
        override suspend fun groupCount(input: GroupCountQuery<Make>): Map<String, Int> = api.groupCount(input, null, null)
        override suspend fun aggregate(input: AggregateQuery<Make>): Double? = api.aggregate(input, null, null)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Make>): Map<String, Double?> = api.groupAggregate(input, null, null)
    }
    open class AbstractAnonymousSessionModelApi(val api: Api.ModelApi) {
    }
    open class AbstractAnonymousSessionEarnestPaymentApi(val api: Api.EarnestPaymentApi) {
    }
    open class AbstractAnonymousSessionExternalFinancingFormApi(val api: Api.ExternalFinancingFormApi) {
    }
}

abstract class AbstractUserSession(api: Api, userToken: String, userAccessToken: suspend () -> String, masquerade: String? = null) {
    abstract val api: Api
    abstract val userToken: String
    abstract val userAccessToken: suspend () -> String
    open val masquerade: String? = null
    val user: UserSessionUserApi = UserSessionUserApi(api.user, userToken, userAccessToken, masquerade)
    val emailProof: UserSessionEmailProofApi = UserSessionEmailProofApi(api.emailProof, userToken, userAccessToken, masquerade)
    val otpSecret: UserSessionOtpSecretApi = UserSessionOtpSecretApi(api.otpSecret, userToken, userAccessToken, masquerade)
    val oneTimePasswordProof: UserSessionOneTimePasswordProofApi = UserSessionOneTimePasswordProofApi(api.oneTimePasswordProof, userToken, userAccessToken, masquerade)
    val passwordSecret: UserSessionPasswordSecretApi = UserSessionPasswordSecretApi(api.passwordSecret, userToken, userAccessToken, masquerade)
    val passwordProof: UserSessionPasswordProofApi = UserSessionPasswordProofApi(api.passwordProof, userToken, userAccessToken, masquerade)
    val userAuth: UserSessionUserAuthApi = UserSessionUserAuthApi(api.userAuth, userToken, userAccessToken, masquerade)
    val userSession: UserSessionUserSessionApi = UserSessionUserSessionApi(api.userSession, userToken, userAccessToken, masquerade)
    val fcmToken: UserSessionFcmTokenApi = UserSessionFcmTokenApi(api.fcmToken, userToken, userAccessToken, masquerade)
    val vehicleRelationship: UserSessionVehicleRelationshipApi = UserSessionVehicleRelationshipApi(api.vehicleRelationship, userToken, userAccessToken, masquerade)
    val vehicle: UserSessionVehicleApi = UserSessionVehicleApi(api.vehicle, userToken, userAccessToken, masquerade)
    val auctionLane: UserSessionAuctionLaneApi = UserSessionAuctionLaneApi(api.auctionLane, userToken, userAccessToken, masquerade)
    val bid: UserSessionBidApi = UserSessionBidApi(api.bid, userToken, userAccessToken, masquerade)
    val liveAuctionData: UserSessionLiveAuctionDataApi = UserSessionLiveAuctionDataApi(api.liveAuctionData, userToken, userAccessToken, masquerade)
    val sellerTalkingPoint: UserSessionSellerTalkingPointApi = UserSessionSellerTalkingPointApi(api.sellerTalkingPoint, userToken, userAccessToken, masquerade)
    val savedSearch: UserSessionSavedSearchApi = UserSessionSavedSearchApi(api.savedSearch, userToken, userAccessToken, masquerade)
    val notification: UserSessionNotificationApi = UserSessionNotificationApi(api.notification, userToken, userAccessToken, masquerade)
    val faker: UserSessionFakerApi = UserSessionFakerApi(api.faker, userToken, userAccessToken, masquerade)
    val make: UserSessionMakeApi = UserSessionMakeApi(api.make, userToken, userAccessToken, masquerade)
    val model: UserSessionModelApi = UserSessionModelApi(api.model, userToken, userAccessToken, masquerade)
    val earnestPayment: UserSessionEarnestPaymentApi = UserSessionEarnestPaymentApi(api.earnestPayment, userToken, userAccessToken, masquerade)
    val externalFinancingForm: UserSessionExternalFinancingFormApi = UserSessionExternalFinancingFormApi(api.externalFinancingForm, userToken, userAccessToken, masquerade)
    suspend fun startStripe(): String = api.startStripe(userAccessToken, masquerade)
    suspend fun logInViaApple(): String = api.logInViaApple()
    suspend fun logInViaGoogle(): String = api.logInViaGoogle()
    suspend fun logInViaMicrosoft(): String = api.logInViaMicrosoft()
    suspend fun uploadFileForRequest(): UploadInformation = api.uploadFileForRequest()
    suspend fun getServerHealth(): ServerHealth = api.getServerHealth(userAccessToken, masquerade)
    suspend fun bulkRequest(input: Map<String, BulkRequest>): Map<String, BulkResponse> = api.bulkRequest(input)
    suspend fun getPaymentSetupToken(): String = api.getPaymentSetupToken(userAccessToken, masquerade)
    suspend fun getPaymentPublishableKey(): String = api.getPaymentPublishableKey(userAccessToken, masquerade)
    suspend fun getPaymentMethods(): List<PaymentMethod> = api.getPaymentMethods(userAccessToken, masquerade)
    suspend fun getPaymentMethod(methodId: String): PaymentMethod = api.getPaymentMethod(methodId, userAccessToken, masquerade)
    suspend fun updatePaymentMethodName(methodId: String, input: String): PaymentMethod = api.updatePaymentMethodName(methodId, input, userAccessToken, masquerade)
    suspend fun deletePaymentMethod(methodId: String): Unit = api.deletePaymentMethod(methodId, userAccessToken, masquerade)
    suspend fun submitFinancingApplication(input: FinancingApplication): EntryChange<User> = api.submitFinancingApplication(input, userAccessToken, masquerade)
    suspend fun checkIfUserExistsByEmail(input: String): Boolean = api.checkIfUserExistsByEmail(input)
    class UserSessionUserApi(val api: Api.UserApi,val userToken:String, val userAccessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<User, UUID>, ClientModelRestEndpointsPlusWs<User, UUID> {
        override suspend fun default(): User = api.default(userAccessToken, masquerade)
        override suspend fun query(input: Query<User>): List<User> = api.query(input, userAccessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<User>): List<Partial<User>> = api.queryPartial(input, userAccessToken, masquerade)
        override suspend fun detail(id: UUID): User = api.detail(id, userAccessToken, masquerade)
        override suspend fun insertBulk(input: List<User>): List<User> = api.insertBulk(input, userAccessToken, masquerade)
        override suspend fun insert(input: User): User = api.insert(input, userAccessToken, masquerade)
        override suspend fun upsert(id: UUID, input: User): User = api.upsert(id, input, userAccessToken, masquerade)
        override suspend fun bulkReplace(input: List<User>): List<User> = api.bulkReplace(input, userAccessToken, masquerade)
        override suspend fun replace(id: UUID, input: User): User = api.replace(id, input, userAccessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<User>): Int = api.bulkModify(input, userAccessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<User>): EntryChange<User> = api.modifyWithDiff(id, input, userAccessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<User>): User = api.modify(id, input, userAccessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<User>): Int = api.bulkDelete(input, userAccessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken, masquerade)
        override suspend fun count(input: Condition<User>): Int = api.count(input, userAccessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<User>): Map<String, Int> = api.groupCount(input, userAccessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<User>): Double? = api.aggregate(input, userAccessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<User>): Map<String, Double?> = api.groupAggregate(input, userAccessToken, masquerade)
        override fun watch(): TypedWebSocket<Query<User>, ListChange<User>> = api.watch(userToken)
    }
    class UserSessionEmailProofApi(val api: Api.EmailProofApi,val userToken:String, val userAccessToken: suspend () -> String, val masquerade: String?): EmailProofClientEndpoints {
        override suspend fun beginEmailOwnershipProof(input: String): String = api.beginEmailOwnershipProof(input)
        override suspend fun proveEmailOwnership(input: FinishProof): Proof = api.proveEmailOwnership(input)
    }
    class UserSessionOtpSecretApi(val api: Api.OtpSecretApi,val userToken:String, val userAccessToken: suspend () -> String, val masquerade: String?) {
    }
    class UserSessionOneTimePasswordProofApi(val api: Api.OneTimePasswordProofApi,val userToken:String, val userAccessToken: suspend () -> String, val masquerade: String?): OneTimePasswordProofClientEndpoints {
        override suspend fun proveOTP(input: IdentificationAndPassword): Proof = api.proveOTP(input)
    }
    class UserSessionPasswordSecretApi(val api: Api.PasswordSecretApi,val userToken:String, val userAccessToken: suspend () -> String, val masquerade: String?) {
    }
    class UserSessionPasswordProofApi(val api: Api.PasswordProofApi,val userToken:String, val userAccessToken: suspend () -> String, val masquerade: String?): PasswordProofClientEndpoints {
        override suspend fun provePasswordOwnership(input: IdentificationAndPassword): Proof = api.provePasswordOwnership(input)
    }
    class UserSessionUserAuthApi(val api: Api.UserAuthApi,val userToken:String, val userAccessToken: suspend () -> String, val masquerade: String?): UserAuthClientEndpoints<UUID>, AuthenticatedUserAuthClientEndpoints<User, UUID> {
        override suspend fun logIn(input: List<Proof>): IdAndAuthMethods<UUID> = api.logIn(input)
        override suspend fun logInV2(input: LogInRequest): IdAndAuthMethods<UUID> = api.logInV2(input)
        override suspend fun checkProofs(input: List<Proof>): ProofsCheckResult<UUID> = api.checkProofs(input)
        override suspend fun openSession(input: String): String = api.openSession(input)
        override suspend fun createSubSession(input: SubSessionRequest): String = api.createSubSession(input, userAccessToken, masquerade)
        override suspend fun getToken(input: OauthTokenRequest): OauthResponse = api.getToken(input)
        override suspend fun getTokenSimple(input: String): String = api.getTokenSimple(input)
        override suspend fun getSelf(): User = api.getSelf(userAccessToken, masquerade)
        override suspend fun terminateSession(): Unit = api.terminateSession(userAccessToken, masquerade)
        override suspend fun terminateOtherSession(sessionId: UUID): Unit = api.terminateOtherSession(sessionId, userAccessToken, masquerade)
    }
    class UserSessionUserSessionApi(val api: Api.UserSessionApi,val userToken:String, val userAccessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<Session<User, UUID>, UUID> {
        override suspend fun query(input: Query<Session<User, UUID>>): List<Session<User, UUID>> = api.query(input, userAccessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<Session<User, UUID>>): List<Partial<Session<User, UUID>>> = api.queryPartial(input, userAccessToken, masquerade)
        override suspend fun detail(id: UUID): Session<User, UUID> = api.detail(id, userAccessToken, masquerade)
        override suspend fun insertBulk(input: List<Session<User, UUID>>): List<Session<User, UUID>> = api.insertBulk(input, userAccessToken, masquerade)
        override suspend fun insert(input: Session<User, UUID>): Session<User, UUID> = api.insert(input, userAccessToken, masquerade)
        override suspend fun upsert(id: UUID, input: Session<User, UUID>): Session<User, UUID> = api.upsert(id, input, userAccessToken, masquerade)
        override suspend fun bulkReplace(input: List<Session<User, UUID>>): List<Session<User, UUID>> = api.bulkReplace(input, userAccessToken, masquerade)
        override suspend fun replace(id: UUID, input: Session<User, UUID>): Session<User, UUID> = api.replace(id, input, userAccessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<Session<User, UUID>>): Int = api.bulkModify(input, userAccessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Session<User, UUID>>): EntryChange<Session<User, UUID>> = api.modifyWithDiff(id, input, userAccessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<Session<User, UUID>>): Session<User, UUID> = api.modify(id, input, userAccessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<Session<User, UUID>>): Int = api.bulkDelete(input, userAccessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken, masquerade)
        override suspend fun count(input: Condition<Session<User, UUID>>): Int = api.count(input, userAccessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<Session<User, UUID>>): Map<String, Int> = api.groupCount(input, userAccessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<Session<User, UUID>>): Double? = api.aggregate(input, userAccessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Session<User, UUID>>): Map<String, Double?> = api.groupAggregate(input, userAccessToken, masquerade)
    }
    class UserSessionFcmTokenApi(val api: Api.FcmTokenApi,val userToken:String, val userAccessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<FcmToken, String> {
        override suspend fun default(): FcmToken = api.default(userAccessToken, masquerade)
        override suspend fun query(input: Query<FcmToken>): List<FcmToken> = api.query(input, userAccessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<FcmToken>): List<Partial<FcmToken>> = api.queryPartial(input, userAccessToken, masquerade)
        override suspend fun detail(id: String): FcmToken = api.detail(id, userAccessToken, masquerade)
        override suspend fun insertBulk(input: List<FcmToken>): List<FcmToken> = api.insertBulk(input, userAccessToken, masquerade)
        override suspend fun insert(input: FcmToken): FcmToken = api.insert(input, userAccessToken, masquerade)
        override suspend fun upsert(id: String, input: FcmToken): FcmToken = api.upsert(id, input, userAccessToken, masquerade)
        override suspend fun bulkReplace(input: List<FcmToken>): List<FcmToken> = api.bulkReplace(input, userAccessToken, masquerade)
        override suspend fun replace(id: String, input: FcmToken): FcmToken = api.replace(id, input, userAccessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<FcmToken>): Int = api.bulkModify(input, userAccessToken, masquerade)
        override suspend fun modifyWithDiff(id: String, input: Modification<FcmToken>): EntryChange<FcmToken> = api.modifyWithDiff(id, input, userAccessToken, masquerade)
        override suspend fun modify(id: String, input: Modification<FcmToken>): FcmToken = api.modify(id, input, userAccessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<FcmToken>): Int = api.bulkDelete(input, userAccessToken, masquerade)
        override suspend fun delete(id: String): Unit = api.delete(id, userAccessToken, masquerade)
        override suspend fun count(input: Condition<FcmToken>): Int = api.count(input, userAccessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<FcmToken>): Map<String, Int> = api.groupCount(input, userAccessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<FcmToken>): Double? = api.aggregate(input, userAccessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<FcmToken>): Map<String, Double?> = api.groupAggregate(input, userAccessToken, masquerade)
        suspend fun testInAppNotifications(id: String): String = api.testInAppNotifications(id, userAccessToken, masquerade)
    }
    class UserSessionVehicleRelationshipApi(val api: Api.VehicleRelationshipApi,val userToken:String, val userAccessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<VehicleRelationship, UserVehiclePair> {
        override suspend fun default(): VehicleRelationship = api.default(userAccessToken, masquerade)
        override suspend fun query(input: Query<VehicleRelationship>): List<VehicleRelationship> = api.query(input, userAccessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<VehicleRelationship>): List<Partial<VehicleRelationship>> = api.queryPartial(input, userAccessToken, masquerade)
        override suspend fun detail(id: UserVehiclePair): VehicleRelationship = api.detail(id, userAccessToken, masquerade)
        override suspend fun insertBulk(input: List<VehicleRelationship>): List<VehicleRelationship> = api.insertBulk(input, userAccessToken, masquerade)
        override suspend fun insert(input: VehicleRelationship): VehicleRelationship = api.insert(input, userAccessToken, masquerade)
        override suspend fun upsert(id: UserVehiclePair, input: VehicleRelationship): VehicleRelationship = api.upsert(id, input, userAccessToken, masquerade)
        override suspend fun bulkReplace(input: List<VehicleRelationship>): List<VehicleRelationship> = api.bulkReplace(input, userAccessToken, masquerade)
        override suspend fun replace(id: UserVehiclePair, input: VehicleRelationship): VehicleRelationship = api.replace(id, input, userAccessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<VehicleRelationship>): Int = api.bulkModify(input, userAccessToken, masquerade)
        override suspend fun modifyWithDiff(id: UserVehiclePair, input: Modification<VehicleRelationship>): EntryChange<VehicleRelationship> = api.modifyWithDiff(id, input, userAccessToken, masquerade)
        override suspend fun modify(id: UserVehiclePair, input: Modification<VehicleRelationship>): VehicleRelationship = api.modify(id, input, userAccessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<VehicleRelationship>): Int = api.bulkDelete(input, userAccessToken, masquerade)
        override suspend fun delete(id: UserVehiclePair): Unit = api.delete(id, userAccessToken, masquerade)
        override suspend fun count(input: Condition<VehicleRelationship>): Int = api.count(input, userAccessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<VehicleRelationship>): Map<String, Int> = api.groupCount(input, userAccessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<VehicleRelationship>): Double? = api.aggregate(input, userAccessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<VehicleRelationship>): Map<String, Double?> = api.groupAggregate(input, userAccessToken, masquerade)
    }
    class UserSessionVehicleApi(val api: Api.VehicleApi,val userToken:String, val userAccessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<Vehicle, UUID>, ClientModelRestEndpointsPlusUpdatesWebsocket<Vehicle, UUID> {
        override suspend fun default(): Vehicle = api.default(userAccessToken, masquerade)
        override suspend fun query(input: Query<Vehicle>): List<Vehicle> = api.query(input, userAccessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<Vehicle>): List<Partial<Vehicle>> = api.queryPartial(input, userAccessToken, masquerade)
        override suspend fun detail(id: UUID): Vehicle = api.detail(id, userAccessToken, masquerade)
        override suspend fun insertBulk(input: List<Vehicle>): List<Vehicle> = api.insertBulk(input, userAccessToken, masquerade)
        override suspend fun insert(input: Vehicle): Vehicle = api.insert(input, userAccessToken, masquerade)
        override suspend fun upsert(id: UUID, input: Vehicle): Vehicle = api.upsert(id, input, userAccessToken, masquerade)
        override suspend fun bulkReplace(input: List<Vehicle>): List<Vehicle> = api.bulkReplace(input, userAccessToken, masquerade)
        override suspend fun replace(id: UUID, input: Vehicle): Vehicle = api.replace(id, input, userAccessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<Vehicle>): Int = api.bulkModify(input, userAccessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Vehicle>): EntryChange<Vehicle> = api.modifyWithDiff(id, input, userAccessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<Vehicle>): Vehicle = api.modify(id, input, userAccessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<Vehicle>): Int = api.bulkDelete(input, userAccessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken, masquerade)
        override suspend fun count(input: Condition<Vehicle>): Int = api.count(input, userAccessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<Vehicle>): Map<String, Int> = api.groupCount(input, userAccessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<Vehicle>): Double? = api.aggregate(input, userAccessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Vehicle>): Map<String, Double?> = api.groupAggregate(input, userAccessToken, masquerade)
        suspend fun acceptInitialOffer(id: UUID): Vehicle = api.acceptInitialOffer(id, userAccessToken, masquerade)
        override fun updates(): TypedWebSocket<Condition<Vehicle>, CollectionUpdates<Vehicle, UUID>> = api.updates(userToken)
    }
    class UserSessionAuctionLaneApi(val api: Api.AuctionLaneApi,val userToken:String, val userAccessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<AuctionLane, UUID> {
        override suspend fun default(): AuctionLane = api.default(userAccessToken, masquerade)
        override suspend fun query(input: Query<AuctionLane>): List<AuctionLane> = api.query(input, userAccessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<AuctionLane>): List<Partial<AuctionLane>> = api.queryPartial(input, userAccessToken, masquerade)
        override suspend fun detail(id: UUID): AuctionLane = api.detail(id, userAccessToken, masquerade)
        override suspend fun insertBulk(input: List<AuctionLane>): List<AuctionLane> = api.insertBulk(input, userAccessToken, masquerade)
        override suspend fun insert(input: AuctionLane): AuctionLane = api.insert(input, userAccessToken, masquerade)
        override suspend fun upsert(id: UUID, input: AuctionLane): AuctionLane = api.upsert(id, input, userAccessToken, masquerade)
        override suspend fun bulkReplace(input: List<AuctionLane>): List<AuctionLane> = api.bulkReplace(input, userAccessToken, masquerade)
        override suspend fun replace(id: UUID, input: AuctionLane): AuctionLane = api.replace(id, input, userAccessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<AuctionLane>): Int = api.bulkModify(input, userAccessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<AuctionLane>): EntryChange<AuctionLane> = api.modifyWithDiff(id, input, userAccessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<AuctionLane>): AuctionLane = api.modify(id, input, userAccessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<AuctionLane>): Int = api.bulkDelete(input, userAccessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken, masquerade)
        override suspend fun count(input: Condition<AuctionLane>): Int = api.count(input, userAccessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<AuctionLane>): Map<String, Int> = api.groupCount(input, userAccessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<AuctionLane>): Double? = api.aggregate(input, userAccessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<AuctionLane>): Map<String, Double?> = api.groupAggregate(input, userAccessToken, masquerade)
    }
    class UserSessionBidApi(val api: Api.BidApi,val userToken:String, val userAccessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<Bid, UUID>, ClientModelRestEndpointsPlusUpdatesWebsocket<Bid, UUID> {
        override suspend fun default(): Bid = api.default(userAccessToken, masquerade)
        override suspend fun query(input: Query<Bid>): List<Bid> = api.query(input, userAccessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<Bid>): List<Partial<Bid>> = api.queryPartial(input, userAccessToken, masquerade)
        override suspend fun detail(id: UUID): Bid = api.detail(id, userAccessToken, masquerade)
        override suspend fun insertBulk(input: List<Bid>): List<Bid> = api.insertBulk(input, userAccessToken, masquerade)
        override suspend fun insert(input: Bid): Bid = api.insert(input, userAccessToken, masquerade)
        override suspend fun upsert(id: UUID, input: Bid): Bid = api.upsert(id, input, userAccessToken, masquerade)
        override suspend fun bulkReplace(input: List<Bid>): List<Bid> = api.bulkReplace(input, userAccessToken, masquerade)
        override suspend fun replace(id: UUID, input: Bid): Bid = api.replace(id, input, userAccessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<Bid>): Int = api.bulkModify(input, userAccessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Bid>): EntryChange<Bid> = api.modifyWithDiff(id, input, userAccessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<Bid>): Bid = api.modify(id, input, userAccessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<Bid>): Int = api.bulkDelete(input, userAccessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken, masquerade)
        override suspend fun count(input: Condition<Bid>): Int = api.count(input, userAccessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<Bid>): Map<String, Int> = api.groupCount(input, userAccessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<Bid>): Double? = api.aggregate(input, userAccessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Bid>): Map<String, Double?> = api.groupAggregate(input, userAccessToken, masquerade)
        override fun updates(): TypedWebSocket<Condition<Bid>, CollectionUpdates<Bid, UUID>> = api.updates(userToken)
    }
    class UserSessionLiveAuctionDataApi(val api: Api.LiveAuctionDataApi,val userToken:String, val userAccessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<LiveAuctionData, UUID>, ClientModelRestEndpointsPlusUpdatesWebsocket<LiveAuctionData, UUID> {
        override suspend fun default(): LiveAuctionData = api.default(userAccessToken, masquerade)
        override suspend fun query(input: Query<LiveAuctionData>): List<LiveAuctionData> = api.query(input, userAccessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<LiveAuctionData>): List<Partial<LiveAuctionData>> = api.queryPartial(input, userAccessToken, masquerade)
        override suspend fun detail(id: UUID): LiveAuctionData = api.detail(id, userAccessToken, masquerade)
        override suspend fun insertBulk(input: List<LiveAuctionData>): List<LiveAuctionData> = api.insertBulk(input, userAccessToken, masquerade)
        override suspend fun insert(input: LiveAuctionData): LiveAuctionData = api.insert(input, userAccessToken, masquerade)
        override suspend fun upsert(id: UUID, input: LiveAuctionData): LiveAuctionData = api.upsert(id, input, userAccessToken, masquerade)
        override suspend fun bulkReplace(input: List<LiveAuctionData>): List<LiveAuctionData> = api.bulkReplace(input, userAccessToken, masquerade)
        override suspend fun replace(id: UUID, input: LiveAuctionData): LiveAuctionData = api.replace(id, input, userAccessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<LiveAuctionData>): Int = api.bulkModify(input, userAccessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<LiveAuctionData>): EntryChange<LiveAuctionData> = api.modifyWithDiff(id, input, userAccessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<LiveAuctionData>): LiveAuctionData = api.modify(id, input, userAccessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<LiveAuctionData>): Int = api.bulkDelete(input, userAccessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken, masquerade)
        override suspend fun count(input: Condition<LiveAuctionData>): Int = api.count(input, userAccessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<LiveAuctionData>): Map<String, Int> = api.groupCount(input, userAccessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<LiveAuctionData>): Double? = api.aggregate(input, userAccessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<LiveAuctionData>): Map<String, Double?> = api.groupAggregate(input, userAccessToken, masquerade)
        suspend fun start(id: UUID): LiveAuctionData? = api.start(id, userAccessToken, masquerade)
        suspend fun notifyHover(id: UUID, dealership: UUID): Unit = api.notifyHover(id, dealership, userAccessToken, masquerade)
        suspend fun notifyUnhover(id: UUID, dealership: UUID): Unit = api.notifyUnhover(id, dealership, userAccessToken, masquerade)
        suspend fun notifyJoin(id: UUID, dealership: UUID): Unit = api.notifyJoin(id, dealership, userAccessToken, masquerade)
        suspend fun notifyLeave(id: UUID, dealership: UUID): Unit = api.notifyLeave(id, dealership, userAccessToken, masquerade)
        override fun updates(): TypedWebSocket<Condition<LiveAuctionData>, CollectionUpdates<LiveAuctionData, UUID>> = api.updates(userToken)
    }
    class UserSessionSellerTalkingPointApi(val api: Api.SellerTalkingPointApi,val userToken:String, val userAccessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<SellerTalkingPoint, UUID>, ClientModelRestEndpointsPlusUpdatesWebsocket<SellerTalkingPoint, UUID> {
        override suspend fun default(): SellerTalkingPoint = api.default(userAccessToken, masquerade)
        override suspend fun query(input: Query<SellerTalkingPoint>): List<SellerTalkingPoint> = api.query(input, userAccessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<SellerTalkingPoint>): List<Partial<SellerTalkingPoint>> = api.queryPartial(input, userAccessToken, masquerade)
        override suspend fun detail(id: UUID): SellerTalkingPoint = api.detail(id, userAccessToken, masquerade)
        override suspend fun insertBulk(input: List<SellerTalkingPoint>): List<SellerTalkingPoint> = api.insertBulk(input, userAccessToken, masquerade)
        override suspend fun insert(input: SellerTalkingPoint): SellerTalkingPoint = api.insert(input, userAccessToken, masquerade)
        override suspend fun upsert(id: UUID, input: SellerTalkingPoint): SellerTalkingPoint = api.upsert(id, input, userAccessToken, masquerade)
        override suspend fun bulkReplace(input: List<SellerTalkingPoint>): List<SellerTalkingPoint> = api.bulkReplace(input, userAccessToken, masquerade)
        override suspend fun replace(id: UUID, input: SellerTalkingPoint): SellerTalkingPoint = api.replace(id, input, userAccessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<SellerTalkingPoint>): Int = api.bulkModify(input, userAccessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<SellerTalkingPoint>): EntryChange<SellerTalkingPoint> = api.modifyWithDiff(id, input, userAccessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<SellerTalkingPoint>): SellerTalkingPoint = api.modify(id, input, userAccessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<SellerTalkingPoint>): Int = api.bulkDelete(input, userAccessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken, masquerade)
        override suspend fun count(input: Condition<SellerTalkingPoint>): Int = api.count(input, userAccessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<SellerTalkingPoint>): Map<String, Int> = api.groupCount(input, userAccessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<SellerTalkingPoint>): Double? = api.aggregate(input, userAccessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<SellerTalkingPoint>): Map<String, Double?> = api.groupAggregate(input, userAccessToken, masquerade)
        override fun updates(): TypedWebSocket<Condition<SellerTalkingPoint>, CollectionUpdates<SellerTalkingPoint, UUID>> = api.updates(userToken)
    }
    class UserSessionSavedSearchApi(val api: Api.SavedSearchApi,val userToken:String, val userAccessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<SavedSearch, UUID> {
        override suspend fun default(): SavedSearch = api.default(userAccessToken, masquerade)
        override suspend fun query(input: Query<SavedSearch>): List<SavedSearch> = api.query(input, userAccessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<SavedSearch>): List<Partial<SavedSearch>> = api.queryPartial(input, userAccessToken, masquerade)
        override suspend fun detail(id: UUID): SavedSearch = api.detail(id, userAccessToken, masquerade)
        override suspend fun insertBulk(input: List<SavedSearch>): List<SavedSearch> = api.insertBulk(input, userAccessToken, masquerade)
        override suspend fun insert(input: SavedSearch): SavedSearch = api.insert(input, userAccessToken, masquerade)
        override suspend fun upsert(id: UUID, input: SavedSearch): SavedSearch = api.upsert(id, input, userAccessToken, masquerade)
        override suspend fun bulkReplace(input: List<SavedSearch>): List<SavedSearch> = api.bulkReplace(input, userAccessToken, masquerade)
        override suspend fun replace(id: UUID, input: SavedSearch): SavedSearch = api.replace(id, input, userAccessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<SavedSearch>): Int = api.bulkModify(input, userAccessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<SavedSearch>): EntryChange<SavedSearch> = api.modifyWithDiff(id, input, userAccessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<SavedSearch>): SavedSearch = api.modify(id, input, userAccessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<SavedSearch>): Int = api.bulkDelete(input, userAccessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken, masquerade)
        override suspend fun count(input: Condition<SavedSearch>): Int = api.count(input, userAccessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<SavedSearch>): Map<String, Int> = api.groupCount(input, userAccessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<SavedSearch>): Double? = api.aggregate(input, userAccessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<SavedSearch>): Map<String, Double?> = api.groupAggregate(input, userAccessToken, masquerade)
    }
    class UserSessionNotificationApi(val api: Api.NotificationApi,val userToken:String, val userAccessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<Notification, UUID> {
        override suspend fun default(): Notification = api.default(userAccessToken, masquerade)
        override suspend fun query(input: Query<Notification>): List<Notification> = api.query(input, userAccessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<Notification>): List<Partial<Notification>> = api.queryPartial(input, userAccessToken, masquerade)
        override suspend fun detail(id: UUID): Notification = api.detail(id, userAccessToken, masquerade)
        override suspend fun insertBulk(input: List<Notification>): List<Notification> = api.insertBulk(input, userAccessToken, masquerade)
        override suspend fun insert(input: Notification): Notification = api.insert(input, userAccessToken, masquerade)
        override suspend fun upsert(id: UUID, input: Notification): Notification = api.upsert(id, input, userAccessToken, masquerade)
        override suspend fun bulkReplace(input: List<Notification>): List<Notification> = api.bulkReplace(input, userAccessToken, masquerade)
        override suspend fun replace(id: UUID, input: Notification): Notification = api.replace(id, input, userAccessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<Notification>): Int = api.bulkModify(input, userAccessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Notification>): EntryChange<Notification> = api.modifyWithDiff(id, input, userAccessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<Notification>): Notification = api.modify(id, input, userAccessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<Notification>): Int = api.bulkDelete(input, userAccessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken, masquerade)
        override suspend fun count(input: Condition<Notification>): Int = api.count(input, userAccessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<Notification>): Map<String, Int> = api.groupCount(input, userAccessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<Notification>): Double? = api.aggregate(input, userAccessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Notification>): Map<String, Double?> = api.groupAggregate(input, userAccessToken, masquerade)
    }
    class UserSessionFakerApi(val api: Api.FakerApi,val userToken:String, val userAccessToken: suspend () -> String, val masquerade: String?) {
        suspend fun saveState(): String = api.saveState(userAccessToken, masquerade)
        suspend fun loadState(): String = api.loadState(userAccessToken, masquerade)
        suspend fun resetTestData(input: ResetDataRequest): String = api.resetTestData(input, userAccessToken, masquerade)
    }
    class UserSessionMakeApi(val api: Api.MakeApi,val userToken:String, val userAccessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<Make, String> {
        override suspend fun default(): Make = api.default(userAccessToken, masquerade)
        override suspend fun query(input: Query<Make>): List<Make> = api.query(input, userAccessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<Make>): List<Partial<Make>> = api.queryPartial(input, userAccessToken, masquerade)
        override suspend fun detail(id: String): Make = api.detail(id, userAccessToken, masquerade)
        override suspend fun insertBulk(input: List<Make>): List<Make> = api.insertBulk(input, userAccessToken, masquerade)
        override suspend fun insert(input: Make): Make = api.insert(input, userAccessToken, masquerade)
        override suspend fun upsert(id: String, input: Make): Make = api.upsert(id, input, userAccessToken, masquerade)
        override suspend fun bulkReplace(input: List<Make>): List<Make> = api.bulkReplace(input, userAccessToken, masquerade)
        override suspend fun replace(id: String, input: Make): Make = api.replace(id, input, userAccessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<Make>): Int = api.bulkModify(input, userAccessToken, masquerade)
        override suspend fun modifyWithDiff(id: String, input: Modification<Make>): EntryChange<Make> = api.modifyWithDiff(id, input, userAccessToken, masquerade)
        override suspend fun modify(id: String, input: Modification<Make>): Make = api.modify(id, input, userAccessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<Make>): Int = api.bulkDelete(input, userAccessToken, masquerade)
        override suspend fun delete(id: String): Unit = api.delete(id, userAccessToken, masquerade)
        override suspend fun count(input: Condition<Make>): Int = api.count(input, userAccessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<Make>): Map<String, Int> = api.groupCount(input, userAccessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<Make>): Double? = api.aggregate(input, userAccessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Make>): Map<String, Double?> = api.groupAggregate(input, userAccessToken, masquerade)
        suspend fun sync(): List<Make> = api.sync(userAccessToken, masquerade)
        suspend fun syncAll(): Unit = api.syncAll(userAccessToken, masquerade)
    }
    class UserSessionModelApi(val api: Api.ModelApi,val userToken:String, val userAccessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<Model, String> {
        override suspend fun default(): Model = api.default(userAccessToken, masquerade)
        override suspend fun query(input: Query<Model>): List<Model> = api.query(input, userAccessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<Model>): List<Partial<Model>> = api.queryPartial(input, userAccessToken, masquerade)
        override suspend fun detail(id: String): Model = api.detail(id, userAccessToken, masquerade)
        override suspend fun insertBulk(input: List<Model>): List<Model> = api.insertBulk(input, userAccessToken, masquerade)
        override suspend fun insert(input: Model): Model = api.insert(input, userAccessToken, masquerade)
        override suspend fun upsert(id: String, input: Model): Model = api.upsert(id, input, userAccessToken, masquerade)
        override suspend fun bulkReplace(input: List<Model>): List<Model> = api.bulkReplace(input, userAccessToken, masquerade)
        override suspend fun replace(id: String, input: Model): Model = api.replace(id, input, userAccessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<Model>): Int = api.bulkModify(input, userAccessToken, masquerade)
        override suspend fun modifyWithDiff(id: String, input: Modification<Model>): EntryChange<Model> = api.modifyWithDiff(id, input, userAccessToken, masquerade)
        override suspend fun modify(id: String, input: Modification<Model>): Model = api.modify(id, input, userAccessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<Model>): Int = api.bulkDelete(input, userAccessToken, masquerade)
        override suspend fun delete(id: String): Unit = api.delete(id, userAccessToken, masquerade)
        override suspend fun count(input: Condition<Model>): Int = api.count(input, userAccessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<Model>): Map<String, Int> = api.groupCount(input, userAccessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<Model>): Double? = api.aggregate(input, userAccessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Model>): Map<String, Double?> = api.groupAggregate(input, userAccessToken, masquerade)
        suspend fun sync(make: String): List<Model> = api.sync(make, userAccessToken, masquerade)
    }
    class UserSessionEarnestPaymentApi(val api: Api.EarnestPaymentApi,val userToken:String, val userAccessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<EarnestPayment, UUID> {
        override suspend fun default(): EarnestPayment = api.default(userAccessToken, masquerade)
        override suspend fun query(input: Query<EarnestPayment>): List<EarnestPayment> = api.query(input, userAccessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<EarnestPayment>): List<Partial<EarnestPayment>> = api.queryPartial(input, userAccessToken, masquerade)
        override suspend fun detail(id: UUID): EarnestPayment = api.detail(id, userAccessToken, masquerade)
        override suspend fun insertBulk(input: List<EarnestPayment>): List<EarnestPayment> = api.insertBulk(input, userAccessToken, masquerade)
        override suspend fun insert(input: EarnestPayment): EarnestPayment = api.insert(input, userAccessToken, masquerade)
        override suspend fun upsert(id: UUID, input: EarnestPayment): EarnestPayment = api.upsert(id, input, userAccessToken, masquerade)
        override suspend fun bulkReplace(input: List<EarnestPayment>): List<EarnestPayment> = api.bulkReplace(input, userAccessToken, masquerade)
        override suspend fun replace(id: UUID, input: EarnestPayment): EarnestPayment = api.replace(id, input, userAccessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<EarnestPayment>): Int = api.bulkModify(input, userAccessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<EarnestPayment>): EntryChange<EarnestPayment> = api.modifyWithDiff(id, input, userAccessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<EarnestPayment>): EarnestPayment = api.modify(id, input, userAccessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<EarnestPayment>): Int = api.bulkDelete(input, userAccessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken, masquerade)
        override suspend fun count(input: Condition<EarnestPayment>): Int = api.count(input, userAccessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<EarnestPayment>): Map<String, Int> = api.groupCount(input, userAccessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<EarnestPayment>): Double? = api.aggregate(input, userAccessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<EarnestPayment>): Map<String, Double?> = api.groupAggregate(input, userAccessToken, masquerade)
    }
    class UserSessionExternalFinancingFormApi(val api: Api.ExternalFinancingFormApi,val userToken:String, val userAccessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<ExternalFinancingForm, UUID> {
        override suspend fun default(): ExternalFinancingForm = api.default(userAccessToken, masquerade)
        override suspend fun query(input: Query<ExternalFinancingForm>): List<ExternalFinancingForm> = api.query(input, userAccessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<ExternalFinancingForm>): List<Partial<ExternalFinancingForm>> = api.queryPartial(input, userAccessToken, masquerade)
        override suspend fun detail(id: UUID): ExternalFinancingForm = api.detail(id, userAccessToken, masquerade)
        override suspend fun insertBulk(input: List<ExternalFinancingForm>): List<ExternalFinancingForm> = api.insertBulk(input, userAccessToken, masquerade)
        override suspend fun insert(input: ExternalFinancingForm): ExternalFinancingForm = api.insert(input, userAccessToken, masquerade)
        override suspend fun upsert(id: UUID, input: ExternalFinancingForm): ExternalFinancingForm = api.upsert(id, input, userAccessToken, masquerade)
        override suspend fun bulkReplace(input: List<ExternalFinancingForm>): List<ExternalFinancingForm> = api.bulkReplace(input, userAccessToken, masquerade)
        override suspend fun replace(id: UUID, input: ExternalFinancingForm): ExternalFinancingForm = api.replace(id, input, userAccessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<ExternalFinancingForm>): Int = api.bulkModify(input, userAccessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<ExternalFinancingForm>): EntryChange<ExternalFinancingForm> = api.modifyWithDiff(id, input, userAccessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<ExternalFinancingForm>): ExternalFinancingForm = api.modify(id, input, userAccessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<ExternalFinancingForm>): Int = api.bulkDelete(input, userAccessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken, masquerade)
        override suspend fun count(input: Condition<ExternalFinancingForm>): Int = api.count(input, userAccessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<ExternalFinancingForm>): Map<String, Int> = api.groupCount(input, userAccessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<ExternalFinancingForm>): Double? = api.aggregate(input, userAccessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<ExternalFinancingForm>): Map<String, Double?> = api.groupAggregate(input, userAccessToken, masquerade)
    }
}

abstract class AbstractAnySession(api: Api, anyToken: String, anyAccessToken: suspend () -> String, masquerade: String? = null) {
    abstract val api: Api
    abstract val anyToken: String
    abstract val anyAccessToken: suspend () -> String
    open val masquerade: String? = null
    val user: AnySessionUserApi = AnySessionUserApi(api.user, anyToken, anyAccessToken, masquerade)
    val emailProof: AnySessionEmailProofApi = AnySessionEmailProofApi(api.emailProof, anyToken, anyAccessToken, masquerade)
    val otpSecret: AnySessionOtpSecretApi = AnySessionOtpSecretApi(api.otpSecret, anyToken, anyAccessToken, masquerade)
    val oneTimePasswordProof: AnySessionOneTimePasswordProofApi = AnySessionOneTimePasswordProofApi(api.oneTimePasswordProof, anyToken, anyAccessToken, masquerade)
    val passwordSecret: AnySessionPasswordSecretApi = AnySessionPasswordSecretApi(api.passwordSecret, anyToken, anyAccessToken, masquerade)
    val passwordProof: AnySessionPasswordProofApi = AnySessionPasswordProofApi(api.passwordProof, anyToken, anyAccessToken, masquerade)
    val userAuth: AnySessionUserAuthApi = AnySessionUserAuthApi(api.userAuth, anyToken, anyAccessToken, masquerade)
    val userSession: AnySessionUserSessionApi = AnySessionUserSessionApi(api.userSession, anyToken, anyAccessToken, masquerade)
    val fcmToken: AnySessionFcmTokenApi = AnySessionFcmTokenApi(api.fcmToken, anyToken, anyAccessToken, masquerade)
    val vehicleRelationship: AnySessionVehicleRelationshipApi = AnySessionVehicleRelationshipApi(api.vehicleRelationship, anyToken, anyAccessToken, masquerade)
    val vehicle: AnySessionVehicleApi = AnySessionVehicleApi(api.vehicle, anyToken, anyAccessToken, masquerade)
    val auctionLane: AnySessionAuctionLaneApi = AnySessionAuctionLaneApi(api.auctionLane, anyToken, anyAccessToken, masquerade)
    val bid: AnySessionBidApi = AnySessionBidApi(api.bid, anyToken, anyAccessToken, masquerade)
    val liveAuctionData: AnySessionLiveAuctionDataApi = AnySessionLiveAuctionDataApi(api.liveAuctionData, anyToken, anyAccessToken, masquerade)
    val sellerTalkingPoint: AnySessionSellerTalkingPointApi = AnySessionSellerTalkingPointApi(api.sellerTalkingPoint, anyToken, anyAccessToken, masquerade)
    val savedSearch: AnySessionSavedSearchApi = AnySessionSavedSearchApi(api.savedSearch, anyToken, anyAccessToken, masquerade)
    val notification: AnySessionNotificationApi = AnySessionNotificationApi(api.notification, anyToken, anyAccessToken, masquerade)
    val faker: AnySessionFakerApi = AnySessionFakerApi(api.faker, anyToken, anyAccessToken, masquerade)
    val make: AnySessionMakeApi = AnySessionMakeApi(api.make, anyToken, anyAccessToken, masquerade)
    val model: AnySessionModelApi = AnySessionModelApi(api.model, anyToken, anyAccessToken, masquerade)
    val earnestPayment: AnySessionEarnestPaymentApi = AnySessionEarnestPaymentApi(api.earnestPayment, anyToken, anyAccessToken, masquerade)
    val externalFinancingForm: AnySessionExternalFinancingFormApi = AnySessionExternalFinancingFormApi(api.externalFinancingForm, anyToken, anyAccessToken, masquerade)
    suspend fun logInViaApple(): String = api.logInViaApple()
    suspend fun logInViaGoogle(): String = api.logInViaGoogle()
    suspend fun logInViaMicrosoft(): String = api.logInViaMicrosoft()
    suspend fun uploadFileForRequest(): UploadInformation = api.uploadFileForRequest()
    suspend fun bulkRequest(input: Map<String, BulkRequest>): Map<String, BulkResponse> = api.bulkRequest(input)
    suspend fun checkIfUserExistsByEmail(input: String): Boolean = api.checkIfUserExistsByEmail(input)
    class AnySessionUserApi(val api: Api.UserApi,val anyToken:String, val anyAccessToken: suspend () -> String, val masquerade: String?) {
    }
    class AnySessionEmailProofApi(val api: Api.EmailProofApi,val anyToken:String, val anyAccessToken: suspend () -> String, val masquerade: String?): EmailProofClientEndpoints {
        override suspend fun beginEmailOwnershipProof(input: String): String = api.beginEmailOwnershipProof(input)
        override suspend fun proveEmailOwnership(input: FinishProof): Proof = api.proveEmailOwnership(input)
    }
    class AnySessionOtpSecretApi(val api: Api.OtpSecretApi,val anyToken:String, val anyAccessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<OtpSecret, UUID> {
        override suspend fun query(input: Query<OtpSecret>): List<OtpSecret> = api.query(input, anyAccessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<OtpSecret>): List<Partial<OtpSecret>> = api.queryPartial(input, anyAccessToken, masquerade)
        override suspend fun detail(id: UUID): OtpSecret = api.detail(id, anyAccessToken, masquerade)
        override suspend fun insertBulk(input: List<OtpSecret>): List<OtpSecret> = api.insertBulk(input, anyAccessToken, masquerade)
        override suspend fun insert(input: OtpSecret): OtpSecret = api.insert(input, anyAccessToken, masquerade)
        override suspend fun upsert(id: UUID, input: OtpSecret): OtpSecret = api.upsert(id, input, anyAccessToken, masquerade)
        override suspend fun bulkReplace(input: List<OtpSecret>): List<OtpSecret> = api.bulkReplace(input, anyAccessToken, masquerade)
        override suspend fun replace(id: UUID, input: OtpSecret): OtpSecret = api.replace(id, input, anyAccessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<OtpSecret>): Int = api.bulkModify(input, anyAccessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<OtpSecret>): EntryChange<OtpSecret> = api.modifyWithDiff(id, input, anyAccessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<OtpSecret>): OtpSecret = api.modify(id, input, anyAccessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<OtpSecret>): Int = api.bulkDelete(input, anyAccessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, anyAccessToken, masquerade)
        override suspend fun count(input: Condition<OtpSecret>): Int = api.count(input, anyAccessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<OtpSecret>): Map<String, Int> = api.groupCount(input, anyAccessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<OtpSecret>): Double? = api.aggregate(input, anyAccessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<OtpSecret>): Map<String, Double?> = api.groupAggregate(input, anyAccessToken, masquerade)
    }
    class AnySessionOneTimePasswordProofApi(val api: Api.OneTimePasswordProofApi,val anyToken:String, val anyAccessToken: suspend () -> String, val masquerade: String?): AuthenticatedOneTimePasswordProofClientEndpoints, OneTimePasswordProofClientEndpoints {
        override suspend fun establishOneTimePassword(input: EstablishOtp): String = api.establishOneTimePassword(input, anyAccessToken, masquerade)
        override suspend fun proveOTP(input: IdentificationAndPassword): Proof = api.proveOTP(input)
    }
    class AnySessionPasswordSecretApi(val api: Api.PasswordSecretApi,val anyToken:String, val anyAccessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<PasswordSecret, UUID> {
        override suspend fun query(input: Query<PasswordSecret>): List<PasswordSecret> = api.query(input, anyAccessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<PasswordSecret>): List<Partial<PasswordSecret>> = api.queryPartial(input, anyAccessToken, masquerade)
        override suspend fun detail(id: UUID): PasswordSecret = api.detail(id, anyAccessToken, masquerade)
        override suspend fun insertBulk(input: List<PasswordSecret>): List<PasswordSecret> = api.insertBulk(input, anyAccessToken, masquerade)
        override suspend fun insert(input: PasswordSecret): PasswordSecret = api.insert(input, anyAccessToken, masquerade)
        override suspend fun upsert(id: UUID, input: PasswordSecret): PasswordSecret = api.upsert(id, input, anyAccessToken, masquerade)
        override suspend fun bulkReplace(input: List<PasswordSecret>): List<PasswordSecret> = api.bulkReplace(input, anyAccessToken, masquerade)
        override suspend fun replace(id: UUID, input: PasswordSecret): PasswordSecret = api.replace(id, input, anyAccessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<PasswordSecret>): Int = api.bulkModify(input, anyAccessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<PasswordSecret>): EntryChange<PasswordSecret> = api.modifyWithDiff(id, input, anyAccessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<PasswordSecret>): PasswordSecret = api.modify(id, input, anyAccessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<PasswordSecret>): Int = api.bulkDelete(input, anyAccessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, anyAccessToken, masquerade)
        override suspend fun count(input: Condition<PasswordSecret>): Int = api.count(input, anyAccessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<PasswordSecret>): Map<String, Int> = api.groupCount(input, anyAccessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<PasswordSecret>): Double? = api.aggregate(input, anyAccessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<PasswordSecret>): Map<String, Double?> = api.groupAggregate(input, anyAccessToken, masquerade)
    }
    class AnySessionPasswordProofApi(val api: Api.PasswordProofApi,val anyToken:String, val anyAccessToken: suspend () -> String, val masquerade: String?): AuthenticatedPasswordProofClientEndpoints, PasswordProofClientEndpoints {
        override suspend fun establishPassword(input: EstablishPassword): Unit = api.establishPassword(input, anyAccessToken, masquerade)
        override suspend fun provePasswordOwnership(input: IdentificationAndPassword): Proof = api.provePasswordOwnership(input)
    }
    class AnySessionUserAuthApi(val api: Api.UserAuthApi,val anyToken:String, val anyAccessToken: suspend () -> String, val masquerade: String?): UserAuthClientEndpoints<UUID> {
        override suspend fun logIn(input: List<Proof>): IdAndAuthMethods<UUID> = api.logIn(input)
        override suspend fun logInV2(input: LogInRequest): IdAndAuthMethods<UUID> = api.logInV2(input)
        override suspend fun checkProofs(input: List<Proof>): ProofsCheckResult<UUID> = api.checkProofs(input)
        override suspend fun openSession(input: String): String = api.openSession(input)
        override suspend fun getToken(input: OauthTokenRequest): OauthResponse = api.getToken(input)
        override suspend fun getTokenSimple(input: String): String = api.getTokenSimple(input)
    }
    class AnySessionUserSessionApi(val api: Api.UserSessionApi,val anyToken:String, val anyAccessToken: suspend () -> String, val masquerade: String?) {
    }
    class AnySessionFcmTokenApi(val api: Api.FcmTokenApi,val anyToken:String, val anyAccessToken: suspend () -> String, val masquerade: String?) {
    }
    class AnySessionVehicleRelationshipApi(val api: Api.VehicleRelationshipApi,val anyToken:String, val anyAccessToken: suspend () -> String, val masquerade: String?) {
    }
    class AnySessionVehicleApi(val api: Api.VehicleApi,val anyToken:String, val anyAccessToken: suspend () -> String, val masquerade: String?) {
    }
    class AnySessionAuctionLaneApi(val api: Api.AuctionLaneApi,val anyToken:String, val anyAccessToken: suspend () -> String, val masquerade: String?) {
    }
    class AnySessionBidApi(val api: Api.BidApi,val anyToken:String, val anyAccessToken: suspend () -> String, val masquerade: String?) {
    }
    class AnySessionLiveAuctionDataApi(val api: Api.LiveAuctionDataApi,val anyToken:String, val anyAccessToken: suspend () -> String, val masquerade: String?) {
    }
    class AnySessionSellerTalkingPointApi(val api: Api.SellerTalkingPointApi,val anyToken:String, val anyAccessToken: suspend () -> String, val masquerade: String?) {
    }
    class AnySessionSavedSearchApi(val api: Api.SavedSearchApi,val anyToken:String, val anyAccessToken: suspend () -> String, val masquerade: String?) {
    }
    class AnySessionNotificationApi(val api: Api.NotificationApi,val anyToken:String, val anyAccessToken: suspend () -> String, val masquerade: String?) {
    }
    class AnySessionFakerApi(val api: Api.FakerApi,val anyToken:String, val anyAccessToken: suspend () -> String, val masquerade: String?) {
    }
    class AnySessionMakeApi(val api: Api.MakeApi,val anyToken:String, val anyAccessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<Make, String> {
        override suspend fun default(): Make = api.default(null, null)
        override suspend fun query(input: Query<Make>): List<Make> = api.query(input, null, null)
        override suspend fun queryPartial(input: QueryPartial<Make>): List<Partial<Make>> = api.queryPartial(input, null, null)
        override suspend fun detail(id: String): Make = api.detail(id, null, null)
        override suspend fun insertBulk(input: List<Make>): List<Make> = api.insertBulk(input, null, null)
        override suspend fun insert(input: Make): Make = api.insert(input, null, null)
        override suspend fun upsert(id: String, input: Make): Make = api.upsert(id, input, null, null)
        override suspend fun bulkReplace(input: List<Make>): List<Make> = api.bulkReplace(input, null, null)
        override suspend fun replace(id: String, input: Make): Make = api.replace(id, input, null, null)
        override suspend fun bulkModify(input: MassModification<Make>): Int = api.bulkModify(input, null, null)
        override suspend fun modifyWithDiff(id: String, input: Modification<Make>): EntryChange<Make> = api.modifyWithDiff(id, input, null, null)
        override suspend fun modify(id: String, input: Modification<Make>): Make = api.modify(id, input, null, null)
        override suspend fun bulkDelete(input: Condition<Make>): Int = api.bulkDelete(input, null, null)
        override suspend fun delete(id: String): Unit = api.delete(id, null, null)
        override suspend fun count(input: Condition<Make>): Int = api.count(input, null, null)
        override suspend fun groupCount(input: GroupCountQuery<Make>): Map<String, Int> = api.groupCount(input, null, null)
        override suspend fun aggregate(input: AggregateQuery<Make>): Double? = api.aggregate(input, null, null)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Make>): Map<String, Double?> = api.groupAggregate(input, null, null)
    }
    class AnySessionModelApi(val api: Api.ModelApi,val anyToken:String, val anyAccessToken: suspend () -> String, val masquerade: String?) {
    }
    class AnySessionEarnestPaymentApi(val api: Api.EarnestPaymentApi,val anyToken:String, val anyAccessToken: suspend () -> String, val masquerade: String?) {
    }
    class AnySessionExternalFinancingFormApi(val api: Api.ExternalFinancingFormApi,val anyToken:String, val anyAccessToken: suspend () -> String, val masquerade: String?) {
    }
}

