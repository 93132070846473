package com.ilussobsa.views

import com.ilussobsa.Strings
import com.ilussobsa.sdk.currentSessionNullable
import com.ilussobsa.sdk.currentSession
import com.lightningkite.UUID
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.navigation.KiteUiScreen
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.shared
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.scrolls
import com.lightningkite.kiteui.views.navigator

@Routable("vehicle/{id}")
class VehicleDetailScreen(val id: UUID): KiteUiScreen {
    var gotoOverride: (suspend (UUID?)->Unit)? = null
    override val title: Readable<String> = shared { currentSessionNullable.awaitNotNull().vehicles[id]()?.ymmt ?: Strings.vehicleDetail }
    override fun ViewWriter.render() {
        scrolls - vehicleDetail(id, goto = {
            gotoOverride?.invoke(it) ?: if(it != null) navigator.replace(VehicleDetailScreen(it)) else null
        }, backButtonInfo = null)
    }
}