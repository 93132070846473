package com.ilussobsa.views

import com.ilussobsa.*
import com.lightningkite.nowLocal
import com.ilussobsa.Strings
import com.ilussobsa.sdk.currentSessionNullable
import com.ilussobsa.sdk.currentSession
import com.lightningkite.kiteui.locale.RenderSize
import com.lightningkite.kiteui.locale.renderToString
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.nowLocal
import kotlin.time.Duration.Companion.days
import kotlinx.datetime.*
import kotlin.time.Duration.Companion.days
import com.ilussobsa.sdk.currentSession
import com.ilussobsa.sdk.currentUser

fun LocalDate.nextOrCurrentWeekday(day: DayOfWeek): LocalDate {
    var current = this
    while (current.dayOfWeek != day) current += DatePeriod(days = 1)
    return current
}
fun LocalDate.previousOrCurrentWeekday(day: DayOfWeek): LocalDate {
    var current = this
    while (current.dayOfWeek != day) current -= DatePeriod(days = 1)
    return current
}

val Vehicle.statusDescription get() = shared{
    completion?.let {
        if(it.bids == 0) {
            Strings.noBids
        } else if(it.sold) {
            if(currentUser()?._id == it.winner)
                Strings.purchasedAtX(it.price.renderPriceInDollars())
            else if(currentUser()?.role?.let { it >= UserRole.Manager } == true)
                Strings.soldForX(it.price.renderPriceInDollars())
            else
                Strings.soldAtX(it.price.renderPriceInDollars())
        } else {
            if(cancelled == null) {
                if(currentUser()?._id == it.winner)
                    Strings.youOfferedXWaitingOnSeller(it.price.renderPriceInDollars())
                else  if(currentUser()?.role?.let { it >= UserRole.Manager } == true)
                    Strings.openOfferOfX(it.price.renderPriceInDollars())
                else
                    Strings.highBidOfX(it.price.renderPriceInDollars())
            } else {
                when (cancellationReason) {
                    CancellationReason.CANCELLED_BY_SELLER, CancellationReason.CANCELLED_BY_BUYER -> Strings.dealCancelled
                    CancellationReason.OFFER_EXPIRED, CancellationReason.OFFER_DECLINED -> {
                        if (currentUser()?._id == it.winner)
                            Strings.offerOfXDeclined(it.price.renderPriceInDollars())
                        else if(currentUser()?.role?.let { it >= UserRole.Manager } == true)
                            Strings.declinedOfferOfX(it.price.renderPriceInDollars())
                        else
                            Strings.highBidOfX(it.price.renderPriceInDollars())
                    }
                    else -> Strings.highBidOfX(it.price.renderPriceInDollars())
                }
            }
        }
    } ?: liveAt?.let {
        Strings.liveNow
    } ?: submitted?.let {
        val relationship =
            currentSessionNullable.awaitNotNull().vehicleRelationships.watch(
                UserVehiclePair(
                    currentUser()?._id ?: nullUuid, _id
                )
            )
        relationship()?.autobid?.let {
            Strings.proxyBidUpToX(it.renderPriceInDollars())
        } ?: Strings.runningInNextAuction
    } ?: Strings.draft
}