@file:UseContextualSerialization(Instant::class, UUID::class, ServerFile::class, LocalDate::class)

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization

val nullUuid = uuid("00000000-0000-0000-0000-000000000000")

typealias VehicleColor = String
typealias MakeName = String
typealias PriceInDollars = Int

@Serializable
data class ResetDataRequest(
    val proxyBurstMode: Boolean = false,
    val postAuction: Boolean = false,
    val justClean: Boolean = false,
)

@Serializable
@JvmInline
value class RoundingLevel(val level: Int) : Comparable<RoundingLevel> {
    override fun compareTo(other: RoundingLevel): Int = this.level.compareTo(other.level)
    val price: PriceInDollars
        get() {
            var basis = 1
            repeat(level / 3) { basis *= 10 }
            return when (level % 3) {
                0 -> basis
                1 -> basis * 5 / 2
                2 -> basis * 5
                else -> TODO()
            }
        }

    fun increase() = RoundingLevel(level + 1)
    fun decrease() = RoundingLevel(level - 1)

    companion object {
        val valueOf1 = RoundingLevel(0)
        val valueOf2 = RoundingLevel(1)
        val valueOf5 = RoundingLevel(2)
        val valueOf10 = RoundingLevel(3)
        val valueOf25 = RoundingLevel(4)
        val valueOf50 = RoundingLevel(5)
        val valueOf100 = RoundingLevel(6)
        val valueOf250 = RoundingLevel(7)
        val valueOf500 = RoundingLevel(8)
        val valueOf1000 = RoundingLevel(9)
        val valueOf2500 = RoundingLevel(10)
        val valueOf5000 = RoundingLevel(11)
        val valueOf10000 = RoundingLevel(12)
        val valueOf25000 = RoundingLevel(13)
        val valueOf50000 = RoundingLevel(14)
        val valueOf100000 = RoundingLevel(15)
        val valueOf250000 = RoundingLevel(16)
        val valueOf500000 = RoundingLevel(17)
        val valueOf1000000 = RoundingLevel(18)
        val valueOf2500000 = RoundingLevel(19)
        val valueOf5000000 = RoundingLevel(20)
        val valueOf10000000 = RoundingLevel(21)
        val valueOf25000000 = RoundingLevel(22)
        val valueOf50000000 = RoundingLevel(23)
        val valueOf100000000 = RoundingLevel(24)
        val valueOf250000000 = RoundingLevel(25)
        val valueOf500000000 = RoundingLevel(26)
        val valueOf1000000000 = RoundingLevel(27)
    }
}

fun PriceInDollars.incrementedToRounded(rounding: Int): PriceInDollars {
    val candidateA = (this / rounding + 1) * rounding
    val candidateB = (this / rounding + 2) * rounding
    return if (candidateA - this >= rounding / 2) candidateA else candidateB
}

fun PriceInDollars.roundDown(rounding: Int): PriceInDollars = (this / rounding) * rounding

interface HasTimestamp {
    val at: Instant
}

@Serializable
data class UserPartial(
    @Serializable(TrimLowercaseOnSerialize::class) @MaxLength(128) val email: String,
    @Serializable(TrimOnSerialize::class) @MaxLength(32) val phoneNumber: String? = null,
    @Serializable(TrimOnSerialize::class) @MaxLength(128) val name: String = "",
)

var debugMode: Boolean = false

const val TenMb = 10_000_000L

@GenerateDataClassPaths
@Serializable
@AdminTableColumns(["email"])
@AdminSearchFields(["email"])
@AdminTitleFields(["email"])
data class User(
    override val _id: UUID = uuid(),
    @Serializable(TrimLowercaseOnSerialize::class) @MaxLength(128) override val email: String,
    @Serializable(TrimOnSerialize::class) @MaxLength(32) override val phoneNumber: String? = null,
    @Serializable(TrimOnSerialize::class) @MaxLength(128) val name: String = "",
    val address: Address = Address.EMPTY,
    @MimeType("image/png", "image/jpeg", maxSize = TenMb) val profilePicture: ServerFile? = null,
    val notifyAuctionStarting: NotificationTypeSettings = NotificationTypeSettings(defaultFor = NotificationTopic.AuctionStarting),
    val notifySearchMatch: NotificationTypeSettings = NotificationTypeSettings(defaultFor = NotificationTopic.SearchMatch),
    val notifyLaneNotifications: NotificationTypeSettings = NotificationTypeSettings(defaultFor = NotificationTopic.LaneNotifications),
    val notifyProxyBidBeaten: NotificationTypeSettings = NotificationTypeSettings(defaultFor = NotificationTopic.ProxyBidBeaten),
    val notifyVehicleBought: NotificationTypeSettings = NotificationTypeSettings(defaultFor = NotificationTopic.VehicleBought),
    val notifyVehicleSold: NotificationTypeSettings = NotificationTypeSettings(defaultFor = NotificationTopic.VehicleSold),
    val notifySystem: NotificationTypeSettings = NotificationTypeSettings(defaultFor = NotificationTopic.System),
    val notificationSummaries: Boolean = false,
    val notificationsLastCheckedAt: Instant = Instant.fromEpochSeconds(0),
    val role: UserRole = UserRole.UnverifiedCustomer,
    @MaxSize(20) @MaxLength(64) val managesMakes: Set<MakeName> = setOf(),
    @References(AuctionLane::class) val presentAtAuction: UUID? = null,
    val lastPresent: Instant = now(),
    @MaxSize(64) @MaxLength(64) val tutorialsComplete: Set<String> = setOf(),
    @MaxSize(64) @MaxLength(64) val checklistIgnore: Set<String> = setOf(),
    val financingSource: FinancingSource? = null,
    @References(ExternalFinancingForm::class) val currentExternalFinancingForm: UUID? = null,
    val paymentId: String? = null,
    val paymentSetup: Instant? = null,
) : HasId<UUID>, HasEmail, HasMaybePhoneNumber {
    fun notify(type: NotificationTopic): NotificationTypeSettings {
        return when (type) {
            NotificationTopic.AuctionStarting -> notifyAuctionStarting
            NotificationTopic.SearchMatch -> notifySearchMatch
            NotificationTopic.LaneNotifications -> notifyLaneNotifications
            NotificationTopic.ProxyBidBeaten -> notifyProxyBidBeaten
            NotificationTopic.VehicleBought -> notifyVehicleBought
            NotificationTopic.VehicleSold -> notifyVehicleSold
            NotificationTopic.System -> notifySystem
        }
    }

    fun paymentIdOrNotFound(): String = paymentId ?: ""

    val notificationSettingsChanged: Boolean
        get() = NotificationTopic.values().any { notify(it) != NotificationTypeSettings(it) }

    fun mayMasqueradeAs(other: User): Boolean = role >= UserRole.Manager && role > other.role
}

fun <S> DataClassPath<S, User>.notify(type: NotificationTopic): DataClassPath<S, NotificationTypeSettings> {
    return when (type) {
        NotificationTopic.AuctionStarting -> notifyAuctionStarting
        NotificationTopic.SearchMatch -> notifySearchMatch
        NotificationTopic.LaneNotifications -> notifyLaneNotifications
        NotificationTopic.ProxyBidBeaten -> notifyProxyBidBeaten
        NotificationTopic.VehicleBought -> notifyVehicleBought
        NotificationTopic.VehicleSold -> notifyVehicleSold
        NotificationTopic.System -> notifySystem
    }
}

@Serializable
enum class FinancingSource { Cash, OwnFinancing, IlussoFinancing }

@Serializable
@GenerateDataClassPaths
data class ExternalFinancingForm(
    override val _id: UUID = uuid(),
    @References(User::class) val user: UUID = nullUuid,
    val bankName: String = "",
    val approvalAmount: PriceInDollars? = null,
    val termLengthInMonths: Int? = null,
    val interestRate: Double? = null,
    val submittedAt: Instant? = null,
) : HasId<UUID>

@Serializable
@GenerateDataClassPaths
data class NotificationTypeSettings(
    val push: Boolean = false,
    val email: Boolean = false,
) {
    constructor(defaultFor: NotificationTopic) : this(
        !debugMode && defaultFor.defaultImmediacy,
        !debugMode && defaultFor.defaultImmediacy
    )
}

@Serializable
enum class UserRole { Anonymous, Disabled, UnverifiedCustomer, Customer, Manager, Developer, Admin, Root }

@GenerateDataClassPaths
@Serializable
data class FcmToken(
    override val _id: String,
    @Index @References(User::class) val user: UUID,
    val active: Boolean = true,
    val created: Instant = now(),
) : HasId<String>


annotation class Denormalized


/**
 * Exists for searchability
 */
@GenerateDataClassPaths
@Serializable
data class ShortVehicle(
    override val _id: UUID = uuid(),
    @Index val submitted: Instant? = null,
    val completion: Completion? = null,
    val paid: Instant? = null,
    val received: Instant? = null,
    val cancelled: Instant? = null,
    val counterOffer: PriceInDollars? = null,
    val estimatedValue: PriceInDollars,
    @Serializable(TrimOnSerialize::class) @MaxLength(64) val vin: String,
    val year: Short? = null,
    @Serializable(TrimOnSerialize::class) @MaxLength(64) val make: String? = null,
    @Serializable(TrimOnSerialize::class) @MaxLength(64) val model: String? = null,
    @Serializable(TrimOnSerialize::class) @MaxLength(64) val trim: String? = null,
    @IntegerRange(0L, 10_000_000L) val odometer: Int? = null,
    val transmission: Transmission? = null,
    val fuelType: FuelType? = null,
    @Serializable(TrimOnSerialize::class) @MaxLength(64) val interiorColor: VehicleColor? = null,
    @Serializable(TrimOnSerialize::class) @MaxLength(64) val exteriorColor: VehicleColor? = null,
) : HasId<UUID>

fun Vehicle.short() = ShortVehicle(
    _id = _id,
    submitted = submitted,
    completion = completion,
    paid = paid,
    received = received,
    cancelled = cancelled,
    estimatedValue = estimatedValue,
    vin = vin,
    year = year,
    make = make,
    model = model,
    trim = trim,
    odometer = odometer,
    transmission = transmission,
    fuelType = fuelType,
    interiorColor = interiorColor,
    exteriorColor = exteriorColor,
)

@GenerateDataClassPaths
@Serializable
@TextIndex(["vehicleDenormalizedInfo.make", "vehicleDenormalizedInfo.model", "vehicleDenormalizedInfo.trim"])
data class VehicleRelationship(
    override val _id: UserVehiclePair,
    @Denormalized val vehicleDenormalizedInfo: ShortVehicle,
    val firstInteraction: Instant = now(),
    @Denormalized val bidPlaced: Instant? = null,
    val favorite: Boolean = false,
    val notifications: Boolean = false,
    val autobid: PriceInDollars? = null,
    val autoBidsPlaced: Int? = null,
    val autobidLastActivated: Instant = Instant.fromEpochSeconds(0),
    val autobidBeaten: Instant? = null,
    @Serializable(TrimOnSerialize::class) @MaxLength(256) val personalNotes: String = "",
) : HasId<UserVehiclePair>

@Serializable
@GenerateDataClassPaths
data class UserVehiclePair(
    @References(User::class) val user: UUID,
    @References(Vehicle::class) val vehicle: UUID,
) : Comparable<UserVehiclePair> {
    companion object {
        val comparator = compareBy<UserVehiclePair> { it.user }.thenBy { it.vehicle }
    }

    override fun compareTo(other: UserVehiclePair): Int = comparator.compare(this, other)
}

@Serializable
@GenerateDataClassPaths
data class VehicleSearch(
    override val _id: UUID = uuid(),
    @Serializable(TrimOnSerialize::class) @MaxLength(128) val name: String,
) : HasId<UUID>

@Serializable
@GenerateDataClassPaths
data class Completion(
    val at: Instant = now(),
    val bids: Int,
    val participants: Int,
    val sold: Boolean,
    val price: PriceInDollars,
    val bidPrice: PriceInDollars = price,
    @References(User::class) val winner: UUID? = null,
    val expirationWarningSent: Boolean = false
)

@Serializable
enum class CancellationReason {
    CANCELLED_BY_BUYER, CANCELLED_BY_SELLER, OFFER_DECLINED, OFFER_EXPIRED, OTHER;

    companion object {
        val PRIVACY_SENSITIVE = setOf(CANCELLED_BY_BUYER, CANCELLED_BY_SELLER)
    }
}

@GenerateDataClassPaths
@Serializable
data class LiveAuctionData(
    @References(AuctionLane::class) override val _id: UUID,
    @References(Vehicle::class) val currentVehicle: UUID,
    val winningBid: Bid? = null,
    val base: PriceInDollars = 100,
    val increment: RoundingLevel = base.startIncrement(),
    val startedAt: Instant = now(),
    val laneStartedAt: Instant = now(),
    val lastAskChange: Instant = now(),
    val timeout: Instant = now() + LiveAuctionData.initialDropDelay,
    val dropReserveHovered: Boolean = false,
    val hovers: Set<UUID> = setOf(),
    val participants: Set<UUID> = setOf(),
    val dropsLeft: Int = 3,

    val bids: Int = 0,
    val completion: Completion? = null,
    val reserveMet: Boolean = false,
    val talkingPointUsed: Boolean = false,
) : HasId<UUID> {

    val asking: PriceInDollars get() = base.incrementedToRounded(increment.price)
    val duration get() = timeout - startedAt
    val winningBidPrice get() = winningBid?.price

    companion object {
        val initialDropDelay = 12.seconds
        val lowInterestDropDelay = 12.seconds
        val singleIncrementDuration = 12.seconds
        val talkingPointDelay = 5.seconds
        val hoverDropDelay = 2.seconds
        val lagAccounting = 1.seconds
        val fewHoversCap = 3.seconds
        val goingDuration = 10.seconds
        val finalIncrementDuration = singleIncrementDuration + goingDuration * 2 + lagAccounting
    }

    val bottomIncrement get() = asking.lowestIncrement()

    fun onTimeout(): Modification<LiveAuctionData>? {
        if (now() < timeout) throw IllegalStateException("Something is wrong.  We haven't reached the agreed timeout yet. ${now()} < $timeout")

        // Drop if no one has bid yet, but only up to two times
        return if (winningBid == null) {
            println("Winning bid is null and we hit timeout.  Drops left: $dropsLeft")
            if (dropsLeft > 0) {
                val ratio = if (dropsLeft == 1) 0.90 else 0.95
                val newIncrement = (base * ratio).toInt().startIncrement()
                val newBase = (base * ratio).toInt().roundDown(increment.price)
                modification {
                    it.dropsLeft assign dropsLeft - 1
                    it.base assign newBase
                    it.timeout assign now() + (if (dropsLeft == 1 || newIncrement == bottomIncrement) finalIncrementDuration else singleIncrementDuration)
                    it.increment assign newIncrement
                    it.lastAskChange assign now()
                }
            } else {
                null
            }
        } else if (increment > bottomIncrement) {
            // If we're not at the "normal" increment, (AKA we boosted it previously) just drop back to previous increment and continue the bidding process
            if (hovers.size < 2) {
                // low interest, let's get this over with and drop faster
                val newIncrement = increment.decrease().coerceAtLeast(bottomIncrement)
                return modification {
                    it.increment assign newIncrement
                    it.timeout assign
                            now() + (if (newIncrement == bottomIncrement) finalIncrementDuration else lowInterestDropDelay)
                    it.lastAskChange assign now()
                }
            } else {
                val newIncrement = increment.decrease().coerceAtLeast(bottomIncrement)
                return modification {
                    it.increment assign newIncrement
                    it.timeout assign
                            now() + (if (newIncrement == bottomIncrement) finalIncrementDuration else singleIncrementDuration)
                    it.lastAskChange assign now()
                }
            }
        } else {
            return null
        }
    }

    fun onWinningBid(bid: Bid): Modification<LiveAuctionData>? {
        // Kick out invalid bids
        if (this.completion != null) return null
        if (bid.price < asking) return null
        return modification {
            it.winningBid assign bid
            it.timeout assign now() + if (increment == bottomIncrement) singleIncrementDuration + goingDuration * 2 + lagAccounting else singleIncrementDuration
            it.base assign bid.price
            it.bids plusAssign 1
            it.dropsLeft assign 0
            it.lastAskChange assign now()
        }
    }

    fun onHover(id: UUID): Modification<LiveAuctionData> {
        // Someone might be interested; let's stretch it out
        // Potential problem: they might be interested in waiting for the drop, and this would extend it
        // To solve this, we only apply hover logic if we've done a drop
        return modification { it.hovers.addAll(setOf(id)) }
    }

    fun onUnhover(id: UUID): Modification<LiveAuctionData> {
        // Interest is dwindling; let's make it shorter
        val newHovers = hovers - id
        return if (newHovers.size < 2 && hovers.contains(id))
            modification {
                it.hovers.removeAll(setOf(id))
                it.timeout.coerceAtMost(if (increment == bottomIncrement) now() + goingDuration * 2 + fewHoversCap else now() + fewHoversCap)
            }
        else
            modification {
                it.hovers.removeAll(setOf(id))
            }
    }

    fun onEnter(id: UUID): Modification<LiveAuctionData> {
        // Someone might be interested; let's stretch it out
        // Potential problem: they might be interested in waiting for the drop, and this would extend it
        // To solve this, we only apply hover logic if we've done a drop
        return modification { it.participants.addAll(setOf(id)) }
    }

    fun onLeave(id: UUID): Modification<LiveAuctionData> {
        return modification {
            it.participants.removeAll(setOf(id))
        }
    }

    fun onDropReserve(): Modification<LiveAuctionData> {
        return modification {
            it.reserveMet assign true
            it.timeout assign timeout + singleIncrementDuration
        }
    }

    fun onTalkingPoint(): Modification<LiveAuctionData>? {
        if (talkingPointUsed) return null
        return modification {
            it.talkingPointUsed assign true
            it.timeout assign timeout + talkingPointDelay
        }
    }

//    fun onDropReserveHovered(): Modification<LiveAuctionData> {
//        // We only increase the timeout for hovering once to prevent abuse
//        return if (dropReserveHovered) this else copy(
//            dropReserveHovered = true,
//            timeout = timeout + hoverDropDelay
//        )
//    }
}

@GenerateDataClassPaths
@Serializable
data class SellerTalkingPoint(
    override val _id: UUID = uuid(),
    override val at: Instant = now(),
    @References(Vehicle::class) val vehicle: UUID,
    @Serializable(TrimOnSerialize::class) @MaxLength(256) val message: String,
) : HasId<UUID>, HasTimestamp


object AuctioneerSerializer :
    EnumIgnoreUnknownSerializer<Auctioneer>("com.ilussobsa.Auctioneer", Auctioneer.values(), Auctioneer.Recording1)

@Serializable(AuctioneerSerializer::class)
enum class Auctioneer(val displayName: String) {
    Recording1("First Recording"),
}

@GenerateDataClassPaths
@Serializable
@IndexSet(["make", "model", "trim", "year"])
@TextIndex(["make", "model", "trim", "yearString"])
data class Vehicle(
    override val _id: UUID = uuid(),
    val orderingValue: Float = 0f,

    val createdAt: Instant = now(),
    @Index val submitted: Instant? = null,
    val liveAt: Instant? = null,
    val archived: Instant? = null,
    val completion: Completion? = null,
    val paid: Instant? = null,
    val received: Instant? = null,
    val cancelled: Instant? = null,
    val cancellationReason: CancellationReason? = null,
    val rerunStarted: Instant? = null,
    val auctioneer: Auctioneer? = null,

    val estimatedValue: PriceInDollars = 0,
    val reserve: PriceInDollars? = null,
    val internalCost: PriceInDollars? = null,
    val autobids: Int = 0,

    @Serializable(TrimOnSerialize::class) @MaxLength(64) val vin: String,
    val year: Short? = null,
    @Serializable(TrimOnSerialize::class) @MaxLength(5) val yearString: String? = year?.toString(),
    @Serializable(TrimOnSerialize::class) @MaxLength(64) val make: String? = null,
    @Serializable(TrimOnSerialize::class) @MaxLength(64) val model: String? = null,
    @Serializable(TrimOnSerialize::class) @MaxLength(64) val trim: String? = null,
    @Serializable(TrimOnSerialize::class) @MaxLength(256) val options: String? = null,
    @IntegerRange(0, 10_000_000) val odometer: Int? = null,
    val transmission: Transmission? = null,
    val fuelType: FuelType? = null,
    @Serializable(TrimOnSerialize::class) @MaxLength(64) val interiorColor: VehicleColor? = null,
    @Serializable(TrimOnSerialize::class) @MaxLength(64) val exteriorColor: VehicleColor? = null,
    @MaxSize(40) val photos: List<VehiclePhoto> = listOf(),
    @MimeType("image/png", "image/jpeg", maxSize = TenMb) val thumbnail: ServerFile? = null,
    @MaxSize(32) val damage: List<Damage>? = null,
    val keys: KeyCount? = null,
    val tires: TireStatus? = null,
    // val reconditioning: ReconditioningStatus? = null,
    val activeWarranty: Boolean? = null,
    @Serializable(TrimOnSerialize::class) @MaxLength(16384) val description: String? = null,

    val considerationsFilled: Boolean = false,

    val priorAccident: ExtraInfo? = null,
    val paintwork: ExtraInfo? = null,
    val warningLights: ExtraInfo? = null,
    val towRequired: ExtraInfo? = null,
    val nonRunner: ExtraInfo? = null,
    val structuralDamage: ExtraInfo? = null,
    val airConditioningIssue: ExtraInfo? = null,
    val transmissionIssue: ExtraInfo? = null,
    val odometerIssue: ExtraInfo? = null,
    val canadian: ExtraInfo? = null,

    val salvage: ExtraInfo? = null,
    val lemonLaw: ExtraInfo? = null,
    val flood: ExtraInfo? = null,
    val stolenOrRecovery: ExtraInfo? = null,
    val rentalOrTaxi: ExtraInfo? = null,
    val trueMileageUnknown: ExtraInfo? = null,

//    val titleNotPresentFilled: Boolean = false,
//    val titleNotPresent: ExtraInfo? = null,

    val address: Address = Address.EMPTY,


    @MaxSize(8) val attachments: List<Attachment> = listOf(),

    val nearNotificationSent: Instant? = null,

    @References(AuctionLane::class) val auctionLane: UUID? = null,
) : HasId<UUID> {
    val majorInfoHash: Int get() = year.hashCode() + make.hashCode() + model.hashCode() + address.hashCode() + fuelType.hashCode() + transmission.hashCode()
    val minorInfoHash: Int
        get() = vin.hashCode() +
                year.hashCode() +
                make.hashCode() +
                model.hashCode() +
                trim.hashCode() +
                options.hashCode() +
                odometer.hashCode() +
                transmission.hashCode() +
                fuelType.hashCode() +
                interiorColor.hashCode() +
                exteriorColor.hashCode() +
                photos.hashCode() +
                damage.hashCode() +
                keys.hashCode() +
                tires.hashCode() +
                // reconditioning.hashCode() +
                activeWarranty.hashCode() +
                description.hashCode() +
                priorAccident.hashCode() +
                paintwork.hashCode() +
                warningLights.hashCode() +
                towRequired.hashCode() +
                nonRunner.hashCode() +
                structuralDamage.hashCode() +
                airConditioningIssue.hashCode() +
                transmissionIssue.hashCode() +
                odometerIssue.hashCode() +
                canadian.hashCode() +
                salvage.hashCode() +
                lemonLaw.hashCode() +
                flood.hashCode() +
                stolenOrRecovery.hashCode() +
                rentalOrTaxi.hashCode() +
                trueMileageUnknown.hashCode() +
                // titleNotPresent.hashCode() +
                attachments.hashCode()
    val ymmt: String
        get() = listOfNotNull(year?.toString(), make, model, trim).filter { it.isNotBlank() }.joinToString(" ")
    val completelyClean: ExtraInfo?
        get() = if (priorAccident == null &&
            paintwork == null &&
            warningLights == null &&
            towRequired == null &&
            nonRunner == null &&
            structuralDamage == null &&
            airConditioningIssue == null &&
            transmissionIssue == null &&
            odometerIssue == null &&
            canadian == null &&
            salvage == null &&
            lemonLaw == null &&
            flood == null &&
            stolenOrRecovery == null &&
            rentalOrTaxi == null &&
            trueMileageUnknown == null
        ) ExtraInfo("No issues or changes reported") else null
}

@Serializable
@GenerateDataClassPaths
data class Attachment(
    @Serializable(TrimOnSerialize::class) @MaxLength(256) val label: String,
    @MimeType("image/png", "image/jpeg", "application/pdf", maxSize = TenMb) val file: ServerFile,
)

@Serializable
@GenerateDataClassPaths
data class VehiclePhoto(
    // Using a separate class in case we add metadata in the future
    @MimeType("image/png", "image/jpeg", maxSize = TenMb) val file: ServerFile,
)

@Serializable
@GenerateDataClassPaths
data class ExtraInfo(
    @Serializable(TrimOnSerialize::class) @MaxLength(256) val description: String? = null
)

@Serializable
enum class Severity(val display: String) {
    Minor("Minor"),
    Major("Major"),
    DealBreaker("Deal Breaker")
}

fun PriceInDollars.renderPriceInDollars() =
    "$" + toCommaString()

fun String.formatPhoneNumber(): String {
    val digits = filter { it.isDigit() }
    return when (digits.length) {
        7 -> digits.substring(0, 3) + "-" + digits.substring(3)
        10 -> digits.substring(0, 3) + "-" + digits.substring(3, 6) + "-" + digits.substring(6)
        11 -> digits.substring(0, 1) + "-" + digits.substring(1, 4) + "-" + digits.substring(
            4,
            7
        ) + "-" + digits.substring(7)

        else -> digits
    }
}

fun Int.toCommaString() =
    toString().reversed().chunked(3) { it.reversed() }.reversed().joinToString(",")

@Serializable
enum class TireStatus(val range: ClosedFloatingPointRange<Float>) {
    Low(0f..0.5f),
    Good(0.5f..0.7f),
    Great(0.7f..1f);

    val text: String =
        "$name (${range.start.times(100).roundToInt()}% - ${range.endInclusive.times(100).roundToInt()}%)"
    val rangeText: String
        get() = "${range.start.times(100).roundToInt()}% - ${
            range.endInclusive.times(100).roundToInt()
        }%"
}

@Serializable
enum class KeyCount(val text: String) { One("1"), Two("2"), Three("3"), FourPlus("4+") }

@Serializable
enum class ReconditioningStatus { NewTrade, FrontlineUnit, CPO }

@GenerateDataClassPaths
@Serializable
data class Damage(
    @Serializable(TrimOnSerialize::class) @MaxLength(256) val comment: String,
    @MimeType("image/png", "image/jpeg", maxSize = TenMb) val image: ServerFile
)

@Serializable
enum class Transmission {
    Automatic,
    Manual
}

@Serializable
enum class FuelType {
    Gasoline,
    Hybrid,
    Diesel,
    Electric,
}

@Serializable
@GenerateDataClassPaths
data class TransportAddress(
    val address: Address,
    @Serializable(TrimOnSerialize::class) @MaxLength(128) val contactName: String = "",
    @Serializable(TrimOnSerialize::class) @MaxLength(128) val contactEmail: String = "",
    @Serializable(TrimOnSerialize::class) @MaxLength(128) val contactPhone: String = "",
    @References(User::class) val user: UUID? = null,
) {
    companion object {
        val EMPTY: TransportAddress = TransportAddress(Address.EMPTY)
    }
}

/*fun User.transportAddress() = TransportAddress(
    address = address,
    contactName = name,
    contactEmail = email,
    contactPhone = phoneNumber ?: ""
)*/

@GenerateDataClassPaths
@Serializable
data class Address(
    @Serializable(TrimOnSerialize::class) @MaxLength(256) val street: String,
    @Serializable(TrimOnSerialize::class) @MaxLength(64) val city: String,
    val zip: String? = null,
    val state: UsState,
    val geoCoordinate: GeoCoordinate,
) {
    override fun toString(): String = "$street $city, $state"
    fun toStringNewline(): String = "$street\n$city, $state"
    val cityState: String get() = "$city, ${state.text}"

    infix fun eqIgnoreGeoCoordinate(other: Address) = street == other.street &&
            city == other.city &&
            zip == other.zip &&
            state == other.state

    companion object {
        val EMPTY = Address("", "", null, UsState.CA, GeoCoordinate(0.0, 0.0))
    }
}

@GenerateDataClassPaths
@Serializable
data class PurchasedInfo(
    @Index @Denormalized @References(User::class) val buyer: UUID,
    @IntegerRange(0, 1_000_000_000) val price: PriceInDollars,
    @Index val at: Instant = now(),
)

@GenerateDataClassPaths
@Serializable
data class DealershipGroup(
    override val _id: UUID = uuid(),
    @Serializable(TrimOnSerialize::class) @MaxLength(128) val name: String,
) : HasId<UUID>

@GenerateDataClassPaths
@Serializable
data class Make(
    @Serializable(TrimOnSerialize::class) @MaxLength(64) override val _id: String,
    val chromeId: Int,
) : HasId<String>

@GenerateDataClassPaths
@Serializable
data class Model(
    @Serializable(TrimOnSerialize::class) @MaxLength(64) @References(Make::class) val make: String,
    @Serializable(TrimOnSerialize::class) @MaxLength(64) val model: String,
    @Serializable(TrimOnSerialize::class) @MaxLength(128) override val _id: String = "$make $model"
) : HasId<String>

@Serializable
enum class Grade(val display: String) {
    Unknown("A"),
    C("C"),
    B("B"),
    A("A"),
}

interface SearchParams {
    val fuelType: FuelType?
    val transmission: Transmission?
    val minYear: Int?
    val maxYear: Int?
    val mileage: Int?
    val make: Set<String>?
    val model: Set<String>?
    val trim: String?
    val maxDistanceMiles: Int?
}

@Serializable
data class SearchParamsOnly(
    override val fuelType: FuelType? = null,
    override val transmission: Transmission? = null,
    override val minYear: Int? = null,
    override val maxYear: Int? = null,
    override val mileage: Int? = null,
    @MaxSize(32) @MaxLength(64) override val make: Set<String>? = null,
    @MaxSize(32) @MaxLength(64) override val model: Set<String>? = null,
    override val trim: String? = null,
    override val maxDistanceMiles: Int? = null,
) : SearchParams {
    fun toSavedSearch(id: UUID, user: UUID, name: String) = SavedSearch(
        _id = id,
        name = name,
        user = user,
        fuelType = fuelType,
        transmission = transmission,
        minYear = minYear,
        maxYear = maxYear,
        mileage = mileage,
        make = make,
        model = model,
        trim = trim,
        maxDistanceMiles = maxDistanceMiles,
    )
}

@GenerateDataClassPaths
@Serializable
data class SavedSearch(
    override val _id: UUID = uuid(),
    @References(User::class) val user: UUID,
    val name: String,
    override val fuelType: FuelType? = null,
    override val transmission: Transmission? = null,
    override val minYear: Int? = null,
    override val maxYear: Int? = null,
    override val mileage: Int? = null,
    @MaxSize(32) @MaxLength(64) override val make: Set<String>? = null,
    @MaxSize(32) @MaxLength(64) override val model: Set<String>? = null,
    override val trim: String? = null,
    override val maxDistanceMiles: Int? = null,
) : HasId<UUID>, SearchParams {
    fun simplify() = SearchParamsOnly(
        fuelType = fuelType,
        transmission = transmission,
        minYear = minYear,
        maxYear = maxYear,
        mileage = mileage,
        make = make,
        model = model,
        trim = trim,
        maxDistanceMiles = maxDistanceMiles,
    )
}

fun SearchParams.condition(
    location: GeoCoordinate?,
): Condition<Vehicle> = condition<Vehicle> {
    Condition.And(listOfNotNull(
        if (fuelType != null) it.fuelType eq FuelType.entries.find {
            it.name contentEquals fuelType.toString()
        } else null,
        if (transmission != null) it.transmission eq Transmission.entries.find {
            it.name contentEquals transmission.toString()
        } else null,
        minYear?.let { y -> it.year.notNull gte y.toShort() },
        maxYear?.let { y -> it.year.notNull lte y.toShort() },
        mileage?.let { m -> it.odometer.notNull lte m },
        make?.let { makes -> it.make inside makes },
        model?.let { models -> it.model inside models },
        trim?.takeUnless { it.isBlank() }
            ?.let { v -> it.trim.notNull.contains(v, true) },
        location?.let { location ->
            maxDistanceMiles?.let { v ->
                it.address.geoCoordinate.distanceBetween(location, lessThan = v.miles)
            }
        },
        condition(true)
    )
    )
}

suspend fun Vehicle.savedSearchCondition(): Condition<SavedSearch> = condition<SavedSearch> {
    Condition.And(
        listOfNotNull<Condition<SavedSearch>>(
            it.fuelType.inside(listOf(null, fuelType)),
            it.transmission.inside(listOf(null, transmission)),
            year?.let { y -> it.minYear.eq(null) or it.minYear.notNull.lte(y.toInt()) },
            year?.let { y -> it.maxYear.eq(null) or it.maxYear.notNull.gte(y.toInt()) },
            odometer?.let { v -> it.mileage.eq(null) or it.mileage.notNull.lte(v) },
            make?.let { v -> it.make.eq(null) or it.make.notNull.any { it.eq(v) } },
            model?.let { v -> it.model.eq(null) or it.model.notNull.any { it.eq(v) } },
            trim?.let { v -> it.trim.eq(null) or it.trim.notNull.eq(v) },
        )
    )
}

//@GenerateDataClassPaths
//@Serializable
//data class AutoSellPersonality(
//    val
//)

@GenerateDataClassPaths
@Serializable
data class AuctionLane(
    override val _id: UUID = uuid(),
    @Description("The date and time this lane starts (Pacific), assuming it is active")
    val scheduledStart: LocalDateTime = run {
        val now = now().atZone(auctionZone)
        var current = now.date
        while (current.dayOfWeek != DayOfWeek.FRIDAY) {
            current += DatePeriod(days = 1)
        }
        LocalDateTime(current, LocalTime(12, 0, 0))
    },
    @Serializable(TrimOnSerialize::class) @MaxLength(128) val name: String,
    @Serializable(TrimOnSerialize::class) @MaxLength(16384) val description: String,
    val condition: Condition<Vehicle> = Condition.Always,
    val active: Boolean = true,
) : HasId<UUID> {
    val scheduledStartInstant: Instant get() = scheduledStart.toInstant(auctionZone)
    val nextVehicle: Condition<Vehicle> get() = condition { it.completion.eq(null) and it.auctionLane.notNull.eq(_id) }
    val submittedAwaitingAssignment: Condition<Vehicle> get() = condition<Vehicle> {
        it.submitted.notNull.lte(now()) and condition and it.auctionLane.eq(null) and it.completion.eq(null)
    }
    val runlist: Condition<Vehicle> get() = condition<Vehicle> { it.auctionLane.notNull.eq(_id) }
}

expect fun timeZoneInit()
val auctionZone = TimeZone.also {
    timeZoneInit()
}.of("America/Los_Angeles")
val auctionExpiry = LocalTime(12 + 6, 0, 0)

@GenerateDataClassPaths
@Serializable
data class Bid(
    override val _id: UUID = uuid(),
    @References(Vehicle::class) val vehicle: UUID,
    @References(User::class) val buyer: UUID,
    val price: PriceInDollars,
    override val at: Instant = now(),
) : HasId<UUID>, HasTimestamp

@GenerateDataClassPaths
@Serializable
data class Notification(
    override val _id: UUID = uuid(),
    @References(User::class) val receiver: UUID,
    @Serializable(TrimOnSerialize::class) @MaxLength(256) val title: String,
    @Serializable(TrimOnSerialize::class) @MaxLength(512) val content: String? = null,
    val topic: NotificationTopic = NotificationTopic.System,
    @Serializable(TrimOnSerialize::class) @MaxLength(128) val link: String? = null,
    val at: Instant = now(),
) : HasId<UUID>

@Serializable
enum class NotificationUserType {
    SellerOnly, BuyerOnly, AllUsers
}

@Serializable
enum class NotificationTopic(
    val defaultImmediacy: Boolean,
    val section: String,
    val title: String,
    val description: String,
    val forUserType: NotificationUserType
) {
    AuctionStarting(
        true,
        "General",
        "Auction Starting",
        "Notify me when an auction I would be interested in is starting",
        NotificationUserType.AllUsers
    ),
    //TODO
    SearchMatch(
        true,
        "Buying",
        "New Vehicle Matching Search Uploaded",
        "Notify me when someone uploads a vehicle matching one of my dealership's saved searches",
        NotificationUserType.BuyerOnly
    ),
    LaneNotifications(true, "Buying", "Lane Alert", "Requested notifications for when a vehicle is on the block", NotificationUserType.BuyerOnly),
    ProxyBidBeaten(true, "Buying", "Proxy Beat", "Notify me when my dealership's proxy bid is beaten", NotificationUserType.BuyerOnly),
    VehicleBought(
        false,
        "Buying",
        "Vehicle Bought",
        "Notify me when I win an auction for a vehicle and the reserve was met.",
        NotificationUserType.BuyerOnly
    ),
    VehicleSold(
        false,
        "Selling",
        "Vehicle Sold",
        "Notify me when a vehicle is sold.",
        NotificationUserType.SellerOnly
    ),
    System(true, "System", "System", "Information about logging in", NotificationUserType.SellerOnly),
}

fun PriceInDollars.startIncrement(): RoundingLevel =
    if (this > 150_000) RoundingLevel.valueOf5000 else RoundingLevel.valueOf1000

fun PriceInDollars.lowestIncrement(): RoundingLevel =
    if (this > 150_000) RoundingLevel.valueOf1000 else RoundingLevel.valueOf250

@Serializable
enum class UsState(val text: String, val coordinate: GeoCoordinate) {
    AL("Alabama", GeoCoordinate(32.806671, -86.791130)),
    AK("Alaska", GeoCoordinate(61.370716, -152.404419)),
    AZ("Arizona", GeoCoordinate(33.729759, -111.431221)),
    AR("Arkansas", GeoCoordinate(34.969704, -92.373123)),
    CA("California", GeoCoordinate(36.116203, -119.681564)),
    CO("Colorado", GeoCoordinate(39.059811, -105.311104)),
    CT("Connecticut", GeoCoordinate(41.597782, -72.755371)),
    DE("Delaware", GeoCoordinate(39.318523, -75.507141)),
    DC("District Of Columbia", GeoCoordinate(38.897438, -77.026817)),
    FL("Florida", GeoCoordinate(27.766279, -81.686783)),
    GA("Georgia", GeoCoordinate(33.040619, -83.643074)),
    HI("Hawaii", GeoCoordinate(21.094318, -157.498337)),
    ID("Idaho", GeoCoordinate(44.240459, -114.478828)),
    IL("Illinois", GeoCoordinate(40.349457, -88.986137)),
    IN("Indiana", GeoCoordinate(39.849426, -86.258278)),
    IA("Iowa", GeoCoordinate(42.011539, -93.210526)),
    KS("Kansas", GeoCoordinate(38.526600, -96.726486)),
    KY("Kentucky", GeoCoordinate(37.668140, -84.670067)),
    LA("Louisiana", GeoCoordinate(31.169546, -91.867805)),
    ME("Maine", GeoCoordinate(44.693947, -69.381927)),
    MD("Maryland", GeoCoordinate(39.063946, -76.802101)),
    MA("Massachusetts", GeoCoordinate(42.230171, -71.530106)),
    MI("Michigan", GeoCoordinate(43.326618, -84.536095)),
    MN("Minnesota", GeoCoordinate(45.694454, -93.900192)),
    MS("Mississippi", GeoCoordinate(32.741646, -89.678696)),
    MO("Missouri", GeoCoordinate(38.456085, -92.288368)),
    MT("Montana", GeoCoordinate(46.921925, -110.454353)),
    NE("Nebraska", GeoCoordinate(41.125370, -98.268082)),
    NV("Nevada", GeoCoordinate(38.313515, -117.055374)),
    NH("New Hampshire", GeoCoordinate(43.452492, -71.563896)),
    NJ("New Jersey", GeoCoordinate(40.298904, -74.521011)),
    NM("New Mexico", GeoCoordinate(34.840515, -106.248482)),
    NY("New York", GeoCoordinate(42.165726, -74.948051)),
    NC("North Carolina", GeoCoordinate(35.630066, -79.806419)),
    ND("North Dakota", GeoCoordinate(47.528912, -99.784012)),
    OH("Ohio", GeoCoordinate(40.388783, -82.764915)),
    OK("Oklahoma", GeoCoordinate(35.565342, -96.928917)),
    OR("Oregon", GeoCoordinate(44.572021, -122.070938)),
    PA("Pennsylvania", GeoCoordinate(40.590752, -77.209755)),
    RI("Rhode Island", GeoCoordinate(41.680893, -71.511780)),
    SC("South Carolina", GeoCoordinate(33.856892, -80.945007)),
    SD("South Dakota", GeoCoordinate(44.299782, -99.438828)),
    TN("Tennessee", GeoCoordinate(35.747845, -86.692345)),
    TX("Texas", GeoCoordinate(31.054487, -97.563461)),
    UT("Utah", GeoCoordinate(40.150032, -111.862434)),
    VT("Vermont", GeoCoordinate(44.045876, -72.710686)),
    VA("Virginia", GeoCoordinate(37.769337, -78.169968)),
    WA("Washington", GeoCoordinate(47.400902, -121.490494)),
    WV("West Virginia", GeoCoordinate(38.491226, -80.954453)),
    WI("Wisconsin", GeoCoordinate(44.268543, -89.616508)),
    WY("Wyoming", GeoCoordinate(42.755966, -107.302490)),
}

@Serializable
@GenerateDataClassPaths
data class StripeWebhookSecret(
    override val _id: String,
    val secret: String,
    val stripeId: String = "",
    val events: Set<String> = setOf(),
) : HasId<String>


@GenerateDataClassPaths
@Serializable
data class Item(override val _id: Int, val creation: Int = 0) : HasId<Int>

@GenerateDataClassPaths
@Serializable
data class EarnestPayment(
    override val _id: UUID = uuid(),
    @References(User::class) val user: UUID,
    val at: Instant = now(),
    val method: String = "Not recorded",
    val priceInCents: Int = 0,
) : HasId<UUID>


@GenerateDataClassPaths
@Serializable
data class FinancingApplication(
    override val _id: UUID = UUID.random(),
    @References(User::class) val user: UUID,
    val at: Instant = now(),

    val primary: FinancingApplicant,
    val secondary: FinancingApplicant? = null,

    val additionalInformation: String? = null,
): HasId<UUID>

@Serializable
@GenerateDataClassPaths
data class FinancingApplicant(
    val email: String? = null,
    val phoneNumber: String? = null,
    val givenName: String? = null,
    val middleName: String? = null,
    val surname: String? = null,

    val driversLicenseNumber: String? = null,
    val driversLicenseExpiration: LocalDate? = null,
    val birthday: LocalDate? = null,
    val socialSecurityNumber: String? = null,

    val residency: ResidencyInfo = ResidencyInfo(),
    val previousResidency: ResidencyInfo? = null,

    val employer: EmployerInfo = EmployerInfo(),
    val previousEmployer: EmployerInfo? = null,
)

@Serializable
@GenerateDataClassPaths
data class ResidencyInfo(
    val address: UsAddress = UsAddress(),
    val yearsAtAddress: Int? = null,
    val monthsAtAddress: Int? = null,
    val residenceType: ResidenceType? = null,
    val monthlyPayment: PriceInDollars? = null,
) {
    override fun toString(): String = """
        $address
        $yearsAtAddress years, $monthsAtAddress months
        $residenceType for ${monthlyPayment?.renderPriceInDollars()}
    """.trimIndent()
}

@Serializable
@GenerateDataClassPaths
data class EmployerInfo(
    val name: String? = null,
    val address: UsAddress? = null,
    val phone: String? = null,
    val yearsEmployed: Int? = null,
    val supervisor: String? = null,
    val grossMonthlyIncome: PriceInDollars? = null,
    val otherMonthlyIncome: PriceInDollars? = null,
    val occupation: String? = null,
) {
    override fun toString(): String = """
        $occupation at $name ($phone)
        $address
        $yearsEmployed years
        Supervisor: $supervisor
        ${grossMonthlyIncome?.renderPriceInDollars()} + ${otherMonthlyIncome?.renderPriceInDollars()} other
    """.trimIndent()
}

@Serializable
@GenerateDataClassPaths
data class UsAddress(
    val street: String? = null,
    val city: String? = null,
    val state: UsState? = null,
    val zip: String? = null,
) {
    override fun toString(): String = " ${if(street!=null) "$street, " else ""}${if(city != null) "$city," else ""} ${if(state != null)"$state," else ""} ${if(zip != null)"$zip" else ""}"
}

@Serializable
enum class ResidenceType {
    Own, Rent, Mortgage
}