// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareResidencyInfoFields() {
    val props: Array<SerializableProperty<ResidencyInfo, *>> = arrayOf(ResidencyInfo_address, ResidencyInfo_yearsAtAddress, ResidencyInfo_monthsAtAddress, ResidencyInfo_residenceType, ResidencyInfo_monthlyPayment)
    ResidencyInfo.serializer().properties { props }
}
val <K> DataClassPath<K, ResidencyInfo>.address: DataClassPath<K, UsAddress> get() = this[ResidencyInfo_address]
val <K> DataClassPath<K, ResidencyInfo>.yearsAtAddress: DataClassPath<K, Int?> get() = this[ResidencyInfo_yearsAtAddress]
val <K> DataClassPath<K, ResidencyInfo>.monthsAtAddress: DataClassPath<K, Int?> get() = this[ResidencyInfo_monthsAtAddress]
val <K> DataClassPath<K, ResidencyInfo>.residenceType: DataClassPath<K, ResidenceType?> get() = this[ResidencyInfo_residenceType]
val <K> DataClassPath<K, ResidencyInfo>.monthlyPayment: DataClassPath<K, PriceInDollars?> get() = this[ResidencyInfo_monthlyPayment]
inline val ResidencyInfo.Companion.path: DataClassPath<ResidencyInfo, ResidencyInfo> get() = path<ResidencyInfo>()


object ResidencyInfo_address: SerializableProperty<ResidencyInfo, UsAddress> {
    override val name: String = "address"
    override fun get(receiver: ResidencyInfo): UsAddress = receiver.address
    override fun setCopy(receiver: ResidencyInfo, value: UsAddress) = receiver.copy(address = value)
    override val serializer: KSerializer<UsAddress> = UsAddress.serializer()
    override val annotations: List<Annotation> = ResidencyInfo.serializer().tryFindAnnotations("address")
    override val default: UsAddress = UsAddress()
}
object ResidencyInfo_yearsAtAddress: SerializableProperty<ResidencyInfo, Int?> {
    override val name: String = "yearsAtAddress"
    override fun get(receiver: ResidencyInfo): Int? = receiver.yearsAtAddress
    override fun setCopy(receiver: ResidencyInfo, value: Int?) = receiver.copy(yearsAtAddress = value)
    override val serializer: KSerializer<Int?> = Int.serializer().nullable2
    override val annotations: List<Annotation> = ResidencyInfo.serializer().tryFindAnnotations("yearsAtAddress")
    override val default: Int? = null
}
object ResidencyInfo_monthsAtAddress: SerializableProperty<ResidencyInfo, Int?> {
    override val name: String = "monthsAtAddress"
    override fun get(receiver: ResidencyInfo): Int? = receiver.monthsAtAddress
    override fun setCopy(receiver: ResidencyInfo, value: Int?) = receiver.copy(monthsAtAddress = value)
    override val serializer: KSerializer<Int?> = Int.serializer().nullable2
    override val annotations: List<Annotation> = ResidencyInfo.serializer().tryFindAnnotations("monthsAtAddress")
    override val default: Int? = null
}
object ResidencyInfo_residenceType: SerializableProperty<ResidencyInfo, ResidenceType?> {
    override val name: String = "residenceType"
    override fun get(receiver: ResidencyInfo): ResidenceType? = receiver.residenceType
    override fun setCopy(receiver: ResidencyInfo, value: ResidenceType?) = receiver.copy(residenceType = value)
    override val serializer: KSerializer<ResidenceType?> = ResidenceType.serializer().nullable2
    override val annotations: List<Annotation> = ResidencyInfo.serializer().tryFindAnnotations("residenceType")
    override val default: ResidenceType? = null
}
object ResidencyInfo_monthlyPayment: SerializableProperty<ResidencyInfo, PriceInDollars?> {
    override val name: String = "monthlyPayment"
    override fun get(receiver: ResidencyInfo): PriceInDollars? = receiver.monthlyPayment
    override fun setCopy(receiver: ResidencyInfo, value: PriceInDollars?) = receiver.copy(monthlyPayment = value)
    override val serializer: KSerializer<PriceInDollars?> = PriceInDollars.serializer().nullable2
    override val annotations: List<Annotation> = ResidencyInfo.serializer().tryFindAnnotations("monthlyPayment")
    override val default: PriceInDollars? = null
}
