// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareNotificationTypeSettingsFields() {
    val props: Array<SerializableProperty<NotificationTypeSettings, *>> = arrayOf(NotificationTypeSettings_push, NotificationTypeSettings_email)
    NotificationTypeSettings.serializer().properties { props }
}
val <K> DataClassPath<K, NotificationTypeSettings>.push: DataClassPath<K, Boolean> get() = this[NotificationTypeSettings_push]
val <K> DataClassPath<K, NotificationTypeSettings>.email: DataClassPath<K, Boolean> get() = this[NotificationTypeSettings_email]
inline val NotificationTypeSettings.Companion.path: DataClassPath<NotificationTypeSettings, NotificationTypeSettings> get() = path<NotificationTypeSettings>()


object NotificationTypeSettings_push: SerializableProperty<NotificationTypeSettings, Boolean> {
    override val name: String = "push"
    override fun get(receiver: NotificationTypeSettings): Boolean = receiver.push
    override fun setCopy(receiver: NotificationTypeSettings, value: Boolean) = receiver.copy(push = value)
    override val serializer: KSerializer<Boolean> = Boolean.serializer()
    override val annotations: List<Annotation> = NotificationTypeSettings.serializer().tryFindAnnotations("push")
    override val default: Boolean = false
}
object NotificationTypeSettings_email: SerializableProperty<NotificationTypeSettings, Boolean> {
    override val name: String = "email"
    override fun get(receiver: NotificationTypeSettings): Boolean = receiver.email
    override fun setCopy(receiver: NotificationTypeSettings, value: Boolean) = receiver.copy(email = value)
    override val serializer: KSerializer<Boolean> = Boolean.serializer()
    override val annotations: List<Annotation> = NotificationTypeSettings.serializer().tryFindAnnotations("email")
    override val default: Boolean = false
}
