package com.ilussobsa

import com.ilussobsa.sdk.*
import com.ilussobsa.utils.*
import com.ilussobsa.views.*
import com.ilussobsa.views.dialogs.GenericDialog
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.appBase
import com.lightningkite.kiteui.views.l2.appNavFactory
import com.lightningkite.kiteui.views.l2.appNavHamburger
import com.ilussobsa.views.dialogs.userModal
import com.lightningkite.kiteui.*
import com.lightningkite.lightningdb.*
import com.lightningkite.now
import kotlinx.coroutines.*
import kotlinx.serialization.Serializable


@Serializable
enum class ApiOption(val apiName: String, val http: String, val ws: String) {
    //    Prod("", "https://backend.brandspecificauctions.com", "wss://ws.backend.brandspecificauctions.com"),
    /*Demo(
        "Demo",
        "https://demo.backend.brandspecificauctions.com",
        "wss://ws.demo.backend.brandspecificauctions.com"
    ),*/
    Staging(
        "Staging",
        "https://staging.api.ilussoauctions.com",
        "wss://ws.staging.api.ilussoauctions.com"
    ),
    Dev("Dev", "https://ilussooffmarket-api.cs.lightningkite.com", "wss://ws.ilussooffmarket-api.cs.lightningkite.com"),
    Jivie("Joseph's", "https://jivie.lightningkite.com", "wss://jivie.lightningkite.com"),
    LocalNet("LocalNet", "http://192.168.1.135:8941", "ws://192.168.1.135:8941"),
    Local("Local", "http://0.0.0.0:8080", "ws://0.0.0.0:8080"),
    SameServer("SameServer", "", ""),
    ;

    companion object {
        var selectedIndex = 0
    }
}

val ApiOption.api get() = LiveApi(http, ws)

val fcmToken: Property<String?> = Property(null)
val setFcmToken = { token: String -> fcmToken.value = token }

expect fun getDefaultServerBackend(): ApiOption

val selectedApi = PersistentProperty<ApiOption>("apiOption", getDefaultServerBackend())

val preferredTheme = shared { appThemePreference()?.theme ?: defaultAppTheme }
val useNewMenu = PersistentProperty<Boolean>("useNewMenu", false)
val tabBehavior = PersistentProperty<TabBehavior>("tabBehavior", TabBehavior.NextInTab)

@Serializable
enum class TabBehavior(val title: String) {
    NextInTab("Show Next in Tab"),
    FollowVehicle("Follow Vehicle"),
    Clear("Clear Vehicle")
}

val useTwoPane = shared { WindowInfo().width > 1000.dp }

lateinit var mainNavigatorGlobalIsBad: ScreenNavigator

interface AdminOnly : Screen

fun ViewWriter.app(main: ScreenNavigator, dialog: ScreenNavigator) {
    mainNavigatorGlobalIsBad = main
    if (appThemePreference.value == null) {
//        when (context.darkMode) {
//            true -> appThemePreference.value = ThemePreference.Dark
//            false -> appThemePreference.value = ThemePreference.Light
//            else -> {}
//        }
    }
    if (appNavPreference.value == null) {
        when (Platform.current) {
            Platform.iOS -> appNavPreference.value = NavPreference.BottomTabs
            Platform.Android -> appNavPreference.value = NavPreference.BottomTabs
            Platform.Web -> {
                if (Platform.usesTouchscreen)
                    appNavPreference.value = NavPreference.BottomTabs
                else
                    appNavPreference.value = NavPreference.Top
            }

            Platform.Desktop -> appNavPreference.value = NavPreference.TopAndLeft
        }
    }

    val big = shared { WindowInfo().width > 1000.dp }
    AppScope.reactiveScope {
        appNavFactory.value = appNavPreference()?.takeIf {
            big() || it.worksForSmall
        }?.factory ?: ViewWriter::appNavHamburger
    }
//    appNavFactory.value = ViewWriter::appNavTopAndLeft
//    AppScope.reactiveScope {
//        val screenWidth = WindowInfo.await()
//        val newBig = screenWidth.width > 1000.dp
//        if (big != newBig) {
//            appNavFactory set if (newBig) ViewWriter::appNavTopAndLeft else ViewWriter::appNavHamburger
//        }
//        big = newBig
//    }

    //ffmpeg -i loginback.mp4 -s 720x480 -b

    defaultAppTheme.onNext - appBase(main, dialog) {
        AppScope.reactiveSuspending {
            val session = currentSessionNullable.await() ?: return@reactiveSuspending
            val token = fcmToken.await()
            println("fcmToken: $token")
            if (token != null) {
                session.fcmToken.upsert(token, FcmToken(token, session.userId.await(), true))
            }
        }

        // Really evil hack forces data to reload whenever we navigate screens
        // Not sure if this is a good idea, but it should stymie some complaints about the cache.
        AppScope.reactiveScope {
            val session = currentSessionNullable() ?: return@reactiveScope
            val screen = mainScreenNavigator.currentScreen()
            session.refresh()
        }

        AppScope.reactiveSuspending {
            if (currentSessionNullable.invoke() == null) return@reactiveSuspending
            val permission = notificationPermissions()
            when (permission) {
                false -> {}

                true -> {
                    fcmSetup()
                }

                null -> {
                    dialogScreenNavigator.navigate(GenericDialog(
                        "BSA would like to send you notifications about auction events."
                    ) {
                        launchGlobal {
                            requestNotificationPermissions()
                        }
                    })
                }
            }
        }

        AppScope.reactiveScope {
            if (screenNavigator.currentScreen() is AdminOnly && currentUser.awaitNotNull().role < UserRole.Manager)
                screenNavigator.replace(DashboardScreen())
        }
        swapView {
            swapping(
                current = { appNavFactory() },
                views = {
                    it(this) {

                        appName = "iLusso BSA"

                        ::appLogo {
                            Resources.ilussoFullLogo(defaultAppTheme)
                        }

                        ::exists {
                            (navigator.currentScreen() as? ControlsNavVisibility)?.run { showNav() } ?: true
                        }

                        val notificationCount = shared {
                            currentSessionNullable()?.let {
                                val after = it.me()?.notificationsLastCheckedAt ?: return@let null
                                it.notifications.watch(
                                    Query(
                                        condition = condition {
                                            it.at.gt(after) and it.receiver.eqNn(currentUser()?._id)
                                        },
                                        limit = 100
                                    )
                                )().size.takeUnless { it == 0 }
                            }
                        }

                        actions = listOf(
                            NavLink(
                                { Strings.notifications },
                                {
                                    if (notificationCount() != null) Icon.notificationFilled
                                    else Icon.notification
                                },
                                { notificationCount() },
                            ) { { NotificationsScreen() } },
                            NavCustom(
                                square = {
                                    centered - menuButton {
                                        spacing = 0.rem
                                        preferredDirection = PopoverPreferredDirection.belowLeft
                                        opensMenu {
                                            userModal { closePopovers() }
                                        }
                                        col {
                                            spacing = 0.25.rem
                                            centered - sizeConstraints(width = 2.rem, height = 2.rem) - image {
                                                description = ""
                                                ::source {
                                                    currentUser()?.profilePicture?.location?.let(::ImageRemote)
                                                        ?: Icon.person.toImageSource(Color.gray)
                                                }
                                            }
                                            subtext {
                                                ::exists { big() }
                                                ::content {
                                                    currentUser()?.name ?: "-"
                                                }
                                            }
                                        }
                                    }
                                },
                            )
                        )


                        ::navItems{
                            if (useNewMenu()) {
                                listOf(
                                    NavLink(Strings.dashboard, Icon.home, { DashboardScreen() }),
                                    NavLink(
                                        title = { Strings.liveAuctions },
                                        icon = { Icon.gavel },
                                        destination = { { AuctionsScreen() } }),
                                    NavLink(
                                        title = { Strings.buyingCenter },
                                        icon = { Icon.buy },
                                        destination = { { BuyingCenterScreen() } }),
                                    NavLink(
                                        title = { Strings.sellingCenter },
                                        icon = { Icon.sell },
                                        destination = { { SellingCenterScreen() } },
                                        hidden = { (currentUser()?.role ?: UserRole.Anonymous) < UserRole.Manager }),
                                    NavLink(
                                        title = { Strings.findVehicles },
                                        icon = { Icon.search },
                                        destination = { { SearchScreen() } }),
                                    NavLink(
                                        title = { Strings.users },
                                        icon = { Icon.people },
                                        destination = { { UsersScreen().apply {} } },
                                    ),
                                )
                            } else {
                                listOf(
                                    NavLink(Strings.dashboard, Icon.home, { DashboardScreen() }),
                                    NavGroup(
                                        title = { Strings.buy },
                                        icon = { Icon.buy },
                                        children = {
                                            listOf(
                                                NavLink(
                                                    title = { Strings.liveAuctions },
                                                    icon = { Icon.gavel },
                                                    destination = { { AuctionsScreen() } }),
                                                NavLink(
                                                    title = { Strings.findVehicles },
                                                    icon = { Icon.search },
                                                    destination = { { SearchScreen() } }),
                                                NavLink(
                                                    title = { Strings.buyingCenter },
                                                    icon = { Icon.buy },
                                                    destination = { { BuyingCenterScreen() } },
                                                )
                                            )
                                        },
                                        hidden = { (currentUser()?.role ?: UserRole.Anonymous) >= UserRole.Manager },
                                    ),
                                    NavGroup(
                                        title = { Strings.sell },
                                        icon = { Icon.sell },
                                        children = {
                                            listOf(
                                                NavLink(
                                                    title = { Strings.liveAuctions },
                                                    icon = { Icon.gavel },
                                                    destination = { { AuctionsScreen() } }),
                                                NavLink(
                                                    title = { Strings.findVehicles },
                                                    icon = { Icon.search },
                                                    destination = { { SearchScreen() } }),
                                                NavLink(
                                                    title = { Strings.uploadNew },
                                                    icon = { Icon.publish },
                                                    destination = { { TakeNewVehicleImagesScreen() } }),
                                                NavLink(
                                                    title = { Strings.sellingCenter },
                                                    icon = { Icon.sell },
                                                    destination = { { SellingCenterScreen() } }
                                                )
                                            )
                                        },
                                        hidden = { (currentUser()?.role ?: UserRole.Anonymous) < UserRole.Manager },
                                    ),
                                    NavLink(
                                        title = { Strings.users },
                                        icon = { Icon.people },
                                        destination = { { UsersScreen().apply {} } },
                                        hidden = { (currentUser()?.role ?: UserRole.Anonymous) < UserRole.Manager }),
                                    NavGroup(
                                        Strings.myAccount, Icon.person, listOf(
                                            NavLink(
                                                title = { Strings.settings },
                                                icon = { Icon.settings },
                                                destination = { { SettingsScreen() } }),
                                            NavLink(
                                                title = { Strings.logOut },
                                                icon = { Icon.logout },
                                                destination = { { LogInScreen() } }),
                                        )
                                    ),
                                )
                            }
                        }
                    }
                }
            )
        }
        connectivityDialog()
    }

}

//val ViewWriter.standardMaxWidth get() = gravity(Align.Center, Align.Stretch) - sizeConstraints(width = 70.rem)
val ViewWriter.standardMaxWidth get() = sizeConstraints(maxWidth = 70.rem)

fun ViewWriter.connectivityDialog() = dismissBackground {
    reactiveScope {
        if (Connectivity.lastConnectivityIssueCode() in Connectivity.tooMuchCodes) {
            val breakdown = recentUrlHits
                .groupBy { it.second }
                .mapValues { it.value.size }.entries
                .sortedByDescending { it.value }
                .joinToString(", ") {
                    "${it.key.substringAfter("://").substringAfter("/")} = ${it.value}"
                }
            Exception("Too many requests. Breakdown: $breakdown").report()
        }
    }
    ::exists { Connectivity.fetchGate.retryAt() != null }
    onClick { }
    centered - card - col {
        h2("Connectivity Issues")
        col {
            ::exists { Connectivity.lastConnectivityIssueCode() !in Connectivity.tooMuchCodes }
            text("Your access to the internet has some problems.")
            text("Please reconnect to the internet to continue.")
        }
        col {
            ::exists { Connectivity.lastConnectivityIssueCode() in Connectivity.tooMuchCodes }
            text("You're requesting too much.")
            text("Please wait for a minute and retry.")
        }
        text {
            launch {
                while (true) {
                    delay(200)
                    content =
                        "Retrying in ${((Connectivity.fetchGate.retryAt() ?: now()) - now()).inWholeSeconds + 1} seconds"
                }
            }
        }
        row {
            expanding - danger - button {
                centered - text("Log Out")
                onClick {
                    Connectivity.fetchGate.abandon()
                    mainScreenNavigator.navigate(LogInScreen())
                }
            }
            expanding - button {
                centered - text("Retry now")
                onClick {
                    Connectivity.fetchGate.retryNow()
                }
            }
        }
    }
}