package com.ilussobsa.views

import com.ilussobsa.*
import com.ilussobsa.Strings
import com.ilussobsa.Vehicle
import com.ilussobsa.sdk.currentSessionNullable
import com.ilussobsa.sdk.currentSession
import com.ilussobsa.utils.*
import com.lightningkite.UUID
import com.lightningkite.ZonedDateTime
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.locale.renderToString
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.field
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.lightningserver.websocket.*
import com.lightningkite.now
import com.lightningkite.nowLocal
import com.lightningkite.serialization.*
import com.lightningkite.GeoCoordinate
import kotlin.js.JsName
import kotlin.jvm.JvmName
import kotlinx.coroutines.*
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.Serializable
import kotlin.math.round

@Routable("edit-vehicle/{vehicleId}")
class EditVehicleScreen(val vehicleId: UUID) : Screen {
    @QueryParameter
    val flowMode = Property(true)

    companion object {
        val log = ConsoleRoot.tag("EditVehicleScreen")
    }

    @Serializable
    enum class Tab(
        val title: String,
        val render: ViewWriter.(vehicle: Property<Vehicle>, proceed: suspend () -> Unit) -> Unit,
        val fields: List<FieldWithValidator<*>>
    ) {
        Images(
            Strings.images, render = { vehicle, proceed ->
                col {
                    expanding - recyclerView {
                        ::columns { round(AppState.windowInfo().width.px / 20.rem.px).toInt() }

                        val photos = LazyProperty { (vehicle().photos + null).withIndex().toList() }
                        reactiveSuspending { vehicle set vehicle.awaitOnce().copy(photos = photos().mapNotNull { it.value }) }
                        fun <T> List<IndexedValue<T>>.rearrange(block: MutableList<T>.() -> Unit): List<IndexedValue<T>> {
                            val mutable = map { it.value }.toMutableList()
                            block(mutable)
                            return mutable.withIndex().toList()
                        }
                        fun <T> List<IndexedValue<T>>.swap(indexA: Int, indexB: Int) =
                            List(size) { index ->
                                if (index == indexA) this[indexB].value
                                else if (index == indexB) this[indexA].value
                                else this[index].value
                            }.withIndex().toList()

                        children(photos) { photo ->
                            card - compact - col {
                                button {
                                    sizeConstraints(width = 20.rem, height = 12.rem) - image {
                                        ::exists { photo().value != null }
                                        scaleType = ImageScaleType.Crop
                                        this.description = ""
                                        ::source { photo().value?.file?.toImage() }
                                    }
                                    sizeConstraints(width = 20.rem, height = 12.rem) - stack {
                                        ::exists { photo().value == null }
                                        centered - col {
                                            centered - icon(Icon.add, "")
                                            centered - text(Strings.add)
                                        }
                                    }
                                    onClick {
                                        val photoWhenClicked = photo()
                                        log.info("Requesting capture")
                                        val images = ExternalServices.requestFiles(listOf("image/*")) ?: return@onClick
                                        log.info("Got images $images")
                                        val session = currentSessionNullable.await() ?: return@onClick
                                        log.info("Uploading ${images.size} images...")
                                        val uploads = images.map { session.uploadFileForRequest().upload(RequestBodyFile(it)) }
                                        log.info("Uploaded")
                                        photos set photos().rearrange {
                                            removeAt(photoWhenClicked.index)
                                            uploads.zip(images).reversed().forEach {
                                                tempImagePointers[it.first] = ImageLocal(it.second)
                                                add(photoWhenClicked.index, VehiclePhoto(it.first))
                                            }
                                            if (last() != null) add(null)
                                        }
                                    }
                                }
                                compact - stack {
                                    compact - centered - row {
                                        ::exists { photo().value != null }
                                        button {
                                            ::enabled { photo().index > 0 }
                                            icon(Icon.chevronLeft, "")
                                            onClick {
                                                val index = photo().index
                                                photos set photos().swap(index, index - 1)
                                            }
                                        }
                                        button {
                                            ::enabled { photo().index < (photos().size - 2) }
                                            icon(Icon.chevronRight, "")
                                            onClick {
                                                val index = photo().index
                                                photos set photos().swap(index, index + 1)
                                            }
                                        }
                                    }
                                    atTopEnd - button {
                                        ::exists { photo().value != null }
                                        icon(Icon.delete, "")
                                        onClick {
                                            val index = photo().index
                                            photos set photos().rearrange {
                                                removeAt(index)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }, fields = listOf(
                Vehicle_photos.required()
            )
        ),
        BasicInformation(
            Strings.basics, render = { it, proceed ->
                col {
                    label {
                        content = Strings.vin
                        fieldTheme - textField {
                            keyboardHints = KeyboardHints.id
                            content bind it.prop(Vehicle.path.vin)
                        }
                    }
                    rowCollapsingToColumn(45.rem) {
                        expanding - rowCollapsingToColumn(30.rem) {
                            weight(2f) - label {
                                content = Strings.year
                                fieldTheme - textField {
                                    keyboardHints = KeyboardHints.integer
                                    content bind it.prop(Vehicle.path.year).lens(
                                        get = { it?.toString() ?: "" },
                                        modify = { o, it -> it.toShortOrNull() ?: o }
                                    )
                                }
                            }
                            weight(3f) - label {
                                content = Strings.make
                                fieldTheme - textField {
                                    keyboardHints = KeyboardHints.title
                                    content bind it.prop(Vehicle.path.make).nullToBlank()
                                }
                            }
                        }
                        expanding - rowCollapsingToColumn(30.rem) {
                            weight(3f) - label {
                                content = Strings.model
                                fieldTheme - textField {
                                    keyboardHints = KeyboardHints.title
                                    content bind it.prop(Vehicle.path.model).nullToBlank()
                                }
                            }
                            weight(3f) - label {
                                content = Strings.trim2
                                fieldTheme - textField {
                                    keyboardHints = KeyboardHints.title
                                    content bind it.prop(Vehicle.path.trim).nullToBlank()
                                }
                            }
                        }
                    }
                    rowCollapsingToColumn(30.rem) {
                        weight(1f) - label {
                            content = Strings.exteriorColor
                            fieldTheme - textField {
                                keyboardHints = KeyboardHints.title
                                content bind it.prop(Vehicle.path.exteriorColor).nullToBlank()
                            }
                        }
                        weight(1f) - label {
                            content = Strings.interiorColor
                            fieldTheme - textField {
                                keyboardHints = KeyboardHints.title
                                content bind it.prop(Vehicle.path.interiorColor).nullToBlank()
                            }
                        }
                    }
                    rowCollapsingToColumn(30.rem) {
                        weight(3f) - label {
                            content = Strings.fuel2
                            fieldTheme - select {
                                bind(it.prop(Vehicle.path.fuelType),
                                    Constant(listOf(null) + FuelType.values().toList()),
                                    { it?.name ?: Strings.selectOne })
                            }
                        }
                        weight(3f) - label {
                            content = Strings.transmission1
                            fieldTheme - select {
                                bind(it.prop(Vehicle.path.transmission),
                                    Constant(listOf(null) + Transmission.values().toList()),
                                    { it?.name ?: Strings.selectOne })
                            }
                        }
                    }
                }
            }, fields = listOf(
                Vehicle_vin.required(),
                Vehicle_year.required(),
                Vehicle_make.required(),
                Vehicle_model.required(),
                Vehicle_trim.optional(),
                Vehicle_transmission.required(),
                Vehicle_fuelType.required(),
                Vehicle_interiorColor.required(),
                Vehicle_exteriorColor.required(),
            )
        ),
        Odometer(
            Strings.odometer, render = { it, proceed ->
                col {
                    centered - sizeConstraints(width = 25.rem) - label {
                        content = Strings.odometer
                        fieldTheme - row {
                            expanding - numberField {
                                content bind it.prop(Vehicle.path.odometer).asDouble()
                            }
                            text("mi")
                        }
                    }
                }
            }, fields = listOf(
                Vehicle_odometer.required(),
            )
        ),
        Condition(
            Strings.condition, render = { it, proceed ->
                col {
                    h3 { content = Strings.keys1 }
                    row {
                        for (value in KeyCount.values()) {
                            expanding - card - radioToggleButton {
                                centered - text("${value.text} keys")
                                checked bind it.prop(Vehicle.path.keys).equalTo(value)
                            }
                        }
                    }
                    h3 { content = Strings.tireCondition1 }
                    row {
                        for (value in TireStatus.values()) {
                            expanding - card - radioToggleButton {
                                col {
                                    centered - text(value.name)
                                    centered - text(value.rangeText)
                                }
                                checked bind it.prop(Vehicle.path.tires).equalTo(value)
                            }
                        }
                    }
                    h3 { content = Strings.activeWarranty }
                    row {
                        for (value in listOf(true, false)) {
                            expanding - card - radioToggleButton {
                                col {
                                    centered - text(Strings.yesOrNo(value))
                                }
                                checked bind it.prop(Vehicle.path.activeWarranty).equalTo(value)
                            }
                        }
                    }
                    h3 { content = Strings.attachments }
                    text { content = Strings.recommendedUploads }
                    col {
                        forEachUpdating(shared {
                            (it().attachments?.indices?.toList() ?: listOf())
                        }) { index ->
                            row {
                                card - button {
                                    centered - icon {
                                        source = Icon.download
                                    }
                                    onClick {
                                        val index = index()
                                        val session = currentSessionNullable.await() ?: return@onClick
                                        ExternalServices.requestFile(listOf("image/png", "image/jpeg", "application/pdf"))?.let {
                                            session.uploadFileForRequest().upload(RequestBodyFile(it))
                                        }?.let { file ->
                                            it.modify {
                                                it.copy(
                                                    attachments = it.attachments?.toMutableList()?.apply {
                                                        this[index] = this[index].copy(file = file)
                                                    } ?: listOf()
                                                )
                                            }
                                        }
                                    }
                                }

                                expanding - fieldTheme - textField {
                                    hint = "Label"
                                    content bind shared {
                                        it().attachments?.getOrNull(
                                            index()
                                        )?.label ?: ""
                                    }
                                        .withWrite { value ->
                                            val index = index()
                                            it.modify {
                                                it.copy(
                                                    attachments = it.attachments.toMutableList().apply {
                                                        this[index] = this[index].copy(label = value)
                                                    }
                                                )
                                            }
                                        }
                                }

                                button {
                                    gravity(
                                        Align.Center,
                                        Align.Center
                                    ) - icon(Icon.delete, Strings.removeEntry)
                                    onClick {
                                        it.modify {
                                            it.copy(attachments = it.attachments.toMutableList().apply {
                                                removeAt(index())
                                            })
                                        }
                                    }
                                }
                            }
                        }
                    }
                    button {
                        centered - row {
                            centered - icon(Icon.add, "")
                            centered - text {
                                ::content {
                                    if (it().attachments.isEmpty()) Strings.add else Strings.addAnother
                                }
                            }
                        }
                        onClick {
                            val session = currentSessionNullable.await() ?: return@onClick
                            ExternalServices.requestFiles(listOf("image/png", "image/jpeg", "application/pdf")).map {
                                it.fileName() to session.uploadFileForRequest().upload(RequestBodyFile(it))
                            }.takeIf { it.isNotEmpty() }?.let { files ->
                                it.modify {
                                    it.copy(
                                        attachments = it.attachments + files.map { Attachment(it.first, it.second) }
                                    )
                                }
                            }
                        }
                    }
                }

            }, fields = listOf(
                Vehicle_tires.required(),
                Vehicle_keys.required(),
                Vehicle_activeWarranty.required(),
                Vehicle_attachments.optional(),
            )
        ),
        Issues(
            Strings.announcements, render = { vehicle, proceed ->
                col {
                    fun extraInfoField(name: String, icon: Icon, property: DataClassPath<Vehicle, ExtraInfo?>) {
                        val selected = shared {
                            vehicle.awaitNotNull().let { property.get(it) } != null
                        }.withWrite {
                            if (it) {
                                vehicle.modify { property.set(it, ExtraInfo()) }
                            } else {
                                vehicle.modify { property.set(it, null) }
                            }
                        }
                        col {
                            row {
                                fieldTheme - checkbox {
                                    checked bind selected
                                }
                                icon {
                                    source = icon
                                }
                                expanding - h3(name)
                            }
                            card - col {
                                ::exists { selected() }
                                label {
                                    content = Strings.explanation
                                    sizeConstraints(minHeight = 6.rem) - fieldTheme - textArea {
                                        keyboardHints = KeyboardHints.paragraph
                                        hint = Strings.explainWhatYouKnowAboutThisHere
                                        content bind vehicle.prop(property.notNull.description).nullToBlank()
                                    }
                                }
                            }
                        }
                    }

                    extraInfoField(Strings.priorAccident, Icon.priorAccident, Vehicle.path.priorAccident)
                    extraInfoField(Strings.paintWork, Icon.paintwork, Vehicle.path.paintwork)
                    extraInfoField(Strings.warningLights, Icon.warning, Vehicle.path.warningLights)
                    extraInfoField(Strings.towRequired, Icon.towing, Vehicle.path.towRequired)
                    extraInfoField(Strings.nonRunner, Icon.close, Vehicle.path.nonRunner)
                    extraInfoField(Strings.structuralDamage, Icon.damage, Vehicle.path.structuralDamage)
                    extraInfoField(Strings.airConditioningIssue, Icon.acIssues, Vehicle.path.airConditioningIssue)
                    extraInfoField(Strings.transmissionIssue, Icon.close, Vehicle.path.transmissionIssue)
                    extraInfoField(Strings.odometerIssue, Icon.speedometer, Vehicle.path.odometerIssue)
                    extraInfoField(Strings.canadian, Icon.canada, Vehicle.path.canadian)

                    h2(Strings.titleBranding)
                    extraInfoField(Strings.salvage, Icon.recycling, Vehicle.path.salvage)
                    extraInfoField(Strings.lemonLaw, Icon.lemon, Vehicle.path.lemonLaw)
                    extraInfoField(Strings.flood, Icon.waterDrop, Vehicle.path.flood)
                    extraInfoField(Strings.stolenRecovery, Icon.money, Vehicle.path.stolenOrRecovery)
                    extraInfoField(Strings.rentalTaxi, Icon.taxi, Vehicle.path.rentalOrTaxi)
                    extraInfoField(Strings.trueMileageUnknown, Icon.close, Vehicle.path.trueMileageUnknown)

                    space(2.0)
                    row {
                        fieldTheme - checkbox {
                            checked bind shared { vehicle().considerationsFilled }
                                .withWrite { v -> vehicle.modify { it.copy(considerationsFilled = v) } }
                        }
                        centered - expanding - text(Strings.informationIsAccurate)
                    }
                }
            }, fields = listOf(
                Vehicle_considerationsFilled.required(),
                Vehicle_priorAccident.optional(),
                Vehicle_paintwork.optional(),
                Vehicle_warningLights.optional(),
                Vehicle_towRequired.optional(),
                Vehicle_nonRunner.optional(),
                Vehicle_structuralDamage.optional(),
                Vehicle_airConditioningIssue.optional(),
                Vehicle_transmissionIssue.optional(),
                Vehicle_odometerIssue.optional(),
                Vehicle_canadian.optional(),
                Vehicle_salvage.optional(),
                Vehicle_lemonLaw.optional(),
                Vehicle_flood.optional(),
                Vehicle_stolenOrRecovery.optional(),
                Vehicle_rentalOrTaxi.optional(),
                Vehicle_trueMileageUnknown.optional(),
            )
        ),
        Location(
            Strings.location, render = { vehicle, proceed ->
                fun ViewWriter.address(address: Writable<Address>) {
                    col {
                        field(Strings.streetAddress) {
                            textInput { content bind address.lensPath { it.street } }
                        }

                        rowCollapsingToColumn(50.rem) {
                            weight(1f) - field(Strings.city) {
                                textInput { content bind address.lensPath { it.city } }
                            }
                            weight(1f) - field(Strings.state) {
                                select {
                                    bind(
                                        address.lensPath { it.state },
                                        Constant(UsState.entries.toList())
                                    ) { it.text }
                                }
                            }
                            weight(1f) - field(Strings.zip) {
                                textInput { content bind address.lensPath { it.zip }.nullToBlank() }
                            }
                        }
                    }
                }

                val locations = listOf(
                    UsAddress("121 Commerce Rd", "Boynton Beach", UsState.FL, "33426"),
                    UsAddress("1561 MacArthur Blvd", "Costa Mesa", UsState.CA, "92626")
                )

                col {
                    for (value in locations) {
                        card - radioToggleButton {
                            text(value.toString())
                            checked bind shared {
                                vehicle().address.run {
                                    street == value.street && city == value.city && state == value.state && zip == value.zip
                                }
                            }.withWrite {
                                if (it) {
                                    vehicle.value = vehicle().copy(
                                        address = Address(
                                            value.street ?: return@withWrite,
                                            value.city ?: return@withWrite,
                                            value.zip,
                                            value.state ?: return@withWrite,
                                            GeoCoordinate(0.0, 0.0)
                                        )
                                    )
                                }
                            }
                        }
                    }
                    space()
                    address(vehicle.lensPath { it.address })
                }
            }, fields = listOf(
                FieldWithValidator(Vehicle_address) {
                    if(it.street.isEmpty()) EditStatus.Empty
                    if(it.city.isEmpty()) EditStatus.Empty
                    if(it.zip.isNullOrEmpty()) EditStatus.Empty
                    else EditStatus.Complete
                }
            )
        ),
        Damages(
            Strings.damages, render = { vehicle, proceed ->
                stack {
                    col {
                        ::exists { vehicle().damage == null }
                        h3(Strings.doesThisVehicleHaveAnyVisibleDamage)
                        row {
                            expanding - important - button {
                                centered - text(Strings.yesTakeTheFirstPicture)
                                onClick {
                                    val image = ExternalServices.requestCaptureEnvironment() ?: return@onClick
                                    val session = currentSessionNullable.await() ?: return@onClick
                                    val upload = session.uploadFileForRequest().upload(
                                        RequestBodyFile(
                                            image
                                        )
                                    )
                                    tempImagePointers[upload] = ImageLocal(image)
                                    vehicle.modify {
                                        it.copy(
                                            damage = listOf(
                                                Damage(comment = "", image = upload)
                                            )
                                        )
                                    }
                                }
                            }
                            expanding - important - button {
                                centered - text(Strings.noItsPerfect)
                                onClick { vehicle.modify { it.copy(damage = listOf()) }/*; proceed()*/ }
                            }
                        }
                    }
                    col {
                        ::exists { vehicle().damage != null }
                        col {
                            forEachUpdating(shared { (vehicle().damage?.indices?.toList() ?: listOf()) }) { index ->
                                sizeConstraints(minHeight = 5.rem) - row {
                                    card - button {
                                        spacing = 0.px
                                        sizeConstraints(width = (20 * 0.3).rem, height = (12 * 0.3).rem) - image {
                                            this.description = ""
                                            scaleType = ImageScaleType.Crop
                                            ::source { vehicle().damage?.getOrNull(index())?.image?.toImage() }
                                        }
                                        onClick {
                                            val index = index()
                                            val image = ExternalServices.requestCaptureEnvironment() ?: return@onClick
                                            val session = currentSessionNullable.await() ?: return@onClick
                                            val upload = session.uploadFileForRequest().upload(RequestBodyFile(image))
                                            tempImagePointers[upload] = ImageLocal(image)
                                            vehicle.modify {
                                                it.copy(
                                                    damage = it.damage?.toMutableList()?.apply {
                                                        this[index] = this[index].copy(image = upload)
                                                    }
                                                )
                                            }
                                        }
                                    }

                                    expanding - fieldTheme - textArea {
                                        hint = Strings.explanation
                                        keyboardHints = KeyboardHints.paragraph
                                        val s = shared { vehicle().damage?.getOrNull(index())?.comment ?: "" }
                                        reactiveScope { s() }
                                        content bind s
                                            .withWrite { value ->
                                                val index = index()
                                                vehicle.modify {
                                                    it.copy(
                                                        damage = it.damage?.toMutableList()?.apply {
                                                            this[index] = this[index].copy(comment = value)
                                                        }
                                                    )
                                                }
                                            }
                                    }

                                    button {
                                        gravity(
                                            Align.Center,
                                            Align.Center
                                        ) - icon(Icon.delete, Strings.removeEntry)
                                        onClick {
                                            vehicle.modify {
                                                it.copy(damage = it.damage?.toMutableList()?.apply {
                                                    removeAt(index())
                                                })
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        centered - bold - text {
                            ::exists { vehicle().damage?.isEmpty() == true }
                            content = Strings.noVisibleDamage
                        }
                        button {
                            centered - row {
                                centered - icon(Icon.add, "")
                                centered - text {
                                    ::content {
                                        if (vehicle().damage?.isEmpty() != false) Strings.add else Strings.addAnother
                                    }
                                }
                            }
                            onClick {
                                val image = ExternalServices.requestCaptureEnvironment() ?: return@onClick
                                val session = currentSessionNullable.await() ?: return@onClick
                                val upload = session.uploadFileForRequest().upload(
                                    RequestBodyFile(
                                        image
                                    )
                                )
                                tempImagePointers[upload] = ImageLocal(image)
                                vehicle.modify {
                                    it.copy(
                                        damage = (it.damage ?: listOf()) + (Damage(
                                            comment = "",
                                            image = upload
                                        ))
                                    )
                                }
                            }
                        }
                    }
                }
            }, fields = listOf(Vehicle_damage.required())
        ),
        Description(
            Strings.description, render = { vehicle, proceed ->
                col {
                    text(Strings.vehicleDescriptionPrompt)
                    sizeConstraints(minHeight = 10.rem) - fieldTheme - textArea {
                        keyboardHints = KeyboardHints.paragraph
                        hint = "Vehicle facts and issues here..."

                        content bind vehicle.prop(Vehicle.path.description).nullToBlank()
                    }
                }
            }, fields = listOf(
                Vehicle_description.optional()
            )
        ),
        Pricing(
            Strings.pricing, render = { vehicle, proceed ->
                col {
                    col {
                        stack {
                            gravity(Align.Start, Align.Center) - h3 { content = Strings.reserve2 }
                            centered - priceField(vehicle.prop(Vehicle.path.reserve))
                        }
                        text(Strings.yourReserveIsPrivateToYouAndCan)
                    }

                    space(4.0)

                    col {
                        ::exists {
                            val v = vehicle()
                            Tab.values().all { it.ready(v) == EditStatus.Complete }
                        }
                        centered - critical - row {
                            centered - checkbox {
                                checked bind shared {
                                    vehicle().submitted != null
                                }.withWrite { v ->
                                    vehicle.modify { it.copy(submitted = if (v) now() else null) }
                                }
                            }
                            centered - bold - text(Strings.listThisVehicleInTheNextAuction)
                        }
                        col {
                            ::exists { vehicle().submitted != null }
                            val lanes = shared {
                                currentSessionNullable.awaitNotNull<com.ilussobsa.sdk.UserSession>().auctions.query(
                                    Query()
                                )()
                            }
                            val lane = shared { lanes().find { it.condition(vehicle()) } }
                            /*centered - text {
                                ::content {
                                    lane()?.let { l ->
                                        val local = ZonedDateTime(
                                            kotlinx.datetime.LocalDateTime(
                                                vehicle().sellingDate ?: nowLocal().date, l.startsAt
                                            ), auctionZone
                                        ).toInstant()
                                            .toLocalDateTime(kotlinx.datetime.TimeZone.Companion.currentSystemDefault())
                                        Strings.yourVehicleWillBeInXOnY(
                                            l.name,
                                            local.renderToString(
                                                com.lightningkite.kiteui.locale.RenderSize.Full,
                                                includeWeekday = true,
                                                includeYear = false,
                                            ),
                                            kotlinx.datetime.TimeZone.Companion.currentSystemDefault().renderToString(
                                                com.lightningkite.kiteui.locale.RenderSize.Abbreviation
                                            )
                                        )
                                    } ?: ""
                                }
                            }*/
                        }
                    }
                }
            }, fields = listOf(
                FieldWithValidator(Vehicle_reserve) {
                    if (it == null) EditStatus.Empty
                    else if (it < 1000) EditStatus.Incomplete
                    else EditStatus.Complete
                },
                Vehicle_submitted.optional(),
            )
        ),
        ;

        data class FieldWithValidator<V>(val field: SerializableProperty<Vehicle, V>, val status: (V) -> EditStatus) {
            fun status(vehicle: Vehicle) = field.get(vehicle).let(status)
        }

        fun ready(vehicle: Vehicle) = fields.minOf { it.status(vehicle) }
    }

    private val allEditedProps = Tab.values().flatMap { it.fields.map { it.field } }

    @QueryParameter
    val tab = Property<Tab>(Tab.Images)

    val vehicle = Property<Vehicle>(Vehicle(vin = ""))
    val originalVehicle by lazy {
        LateInitProperty<Vehicle>().also {
            launchGlobal {
                it.value = currentSessionNullable.awaitNotNull().vehicles[vehicleId].awaitNotNull().also { vehicle set it }
            }
        }
    }

    val Tab.status get() = shared { ready(vehicle()) }

    override fun ViewWriter.render() {
        col {
            scrollsHorizontally - row {
                spacing = 0.px
                expanding - space()
                for (t in Tab.values()) sizeConstraints(width = 9.rem) - unpadded - radioToggleButton {
                    col {
                        space()
                        row {
                            spacing = 0.px
                            if (t == Tab.values()
                                    .first()
                            ) expanding - space() else centered - expanding - col { separator() }
                            centered - icon {
                                ::source label@{
                                    t.status().icon
                                }
                            }
                            if (t == Tab.values()
                                    .last()
                            ) expanding - space() else centered - expanding - col { separator() }
                        }
                        centered - text(t.title)
                        space()
                    }
                    checked bind tab.equalTo(t)
                    reactiveScope {
                        if (tab() == t) scrollIntoView(Align.Center, Align.Center, true)
                    }
                }
                expanding - space()
            }
            expanding - swapView {
                swapping(current = { tab() }, views = {
                    pageWithMaxWidth(50.rem) {
                        scrolls - padded - it.render(this, vehicle) { proceed() }
                    }
                })
            }
            row {
                val saveNeeded = shared {
                    val a = originalVehicle()
                    val b = vehicle()
                    allEditedProps.any { it.get(a) != it.get(b) }
                }
                val readyToProceed = shared {
                    val v = vehicle()
                    tab().ready(v) == EditStatus.Complete
                }
                expanding - space()
                row {
                    expanding - space()
                    button {
                        ::exists { !flowMode() }
                        text("Cancel")
                        onClick {
                            navigator.goBack()
                        }
                    }
                    important - button {
                        ::enabled {
                            if (flowMode()) readyToProceed() else saveNeeded()
                        }
                        text {
                            ::content {
                                if (flowMode()) {
                                    if (saveNeeded()) "Save and Continue"
                                    else "Continue"
                                } else {
                                    if (saveNeeded()) Strings.save
                                    else Strings.saved
                                }
                            }
                        }
                        onClick {
                            proceed()
                        }
                    }
                }
            }
        }
    }

    suspend fun ViewWriter.proceed() {
        val v = vehicle()
        try {
            val changeClearsAutobids = originalVehicle.state.getOrNull()?.minorInfoHash != vehicle.value.minorInfoHash
            originalVehicle set (currentSession().vehicles.get(vehicleId).modify(modification {
                for (prop in allEditedProps) {
                    it.get(prop as SerializableProperty<Vehicle, Any?>).assign(prop.get(v))
                }
            })!!).let {
                if(changeClearsAutobids) it.copy(autobids = 0) else it
            }
            if(changeClearsAutobids) {
                currentSession().vehicleRelationships.localSignalUpdate(
                    matching = { it._id.vehicle == vehicleId },
                    modify = { it.copy(autobid = null) }
                )
            }
            vehicle set originalVehicle.value
        } catch (e: Exception) {
            alert(Strings.error, Strings.thereWasAnErrorSavingYourChanges)
            return
        }
        if (flowMode()) {
            val nextTab = Tab.values().getOrNull(tab().ordinal + 1)
            if (nextTab != null) {
                tab set nextTab
            } else {
                navigator.replace(SellingCenterScreen().apply {
                    this.tab set if (v.submitted != null) SellingCenterScreen.SellingTab.Inventory else SellingCenterScreen.SellingTab.Draft
                    this.selectedLot set vehicleId
                })
            }
        } else {
            navigator.goBack()
        }
    }
}

enum class EditStatus(val icon: Icon) { Empty(Icon.emptyCircle), Incomplete(Icon.emptyCircle), Complete(Icon.checkCircle) }

internal fun <T, V> Writable<T>.prop(path: DataClassPath<T, V>): Writable<V> =
    shared { this@prop().let { path.get(it) as V } }
        .withWrite { v -> this@prop.modify { path.set(it, v) } }

internal fun Writable<String?>.nullToBlank() = shared { this@nullToBlank() ?: "" }
    .withWrite { v -> this@nullToBlank set (v.takeUnless { it.isBlank() }) }

internal fun Writable<Short?>.toInt() = shared { this@toInt()?.toInt() }
    .withWrite { v -> this@toInt set (v?.toShort()) }

internal val tempImagePointers = HashMap<ServerFile, ImageLocal>()
internal fun ServerFile.toImage(): ImageSource = tempImagePointers[this] ?: ImageRemote(location)

@JsName("requiredServerFile")
@JvmName("requiredServerFile")
private inline fun SerializableProperty<Vehicle, ServerFile?>.required() =
    EditVehicleScreen.Tab.FieldWithValidator(this) { if (it == null) EditStatus.Empty else EditStatus.Complete }

@JsName("requiredBoolean")
@JvmName("requiredBoolean")
private inline fun SerializableProperty<Vehicle, Boolean>.required() =
    EditVehicleScreen.Tab.FieldWithValidator(this) { if (!it) EditStatus.Empty else EditStatus.Complete }

@JsName("requiredStringN")
@JvmName("requiredStringN")
private inline fun SerializableProperty<Vehicle, String?>.required() =
    EditVehicleScreen.Tab.FieldWithValidator(this) { if (it == null || it.isBlank()) EditStatus.Empty else EditStatus.Complete }

@JsName("requiredString")
@JvmName("requiredString")
private inline fun SerializableProperty<Vehicle, String>.required() =
    EditVehicleScreen.Tab.FieldWithValidator(this) { if (it.isBlank()) EditStatus.Empty else EditStatus.Complete }

@JsName("requiredPhotos")
@JvmName("requiredPhotos")
private inline fun SerializableProperty<Vehicle, List<VehiclePhoto>>.required() =
    EditVehicleScreen.Tab.FieldWithValidator(this) { if (it.isEmpty()) EditStatus.Empty else EditStatus.Complete }

@JsName("requiredVN")
@JvmName("requiredVN")
private inline fun <V> SerializableProperty<Vehicle, V?>.required() =
    EditVehicleScreen.Tab.FieldWithValidator(this) { if (it == null) EditStatus.Empty else EditStatus.Complete }

@JsName("requiredV")
@JvmName("requiredV")
private inline fun <V> SerializableProperty<Vehicle, V>.optional() =
    EditVehicleScreen.Tab.FieldWithValidator(this) { EditStatus.Complete }