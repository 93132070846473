// Automatically generated based off paymentModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.UUID
import com.lightningkite.lightningdb.GenerateDataClassPaths
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import com.lightningkite.lightningdb.*
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID
fun preparePaymentMethodFields() {
    val props: Array<SerializableProperty<PaymentMethod, *>> = arrayOf(PaymentMethod_id, PaymentMethod_name, PaymentMethod_bank, PaymentMethod_default)
    PaymentMethod.serializer().properties { props }
}
val <K> DataClassPath<K, PaymentMethod>.id: DataClassPath<K, String> get() = this[PaymentMethod_id]
val <K> DataClassPath<K, PaymentMethod>.name: DataClassPath<K, String?> get() = this[PaymentMethod_name]
val <K> DataClassPath<K, PaymentMethod>.bank: DataClassPath<K, Bank?> get() = this[PaymentMethod_bank]
val <K> DataClassPath<K, PaymentMethod>.default: DataClassPath<K, Boolean> get() = this[PaymentMethod_default]
inline val PaymentMethod.Companion.path: DataClassPath<PaymentMethod, PaymentMethod> get() = path<PaymentMethod>()


object PaymentMethod_id: SerializableProperty<PaymentMethod, String> {
    override val name: String = "id"
    override fun get(receiver: PaymentMethod): String = receiver.id
    override fun setCopy(receiver: PaymentMethod, value: String) = receiver.copy(id = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = PaymentMethod.serializer().tryFindAnnotations("id")
}
object PaymentMethod_name: SerializableProperty<PaymentMethod, String?> {
    override val name: String = "name"
    override fun get(receiver: PaymentMethod): String? = receiver.name
    override fun setCopy(receiver: PaymentMethod, value: String?) = receiver.copy(name = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = PaymentMethod.serializer().tryFindAnnotations("name")
}
object PaymentMethod_bank: SerializableProperty<PaymentMethod, Bank?> {
    override val name: String = "bank"
    override fun get(receiver: PaymentMethod): Bank? = receiver.bank
    override fun setCopy(receiver: PaymentMethod, value: Bank?) = receiver.copy(bank = value)
    override val serializer: KSerializer<Bank?> = Bank.serializer().nullable2
    override val annotations: List<Annotation> = PaymentMethod.serializer().tryFindAnnotations("bank")
}
object PaymentMethod_default: SerializableProperty<PaymentMethod, Boolean> {
    override val name: String = "default"
    override fun get(receiver: PaymentMethod): Boolean = receiver.default
    override fun setCopy(receiver: PaymentMethod, value: Boolean) = receiver.copy(default = value)
    override val serializer: KSerializer<Boolean> = Boolean.serializer()
    override val annotations: List<Annotation> = PaymentMethod.serializer().tryFindAnnotations("default")
}
