package com.ilussobsa.views

import com.ilussobsa.*
import com.ilussobsa.Strings
import com.ilussobsa.sdk.*
import com.ilussobsa.utils.*
import com.ilussobsa.views.dialogs.ConfirmUpdateListing
import com.ilussobsa.views.dialogs.ExpandedImage
import com.lightningkite.UUID
import com.lightningkite.kiteui.locale.renderDateToString
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import com.lightningkite.uuid
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.hours
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock.System.now
import kotlin.math.max
import kotlin.math.min

fun ViewWriter.vehicleInfo(selectedLot: UUID, immutable: Boolean = false, goto: suspend (vehicleId: UUID?) -> Unit) {
    val selected = shared { currentSessionNullable.awaitNotNull().vehicles.get(selectedLot)() }
    val vehicle = selected

    col {

        rowCollapsingToColumn(110.rem) {
            atTopCenter - sizeConstraints(width = 35.rem) - col {
                val images = shared {
                    vehicle()?.let {
                        it.photos.map {
                            it.file to ""
                        } + (it.damage ?: listOf()).map {
                            it.image to it.comment
                        }
                    } ?: listOf()
                }
                val selectedImageIndex = Property(0)
                val selectedImage = shared { images().getOrNull(selectedImageIndex()) }
                sizeConstraints(
                    height = (35.0 * 2 / 3).rem.coerceAtMost(
                        WindowInfo.value.width.minus(defaultAppTheme.spacing * 2).times(2).div(3)
                    )
                ) - stack {
                    image {
                        ::source {
                            selectedImage()?.first?.location?.let(::ImageRemote)
                        }
                        this.description = ""
                        scaleType = ImageScaleType.Crop
                    }
                    gravity(Align.Start, Align.Center) - card - button {
                        icon(Icon.chevronLeft, Strings.advanceLeft)
                        ::exists { selectedImageIndex() > 0 }
                        onClick {
                            val previous = (selectedImageIndex() - 1).takeIf { it >= 0 } ?: return@onClick
                            selectedImageIndex set previous
                        }
                    }
                    gravity(Align.End, Align.Center) - card - button {
                        icon(Icon.chevronRight, Strings.advanceRight)
                        ::exists { selectedImageIndex() + 1 < images().size }
                        onClick {
                            val next = (selectedImageIndex() + 1).takeIf { it < images().size } ?: return@onClick
                            selectedImageIndex set next
                        }
                    }
                }
                sizedBox(SizeConstraints(height = 5.rem)) - stack {
                    val imageSelectionRecyclerView = horizontalRecyclerView {
                        children(images) {
                            stack {
                                unpadded - button {
                                    sizedBox(SizeConstraints(width = 8.rem)) - image {
                                        ::source { it().first?.location?.let(::ImageRemote) }
                                        this.description = ""
                                        scaleType = ImageScaleType.Crop
                                    }
                                    onClick { selectedImageIndex set images().indexOf(it.await()) }
                                }
                            }
                        }
                    }
                    gravity(Align.Start, Align.Center) - card - button {
                        icon(Icon.chevronLeft, Strings.advanceLeft)
                        ::enabled { imageSelectionRecyclerView.firstVisibleIndex() > 0 }
                        onClick {
                            val goToIndex = max(imageSelectionRecyclerView.firstVisibleIndex() - 3, 0)
                            imageSelectionRecyclerView.scrollToIndex(goToIndex, Align.Start)
                        }
                    }
                    gravity(Align.End, Align.Center) - card - button {
                        icon(Icon.chevronRight, Strings.advanceRight)
                        ::enabled {
                            println("lastVisibileIndex=${imageSelectionRecyclerView.lastVisibleIndex()}, size=${images().size}")
                            imageSelectionRecyclerView.lastVisibleIndex() < (images().size - 1)
                        }
                        onClick {
                            val goToIndex = min(imageSelectionRecyclerView.lastVisibleIndex() + 3, images().size - 1)
                            imageSelectionRecyclerView.scrollToIndex(goToIndex, Align.End)
                        }
                    }
                }
            }
            expanding - vehicleSideCard(
                selected,
                selectedLot,
                immutable,
                goto,
            )
        }
        vehicleDescription(selected)
        vehicleWinner(selected)

        rowCollapsingToColumn(50.rem) {
            expanding - vehicleConsiderations(vehicle)
            expanding - vehicleDamage(vehicle)
        }

        vehiclePreviousRuns(vehicle)
    }
}

fun ViewWriter.vehicleDescription(vehicle: Readable<Vehicle?>) {
    card - col {
        h2(Strings.description)
        text {
            ::exists { vehicle()?.description != null }
            ::content { vehicle()?.description ?: "" }
        }
        italic - text {
            ::exists { vehicle()?.description == null }
            content = Strings.noVehicleDescriptionProvided
        }
    }
}

fun ViewWriter.vehicleSideCard(
    selected: Readable<Vehicle?>,
    selectedLot: UUID,
    immutable: Boolean,
    goto: suspend (vehicleId: UUID?) -> Unit,
) {
    val vehicle = selected
    val isBuyer = shared { selected()?.completion?.winner == currentUser()?._id }
    val reserve = Property<Int?>(null)
    val proxyBid = Property<Int?>(null)
    card - col {
        launch {
            reserve.set(vehicle.awaitNotNull().reserve)
        }
        h2 {
            ::content {
                selected()?.statusDescription() ?: ""
            }
        }

        if (immutable) {
            row {
                ::exists{ isSeller() }
                gravity(Align.Start, Align.Center) - bold - text(Strings.reserve1)
                expanding - text {
                    ::exists { selected()?.completion != null }
                    ::content{
                        selected()?.reserve?.renderPriceInDollars() ?: "-"
                    }
                }
            }
        } else {
            row {
                ::exists{ isSeller() }
                gravity(Align.Start, Align.Center) - bold - text(Strings.reserve1)
                priceField(reserve) { field ->
                    ::exists { selected()?.completion == null }
                    centered - compact - unpadded - button {
                        spacing = 0.px
                        ::enabled { reserve() != selected()?.reserve }
                        icon(Icon.done, Strings.save)
                        onClickAssociatedField(field) {
                            if (reserve.await() !== null) {
                                vehicle.modify(modification {
                                    it.reserve assign reserve.value
                                })
                            } else {
                                alert(Strings.error, Strings.thisFieldIsRequiredPleaseProvideAResponse)
                            }
                        }
                    }
                }
                expanding - text {
                    ::exists { selected()?.completion != null }
                    ::content {
                        selected()?.reserve?.renderPriceInDollars() ?: "Not set"
                    }
                }
            }
            vehicleActionButtons(selected, selectedLot, goto, isSeller)
            row {
                val existingProxyBid =
                    vehicleRelationshipUpsertProp(selectedLot, VehicleRelationship.path.autobid)
                launch {
                    proxyBid set existingProxyBid()
                }
                ::exists { !isSeller() }
                gravity(Align.Start, Align.Center) - bold - text(Strings.proxyBid)
                priceField(proxyBid) { field ->
                    ::exists { selected()?.completion == null }
                    centered - compact - unpadded - button {
                        spacing = 0.px
                        ::exists { selected()?.liveAt == null }
                        ::enabled { proxyBid() != existingProxyBid() }
                        text {
                            ::content { if (proxyBid() != existingProxyBid()) Strings.save else Strings.saved }
                        }
                        onClickAssociatedField(field) {
                            vehicleRelationshipUpsert(selectedLot, modification {
                                it.autobid assign proxyBid.value
                                it.autobidBeaten assign null
                            })
                        }
                    }
                }
                expanding - text {
                    ::exists { selected()?.completion != null }
                    ::content{
                        existingProxyBid()?.renderPriceInDollars() ?: "-"
                    }
                }
            }
        }
        important - row {
            ::exists{ selected()?.certifiedPreOwned != null }
            centered - sizeConstraints(2.rem, 2.rem) - icon {
                source = Icon.certification
            }
            compact - col {
                text {
                    ::content{ Strings.thisVehicleAppearsToMeetTheMinimumCriteria }
                }
                bold - text {
                    ::content{ selected()?.certifiedPreOwned ?: "-" }
                }
            }

        }
        compact - rowCollapsingToColumn(30.rem, 95.rem, 110.rem) {
            expanding - col {
                ::exists { selected.awaitNotNull().exteriorColor?.isNotEmpty() == true || selected.awaitNotNull().interiorColor?.isNotEmpty() == true || selected.awaitNotNull().trim?.isNotEmpty() == true }
                row {
                    ::exists { selected.awaitNotNull().exteriorColor?.isNotEmpty() == true }
                    bold - text(Strings.exterior2)
                    centered - text { ::content{ selected.awaitNotNull().exteriorColor.toString() } }
                }
                row {
                    ::exists { selected.awaitNotNull().interiorColor?.isNotEmpty() == true }
                    bold - text(Strings.interior2)
                    centered - text {
                        ::content{ selected.awaitNotNull().interiorColor.toString() }
                    }
                }
                row {
                    ::exists { selected.awaitNotNull().trim?.isNotEmpty() == true }
                    bold - text(Strings.trim1)
                    centered - text {
                        ::content{ selected.awaitNotNull().trim.toString() }
                    }
                }
                row {
                    ::exists { selected.awaitNotNull().keys !== null }
                    bold - text(Strings.keys2)
                    centered - text {
                        ::content{ selected.awaitNotNull().keys?.text ?: "-" }
                    }
                }
//                row {
//                    ::exists { selected.awaitNotNull().reconditioning !== null }
//                    bold - text(Strings.reconditioning2)
//                    centered - text {
//                        ::content{ Strings.reconditioningLabel(selected.awaitNotNull().reconditioning) }
//                    }
//                }
            }
            expanding - col {
                ::exists { selected.awaitNotNull().transmission !== null || selected.awaitNotNull().fuelType !== null }

                row {
                    ::exists { selected.awaitNotNull().transmission !== null }
                    bold - text(Strings.transmission2)
                    centered - text {
                        ::content{ selected.awaitNotNull().transmission.toString() }
                    }
                }
                row {
                    ::exists { selected.awaitNotNull().fuelType !== null }
                    bold - text(Strings.fuel1)
                    centered - text {
                        ::content{ selected.awaitNotNull().fuelType.toString() }
                    }
                }
                row {
                    ::exists { selected.awaitNotNull().tires !== null }
                    bold - text(Strings.tireCondition2)
                    centered - text {
                        ::content{
                            "${
                                selected.awaitNotNull().tires?.range?.start?.times(100)?.roundToInt()
                            }% - ${
                                selected.awaitNotNull().tires?.range?.endInclusive?.times(100)
                                    ?.roundToInt()
                            }%"
                        }
                    }
                }
                row {
                    ::exists { selected.awaitNotNull().activeWarranty !== null }
                    bold - text(Strings.activeWarranty2)
                    centered - text {
                        ::content{ selected.awaitNotNull().activeWarranty?.let(Strings::yesOrNo) ?: "-" }
                    }
                }
                row {
                    val previousIterations = shared {
                        val current = selected.awaitNotNull()
                        currentSessionNullable.awaitNotNull().vehicles.query(Query(
                            condition {
                                it.vin.eq(current.vin) and
                                        it.cancellationReason.notInside(CancellationReason.PRIVACY_SENSITIVE)
                            },
                            sort { it.submitted.notNull.ascending() }
                        ))().filter { it._id != current._id }
                    }
                    exists = false
                    ::exists { previousIterations().isNotEmpty() }
                    bold - text(Strings.previously)
                    centered - text {
                        ::content{
                            previousIterations().lastOrNull()?.statusDescription() ?: ""
                        }
                    }
                }
                row {
                    bold - text {
                        wraps = false
                        content = "Location:"
                    }
                    expanding - centered - text {
                        ::content{ selected.awaitNotNull().address.toString() }
                    }
                }
            }
        }

        compact - row {
            ::exists{ isSeller() }
            expanding - row {
                bold - text(Strings.proxyBids1)
                centered - text {
                    ::content {
                        val b = selected()?.autobids ?: 0
                        if (b == 0) {
                            Strings.none
                        } else {
                            b.toString()
                        }
                    }
                }
            }
            expanding - row {
                ::exists{
                    selected()?.completion != null
                }
                bold - text(Strings.bids)
                centered - text {
                    ::content{
                        (selected()?.completion?.bids ?: Strings.none).toString()
                    }
                }
            }
        }
        expanding - space()
        /*col {
            ::exists{ !isSeller.await() && currentDealershipId() != selected()?.completion?.winner }
            spacing = 0.px
            h6(Strings.soldBy)
            themeFromLast { it.copy(cornerRadii = CornerRadii.Constant(0.75.rem)) } - unpadded - link {
                if (screenNavigator == dialogScreenNavigator) {
                    onNavigator = dialogScreenNavigator
                    ::to { val id = seller.awaitNotNull()._id; { SellingDialogWrapper(ProfileScreen(id)) } }
                } else {
                    ::to { val id = seller.awaitNotNull()._id; { ProfileScreen(id) } }
                }
                row {
                    dealershipShortDetails(seller.waitForNotNull, context = UsersScreen.DealerContext.Sell)
                    expanding - space()
                    centered - icon { source = Icon.chevronRight }
                }

            }
        }*/
    }
}

fun ViewWriter.vehicleActionButtons(
    selected: Readable<Vehicle?>,
    selectedLot: UUID,
    goto: suspend (vehicleId: UUID?) -> Unit,
    isSeller: Readable<Boolean>,
) {

    row {
        row {
            exists = false
            ::exists {
                selected.awaitNotNull()
                    .let { it.completion?.sold == true && it.completion?.winner == currentUser()?._id }
            }
            compact - important - button {
                row {
                    sizeConstraints(1.5.rem, 1.5.rem) - icon(Icon.done, "")
                    centered - text(Strings.vehicleReceived)
                }
                ::exists { selected.awaitNotNull().received == null }
                onClick {
                    currentSessionNullable.awaitNotNull().vehicles.get(selected.awaitNotNull()._id).modify(modification {
                        it.received assign now()
                    })
                }
            } //done
        }
        row {
            exists = false
            ::exists {
                selected.awaitNotNull()
                    .let { isSeller() && it.completion?.sold == true }
            }
            compact - important - button {
                row {
                    sizeConstraints(1.5.rem, 1.5.rem) - icon(Icon.done, "")
                    centered - text(Strings.paymentReceived)
                }
                ::exists { selected.awaitNotNull().paid == null }
                onClick {
                    currentSessionNullable.awaitNotNull().vehicles.get(selected.awaitNotNull()._id).modify(modification {
                        it.paid assign now()
                    })
                }
            } //done diff
        }

        compact - important - button {
            compact - row {
                icon(Icon.sync, Strings.rerunInTheUpcomingWeek)
                centered - text(Strings.rerunInTheUpcomingWeek)
            }
            exists = false
            ::exists {
                selected()
                    .let { it?.completion?.sold == false && isSeller() && it.cancelled != null && it.rerunStarted == null }
            }
            onClick {
                currentSession().vehicles.get(selectedLot).modify(modification {
                    it.rerunStarted assign now()
                })
                currentSession().vehicles.insert(
                    selected.awaitNotNull().copy(
                        _id = uuid(),
                        submitted = now(),
                        liveAt = null,
                        completion = null,
                        paid = null,
                        received = null,
                        cancelled = null,
                        rerunStarted = null,
                        auctionLane = null,
                    )
                )
                goto(null)
            }
        }  //rerun
        compact - important - button {
            exists = false
            ::exists {
                isSeller() && selected()?.liveAt == null && selected()?.completion == null
                        && selected()?.archived == null
            }
            centered - text(Strings.updateListing)
            onClick {
                if ((selected.await()?.autobids ?: 0) == 0) navigator.navigate(
                    EditVehicleScreen(selectedLot).apply {
                        flowMode.value = false
                    }
                )
                else dialogScreenNavigator.navigate(ConfirmUpdateListing(selectedLot))
            }
        }
        compact - important - button {
            exists = false
            ::exists{
                isSeller()
                        && selected()?.submitted == null
                        && selected()?.archived == null
            }
            centered - text(Strings.submitToAuction)
            onClick {
                val v = currentSessionNullable.awaitNotNull().vehicles.get(selectedLot)
                val v2 = v() ?: return@onClick
                EditVehicleScreen.Tab.values().firstOrNull { it.ready(v2) != EditStatus.Complete }?.let {
                    screenNavigator.navigate(EditVehicleScreen(v2._id))
                } ?: v.modify(modification { it.submitted assign now() })
            }
        }
        compact - danger - button {
            exists = false
            ::exists {
                isSeller() && selected()
                    ?.let { it.liveAt == null && it.completion == null && it.submitted != null } == true
            }
            centered - text(Strings.unlist)
            onClick {
                selected.modify(modification { it.submitted assign null })
            }
        }
        compact - danger - button {
            exists = false
            ::exists {
                isSeller() && selected()
                    ?.let { it.submitted == null || it.cancelled != null } == true
            }
            centered - text {
                ::content { if (selected()?.archived == null) Strings.archive else Strings.unarchive }
            }
            onClick {
                selected.modify(modification { it.archived assign (if (selected()?.archived == null) now() else null) })
            }
        }
    }
}

private suspend fun Readable<Vehicle?>.modify(modification: Modification<Vehicle>) {
    currentSessionNullable()!!.vehicles.get(awaitNotNull()._id).modify(modification)
}

/*fun ViewWriter.vehicleSeller(vehicle: Readable<Vehicle?>, seller: Readable<Dealership?>) =
    vehicleContactInfo({ Strings.seller },
        shared { seller()?.takeIf { vehicle()?.completion?.winner == currentDealershipId() } })*/

/*fun ViewWriter.vehicleContactInfo(
    title: suspend () -> String,
    seller: Readable<Dealership?>,
    setup: ContainingView.() -> Unit = {}
) {
    card - col {
        exists = false
        ::exists { seller() != null }
        h2 { ::content { title() } }
        rowCollapsingToColumn(70.rem) {
            centered - link {
                if (screenNavigator == dialogScreenNavigator) {
                    onNavigator = dialogScreenNavigator
                    ::to { val id = seller.awaitNotNull()._id; { SellingDialogWrapper(ProfileScreen(id)) } }
                } else {
                    ::to { val id = seller.awaitNotNull()._id; { ProfileScreen(id) } }
                }
                dealershipShortDetails(
                    seller.waitForNotNull,
                    context = UsersScreen.DealerContext.Buy
                )
            }
            expanding - scrollsHorizontally - row {
                contactInfo(
                    Strings.usedCarManager,
                    shared { currentSession().users[seller.awaitNotNull().usedCarManager].awaitNotNull() })
                contactInfo(
                    Strings.titleManager,
                    shared { currentSession().users[seller.awaitNotNull().titleManager].awaitNotNull() })
                contactInfo(
                    Strings.transportContact,
                    shared { currentSession().users[seller.awaitNotNull().transportManager].awaitNotNull() })
            }
        }
        setup()
    }
}*/

fun ViewWriter.vehicleWinner(selected: Readable<Vehicle?>) {
    /*val winner = shared {
        val sel = selected() ?: return@shared null
        if (sel.seller == currentDealershipId())
            sel.completion?.winner?.let { currentSessionNullable.awaitNotNull().dealerships[it] }?.await()
        else null
    }
    vehicleContactInfo({ if (selected()?.completion?.sold == true) Strings.buyer else Strings.auctionWinner }, winner)*/
}

fun ViewWriter.vehiclePreviousRuns(vehicle: Readable<Vehicle?>) {
    card - col {
        ::exists { isSeller() }
        h2(Strings.previousRuns)
        val previousIterationsA = shared {
            val current = vehicle.awaitNotNull()
            currentSession().vehicles.query(Query(
                condition {
                    it.vin.eq(current.vin) and
                            it._id.neq(current._id) and
                            it.cancellationReason.notInside(CancellationReason.PRIVACY_SENSITIVE)
                },
                sort { it.submitted.notNull.ascending() }
            ))
        }
        val previousIterations = shared { previousIterationsA()() }
        col {
            forEachUpdating(previousIterations) {
                link {
                    ::to { val id = it.awaitNotNull()._id; { VehicleDetailScreen(id) } }
                    row {
                        text {
                            ::content{
                                it().let { it.completion?.at ?: it.submitted ?: it.createdAt }.renderDateToString()
                            }
                        }
                        text("-")
                        text {
                            ::content { it().statusDescription() }
                        }
                    }
                }
            }
        }
        onlyWhen { previousIterations().isEmpty() } - text(Strings.thisIsTheFirstTimeThisVehicleHas)
    }
}

fun ViewWriter.vehicleDamage(vehicle: Readable<Vehicle?>) {
    card - col {
        h2(Strings.damage)
        val entries = shared { vehicle()?.damage ?: listOf() }
        col {
            forEach(entries) { it ->
                button {
                    onClick {
                        dialogScreenNavigator.navigate(
                            ExpandedImage(
                                it.image.location.let(
                                    ::ImageRemote
                                )
                            )
                        )
                    }
                    row {
                        sizeConstraints(width = 5.rem, height = 5.rem) - image {
                            scaleType = ImageScaleType.Crop
                            ::source {
                                it.image.let { ImageRemote(it.location) }
                            }
                            this.description = ""
                        }

                        centered - text {
                            ::content{
                                it.comment
                            }
                        }
                    }
                }
            }
        }
        italic - text {
            ::exists { entries().isEmpty() }
            content = Strings.thisVehicleHasNoReportedDamage
        }
    }
}

fun ViewWriter.vehicleConsiderations(vehicle: Readable<Vehicle?>) {
    card - col {
        h2(Strings.announcements)
        fun extraInfoField(
            name: String,
            property: (Vehicle) -> ExtraInfo?,
            icon: Icon,
        ) {
            col {
                ::exists {
                    vehicle.awaitNotNull().let { property(it) } != null
                }
                row {
                    centered - compact - icon {
                        ::source{ icon }
                    }
                    centered - expanding - h4(name)
                }
                text {
                    ::exists { vehicle.awaitNotNull().let { property(it) }?.description != null }
                    ::content{
                        vehicle.awaitNotNull().let { property(it) }?.description ?: ""
                    }
                }
                italic - text {
                    ::exists { vehicle.awaitNotNull().let { property(it) }?.description == null }
                    content = Strings.noAdditionalInformationWasProvided
                }
                space()
            }
        }


        extraInfoField(
            Strings.completelyClean,
            { it.completelyClean },
            Icon.clean
        )
        extraInfoField(
            Strings.priorAccident,
            { it.priorAccident },
            Icon.priorAccident
        )
        extraInfoField(Strings.paintWork, { it.paintwork }, Icon.paintwork)
        extraInfoField(Strings.warningLights, { it.warningLights }, Icon.warning)
        extraInfoField(Strings.towRequired, { it.towRequired }, Icon.towing)
        extraInfoField(Strings.nonRunner, { it.nonRunner }, Icon.remove)
        extraInfoField(
            Strings.structuralDamage,
            { it.structuralDamage },
            Icon.damage
        )
        extraInfoField(
            Strings.airConditioningIssue,
            { it.airConditioningIssue },
            Icon.acIssues
        )
        extraInfoField(
            Strings.transmissionIssue,
            { it.transmissionIssue },
            Icon.manual
        )
        extraInfoField(
            Strings.odometerIssue,
            { it.odometerIssue },
            Icon.speedometer
        )
        extraInfoField(Strings.canadian, { it.canadian }, Icon.canada)
        // extraInfoField(Strings.titleNotPresent, { it.titleNotPresent }, Icon.missingTitle)
        extraInfoField(Strings.salvage, { it.salvage }, Icon.recycling)
        extraInfoField(Strings.lemonLaw, { it.lemonLaw }, Icon.lemon)
        extraInfoField(Strings.flood, { it.flood }, Icon.waterDrop)
        extraInfoField(Strings.stolenRecovery, { it.stolenOrRecovery }, Icon.money)
        extraInfoField(Strings.rentalTaxi, { it.rentalOrTaxi }, Icon.taxi)
        extraInfoField(Strings.trueMileageUnknown, { it.trueMileageUnknown }, Icon.close)
    }
}
