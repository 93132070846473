// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareFinancingApplicantFields() {
    val props: Array<SerializableProperty<FinancingApplicant, *>> = arrayOf(FinancingApplicant_email, FinancingApplicant_phoneNumber, FinancingApplicant_givenName, FinancingApplicant_middleName, FinancingApplicant_surname, FinancingApplicant_driversLicenseNumber, FinancingApplicant_driversLicenseExpiration, FinancingApplicant_birthday, FinancingApplicant_socialSecurityNumber, FinancingApplicant_residency, FinancingApplicant_previousResidency, FinancingApplicant_employer, FinancingApplicant_previousEmployer)
    FinancingApplicant.serializer().properties { props }
}
val <K> DataClassPath<K, FinancingApplicant>.email: DataClassPath<K, String?> get() = this[FinancingApplicant_email]
val <K> DataClassPath<K, FinancingApplicant>.phoneNumber: DataClassPath<K, String?> get() = this[FinancingApplicant_phoneNumber]
val <K> DataClassPath<K, FinancingApplicant>.givenName: DataClassPath<K, String?> get() = this[FinancingApplicant_givenName]
val <K> DataClassPath<K, FinancingApplicant>.middleName: DataClassPath<K, String?> get() = this[FinancingApplicant_middleName]
val <K> DataClassPath<K, FinancingApplicant>.surname: DataClassPath<K, String?> get() = this[FinancingApplicant_surname]
val <K> DataClassPath<K, FinancingApplicant>.driversLicenseNumber: DataClassPath<K, String?> get() = this[FinancingApplicant_driversLicenseNumber]
val <K> DataClassPath<K, FinancingApplicant>.driversLicenseExpiration: DataClassPath<K, LocalDate?> get() = this[FinancingApplicant_driversLicenseExpiration]
val <K> DataClassPath<K, FinancingApplicant>.birthday: DataClassPath<K, LocalDate?> get() = this[FinancingApplicant_birthday]
val <K> DataClassPath<K, FinancingApplicant>.socialSecurityNumber: DataClassPath<K, String?> get() = this[FinancingApplicant_socialSecurityNumber]
val <K> DataClassPath<K, FinancingApplicant>.residency: DataClassPath<K, ResidencyInfo> get() = this[FinancingApplicant_residency]
val <K> DataClassPath<K, FinancingApplicant>.previousResidency: DataClassPath<K, ResidencyInfo?> get() = this[FinancingApplicant_previousResidency]
val <K> DataClassPath<K, FinancingApplicant>.employer: DataClassPath<K, EmployerInfo> get() = this[FinancingApplicant_employer]
val <K> DataClassPath<K, FinancingApplicant>.previousEmployer: DataClassPath<K, EmployerInfo?> get() = this[FinancingApplicant_previousEmployer]
inline val FinancingApplicant.Companion.path: DataClassPath<FinancingApplicant, FinancingApplicant> get() = path<FinancingApplicant>()


object FinancingApplicant_email: SerializableProperty<FinancingApplicant, String?> {
    override val name: String = "email"
    override fun get(receiver: FinancingApplicant): String? = receiver.email
    override fun setCopy(receiver: FinancingApplicant, value: String?) = receiver.copy(email = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = FinancingApplicant.serializer().tryFindAnnotations("email")
    override val default: String? = null
}
object FinancingApplicant_phoneNumber: SerializableProperty<FinancingApplicant, String?> {
    override val name: String = "phoneNumber"
    override fun get(receiver: FinancingApplicant): String? = receiver.phoneNumber
    override fun setCopy(receiver: FinancingApplicant, value: String?) = receiver.copy(phoneNumber = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = FinancingApplicant.serializer().tryFindAnnotations("phoneNumber")
    override val default: String? = null
}
object FinancingApplicant_givenName: SerializableProperty<FinancingApplicant, String?> {
    override val name: String = "givenName"
    override fun get(receiver: FinancingApplicant): String? = receiver.givenName
    override fun setCopy(receiver: FinancingApplicant, value: String?) = receiver.copy(givenName = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = FinancingApplicant.serializer().tryFindAnnotations("givenName")
    override val default: String? = null
}
object FinancingApplicant_middleName: SerializableProperty<FinancingApplicant, String?> {
    override val name: String = "middleName"
    override fun get(receiver: FinancingApplicant): String? = receiver.middleName
    override fun setCopy(receiver: FinancingApplicant, value: String?) = receiver.copy(middleName = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = FinancingApplicant.serializer().tryFindAnnotations("middleName")
    override val default: String? = null
}
object FinancingApplicant_surname: SerializableProperty<FinancingApplicant, String?> {
    override val name: String = "surname"
    override fun get(receiver: FinancingApplicant): String? = receiver.surname
    override fun setCopy(receiver: FinancingApplicant, value: String?) = receiver.copy(surname = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = FinancingApplicant.serializer().tryFindAnnotations("surname")
    override val default: String? = null
}
object FinancingApplicant_driversLicenseNumber: SerializableProperty<FinancingApplicant, String?> {
    override val name: String = "driversLicenseNumber"
    override fun get(receiver: FinancingApplicant): String? = receiver.driversLicenseNumber
    override fun setCopy(receiver: FinancingApplicant, value: String?) = receiver.copy(driversLicenseNumber = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = FinancingApplicant.serializer().tryFindAnnotations("driversLicenseNumber")
    override val default: String? = null
}
object FinancingApplicant_driversLicenseExpiration: SerializableProperty<FinancingApplicant, LocalDate?> {
    override val name: String = "driversLicenseExpiration"
    override fun get(receiver: FinancingApplicant): LocalDate? = receiver.driversLicenseExpiration
    override fun setCopy(receiver: FinancingApplicant, value: LocalDate?) = receiver.copy(driversLicenseExpiration = value)
    override val serializer: KSerializer<LocalDate?> = ContextualSerializer(LocalDate::class).nullable2
    override val annotations: List<Annotation> = FinancingApplicant.serializer().tryFindAnnotations("driversLicenseExpiration")
    override val default: LocalDate? = null
}
object FinancingApplicant_birthday: SerializableProperty<FinancingApplicant, LocalDate?> {
    override val name: String = "birthday"
    override fun get(receiver: FinancingApplicant): LocalDate? = receiver.birthday
    override fun setCopy(receiver: FinancingApplicant, value: LocalDate?) = receiver.copy(birthday = value)
    override val serializer: KSerializer<LocalDate?> = ContextualSerializer(LocalDate::class).nullable2
    override val annotations: List<Annotation> = FinancingApplicant.serializer().tryFindAnnotations("birthday")
    override val default: LocalDate? = null
}
object FinancingApplicant_socialSecurityNumber: SerializableProperty<FinancingApplicant, String?> {
    override val name: String = "socialSecurityNumber"
    override fun get(receiver: FinancingApplicant): String? = receiver.socialSecurityNumber
    override fun setCopy(receiver: FinancingApplicant, value: String?) = receiver.copy(socialSecurityNumber = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = FinancingApplicant.serializer().tryFindAnnotations("socialSecurityNumber")
    override val default: String? = null
}
object FinancingApplicant_residency: SerializableProperty<FinancingApplicant, ResidencyInfo> {
    override val name: String = "residency"
    override fun get(receiver: FinancingApplicant): ResidencyInfo = receiver.residency
    override fun setCopy(receiver: FinancingApplicant, value: ResidencyInfo) = receiver.copy(residency = value)
    override val serializer: KSerializer<ResidencyInfo> = ResidencyInfo.serializer()
    override val annotations: List<Annotation> = FinancingApplicant.serializer().tryFindAnnotations("residency")
    override val default: ResidencyInfo = ResidencyInfo()
}
object FinancingApplicant_previousResidency: SerializableProperty<FinancingApplicant, ResidencyInfo?> {
    override val name: String = "previousResidency"
    override fun get(receiver: FinancingApplicant): ResidencyInfo? = receiver.previousResidency
    override fun setCopy(receiver: FinancingApplicant, value: ResidencyInfo?) = receiver.copy(previousResidency = value)
    override val serializer: KSerializer<ResidencyInfo?> = ResidencyInfo.serializer().nullable2
    override val annotations: List<Annotation> = FinancingApplicant.serializer().tryFindAnnotations("previousResidency")
    override val default: ResidencyInfo? = null
}
object FinancingApplicant_employer: SerializableProperty<FinancingApplicant, EmployerInfo> {
    override val name: String = "employer"
    override fun get(receiver: FinancingApplicant): EmployerInfo = receiver.employer
    override fun setCopy(receiver: FinancingApplicant, value: EmployerInfo) = receiver.copy(employer = value)
    override val serializer: KSerializer<EmployerInfo> = EmployerInfo.serializer()
    override val annotations: List<Annotation> = FinancingApplicant.serializer().tryFindAnnotations("employer")
    override val default: EmployerInfo = EmployerInfo()
}
object FinancingApplicant_previousEmployer: SerializableProperty<FinancingApplicant, EmployerInfo?> {
    override val name: String = "previousEmployer"
    override fun get(receiver: FinancingApplicant): EmployerInfo? = receiver.previousEmployer
    override fun setCopy(receiver: FinancingApplicant, value: EmployerInfo?) = receiver.copy(previousEmployer = value)
    override val serializer: KSerializer<EmployerInfo?> = EmployerInfo.serializer().nullable2
    override val annotations: List<Annotation> = FinancingApplicant.serializer().tryFindAnnotations("previousEmployer")
    override val default: EmployerInfo? = null
}
