// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareVehiclePhotoFields() {
    val props: Array<SerializableProperty<VehiclePhoto, *>> = arrayOf(VehiclePhoto_file)
    VehiclePhoto.serializer().properties { props }
}
val <K> DataClassPath<K, VehiclePhoto>.file: DataClassPath<K, ServerFile> get() = this[VehiclePhoto_file]
inline val VehiclePhoto.Companion.path: DataClassPath<VehiclePhoto, VehiclePhoto> get() = path<VehiclePhoto>()


object VehiclePhoto_file: SerializableProperty<VehiclePhoto, ServerFile> {
    override val name: String = "file"
    override fun get(receiver: VehiclePhoto): ServerFile = receiver.file
    override fun setCopy(receiver: VehiclePhoto, value: ServerFile) = receiver.copy(file = value)
    override val serializer: KSerializer<ServerFile> = ContextualSerializer(ServerFile::class)
    override val annotations: List<Annotation> = VehiclePhoto.serializer().tryFindAnnotations("file")
}
