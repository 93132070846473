package com.ilussobsa.views

import com.ilussobsa.*
import com.ilussobsa.Strings
import com.ilussobsa.sdk.currentSession
import com.ilussobsa.sdk.currentSessionNullable
import com.ilussobsa.sdk.managesAuctions
import com.ilussobsa.utils.schedule
import com.lightningkite.UUID
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.locale.RenderSize
import com.lightningkite.kiteui.locale.renderDateToString
import com.lightningkite.kiteui.locale.renderTimeToString
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.KiteUiScreen
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.field
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import com.lightningkite.serialization.lensPath

@Routable("/auctions")
class AuctionsScreen() : KiteUiScreen {

    override val title: Readable<String> = shared {
        Strings.liveAuctions
    }
    val query = shared {
        Query(
            condition<AuctionLane> {
                Condition.And(
                    listOfNotNull(
                        condition(true)
                    )
                )
            },
            orderBy = sort { it.scheduledStart.ascending() },
            limit = 500,
        )
    }

    override fun ViewWriter.render() {
        stack {
            col {
                val toListen = shared {
                    currentSessionNullable.awaitNotNull().auctions.query(query())
                }
                card - col {
                    h1(Strings.liveAuctions)
                    important - atTopStart - button {
                        ::exists { managesAuctions() }
                        row {
                            centered - icon(Icon.schedule, "")
                            centered - text(Strings.scheduleNewAuction)
                        }
                        onClick {
                            dialogScreenNavigator.navigate(AuctionConfigureDialog(nullUuid))
                        }
                    }
                    text(Strings.auctionsAreScheduled)
                }
                expanding - recyclerView {
                    children(shared { toListen()() }) {
                        card - link {
                            row {
                                centered - col {
                                    text { ::content { it().scheduledStartInstant.renderDateToString(RenderSize.Abbreviation)} }
                                    text { ::content { it().scheduledStartInstant.renderTimeToString(RenderSize.Abbreviation)} }
                                }
                                expanding - compact - col {
                                    h3 { ::content { it().name } }
                                    text { ::content { it().description } }
                                }
//                                centered - text { ::content { "${it().vehicles} vehicles" } }
                            }
                            ::to { val id = it()._id; { LiveAuctionScreen(id) } }
                        }
                    }
                }
            }
        }
    }
}

@Routable("/auction-configure/{id}")
class AuctionConfigureDialog(val id: UUID) : Screen {
    override fun ViewWriter.render() {
        val auctionLane = LazyProperty {
            currentSession().auctions[id]() ?: AuctionLane(
                name = "",
                description = "",
                condition = condition(true)
            )
        }
        dismissBackground {
            centered - scrolls - card - col {
                spacing = 2.rem
                h1(Strings.configureScheduledAuction)
                field(Strings.auctionName) {
                    textInput {
                        content bind auctionLane.lensPath { it.name }
                    }
                }
                field(Strings.scheduledDateAndTime) {
                    localDateTimeField {
                        content bind auctionLane.lensPath { it.scheduledStart }.nullable()
                    }
                }
                row {
                    expanding - space()
                    danger - button {
                        text(Strings.delete)
                        action = Action(Strings.delete, Icon.empty) {
                            currentSession().auctions[id].delete()
                            dialogScreenNavigator.dismiss()
                        }
                    }
                    important - button {
                        text(Strings.scheduleAuction)
                        action = Action(Strings.submit, Icon.empty) {
                            currentSession().auctions.upsert(auctionLane())
                            dialogScreenNavigator.dismiss()
                        }
                    }
                }
            }
        }
    }
}