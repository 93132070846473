package com.ilussobsa.utils

import com.ilussobsa.Resources
import com.ilussobsa.Strings
import com.lightningkite.kiteui.models.*

val Icon.Companion.clean get() = Icon(1.5.rem, 1.5.rem, 0, 0, 24, 24, listOf(
    "M19,9L20.25,6.25L23,5L20.25,3.75L19,1L17.75,3.75L15,5L17.75,6.25Z",
            "M19,15L17.75,17.75L15,19L17.75,20.25L19,23L20.25,20.25L23,19L20.25,17.75Z",
            "M11.5,9.5L9,4L6.5,9.5L1,12l5.5,2.5L9,20l2.5-5.5L17,12L11.5,9.5z M9.99,12.99L9,15.17l-0.99-2.18L5.83,12l2.18-0.99 L9,8.83l0.99,2.18L12.17,12L9.99,12.99z",
))

val Icon.Companion.gavel get() = Icon(1.5.rem, 1.5.rem, 0, 0, 126, 123, listOf("M97.48,26.61L97.48,26.61c3.09,3.09,3.15,8.08,0.14,11.1l-1.63,1.63l13.1,12.88L75.96,84.14L63.2,70.92 l-49.74,49.74c-3.01,3.01-8.01,2.95-11.1-0.14l0,0c-3.09-3.09-3.15-8.08-0.14-11.1l49.92-49.92l-9.99-10.39l31.32-31.93l11.21,11 l1.72-1.72C89.4,23.46,94.39,23.52,97.48,26.61L97.48,26.61z M72.11,1.88L72.11,1.88c2.46,2.46,2.51,6.43,0.11,8.83L35.69,47.24 c-2.4,2.4-6.37,2.35-8.83-0.11l0,0c-2.46-2.46-2.51-6.43-0.11-8.83L63.28,1.77C65.68-0.63,69.65-0.58,72.11,1.88L72.11,1.88z M124.04,53.81L124.04,53.81c2.46,2.46,2.51,6.43,0.11,8.83L87.62,99.18c-2.4,2.4-6.37,2.35-8.83-0.11l0,0 c-2.46-2.46-2.51-6.43-0.11-8.83l36.53-36.53C117.61,51.3,121.58,51.35,124.04,53.81L124.04,53.81z"))
val Icon.Companion.publish get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M440-160v-326L336-382l-56-58 200-200 200 200-56 58-104-104v326h-80ZM160-600v-120q0-33 23.5-56.5T240-800h480q33 0 56.5 23.5T800-720v120h-80v-120H240v120h-80Z"))
val Icon.Companion.edit get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z"))
val Icon.Companion.transport get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M240-160q-50 0-85-35t-35-85H40v-440q0-33 23.5-56.5T120-800h560v160h120l120 160v200h-80q0 50-35 85t-85 35q-50 0-85-35t-35-85H360q0 50-35 85t-85 35Zm0-80q17 0 28.5-11.5T280-280q0-17-11.5-28.5T240-320q-17 0-28.5 11.5T200-280q0 17 11.5 28.5T240-240ZM120-360h32q17-18 39-29t49-11q27 0 49 11t39 29h272v-360H120v360Zm600 120q17 0 28.5-11.5T760-280q0-17-11.5-28.5T720-320q-17 0-28.5 11.5T680-280q0 17 11.5 28.5T720-240Zm-40-200h170l-90-120h-80v120ZM360-540Z"))
val Icon.Companion.missingImage get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M480-260q75 0 127.5-52.5T660-440q0-75-52.5-127.5T480-620q-75 0-127.5 52.5T300-440q0 75 52.5 127.5T480-260Zm0-80q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29ZM160-120q-33 0-56.5-23.5T80-200v-480q0-33 23.5-56.5T160-760h126l74-80h240l74 80h126q33 0 56.5 23.5T880-680v480q0 33-23.5 56.5T800-120H160Zm0-80h640v-480H638l-73-80H395l-73 80H160v480Zm320-240Z"))
val Icon.Companion.priorAccident get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M720-520q-83 0-141.5-58.5T520-720q0-82 58-141t142-59q83 0 141.5 58.5T920-720q0 83-58.5 141.5T720-520Zm-20-160h40v-160h-40v160ZM160-160q-17 0-28.5-11.5T120-200v-320l84-240q6-18 21.5-29t34.5-11h192q-6 19-9 39t-3 41H274l-42 120h235q11 23 25.5 43t32.5 37H200v200h560v-123q21-3 41-9t39-15v267q0 17-11.5 28.5T800-160h-40q-17 0-28.5-11.5T720-200v-40H240v40q0 17-11.5 28.5T200-160h-40Zm560-440q8 0 14-6t6-14q0-8-6-14t-14-6q-8 0-14 6t-6 14q0 8 6 14t14 6ZM300-360q25 0 42.5-17.5T360-420q0-25-17.5-42.5T300-480q-25 0-42.5 17.5T240-420q0 25 17.5 42.5T300-360Zm360 0q25 0 42.5-17.5T720-420q0-5-.5-10t-2.5-10q-27 0-52.5-5T616-460q-8 8-12 18.5t-4 21.5q0 25 17.5 42.5T660-360Zm-460 40v-200 200Z"))
val Icon.Companion.paintwork get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M440-80q-33 0-56.5-23.5T360-160v-160H240q-33 0-56.5-23.5T160-400v-280q0-66 47-113t113-47h480v440q0 33-23.5 56.5T720-320H600v160q0 33-23.5 56.5T520-80h-80ZM240-560h480v-200h-40v160h-80v-160h-40v80h-80v-80H320q-33 0-56.5 23.5T240-680v120Zm0 160h480v-80H240v80Zm0 0v-80 80Z"))
val Icon.Companion.towing get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M40-280v-160h328L120-636v116H40v-240h40l440 242v-282h200l200 240v280H820q0 50-35 85t-85 35q-50 0-85-35t-35-85H360q0 50-35 85t-85 35q-50 0-85-35t-35-85H40Zm200 60q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm460 0q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17ZM600-560h216L682-720h-82v160Z"))
val Icon.Companion.damage get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M200-80q-33 0-56.5-23.5T120-160v-160q0-33 23.5-56.5T200-400h560q33 0 56.5 23.5T840-320v160q0 33-23.5 56.5T760-80H200Zm0-80h560v-160H200v160Zm61-300L60-574l240-40-65-235 199 141 120-212 40 240 235-65-141 199 152 86H678l-106-60 62-88-104 29-18-106-52 93-88-62 29 104-106 18 120 72H261Zm226 0Zm-7 220Z"))
val Icon.Companion.waterDrop get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M491-200q12-1 20.5-9.5T520-230q0-14-9-22.5t-23-7.5q-41 3-87-22.5T343-375q-2-11-10.5-18t-19.5-7q-14 0-23 10.5t-6 24.5q17 91 80 130t127 35ZM480-80q-137 0-228.5-94T160-408q0-100 79.5-217.5T480-880q161 137 240.5 254.5T800-408q0 140-91.5 234T480-80Zm0-80q104 0 172-70.5T720-408q0-73-60.5-165T480-774Q361-665 300.5-573T240-408q0 107 68 177.5T480-160Zm0-320Z"))
val Icon.Companion.money get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M444-200h70v-50q50-9 86-39t36-89q0-42-24-77t-96-61q-60-20-83-35t-23-41q0-26 18.5-41t53.5-15q32 0 50 15.5t26 38.5l64-26q-11-35-40.5-61T516-710v-50h-70v50q-50 11-78 44t-28 74q0 47 27.5 76t86.5 50q63 23 87.5 41t24.5 47q0 33-23.5 48.5T486-314q-33 0-58.5-20.5T390-396l-66 26q14 48 43.5 77.5T444-252v52Zm36 120q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"))
val Icon.Companion.taxi get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M160-80v-240h120v240H160Zm200 0v-476q-50 17-65 62.5T280-400h-80q0-128 75-204t205-76q100 0 150-49.5T680-880h80q0 88-37.5 157.5T600-624v544h-80v-240h-80v240h-80Zm120-640q-33 0-56.5-23.5T400-800q0-33 23.5-56.5T480-880q33 0 56.5 23.5T560-800q0 33-23.5 56.5T480-720Z"))
val Icon.Companion.acIssues get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M800-560q-17 0-28.5-11.5T760-600q0-17 11.5-28.5T800-640q17 0 28.5 11.5T840-600q0 17-11.5 28.5T800-560ZM400-80v-144L296-120l-56-56 160-160v-64h-64L176-240l-56-56 104-104H80v-80h144L120-584l56-56 160 160h64v-64L240-704l56-56 104 104v-144h80v144l104-104 56 56-160 160v64h320v80H656l104 104-56 56-160-160h-64v64l160 160-56 56-104-104v144h-80Zm360-600v-200h80v200h-80Z"))
val Icon.Companion.speedometer get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M418-340q24 24 62 23.5t56-27.5l224-336-336 224q-27 18-28.5 55t22.5 61Zm62-460q59 0 113.5 16.5T696-734l-76 48q-33-17-68.5-25.5T480-720q-133 0-226.5 93.5T160-400q0 42 11.5 83t32.5 77h552q23-38 33.5-79t10.5-85q0-36-8.5-70T766-540l48-76q30 47 47.5 100T880-406q1 57-13 109t-41 99q-11 18-30 28t-40 10H204q-21 0-40-10t-30-28q-26-45-40-95.5T80-400q0-83 31.5-155.5t86-127Q252-737 325-768.5T480-800Zm7 313Z"))
val Icon.Companion.electric get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("m422-232 207-248H469l29-227-185 267h139l-30 208ZM320-80l40-280H160l360-520h80l-40 320h240L400-80h-80Zm151-390Z"))
val Icon.Companion.accuracy get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M468-240q-96-5-162-74t-66-166q0-100 70-170t170-70q97 0 166 66t74 162l-84-25q-13-54-56-88.5T480-640q-66 0-113 47t-47 113q0 57 34.5 100t88.5 56l25 84Zm48 158q-9 2-18 2h-18q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480v18q0 9-2 18l-78-24v-12q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93h12l24 78Zm305 22L650-231 600-80 480-480l400 120-151 50 171 171-79 79Z"))
val Icon.Companion.sell get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M570-104q-23 23-57 23t-57-23L104-456q-11-11-17.5-26T80-514v-286q0-33 23.5-56.5T160-880h286q17 0 32 6.5t26 17.5l352 353q23 23 23 56.5T856-390L570-104Zm-57-56 286-286-353-354H160v286l353 354ZM260-640q25 0 42.5-17.5T320-700q0-25-17.5-42.5T260-760q-25 0-42.5 17.5T200-700q0 25 17.5 42.5T260-640ZM160-800Z"))
val Icon.Companion.buy get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M280-80q-33 0-56.5-23.5T200-160q0-33 23.5-56.5T280-240q33 0 56.5 23.5T360-160q0 33-23.5 56.5T280-80Zm400 0q-33 0-56.5-23.5T600-160q0-33 23.5-56.5T680-240q33 0 56.5 23.5T760-160q0 33-23.5 56.5T680-80ZM246-720l96 200h280l110-200H246Zm-38-80h590q23 0 35 20.5t1 41.5L692-482q-11 20-29.5 31T622-440H324l-44 80h480v80H280q-45 0-68-39.5t-2-78.5l54-98-144-304H40v-80h130l38 80Zm134 280h280-280Z"))
val Icon.Companion.manual get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M160-120q-50 0-85-35t-35-85q0-39 22.5-70t57.5-43v-254q-35-12-57.5-43T40-720q0-50 35-85t85-35q50 0 85 35t35 85q0 39-22.5 70T200-607v87h240v-87q-35-12-57.5-43T360-720q0-50 35-85t85-35q50 0 85 35t35 85q0 39-22.5 70T520-607v87h200q17 0 28.5-11.5T760-560v-47q-35-12-57.5-43T680-720q0-50 35-85t85-35q50 0 85 35t35 85q0 39-22.5 70T840-607v47q0 50-35 85t-85 35H520v87q35 12 57.5 43t22.5 70q0 50-35 85t-85 35q-50 0-85-35t-35-85q0-39 22.5-70t57.5-43v-87H200v87q35 12 57.5 43t22.5 70q0 50-35 85t-85 35Zm0-80q17 0 28.5-11.5T200-240q0-17-11.5-28.5T160-280q-17 0-28.5 11.5T120-240q0 17 11.5 28.5T160-200Zm0-480q17 0 28.5-11.5T200-720q0-17-11.5-28.5T160-760q-17 0-28.5 11.5T120-720q0 17 11.5 28.5T160-680Zm320 480q17 0 28.5-11.5T520-240q0-17-11.5-28.5T480-280q-17 0-28.5 11.5T440-240q0 17 11.5 28.5T480-200Zm0-480q17 0 28.5-11.5T520-720q0-17-11.5-28.5T480-760q-17 0-28.5 11.5T440-720q0 17 11.5 28.5T480-680Zm320 0q17 0 28.5-11.5T840-720q0-17-11.5-28.5T800-760q-17 0-28.5 11.5T760-720q0 17 11.5 28.5T800-680ZM160-240Zm0-480Zm320 480Zm0-480Zm320 0Z"))
val Icon.Companion.missingTitle get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M200-800v640-640 200-200Zm80 400h147q11-23 25.5-43t32.5-37H280v80Zm0 160h123q-3-20-3-40t3-40H280v80ZM200-80q-33 0-56.5-23.5T120-160v-640q0-33 23.5-56.5T200-880h320l240 240v92q-19-6-39-9t-41-3v-40H480v-200H200v640h227q11 23 25.5 43T485-80H200Zm480-400q83 0 141.5 58.5T880-280q0 83-58.5 141.5T680-80q-83 0-141.5-58.5T480-280q0-83 58.5-141.5T680-480Zm0 320q11 0 18.5-7.5T706-186q0-11-7.5-18.5T680-212q-11 0-18.5 7.5T654-186q0 11 7.5 18.5T680-160Zm-18-76h36v-10q0-11 6-19.5t14-16.5q14-12 22-23t8-31q0-29-19-46.5T680-400q-23 0-41.5 13.5T612-350l32 14q3-12 12.5-21t23.5-9q15 0 23.5 7.5T712-336q0 11-6 18.5T692-302q-6 6-12.5 12T668-276q-3 6-4.5 12t-1.5 14v14Z"))
val Icon.Companion.recycling get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("m368-592 89-147-59-98q-12-20-34.5-20T329-837l-98 163 137 82Zm387 272-89-148 139-80 64 107q11 17 12 38t-9 39q-10 20-29.5 32T800-320h-45ZM640-40 480-200l160-160v80h190l-58 116q-11 20-30 32t-42 12h-60v80Zm-387-80q-20 0-36.5-10.5T192-158q-8-16-7.5-33.5T194-224l34-56h172v160H253Zm-99-114L89-364q-9-18-8.5-38.5T92-441l16-27-68-41 219-55 55 220-69-42-91 152Zm540-342-219-55 69-41-125-208h141q21 0 39.5 10.5T629-841l52 87 68-42-55 220Z"))
val Icon.Companion.lemon get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M480-120q-117 0-198.5-81.5T200-400q0-94 55.5-168.5T401-669q-20-5-39-14.5T328-708q-33-33-42.5-78.5T281-879q47-5 92.5 4.5T452-832q23 23 33.5 52t13.5 61q13-31 31.5-58.5T572-828q11-11 28-11t28 11q11 11 11 28t-11 28q-22 22-39 48.5T564-667q88 28 142 101.5T760-400q0 117-81.5 198.5T480-120Zm0-80q83 0 141.5-58.5T680-400q0-83-58.5-141.5T480-600q-83 0-141.5 58.5T280-400q0 83 58.5 141.5T480-200Zm0-200Z"))
val Icon.Companion.canada get() = Icon(1.5.rem, 1.5.rem, 0, 0, 55, 39, listOf(
    "M27.6,3.6l-2.62,4.89c-0.3,0.53-0.83,0.48-1.37,0.18l-1.9-0.98l1.41,7.5c0.3,1.37-0.66,1.37-1.13,0.78 l-3.31-3.7l-0.53,1.88c-0.06,0.25-0.33,0.51-0.74,0.45l-4.18-0.88l1.1,4c0.24,0.89,0.42,1.26-0.24,1.49l-1.49,0.7l7.21,5.85 c0.29,0.22,0.43,0.62,0.32,0.98l-0.63,2.07c2.48-0.29,4.71-0.72,7.19-0.98c0.22-0.02,0.58,0.33,0.58,0.59L26.95,36h1.2l-0.19-7.56 c0-0.26,0.33-0.63,0.55-0.61c2.48,0.27,4.71,0.7,7.19,0.98l-0.64-2.08c-0.11-0.36,0.04-0.75,0.32-0.98l7.21-5.85l-1.49-0.7 c-0.66-0.24-0.48-0.6-0.24-1.49l1.1-4l-4.18,0.88c-0.41,0.06-0.68-0.2-0.74-0.45l-0.53-1.88l-3.31,3.7 c-0.47,0.59-1.42,0.59-1.13-0.78l1.41-7.5l-1.9,0.98c-0.53,0.3-1.07,0.34-1.37-0.18L27.6,3.6L27.6,3.6L27.6,3.6z M27.6,3.6 L27.6,3.6L27.6,3.6L27.6,3.6L27.6,3.6z",
))
val Icon.Companion.certification get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf(
  "m387-412 35-114-92-74h114l36-112 36 112h114l-93 74 35 114-92-71-93 71ZM240-40v-309q-38-42-59-96t-21-115q0-134 93-227t227-93q134 0 227 93t93 227q0 61-21 115t-59 96v309l-240-80-240 80Zm240-280q100 0 170-70t70-170q0-100-70-170t-170-70q-100 0-170 70t-70 170q0 100 70 170t170 70ZM320-159l160-41 160 41v-124q-35 20-75.5 31.5T480-240q-44 0-84.5-11.5T320-283v124Zm160-62Z"
))
val Icon.Companion.lightMode get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M480-360q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35Zm0 80q-83 0-141.5-58.5T280-480q0-83 58.5-141.5T480-680q83 0 141.5 58.5T680-480q0 83-58.5 141.5T480-280ZM200-440H40v-80h160v80Zm720 0H760v-80h160v80ZM440-760v-160h80v160h-80Zm0 720v-160h80v160h-80ZM256-650l-101-97 57-59 96 100-52 56Zm492 496-97-101 53-55 101 97-57 59Zm-98-550 97-101 59 57-100 96-56-52ZM154-212l101-97 55 53-97 101-59-57Zm326-268Z"))
val Icon.Companion.darkMode get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M480-120q-150 0-255-105T120-480q0-150 105-255t255-105q14 0 27.5 1t26.5 3q-41 29-65.5 75.5T444-660q0 90 63 153t153 63q55 0 101-24.5t75-65.5q2 13 3 26.5t1 27.5q0 150-105 255T480-120Zm0-80q88 0 158-48.5T740-375q-20 5-40 8t-40 3q-123 0-209.5-86.5T364-660q0-20 3-40t8-40q-78 32-126.5 102T200-480q0 116 82 198t198 82Zm-10-270Z"))
val Icon.Companion.checkCircle get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"))
val Icon.Companion.emptyCircle get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"))
val Icon.Companion.xCircle get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"))
val Icon.Companion.creditCard get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M880-720v480q0 33-23.5 56.5T800-160H160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720Zm-720 80h640v-80H160v80Zm0 160v240h640v-240H160Zm0 240v-480 480Z"))
val Icon.Companion.checkbook get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M240-360h280l80-80H240v80Zm0-160h240v-80H240v80Zm-80-160v400h280l-80 80H80v-560h800v120h-80v-40H160Zm756 212q5 5 5 11t-5 11l-36 36-70-70 36-36q5-5 11-5t11 5l48 48ZM520-120v-70l266-266 70 70-266 266h-70ZM160-680v400-400Z"))
val Icon.Companion.chevronDown get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z"))
val Icon.Companion.people get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf("M0-240v-63q0-43 44-70t116-27q13 0 25 .5t23 2.5q-14 21-21 44t-7 48v65H0Zm240 0v-65q0-32 17.5-58.5T307-410q32-20 76.5-30t96.5-10q53 0 97.5 10t76.5 30q32 20 49 46.5t17 58.5v65H240Zm540 0v-65q0-26-6.5-49T754-397q11-2 22.5-2.5t23.5-.5q72 0 116 26.5t44 70.5v63H780Zm-455-80h311q-10-20-55.5-35T480-370q-55 0-100.5 15T325-320ZM160-440q-33 0-56.5-23.5T80-520q0-34 23.5-57t56.5-23q34 0 57 23t23 57q0 33-23 56.5T160-440Zm640 0q-33 0-56.5-23.5T720-520q0-34 23.5-57t56.5-23q34 0 57 23t23 57q0 33-23 56.5T800-440Zm-320-40q-50 0-85-35t-35-85q0-51 35-85.5t85-34.5q51 0 85.5 34.5T600-600q0 50-34.5 85T480-480Zm0-80q17 0 28.5-11.5T520-600q0-17-11.5-28.5T480-640q-17 0-28.5 11.5T440-600q0 17 11.5 28.5T480-560Zm1 240Zm-1-280Z"))

val Icon.Companion.logo get() = Icon(5.rem, 1.rem, 0, 0, 507, 108, listOf(
    "M220.77,17.08H184l-18.64,41H149.68l18.64-41H124.14v1.7l5.37,2.87L115,53.64c-2.25,5,0,9,4.9,9H191c4.95,0,10.89-4,13.14-9l16.63-36.57Z",
    "M269.43,45.7H225.66c-5.64,0-6.34-5-4.42-9.17l4.28-9.41c3.7-8.14,10.57-10,18.86-10h74.8l-2.07,4.57H264.74L259,34.34h43.78c5.64,0,6.34,5,4.42,9.18L303,52.6c-3.7,8.14-10.57,10-18.86,10h-74.8l2.07-4.56h52.37l5.62-12.37Z",
    "M363.12,45.7H319.34c-5.64,0-6.33-5-4.41-9.17l4.28-9.41c3.7-8.14,10.57-10,18.86-10h74.8l-2.08,4.57H358.43l-5.78,12.69h43.78c5.64,0,6.33,5,4.41,9.18l-4.13,9.08c-3.7,8.14-10.57,10-18.86,10h-74.8l2.08-4.56H357.5l5.62-12.37Z",
    "M431.79,17.08h67.88c6.14,0,8.93,5,6.15,11.14L495.26,51.43c-2.78,6.12-10.05,11.2-16.18,11.2H411.3c-6.13,0-9.06-5.11-6.27-11.24l10.54-23.17c2.78-6.13,10.08-11.14,16.22-11.14Zm23.5,4.57h16.79L455.51,58.07H438.72l16.57-36.42Z",
    "M39.01 74.66L2.27 74.66L26.38 21.65L 21.01 18.78L21.01 17.08L65.19 17.08L39.01 74.66L39.01 74.66Z",
    "M54.47 74.66L78.58 21.65L73.21 18.78L 73.21 17.08L117.39 17.08L93.28 70.09L486.77 70.09L484.7 74.66L54.47 74.66L54.47 74.66Z",
    "M67.65 11.67L30.92 11.67L36.23 0L 72.96 0L67.65 11.67L67.65 11.67Z",
))
val Icon.Companion.schedule get() = Icon(1.5.rem, 1.5.rem, 0, -960, 960, 960, listOf(
    "m612-292 56-56-148-148v-184h-80v216l172 172ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-400Zm0 320q133 0 226.5-93.5T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160Z"
))

fun Resources.iconLogo(theme: Theme) = ImageVector(
    4.rem, 4.rem, 0, 0, 72, 72,
    paths = listOf(
        ImageVector.Path(fillColor = theme.foreground, path = "M39.01 74.66 2.27 74.66 26.38 21.65 21.01 18.78 21.01 17.08 65.19 17.08 39.01 74.66 39.01 74.66Z"), // polygon
        ImageVector.Path(fillColor = theme.foreground, path = "M67.65 11.67 30.92 11.67 36.23 0 72.96 0 67.65 11.67 67.65 11.67Z"), // polygon
    )
)
fun Resources.ilussoFullLogo(theme: Theme) = ImageVector(
    width = 22.6.rem, height = 7.1.rem, viewBoxMinX = 0, viewBoxMinY = 0, viewBoxWidth = 515, viewBoxHeight = 110,
    paths = listOf(
        ImageVector.Path(fillColor = theme.foreground, path = "M39.01 74.66 2.27 74.66 26.38 21.65 21.01 18.78 21.01 17.08 65.19 17.08 39.01 74.66 39.01 74.66Z"), // polygon
        ImageVector.Path(fillColor = theme.foreground, path = "M54.47 74.66 78.58 21.65 73.21 18.78 73.21 17.08 117.39 17.08 93.28 70.09 486.77 70.09 484.7 74.66 54.47 74.66 54.47 74.66Z"), // polygon
        ImageVector.Path(fillColor = theme.foreground, path = "M220.77,17.08H184l-18.64,41H149.68l18.64-41H124.14v1.7l5.37,2.87L115,53.64c-2.25,5,0,9,4.9,9H191c4.95,0,10.89-4,13.14-9l16.63-36.57Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M67.65 11.67 30.92 11.67 36.23 0 72.96 0 67.65 11.67 67.65 11.67Z"), // polygon
        ImageVector.Path(fillColor = theme.foreground, path = "M269.43,45.7H225.66c-5.64,0-6.34-5-4.42-9.17l4.28-9.41c3.7-8.14,10.57-10,18.86-10h74.8l-2.07,4.57H264.74L259,34.34h43.78c5.64,0,6.34,5,4.42,9.18L303,52.6c-3.7,8.14-10.57,10-18.86,10h-74.8l2.07-4.56h52.37l5.62-12.37Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M363.12,45.7H319.34c-5.64,0-6.33-5-4.41-9.17l4.28-9.41c3.7-8.14,10.57-10,18.86-10h74.8l-2.08,4.57H358.43l-5.78,12.69h43.78c5.64,0,6.33,5,4.41,9.18l-4.13,9.08c-3.7,8.14-10.57,10-18.86,10h-74.8l2.08-4.56H357.5l5.62-12.37Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M431.79,17.08h67.88c6.14,0,8.93,5,6.15,11.14L495.26,51.43c-2.78,6.12-10.05,11.2-16.18,11.2H411.3c-6.13,0-9.06-5.11-6.27-11.24l10.54-23.17c2.78-6.13,10.08-11.14,16.22-11.14Zm23.5,4.57h16.79L455.51,58.07H438.72l16.57-36.42Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M3.87 92.48 0 92.48 0 108.25 13.07 108.25 13.07 105.39 3.87 105.39 3.87 92.48 3.87 92.48Z"), // polygon
        ImageVector.Path(fillColor = theme.foreground, path = "M31.43 92.48 17.82 92.48 17.82 108.25 31.87 108.25 31.87 105.46 21.61 105.46 21.61 101.38 30.64 101.38 30.64 98.59 21.61 98.59 21.61 95.27 31.43 95.27 31.43 92.48 31.43 92.48Z"), // polygon
        ImageVector.Path(fillColor = theme.foreground, path = "M41,105h6.83L49,108.25h4.21L46.7,92.48H42.24l-6.6,15.77h4.08L41,105Zm3.4-9h.05l2.3,6.23H42L44.41,96Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M57.77,108.25h8a9.43,9.43,0,0,0,2.69-.38,6.71,6.71,0,0,0,2-1,6.37,6.37,0,0,0,1.42-1.47,7.72,7.72,0,0,0,.92-1.75,10.65,10.65,0,0,0,.49-1.86,11.23,11.23,0,0,0,.14-1.79,10,10,0,0,0-.12-1.59,8.68,8.68,0,0,0-.44-1.68,6.88,6.88,0,0,0-.86-1.6,5.91,5.91,0,0,0-1.38-1.35,6.5,6.5,0,0,0-2-.94,10.06,10.06,0,0,0-2.73-.35H57.77v15.77Zm3.79-13h3.71a5.81,5.81,0,0,1,1.08.07,4,4,0,0,1,1.13.35,3,3,0,0,1,1,.82,4.05,4.05,0,0,1,.74,1.47,8.34,8.34,0,0,1,.29,2.31,8.85,8.85,0,0,1-.14,1.55,6.68,6.68,0,0,1-.43,1.44,4.65,4.65,0,0,1-.79,1.19,3.48,3.48,0,0,1-1.2.81,4.19,4.19,0,0,1-1.65.3H61.56V95.21Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M92.07 92.48 78.45 92.48 78.45 108.25 92.51 108.25 92.51 105.46 82.24 105.46 82.24 101.38 91.27 101.38 91.27 98.59 82.24 98.59 82.24 95.27 92.07 95.27 92.07 92.48 92.07 92.48Z"), // polygon
        ImageVector.Path(fillColor = theme.foreground, path = "M97.72,108.25h3.79v-6.17h4a6.9,6.9,0,0,1,1.68.16,1.68,1.68,0,0,1,.9.52,1.86,1.86,0,0,1,.36.93,8.05,8.05,0,0,1,.07,1.37c0,.54,0,1,0,1.39a6.43,6.43,0,0,0,.15,1,7.73,7.73,0,0,0,.21.8h4.28v-.42a1,1,0,0,1-.47-.28,1.14,1.14,0,0,1-.25-.53,7.72,7.72,0,0,1-.09-.9c0-.38,0-.86,0-1.43a9,9,0,0,0-.1-1.49,3.55,3.55,0,0,0-.29-1,2.28,2.28,0,0,0-.47-.68,2.57,2.57,0,0,0-.66-.45,6.46,6.46,0,0,0-.83-.36,4,4,0,0,0,1.56-.82,3.64,3.64,0,0,0,1-1.3,4.07,4.07,0,0,0,.34-1.63,4.16,4.16,0,0,0-.09-.8,4.23,4.23,0,0,0-2-2.8,5.58,5.58,0,0,0-1.56-.62,9,9,0,0,0-2.14-.24H97.72v15.77Zm3.79-8.83V95.21h4.82a4.11,4.11,0,0,1,1.38.2,2.1,2.1,0,0,1,.83.51,1.81,1.81,0,0,1,.39.68,2,2,0,0,1,.11.67,2.15,2.15,0,0,1-.33,1.25,1.82,1.82,0,0,1-1,.69,5.62,5.62,0,0,1-1.67.21Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M129.13,92.48h-3.87v15.77h3.87V92.48Zm17,11h0l-7.35-11h-4.07v15.77h3.61V97h0l7.55,11.24h3.87V92.48H146.1v11Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M173.62 101.18 173.62 108.25 177.48 108.25 177.48 92.48 173.62 92.48 173.62 98.45 166.4 98.45 166.4 92.48 162.53 92.48 162.53 108.25 166.4 108.25 166.4 101.18 173.62 101.18 173.62 101.18Z"), // polygon
        ImageVector.Path(fillColor = theme.foreground, path = "M192.03 102.34 198.22 92.48 193.83 92.48 190.17 99.33 186.33 92.48 181.77 92.48 188.16 102.3 188.16 108.25 192.03 108.25 192.03 102.34 192.03 102.34Z"), // polygon
        ImageVector.Path(fillColor = theme.foreground, path = "M206.47,102.56h4.44a7.68,7.68,0,0,0,2.16-.27,5.37,5.37,0,0,0,1.58-.72,4.31,4.31,0,0,0,1.08-1,4.92,4.92,0,0,0,.66-1.11,5,5,0,0,0,.33-1.07,4.27,4.27,0,0,0,.09-.87,5,5,0,0,0-.69-2.68,4.46,4.46,0,0,0-2-1.74,7.27,7.27,0,0,0-3.09-.63h-8.45v15.77h3.86v-5.69Zm0-2.72V95.21h3.07a6.62,6.62,0,0,1,2,.22,2.07,2.07,0,0,1,1.11.7,2.1,2.1,0,0,1,.35,1.25c0,.14,0,.32,0,.53a2.31,2.31,0,0,1-.16.64,2,2,0,0,1-.44.63,2.28,2.28,0,0,1-.85.47,5,5,0,0,1-1.41.19Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M235.49 92.48 221.87 92.48 221.87 108.25 235.92 108.25 235.92 105.46 225.66 105.46 225.66 101.38 234.69 101.38 234.69 98.59 225.66 98.59 225.66 95.27 235.49 95.27 235.49 92.48 235.49 92.48Z"), // polygon
        ImageVector.Path(fillColor = theme.foreground, path = "M241.14,108.25h3.79v-6.17h4a6.8,6.8,0,0,1,1.68.16,1.72,1.72,0,0,1,.91.52,1.86,1.86,0,0,1,.36.93,9.24,9.24,0,0,1,.07,1.37c0,.54,0,1,.05,1.39a8,8,0,0,0,.14,1,7.68,7.68,0,0,0,.22.8h4.28v-.42a1,1,0,0,1-.47-.28,1.05,1.05,0,0,1-.25-.53,4.92,4.92,0,0,1-.09-.9c0-.38,0-.86,0-1.43a10.27,10.27,0,0,0-.1-1.49,3.9,3.9,0,0,0-.29-1,2.3,2.3,0,0,0-.48-.68,2.35,2.35,0,0,0-.65-.45,6.46,6.46,0,0,0-.83-.36,4,4,0,0,0,1.56-.82,3.76,3.76,0,0,0,1-1.3,4.06,4.06,0,0,0,.33-1.63,3,3,0,0,0-.09-.8,4.33,4.33,0,0,0-.32-1,4.2,4.2,0,0,0-1.71-1.84,5.58,5.58,0,0,0-1.56-.62,9,9,0,0,0-2.14-.24h-9.36v15.77Zm3.79-8.83V95.21h4.82a4.11,4.11,0,0,1,1.38.2,2,2,0,0,1,.82.51,1.72,1.72,0,0,1,.4.68,2,2,0,0,1,.11.67,2.15,2.15,0,0,1-.33,1.25,1.82,1.82,0,0,1-1,.69,5.66,5.66,0,0,1-1.67.21Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M277.5,97.64a5,5,0,0,0-.38-1.47,6.13,6.13,0,0,0-.93-1.49,6.29,6.29,0,0,0-1.54-1.32,8.31,8.31,0,0,0-2.21-.94,11.29,11.29,0,0,0-2.94-.35,10.35,10.35,0,0,0-3.34.52,7.67,7.67,0,0,0-2.7,1.57,7.19,7.19,0,0,0-1.81,2.6,10.58,10.58,0,0,0,0,7.22,7.21,7.21,0,0,0,1.76,2.59,7.52,7.52,0,0,0,2.67,1.57,11.54,11.54,0,0,0,6.51.1,7.6,7.6,0,0,0,2.36-1.2,6.53,6.53,0,0,0,1.64-1.84,8,8,0,0,0,.94-2.35h-3.87a3.86,3.86,0,0,1-.8,1.62,3.43,3.43,0,0,1-1.39,1,5,5,0,0,1-1.91.34,4.79,4.79,0,0,1-2.1-.44,3.92,3.92,0,0,1-1.42-1.19,5,5,0,0,1-.81-1.71,7.61,7.61,0,0,1-.26-2,7.93,7.93,0,0,1,.3-2.28,4.76,4.76,0,0,1,.78-1.58,4,4,0,0,1,1.09-1,4.17,4.17,0,0,1,1.23-.51,4.62,4.62,0,0,1,1.19-.15,6.21,6.21,0,0,1,1.62.19,3.82,3.82,0,0,1,1.11.49,2.73,2.73,0,0,1,.71.68,3.36,3.36,0,0,1,.42.72,6.64,6.64,0,0,1,.24.64Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M285.39,105h6.84l1.18,3.25h4.2l-6.52-15.77h-4.46L280,108.25h4.07l1.29-3.25Zm3.41-9h.05l2.29,6.23h-4.72L288.8,96Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M302.16,108.25H306v-6.17h4a6.8,6.8,0,0,1,1.68.16,1.72,1.72,0,0,1,.91.52,1.86,1.86,0,0,1,.36.93,9.24,9.24,0,0,1,.07,1.37c0,.54,0,1,.05,1.39a8,8,0,0,0,.14,1,7.68,7.68,0,0,0,.22.8h4.28v-.42a1,1,0,0,1-.47-.28,1.05,1.05,0,0,1-.25-.53,4.92,4.92,0,0,1-.09-.9c0-.38,0-.86,0-1.43a10.27,10.27,0,0,0-.1-1.49,3.9,3.9,0,0,0-.29-1,2.3,2.3,0,0,0-.48-.68,2.51,2.51,0,0,0-.65-.45,6.46,6.46,0,0,0-.83-.36,4,4,0,0,0,1.56-.82,3.76,3.76,0,0,0,1-1.3,4.06,4.06,0,0,0,.33-1.63,3,3,0,0,0-.09-.8,4.33,4.33,0,0,0-.32-1,4.2,4.2,0,0,0-1.71-1.84,5.58,5.58,0,0,0-1.56-.62,9,9,0,0,0-2.14-.24h-9.36v15.77ZM306,99.42V95.21h4.82a4.11,4.11,0,0,1,1.38.2,2.06,2.06,0,0,1,.82.51,1.72,1.72,0,0,1,.4.68,2,2,0,0,1,.11.67,2.15,2.15,0,0,1-.33,1.25,1.82,1.82,0,0,1-1,.69,5.69,5.69,0,0,1-1.68.21Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M333.57 92.48 329.7 92.48 329.7 108.25 333.57 108.25 333.57 92.48 333.57 92.48Z"), // polygon
        ImageVector.Path(fillColor = theme.foreground, path = "M350.54 103.46 350.49 103.46 343.14 92.48 339.06 92.48 339.06 108.25 342.67 108.25 342.67 97.01 342.73 97.01 350.28 108.25 354.15 108.25 354.15 92.48 350.54 92.48 350.54 103.46 350.54 103.46Z"), // polygon
        ImageVector.Path(fillColor = theme.foreground, path = "M366.63 104.43 366.58 104.43 362.61 92.48 358.46 92.48 364.7 108.25 368.38 108.25 374.68 92.48 370.65 92.48 366.63 104.43 366.63 104.43Z"), // polygon
        ImageVector.Path(fillColor = theme.foreground, path = "M392.81 92.48 379.19 92.48 379.19 108.25 393.25 108.25 393.25 105.46 382.98 105.46 382.98 101.38 392.01 101.38 392.01 98.59 382.98 98.59 382.98 95.27 392.81 95.27 392.81 92.48 392.81 92.48Z"), // polygon
        ImageVector.Path(fillColor = theme.foreground, path = "M409.75 103.46 409.7 103.46 402.35 92.48 398.28 92.48 398.28 108.25 401.89 108.25 401.89 97.01 401.94 97.01 409.49 108.25 413.36 108.25 413.36 92.48 409.75 92.48 409.75 103.46 409.75 103.46Z"), // polygon
        ImageVector.Path(fillColor = theme.foreground, path = "M427.01 95.27 432.6 95.27 432.6 92.48 417.54 92.48 417.54 95.27 423.14 95.27 423.14 108.25 427.01 108.25 427.01 95.27 427.01 95.27Z"), // polygon
        ImageVector.Path(fillColor = theme.foreground, path = "M435.11,100.37a10.08,10.08,0,0,0,.36,2.78,7.27,7.27,0,0,0,1,2.1,6.88,6.88,0,0,0,1.4,1.49,8.35,8.35,0,0,0,1.63,1,10.05,10.05,0,0,0,1.7.58,11.11,11.11,0,0,0,1.57.28,10,10,0,0,0,1.26.07,10.17,10.17,0,0,0,1.27-.07,11.11,11.11,0,0,0,1.57-.28,9.67,9.67,0,0,0,1.69-.58,8.42,8.42,0,0,0,1.64-1,6.85,6.85,0,0,0,1.39-1.49,7,7,0,0,0,1-2.1,10.67,10.67,0,0,0,0-5.57,7,7,0,0,0-1-2.1A6.85,6.85,0,0,0,450.17,94a8.42,8.42,0,0,0-1.64-1,9.67,9.67,0,0,0-1.69-.58,11.11,11.11,0,0,0-1.57-.28,10.17,10.17,0,0,0-1.27-.07,10,10,0,0,0-1.26.07,11.11,11.11,0,0,0-1.57.28,10.05,10.05,0,0,0-1.7.58,8.35,8.35,0,0,0-1.63,1,6.88,6.88,0,0,0-1.4,1.49,7.27,7.27,0,0,0-1,2.1,10.1,10.1,0,0,0-.36,2.79Zm3.87,0a7.22,7.22,0,0,1,.32-2.27,4.73,4.73,0,0,1,.84-1.58,4.47,4.47,0,0,1,1.19-1,5.45,5.45,0,0,1,1.35-.52,6.22,6.22,0,0,1,1.32-.15,6.31,6.31,0,0,1,1.33.15,5.45,5.45,0,0,1,1.35.52,4.43,4.43,0,0,1,1.18,1,4.57,4.57,0,0,1,.85,1.58,8.18,8.18,0,0,1,0,4.53,4.67,4.67,0,0,1-.85,1.58,4.43,4.43,0,0,1-1.18,1,5.45,5.45,0,0,1-1.35.52,6.31,6.31,0,0,1-1.33.15,6.22,6.22,0,0,1-1.32-.15,5.45,5.45,0,0,1-1.35-.52,4.47,4.47,0,0,1-1.19-1,4.83,4.83,0,0,1-.84-1.58,7.2,7.2,0,0,1-.32-2.26Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M458.06,108.25h3.79v-6.17h4a6.85,6.85,0,0,1,1.68.16,1.68,1.68,0,0,1,.9.52,1.86,1.86,0,0,1,.36.93,8.05,8.05,0,0,1,.07,1.37c0,.54,0,1,.05,1.39a6.43,6.43,0,0,0,.15,1,5.81,5.81,0,0,0,.22.8h4.28v-.42a1,1,0,0,1-.48-.28,1.14,1.14,0,0,1-.25-.53,7.72,7.72,0,0,1-.09-.9c0-.38,0-.86,0-1.43a9,9,0,0,0-.1-1.49,3.55,3.55,0,0,0-.29-1,2.28,2.28,0,0,0-.47-.68,2.57,2.57,0,0,0-.66-.45,6.46,6.46,0,0,0-.83-.36,4,4,0,0,0,1.56-.82,3.53,3.53,0,0,0,1-1.3,4.07,4.07,0,0,0,.34-1.63,4.16,4.16,0,0,0-.09-.8,4.23,4.23,0,0,0-2-2.8,5.58,5.58,0,0,0-1.56-.62,9,9,0,0,0-2.14-.24h-9.36v15.77Zm3.79-8.83V95.21h4.82a4.11,4.11,0,0,1,1.38.2,2.1,2.1,0,0,1,.83.51,2,2,0,0,1,.4.68,2.32,2.32,0,0,1,.1.67,2.15,2.15,0,0,1-.33,1.25,1.82,1.82,0,0,1-1,.69,5.62,5.62,0,0,1-1.67.21Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M486.14 102.34 492.33 92.48 487.95 92.48 484.29 99.33 480.44 92.48 475.88 92.48 482.28 102.3 482.28 108.25 486.14 108.25 486.14 102.34 486.14 102.34Z"), // polygon
    )
)
fun Resources.ilussoFullLogoRed(theme: Theme) = ImageVector(
    width = 22.6.rem, height = 7.1.rem, viewBoxMinX = 0, viewBoxMinY = 0, viewBoxWidth = 515, viewBoxHeight = 110,
    paths = listOf(
        ImageVector.Path(fillColor = theme.foreground, path = "M39.01 74.66 2.27 74.66 26.38 21.65 21.01 18.78 21.01 17.08 65.19 17.08 39.01 74.66 39.01 74.66Z"), // polygon
        ImageVector.Path(fillColor = Color.red, path = "M54.47 74.66 78.58 21.65 73.21 18.78 73.21 17.08 117.39 17.08 93.28 70.09 486.77 70.09 484.7 74.66 54.47 74.66 54.47 74.66Z"), // polygon
        ImageVector.Path(fillColor = theme.foreground, path = "M220.77,17.08H184l-18.64,41H149.68l18.64-41H124.14v1.7l5.37,2.87L115,53.64c-2.25,5,0,9,4.9,9H191c4.95,0,10.89-4,13.14-9l16.63-36.57Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M67.65 11.67 30.92 11.67 36.23 0 72.96 0 67.65 11.67 67.65 11.67Z"), // polygon
        ImageVector.Path(fillColor = theme.foreground, path = "M269.43,45.7H225.66c-5.64,0-6.34-5-4.42-9.17l4.28-9.41c3.7-8.14,10.57-10,18.86-10h74.8l-2.07,4.57H264.74L259,34.34h43.78c5.64,0,6.34,5,4.42,9.18L303,52.6c-3.7,8.14-10.57,10-18.86,10h-74.8l2.07-4.56h52.37l5.62-12.37Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M363.12,45.7H319.34c-5.64,0-6.33-5-4.41-9.17l4.28-9.41c3.7-8.14,10.57-10,18.86-10h74.8l-2.08,4.57H358.43l-5.78,12.69h43.78c5.64,0,6.33,5,4.41,9.18l-4.13,9.08c-3.7,8.14-10.57,10-18.86,10h-74.8l2.08-4.56H357.5l5.62-12.37Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M431.79,17.08h67.88c6.14,0,8.93,5,6.15,11.14L495.26,51.43c-2.78,6.12-10.05,11.2-16.18,11.2H411.3c-6.13,0-9.06-5.11-6.27-11.24l10.54-23.17c2.78-6.13,10.08-11.14,16.22-11.14Zm23.5,4.57h16.79L455.51,58.07H438.72l16.57-36.42Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M3.87 92.48 0 92.48 0 108.25 13.07 108.25 13.07 105.39 3.87 105.39 3.87 92.48 3.87 92.48Z"), // polygon
        ImageVector.Path(fillColor = theme.foreground, path = "M31.43 92.48 17.82 92.48 17.82 108.25 31.87 108.25 31.87 105.46 21.61 105.46 21.61 101.38 30.64 101.38 30.64 98.59 21.61 98.59 21.61 95.27 31.43 95.27 31.43 92.48 31.43 92.48Z"), // polygon
        ImageVector.Path(fillColor = theme.foreground, path = "M41,105h6.83L49,108.25h4.21L46.7,92.48H42.24l-6.6,15.77h4.08L41,105Zm3.4-9h.05l2.3,6.23H42L44.41,96Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M57.77,108.25h8a9.43,9.43,0,0,0,2.69-.38,6.71,6.71,0,0,0,2-1,6.37,6.37,0,0,0,1.42-1.47,7.72,7.72,0,0,0,.92-1.75,10.65,10.65,0,0,0,.49-1.86,11.23,11.23,0,0,0,.14-1.79,10,10,0,0,0-.12-1.59,8.68,8.68,0,0,0-.44-1.68,6.88,6.88,0,0,0-.86-1.6,5.91,5.91,0,0,0-1.38-1.35,6.5,6.5,0,0,0-2-.94,10.06,10.06,0,0,0-2.73-.35H57.77v15.77Zm3.79-13h3.71a5.81,5.81,0,0,1,1.08.07,4,4,0,0,1,1.13.35,3,3,0,0,1,1,.82,4.05,4.05,0,0,1,.74,1.47,8.34,8.34,0,0,1,.29,2.31,8.85,8.85,0,0,1-.14,1.55,6.68,6.68,0,0,1-.43,1.44,4.65,4.65,0,0,1-.79,1.19,3.48,3.48,0,0,1-1.2.81,4.19,4.19,0,0,1-1.65.3H61.56V95.21Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M92.07 92.48 78.45 92.48 78.45 108.25 92.51 108.25 92.51 105.46 82.24 105.46 82.24 101.38 91.27 101.38 91.27 98.59 82.24 98.59 82.24 95.27 92.07 95.27 92.07 92.48 92.07 92.48Z"), // polygon
        ImageVector.Path(fillColor = theme.foreground, path = "M97.72,108.25h3.79v-6.17h4a6.9,6.9,0,0,1,1.68.16,1.68,1.68,0,0,1,.9.52,1.86,1.86,0,0,1,.36.93,8.05,8.05,0,0,1,.07,1.37c0,.54,0,1,0,1.39a6.43,6.43,0,0,0,.15,1,7.73,7.73,0,0,0,.21.8h4.28v-.42a1,1,0,0,1-.47-.28,1.14,1.14,0,0,1-.25-.53,7.72,7.72,0,0,1-.09-.9c0-.38,0-.86,0-1.43a9,9,0,0,0-.1-1.49,3.55,3.55,0,0,0-.29-1,2.28,2.28,0,0,0-.47-.68,2.57,2.57,0,0,0-.66-.45,6.46,6.46,0,0,0-.83-.36,4,4,0,0,0,1.56-.82,3.64,3.64,0,0,0,1-1.3,4.07,4.07,0,0,0,.34-1.63,4.16,4.16,0,0,0-.09-.8,4.23,4.23,0,0,0-2-2.8,5.58,5.58,0,0,0-1.56-.62,9,9,0,0,0-2.14-.24H97.72v15.77Zm3.79-8.83V95.21h4.82a4.11,4.11,0,0,1,1.38.2,2.1,2.1,0,0,1,.83.51,1.81,1.81,0,0,1,.39.68,2,2,0,0,1,.11.67,2.15,2.15,0,0,1-.33,1.25,1.82,1.82,0,0,1-1,.69,5.62,5.62,0,0,1-1.67.21Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M129.13,92.48h-3.87v15.77h3.87V92.48Zm17,11h0l-7.35-11h-4.07v15.77h3.61V97h0l7.55,11.24h3.87V92.48H146.1v11Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M173.62 101.18 173.62 108.25 177.48 108.25 177.48 92.48 173.62 92.48 173.62 98.45 166.4 98.45 166.4 92.48 162.53 92.48 162.53 108.25 166.4 108.25 166.4 101.18 173.62 101.18 173.62 101.18Z"), // polygon
        ImageVector.Path(fillColor = theme.foreground, path = "M192.03 102.34 198.22 92.48 193.83 92.48 190.17 99.33 186.33 92.48 181.77 92.48 188.16 102.3 188.16 108.25 192.03 108.25 192.03 102.34 192.03 102.34Z"), // polygon
        ImageVector.Path(fillColor = theme.foreground, path = "M206.47,102.56h4.44a7.68,7.68,0,0,0,2.16-.27,5.37,5.37,0,0,0,1.58-.72,4.31,4.31,0,0,0,1.08-1,4.92,4.92,0,0,0,.66-1.11,5,5,0,0,0,.33-1.07,4.27,4.27,0,0,0,.09-.87,5,5,0,0,0-.69-2.68,4.46,4.46,0,0,0-2-1.74,7.27,7.27,0,0,0-3.09-.63h-8.45v15.77h3.86v-5.69Zm0-2.72V95.21h3.07a6.62,6.62,0,0,1,2,.22,2.07,2.07,0,0,1,1.11.7,2.1,2.1,0,0,1,.35,1.25c0,.14,0,.32,0,.53a2.31,2.31,0,0,1-.16.64,2,2,0,0,1-.44.63,2.28,2.28,0,0,1-.85.47,5,5,0,0,1-1.41.19Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M235.49 92.48 221.87 92.48 221.87 108.25 235.92 108.25 235.92 105.46 225.66 105.46 225.66 101.38 234.69 101.38 234.69 98.59 225.66 98.59 225.66 95.27 235.49 95.27 235.49 92.48 235.49 92.48Z"), // polygon
        ImageVector.Path(fillColor = theme.foreground, path = "M241.14,108.25h3.79v-6.17h4a6.8,6.8,0,0,1,1.68.16,1.72,1.72,0,0,1,.91.52,1.86,1.86,0,0,1,.36.93,9.24,9.24,0,0,1,.07,1.37c0,.54,0,1,.05,1.39a8,8,0,0,0,.14,1,7.68,7.68,0,0,0,.22.8h4.28v-.42a1,1,0,0,1-.47-.28,1.05,1.05,0,0,1-.25-.53,4.92,4.92,0,0,1-.09-.9c0-.38,0-.86,0-1.43a10.27,10.27,0,0,0-.1-1.49,3.9,3.9,0,0,0-.29-1,2.3,2.3,0,0,0-.48-.68,2.35,2.35,0,0,0-.65-.45,6.46,6.46,0,0,0-.83-.36,4,4,0,0,0,1.56-.82,3.76,3.76,0,0,0,1-1.3,4.06,4.06,0,0,0,.33-1.63,3,3,0,0,0-.09-.8,4.33,4.33,0,0,0-.32-1,4.2,4.2,0,0,0-1.71-1.84,5.58,5.58,0,0,0-1.56-.62,9,9,0,0,0-2.14-.24h-9.36v15.77Zm3.79-8.83V95.21h4.82a4.11,4.11,0,0,1,1.38.2,2,2,0,0,1,.82.51,1.72,1.72,0,0,1,.4.68,2,2,0,0,1,.11.67,2.15,2.15,0,0,1-.33,1.25,1.82,1.82,0,0,1-1,.69,5.66,5.66,0,0,1-1.67.21Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M277.5,97.64a5,5,0,0,0-.38-1.47,6.13,6.13,0,0,0-.93-1.49,6.29,6.29,0,0,0-1.54-1.32,8.31,8.31,0,0,0-2.21-.94,11.29,11.29,0,0,0-2.94-.35,10.35,10.35,0,0,0-3.34.52,7.67,7.67,0,0,0-2.7,1.57,7.19,7.19,0,0,0-1.81,2.6,10.58,10.58,0,0,0,0,7.22,7.21,7.21,0,0,0,1.76,2.59,7.52,7.52,0,0,0,2.67,1.57,11.54,11.54,0,0,0,6.51.1,7.6,7.6,0,0,0,2.36-1.2,6.53,6.53,0,0,0,1.64-1.84,8,8,0,0,0,.94-2.35h-3.87a3.86,3.86,0,0,1-.8,1.62,3.43,3.43,0,0,1-1.39,1,5,5,0,0,1-1.91.34,4.79,4.79,0,0,1-2.1-.44,3.92,3.92,0,0,1-1.42-1.19,5,5,0,0,1-.81-1.71,7.61,7.61,0,0,1-.26-2,7.93,7.93,0,0,1,.3-2.28,4.76,4.76,0,0,1,.78-1.58,4,4,0,0,1,1.09-1,4.17,4.17,0,0,1,1.23-.51,4.62,4.62,0,0,1,1.19-.15,6.21,6.21,0,0,1,1.62.19,3.82,3.82,0,0,1,1.11.49,2.73,2.73,0,0,1,.71.68,3.36,3.36,0,0,1,.42.72,6.64,6.64,0,0,1,.24.64Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M285.39,105h6.84l1.18,3.25h4.2l-6.52-15.77h-4.46L280,108.25h4.07l1.29-3.25Zm3.41-9h.05l2.29,6.23h-4.72L288.8,96Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M302.16,108.25H306v-6.17h4a6.8,6.8,0,0,1,1.68.16,1.72,1.72,0,0,1,.91.52,1.86,1.86,0,0,1,.36.93,9.24,9.24,0,0,1,.07,1.37c0,.54,0,1,.05,1.39a8,8,0,0,0,.14,1,7.68,7.68,0,0,0,.22.8h4.28v-.42a1,1,0,0,1-.47-.28,1.05,1.05,0,0,1-.25-.53,4.92,4.92,0,0,1-.09-.9c0-.38,0-.86,0-1.43a10.27,10.27,0,0,0-.1-1.49,3.9,3.9,0,0,0-.29-1,2.3,2.3,0,0,0-.48-.68,2.51,2.51,0,0,0-.65-.45,6.46,6.46,0,0,0-.83-.36,4,4,0,0,0,1.56-.82,3.76,3.76,0,0,0,1-1.3,4.06,4.06,0,0,0,.33-1.63,3,3,0,0,0-.09-.8,4.33,4.33,0,0,0-.32-1,4.2,4.2,0,0,0-1.71-1.84,5.58,5.58,0,0,0-1.56-.62,9,9,0,0,0-2.14-.24h-9.36v15.77ZM306,99.42V95.21h4.82a4.11,4.11,0,0,1,1.38.2,2.06,2.06,0,0,1,.82.51,1.72,1.72,0,0,1,.4.68,2,2,0,0,1,.11.67,2.15,2.15,0,0,1-.33,1.25,1.82,1.82,0,0,1-1,.69,5.69,5.69,0,0,1-1.68.21Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M333.57 92.48 329.7 92.48 329.7 108.25 333.57 108.25 333.57 92.48 333.57 92.48Z"), // polygon
        ImageVector.Path(fillColor = theme.foreground, path = "M350.54 103.46 350.49 103.46 343.14 92.48 339.06 92.48 339.06 108.25 342.67 108.25 342.67 97.01 342.73 97.01 350.28 108.25 354.15 108.25 354.15 92.48 350.54 92.48 350.54 103.46 350.54 103.46Z"), // polygon
        ImageVector.Path(fillColor = theme.foreground, path = "M366.63 104.43 366.58 104.43 362.61 92.48 358.46 92.48 364.7 108.25 368.38 108.25 374.68 92.48 370.65 92.48 366.63 104.43 366.63 104.43Z"), // polygon
        ImageVector.Path(fillColor = theme.foreground, path = "M392.81 92.48 379.19 92.48 379.19 108.25 393.25 108.25 393.25 105.46 382.98 105.46 382.98 101.38 392.01 101.38 392.01 98.59 382.98 98.59 382.98 95.27 392.81 95.27 392.81 92.48 392.81 92.48Z"), // polygon
        ImageVector.Path(fillColor = theme.foreground, path = "M409.75 103.46 409.7 103.46 402.35 92.48 398.28 92.48 398.28 108.25 401.89 108.25 401.89 97.01 401.94 97.01 409.49 108.25 413.36 108.25 413.36 92.48 409.75 92.48 409.75 103.46 409.75 103.46Z"), // polygon
        ImageVector.Path(fillColor = theme.foreground, path = "M427.01 95.27 432.6 95.27 432.6 92.48 417.54 92.48 417.54 95.27 423.14 95.27 423.14 108.25 427.01 108.25 427.01 95.27 427.01 95.27Z"), // polygon
        ImageVector.Path(fillColor = theme.foreground, path = "M435.11,100.37a10.08,10.08,0,0,0,.36,2.78,7.27,7.27,0,0,0,1,2.1,6.88,6.88,0,0,0,1.4,1.49,8.35,8.35,0,0,0,1.63,1,10.05,10.05,0,0,0,1.7.58,11.11,11.11,0,0,0,1.57.28,10,10,0,0,0,1.26.07,10.17,10.17,0,0,0,1.27-.07,11.11,11.11,0,0,0,1.57-.28,9.67,9.67,0,0,0,1.69-.58,8.42,8.42,0,0,0,1.64-1,6.85,6.85,0,0,0,1.39-1.49,7,7,0,0,0,1-2.1,10.67,10.67,0,0,0,0-5.57,7,7,0,0,0-1-2.1A6.85,6.85,0,0,0,450.17,94a8.42,8.42,0,0,0-1.64-1,9.67,9.67,0,0,0-1.69-.58,11.11,11.11,0,0,0-1.57-.28,10.17,10.17,0,0,0-1.27-.07,10,10,0,0,0-1.26.07,11.11,11.11,0,0,0-1.57.28,10.05,10.05,0,0,0-1.7.58,8.35,8.35,0,0,0-1.63,1,6.88,6.88,0,0,0-1.4,1.49,7.27,7.27,0,0,0-1,2.1,10.1,10.1,0,0,0-.36,2.79Zm3.87,0a7.22,7.22,0,0,1,.32-2.27,4.73,4.73,0,0,1,.84-1.58,4.47,4.47,0,0,1,1.19-1,5.45,5.45,0,0,1,1.35-.52,6.22,6.22,0,0,1,1.32-.15,6.31,6.31,0,0,1,1.33.15,5.45,5.45,0,0,1,1.35.52,4.43,4.43,0,0,1,1.18,1,4.57,4.57,0,0,1,.85,1.58,8.18,8.18,0,0,1,0,4.53,4.67,4.67,0,0,1-.85,1.58,4.43,4.43,0,0,1-1.18,1,5.45,5.45,0,0,1-1.35.52,6.31,6.31,0,0,1-1.33.15,6.22,6.22,0,0,1-1.32-.15,5.45,5.45,0,0,1-1.35-.52,4.47,4.47,0,0,1-1.19-1,4.83,4.83,0,0,1-.84-1.58,7.2,7.2,0,0,1-.32-2.26Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M458.06,108.25h3.79v-6.17h4a6.85,6.85,0,0,1,1.68.16,1.68,1.68,0,0,1,.9.52,1.86,1.86,0,0,1,.36.93,8.05,8.05,0,0,1,.07,1.37c0,.54,0,1,.05,1.39a6.43,6.43,0,0,0,.15,1,5.81,5.81,0,0,0,.22.8h4.28v-.42a1,1,0,0,1-.48-.28,1.14,1.14,0,0,1-.25-.53,7.72,7.72,0,0,1-.09-.9c0-.38,0-.86,0-1.43a9,9,0,0,0-.1-1.49,3.55,3.55,0,0,0-.29-1,2.28,2.28,0,0,0-.47-.68,2.57,2.57,0,0,0-.66-.45,6.46,6.46,0,0,0-.83-.36,4,4,0,0,0,1.56-.82,3.53,3.53,0,0,0,1-1.3,4.07,4.07,0,0,0,.34-1.63,4.16,4.16,0,0,0-.09-.8,4.23,4.23,0,0,0-2-2.8,5.58,5.58,0,0,0-1.56-.62,9,9,0,0,0-2.14-.24h-9.36v15.77Zm3.79-8.83V95.21h4.82a4.11,4.11,0,0,1,1.38.2,2.1,2.1,0,0,1,.83.51,2,2,0,0,1,.4.68,2.32,2.32,0,0,1,.1.67,2.15,2.15,0,0,1-.33,1.25,1.82,1.82,0,0,1-1,.69,5.62,5.62,0,0,1-1.67.21Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M486.14 102.34 492.33 92.48 487.95 92.48 484.29 99.33 480.44 92.48 475.88 92.48 482.28 102.3 482.28 108.25 486.14 108.25 486.14 102.34 486.14 102.34Z"), // polygon
    )
)
fun Resources.lussoLogo(theme: Theme) = ImageVector(
    10.rem, 1.5.rem, 0, 0, 452, 96,
    paths = listOf(
        ImageVector.Path(fillColor = theme.foreground, path = "M11.55,95.45v-22.77H3.36v-5.25h22.35v5.25h-8.15v22.77h-6.01Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M39.19,95.45v-28.02h19.83v5.25h-13.86v5.92h13.57v5.25h-13.57v6.34h13.86v5.25h-19.83Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M71.99,81.46c0-8.61,6.51-14.49,14.91-14.49,6.17,0,9.75,3.36,11.72,6.85l-5.12,2.52c-1.18-2.27-3.7-4.07-6.6-4.07-5.08,0-8.78,3.91-8.78,9.2s3.7,9.2,8.78,9.2c2.9,0,5.42-1.76,6.6-4.07l5.12,2.48c-2.02,3.49-5.54,6.89-11.72,6.89-8.4,0-14.91-5.88-14.91-14.49Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M131.05,95.45v-11.76h-13.23v11.76h-5.96v-28.02h5.96v11.01h13.23v-11.01h6.01v28.02h-6.01Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M171.53,95.45l-13.36-18.27v18.27h-5.96v-28.02h6.13l12.98,17.6v-17.6h5.96v28.02h-5.75Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M191.14,81.46c0-8.44,6.17-14.49,14.62-14.49s14.66,6.05,14.66,14.49-6.17,14.49-14.66,14.49-14.62-6.05-14.62-14.49ZM214.29,81.46c0-5.25-3.36-9.2-8.53-9.2s-8.49,3.95-8.49,9.2,3.32,9.2,8.49,9.2,8.53-3.99,8.53-9.2Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M234.28,95.45v-28.02h5.96v22.77h11.85v5.25h-17.81Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M263.43,81.46c0-8.44,6.17-14.49,14.62-14.49s14.66,6.05,14.66,14.49-6.17,14.49-14.66,14.49-14.62-6.05-14.62-14.49ZM286.57,81.46c0-5.25-3.36-9.2-8.53-9.2s-8.49,3.95-8.49,9.2,3.32,9.2,8.49,9.2,8.53-3.99,8.53-9.2Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M305.22,81.46c0-8.86,6.72-14.49,14.91-14.49,5.84,0,9.49,2.94,11.59,6.26l-4.96,2.69c-1.26-1.97-3.66-3.65-6.64-3.65-5.08,0-8.78,3.91-8.78,9.2s3.7,9.2,8.78,9.2c2.48,0,4.83-1.09,5.96-2.14v-3.36h-7.39v-5.21h13.36v10.75c-2.86,3.19-6.85,5.29-11.93,5.29-8.19,0-14.91-5.71-14.91-14.53Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M346.46,95.45v-28.02h5.96v28.02h-5.96Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M367.63,95.45v-28.02h19.83v5.25h-13.86v5.92h13.57v5.25h-13.57v6.34h13.86v5.25h-19.83Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M399.88,91.5l3.24-4.54c1.97,2.06,5.04,3.78,8.91,3.78,3.28,0,4.87-1.51,4.87-3.11,0-4.96-16.13-1.55-16.13-12.1,0-4.66,4.03-8.53,10.63-8.53,4.45,0,8.15,1.34,10.92,3.91l-3.32,4.37c-2.27-2.1-5.29-3.07-8.15-3.07-2.56,0-3.99,1.13-3.99,2.81,0,4.45,16.09,1.47,16.09,11.93,0,5.12-3.65,8.99-11.22,8.99-5.38,0-9.24-1.81-11.85-4.45Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M166.3.01h-36.77l-18.64,41h-15.68L113.85.01h-44.18v1.7l5.37,2.87-14.51,31.99c-2.25,5,0,9,4.9,9h71.1c4.95,0,10.89-4,13.14-9L166.3,0h0Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M214.96,28.63h-43.77c-5.64,0-6.34-5-4.42-9.17l4.28-9.41C174.75,1.91,181.62.05,189.91.05h74.8l-2.07,4.57h-52.37l-5.74,12.65h43.78c5.64,0,6.34,5,4.42,9.18l-4.2,9.08c-3.7,8.14-10.57,10-18.86,10h-74.8l2.07-4.56h52.37l5.62-12.37.03.03Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M308.65,28.63h-43.78c-5.64,0-6.33-5-4.41-9.17l4.28-9.41C268.44,1.91,275.31.05,283.6.05h74.8l-2.08,4.57h-52.36l-5.78,12.69h43.78c5.64,0,6.33,5,4.41,9.18l-4.13,9.08c-3.7,8.14-10.57,10-18.86,10h-74.8l2.08-4.56h52.37l5.62-12.37h0Z"),
        ImageVector.Path(fillColor = theme.foreground, path = "M 377.32031 0.009765625 C 371.18032 -0.000234365 363.87961 5.0203968 361.09961 11.150391 L 350.56055 34.320312 C 347.77055 40.450306 350.70008 45.560547 356.83008 45.560547 L 424.60938 45.560547 C 430.73937 45.560547 438.00907 40.479369 440.78906 34.359375 L 451.34961 11.150391 C 454.12961 5.0103968 451.33921 0.009765625 445.19922 0.009765625 L 377.32031 0.009765625 z M 400.82031 4.5800781 L 417.60938 4.5800781 L 401.03906 41 L 384.25 41 L 400.82031 4.5800781 z "),
        ImageVector.Path(fillColor = Color.red, path = "M 0 57.59 L 24.11 4.58 L 18.74 1.71 L 18.74 .01 L 62.92 .01 L 38.81 53.02 L 432.3 53.02 L 430.23 57.59 L 0 57.59 L 0 57.59 Z"),
    )
)