package com.ilussobsa.views

import com.ilussobsa.*
import com.ilussobsa.sdk.currentSessionNullable
import com.ilussobsa.sdk.currentSession
import com.ilussobsa.utils.navList
import com.lightningkite.UUID
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.QueryParameter
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.KiteUiScreen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.ilussobsa.Strings
import com.ilussobsa.sdk.currentSession
import com.ilussobsa.sdk.currentUser
import com.lightningkite.kiteui.delay
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningdb.condition
import com.lightningkite.lightningserver.files.*
import com.lightningkite.lightningserver.websocket.*
import com.lightningkite.serialization.*
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.serialization.Serializable

@Routable("/buying-center")
class BuyingCenterScreen() : KiteUiScreen {

    @Serializable
    enum class BuyingTab(
        val text: String,
        val priority: Int,
        val filter: ReactiveContext.() -> Condition<VehicleRelationship>,
        val notifyCount: Boolean = false
    ) {
        All(text = Strings.allTab, priority = 10, filter = {
            condition {
                it.favorite.eq(true) or it.autobid.neq(null) or it.notifications.eq(true) or it.bidPlaced.neq(null)
            }
        }),
        UpcomingFavorites(text = Strings.favoritesTab, priority = 9, filter = {
            condition {
                it.favorite.eq(true) and it.vehicleDenormalizedInfo.completion.eq(null)
            }
        }),
        OpenProxyBids(text = Strings.proxyBidsTab, priority = 8, filter = {
            condition {
                it.autobid.neq(null) and it.vehicleDenormalizedInfo.completion.eq(null)
            }
        }),
        LaneAlerts(text = Strings.laneAlertsTab, priority = 9, filter = {
            condition {
                it.notifications.eq(true) and it.vehicleDenormalizedInfo.completion.eq(null)
            }
        }),
        Bought(text = Strings.boughtTab, priority = 5, filter = {
            condition {
                it.vehicleDenormalizedInfo.completion.notNull.winner.eq(
                    currentUser()?._id
                ) and
                        it.vehicleDenormalizedInfo.completion.notNull.sold.eq(true) and
                        it.vehicleDenormalizedInfo.received.eq(null) and
                        it.vehicleDenormalizedInfo.cancelled.eq(null)
            }
        }, notifyCount = true),
        BiddingHistory(text = Strings.biddingHistoryTab, priority = 10, filter = { condition { it.bidPlaced neq null } }),

//        Cancelled(text = "Cancelled", filter = {
//            condition {
//                it.vehicleDenormalizedInfo.completion.notNull.winner.eq(
//                    myDealership.await()
//                ) and it.vehicleDenormalizedInfo.cancelled.neq(null)
//            }
//        }),

    }


    @QueryParameter("tab")
    val tab = Property<BuyingTab>(BuyingTab.All)

    @QueryParameter("selectedLot")
    val selectedLot = Property<UUID?>(null)

    @QueryParameter("query")
    val search = Property<String>("")

    @QueryParameter("sort")
    val sort = Property<List<SortPart<VehicleRelationship>>>(sort { it.firstInteraction.descending() })

    val query = shared {
        Query(
            condition<VehicleRelationship> {
                Condition.And(
                    listOfNotNull(
                        tab().filter(),
                        it._id.user.eqNn(currentUser()?._id),
                        search.debounce(500L)().takeUnless { it.isBlank() }
                            ?.let { q ->
                                q.split(' ').filter { it.isNotBlank() }.map { p ->
                                    Condition.Or(
                                        listOf(
                                            it.vehicleDenormalizedInfo.make.notNull.contains(p, true),
//                                            it.vehicleDenormalizedInfo.year.notNull.contains(p, true),
                                            it.vehicleDenormalizedInfo.model.notNull.contains(p, true),
                                            it.vehicleDenormalizedInfo.trim.notNull.contains(p, true),
                                        )
                                    )
                                }.let { Condition.And(it) }
                            },
                    )
                )
            },
            orderBy = sort(),
            limit = 500,
        )
    }

    override fun ViewWriter.render() {

        col {
            val toListen = shared {
                currentSessionNullable.awaitNotNull().vehicleRelationships.watch(query())
            }

            reactiveScope {
                tab()
                launch {
                    delay(1)
                    toListen()().let {
                        if(it.none { it._id.vehicle == selectedLot.value }) {
                            selectedLot.set(it.firstOrNull()?.vehicleDenormalizedInfo?._id)
                        }
                    }
                }
            }

            fun onCurrentNotInTab(action: suspend () -> Unit) {
                reactiveSuspending {
                    val results = toListen()()
                    delay(2)
                    selectedLot.value?.let {
                        if (results.none { r -> r._id.vehicle == it }) {
                            println("$it is not in the results ${results.joinToString { it._id.vehicle.toString() }}")
                            launch { action() }
                        }
                    } ?: run {
                        println("null is not in the results ${results.joinToString { it._id.vehicle.toString() }}")
                        launch { action() }
                    }
                }
            }
            var lastIndex = 0
            reactiveScope {
                val sel = selectedLot()
                val i = toListen()().indexOfFirst { it._id.vehicle == sel }
                if (i != -1) {
                    lastIndex = i
                }
            }
            when(tabBehavior.value) {
                TabBehavior.NextInTab -> onCurrentNotInTab {
                    selectedLot.set(toListen()().let { it.getOrNull(lastIndex) ?: it.firstOrNull() }?._id?.vehicle)
                }
                TabBehavior.FollowVehicle -> onCurrentNotInTab {
                    val full = currentSession().vehicleRelationships.get(UserVehiclePair(currentUser()?._id ?: nullUuid, selectedLot.value ?: nullUuid)).invoke()
                    if (full == null) selectedLot.set(null)
                    else tab.value = BuyingTab.values().sortedBy { it.priority } .find { it.filter()(full) } ?: BuyingTab.All
                }
                TabBehavior.Clear -> onCurrentNotInTab { selectedLot.set(null) }
            }

            scrollsHorizontally - compact - card - row {
                gravity(Align.Start, Align.Center) - row {
                    for (tab in BuyingTab.values()) {
                        centered - radioToggleButton {
                            checked bind this@BuyingCenterScreen.tab.equalTo(tab)
                            row {
                                col {
                                    text(tab.text)
                                }
                                if (tab.notifyCount) {
                                    critical - stack {
                                        val ifBidNum = shared {
                                            currentSession.awaitNotNull().vehicleRelationships.query(Query(Condition.And(
                                                listOfNotNull(
                                                    condition {
                                                        tab.filter() and it._id.user.eqNn(currentUser()?._id)
                                                    }
                                                )
                                            ))).await().size
                                        }
                                        spacing = 0.15.rem
                                        ::exists{ ifBidNum.await() > 0 }
                                        subtext {
                                            ::content{
                                                ifBidNum.await().toString()
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            expanding - listDetailV2(
                selected = selectedLot,
                list = { view ->
                    col {
                        card - row {
                            gravity(Align.Center, Align.Center) - sizeConstraints(
                                width = 1.5.rem,
                                height = 1.5.rem
                            ) - icon(
                                Icon.search,
                                Strings.search
                            )

                            gravity(Align.Start, Align.Center) - sizeConstraints(width = 20.rem) - textField {
                                hint = Strings.search
                                content bind search
                            }
                            gravity(Align.Start, Align.Center) - compact - button {
                                ::exists { search().isNotEmpty() }
                                sizeConstraints(width = 1.5.rem, height = 1.5.rem) - icon(
                                    Icon.close,
                                    Strings.clearSearch
                                )
                                onClick {
                                    search set ""
                                }
                            }
                        }
                        expanding - navList - stack {
                            val items = shared { toListen()() }
                            recyclerView {
                                children(items) { relationship ->
                                    val it =
                                        shared {
                                            currentSessionNullable.awaitNotNull().vehicles.get(relationship()._id.vehicle)()
                                        }
                                    compact - psuedoRadioToggleButton(shared { relationship().vehicleDenormalizedInfo._id }, selectedLot, view) {
                                        sellingLotShortDetails(it.waitForNotNull)
                                    }
                                }
                            }
                            centered - text {
                                exists = false
                                content = Strings.noVehiclesMatchedTheseConditions
                                ::exists { items().isEmpty() }
                            }
                        }
                    }
                },
                detail = {
                    if(it == null) object: Screen {
                        override fun ViewWriter.render() {
                            nothingSelected(Strings.pleaseSelectAVehicleOnTheLeft)
                        }
                    } else VehicleDetailScreen(it).apply { gotoOverride = { selectedLot.value = it } }
                }
//                detail = {
//                    swapView {
//                        swapping(
//                            current = { selectedLot.await() },
//                            views = { selectedLot ->
//                                if (selectedLot == null) {
//                                    nothingSelected(Strings.pleaseSelectAVehicleOnTheLeft)
//                                    launch {
//                                        delay(10L)
//                                        it.action()
//                                    }
//                                    return@swapping
//                                }
//                                scrolls - vehicleDetail(
//                                    selectedLot,
//                                    goto = { this@BuyingCenterScreen.selectedLot set it },
//                                    backButtonInfo = it
//                                )
//                            }
//                        )
//                    }
//                }it
            )

        }
    }
}