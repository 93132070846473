package com.ilussobsa.views

import com.ilussobsa.sdk.currentSession
import com.lightningkite.UUID

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.lightningdb.*
import com.ilussobsa.Strings
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.lightningserver.files.UploadInformation

suspend fun UploadInformation.upload(requestBody: RequestBody): ServerFile {
    val futureCallToken = ServerFile(futureCallToken)
    val uploadUrl = uploadUrl
    val response = fetch(
        url = uploadUrl,
        method = HttpMethod.PUT,
        body = requestBody
    )
    return futureCallToken
}