package com.ilussobsa.views

import com.ilussobsa.*
import com.ilussobsa.sdk.currentSession
import com.ilussobsa.sdk.currentUser
import com.ilussobsa.utils.priceField
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.ExternalServices
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.lazyExpanding
import com.lightningkite.lightningdb.modification
import com.lightningkite.serialization.lensPath

fun ViewWriter.verificationForm() {
    val financingSource = Property<FinancingSource?>(null)
    scrolls - col {
        h3(Strings.howWillYouPay)
        rowCollapsingToColumn(60.rem) {
            expanding - card - radioToggleButton {
                val value = FinancingSource.Cash
                centered - text(Strings.financingSourceForm(value))
                checked bind financingSource.equalTo(value)
            }
            expanding - card - radioToggleButton {
                val value = FinancingSource.OwnFinancing
                centered - text(Strings.financingSourceForm(value))
                checked bind financingSource.equalTo(value)
            }
            expanding - card - link {
                val value = FinancingSource.IlussoFinancing
                dynamicTheme {
                    if(financingSource() == value) SelectedSemantic else UnselectedSemantic
                }
                centered - text(Strings.financingSourceForm(value))
                to = { FinancingFormScreen() }
            }
        }
        lazyExpanding(financingSource.equalTo(FinancingSource.Cash)) {
            val submit = Action(Strings.submit, Icon.done) {
                currentUser()?._id?.let { currentSession().users[it].modify(modification {
                    it.financingSource assign FinancingSource.Cash
                }) }
            }
            important - gravity(Align.Start, Align.Center) - button {
                text(Strings.submit)
                action = submit
            }
        }
        lazyExpanding(financingSource.equalTo(FinancingSource.OwnFinancing)) {
            val prop = Property(ExternalFinancingForm())
            val submit = Action(Strings.submit, Icon.done) {
                val form = prop().copy(user = currentUser()?._id ?: nullUuid)
                currentSession().externalFinancingForms.insert(form)
                currentUser()?._id?.let { currentSession().users[it].modify(modification {
                    it.financingSource assign FinancingSource.OwnFinancing
                    it.currentExternalFinancingForm assign form._id
                }) }
            }
            col {
                externalFinancingForm(prop)
                important - gravity(Align.Start, Align.Center) - button {
                    text(Strings.submit)
                    action = submit
                }
            }
        }
        lazyExpanding(financingSource.equalTo(FinancingSource.IlussoFinancing)) {
            col {

            }
        }
    }
}

fun ViewWriter.externalFinancingForm(form: Property<ExternalFinancingForm>) {
    col {
        label {
            content = Strings.whatBank
            fieldTheme - textInput {
                content bind form.lensPath { it.bankName }
            }
        }
        label {
            content = Strings.howMuch
            gravity(Align.Start, Align.Center) - priceField(form.lensPath { it.approvalAmount })
        }
        label {
            content = Strings.howLong
            fieldTheme - sizeConstraints(width = 6.5.rem) - numberInput {
                content bind form.lensPath { it.termLengthInMonths }.asDouble()
            }
        }
        label {
            content = Strings.whatInterestRate
            gravity(Align.Start, Align.Center) - compact - fieldTheme - row {
                centered - sizeConstraints(width = 6.5.rem) - numberInput {
                    align = Align.End
                    range = 0.0..100.0
                    content bind shared { form().interestRate?.times(100) }
                        .withWrite { value -> form.modify { it.copy(interestRate = value?.div(100)) } }
                }
                centered - h3("%")
            }
        }
    }
}