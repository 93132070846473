package com.ilussobsa.views

import com.ilussobsa.Strings
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.navigation.KiteUiScreen
import com.lightningkite.kiteui.navigation.render
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.reactive.await
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.direct.icon
import com.lightningkite.kiteui.views.l2.icon

class SellingDialogWrapper(val inner: KiteUiScreen) : KiteUiScreen {
    override fun ViewWriter.render() {
        dismissBackground {
            row {
                weight(1f) - space {
                    ignoreInteraction = true
                }
                weight(2f) - card - col {
                    row {
                        expanding - h3 { ::content.invoke { inner.title() } }
                        compact - button {
                            icon(Icon.close, Strings.close)
                            onClick { navigator.dismiss() }
                        }
                    }
                    expanding - stack { inner.render(this) }
                }
            }
        }
    }
}