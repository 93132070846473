// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareEmployerInfoFields() {
    val props: Array<SerializableProperty<EmployerInfo, *>> = arrayOf(EmployerInfo_name, EmployerInfo_address, EmployerInfo_phone, EmployerInfo_yearsEmployed, EmployerInfo_supervisor, EmployerInfo_grossMonthlyIncome, EmployerInfo_otherMonthlyIncome, EmployerInfo_occupation)
    EmployerInfo.serializer().properties { props }
}
val <K> DataClassPath<K, EmployerInfo>.name: DataClassPath<K, String?> get() = this[EmployerInfo_name]
val <K> DataClassPath<K, EmployerInfo>.address: DataClassPath<K, UsAddress?> get() = this[EmployerInfo_address]
val <K> DataClassPath<K, EmployerInfo>.phone: DataClassPath<K, String?> get() = this[EmployerInfo_phone]
val <K> DataClassPath<K, EmployerInfo>.yearsEmployed: DataClassPath<K, Int?> get() = this[EmployerInfo_yearsEmployed]
val <K> DataClassPath<K, EmployerInfo>.supervisor: DataClassPath<K, String?> get() = this[EmployerInfo_supervisor]
val <K> DataClassPath<K, EmployerInfo>.grossMonthlyIncome: DataClassPath<K, PriceInDollars?> get() = this[EmployerInfo_grossMonthlyIncome]
val <K> DataClassPath<K, EmployerInfo>.otherMonthlyIncome: DataClassPath<K, PriceInDollars?> get() = this[EmployerInfo_otherMonthlyIncome]
val <K> DataClassPath<K, EmployerInfo>.occupation: DataClassPath<K, String?> get() = this[EmployerInfo_occupation]
inline val EmployerInfo.Companion.path: DataClassPath<EmployerInfo, EmployerInfo> get() = path<EmployerInfo>()


object EmployerInfo_name: SerializableProperty<EmployerInfo, String?> {
    override val name: String = "name"
    override fun get(receiver: EmployerInfo): String? = receiver.name
    override fun setCopy(receiver: EmployerInfo, value: String?) = receiver.copy(name = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = EmployerInfo.serializer().tryFindAnnotations("name")
    override val default: String? = null
}
object EmployerInfo_address: SerializableProperty<EmployerInfo, UsAddress?> {
    override val name: String = "address"
    override fun get(receiver: EmployerInfo): UsAddress? = receiver.address
    override fun setCopy(receiver: EmployerInfo, value: UsAddress?) = receiver.copy(address = value)
    override val serializer: KSerializer<UsAddress?> = UsAddress.serializer().nullable2
    override val annotations: List<Annotation> = EmployerInfo.serializer().tryFindAnnotations("address")
    override val default: UsAddress? = null
}
object EmployerInfo_phone: SerializableProperty<EmployerInfo, String?> {
    override val name: String = "phone"
    override fun get(receiver: EmployerInfo): String? = receiver.phone
    override fun setCopy(receiver: EmployerInfo, value: String?) = receiver.copy(phone = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = EmployerInfo.serializer().tryFindAnnotations("phone")
    override val default: String? = null
}
object EmployerInfo_yearsEmployed: SerializableProperty<EmployerInfo, Int?> {
    override val name: String = "yearsEmployed"
    override fun get(receiver: EmployerInfo): Int? = receiver.yearsEmployed
    override fun setCopy(receiver: EmployerInfo, value: Int?) = receiver.copy(yearsEmployed = value)
    override val serializer: KSerializer<Int?> = Int.serializer().nullable2
    override val annotations: List<Annotation> = EmployerInfo.serializer().tryFindAnnotations("yearsEmployed")
    override val default: Int? = null
}
object EmployerInfo_supervisor: SerializableProperty<EmployerInfo, String?> {
    override val name: String = "supervisor"
    override fun get(receiver: EmployerInfo): String? = receiver.supervisor
    override fun setCopy(receiver: EmployerInfo, value: String?) = receiver.copy(supervisor = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = EmployerInfo.serializer().tryFindAnnotations("supervisor")
    override val default: String? = null
}
object EmployerInfo_grossMonthlyIncome: SerializableProperty<EmployerInfo, PriceInDollars?> {
    override val name: String = "grossMonthlyIncome"
    override fun get(receiver: EmployerInfo): PriceInDollars? = receiver.grossMonthlyIncome
    override fun setCopy(receiver: EmployerInfo, value: PriceInDollars?) = receiver.copy(grossMonthlyIncome = value)
    override val serializer: KSerializer<PriceInDollars?> = PriceInDollars.serializer().nullable2
    override val annotations: List<Annotation> = EmployerInfo.serializer().tryFindAnnotations("grossMonthlyIncome")
    override val default: PriceInDollars? = null
}
object EmployerInfo_otherMonthlyIncome: SerializableProperty<EmployerInfo, PriceInDollars?> {
    override val name: String = "otherMonthlyIncome"
    override fun get(receiver: EmployerInfo): PriceInDollars? = receiver.otherMonthlyIncome
    override fun setCopy(receiver: EmployerInfo, value: PriceInDollars?) = receiver.copy(otherMonthlyIncome = value)
    override val serializer: KSerializer<PriceInDollars?> = PriceInDollars.serializer().nullable2
    override val annotations: List<Annotation> = EmployerInfo.serializer().tryFindAnnotations("otherMonthlyIncome")
    override val default: PriceInDollars? = null
}
object EmployerInfo_occupation: SerializableProperty<EmployerInfo, String?> {
    override val name: String = "occupation"
    override fun get(receiver: EmployerInfo): String? = receiver.occupation
    override fun setCopy(receiver: EmployerInfo, value: String?) = receiver.copy(occupation = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = EmployerInfo.serializer().tryFindAnnotations("occupation")
    override val default: String? = null
}
