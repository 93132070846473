package com.ilussobsa.utils

import com.ilussobsa.Resources
import com.ilussobsa.Strings
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.ViewWrapper
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.reactive.Constant
import com.lightningkite.kiteui.reactive.PersistentProperty
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.l2.*
import kotlin.math.absoluteValue
import kotlin.reflect.KFunction2
import kotlin.time.Duration.Companion.seconds
import kotlinx.serialization.Serializable


val ferrariInspired = Theme(
    id = "ferrariDark",
    title = FontAndStyle(Resources.goldman),
    body = FontAndStyle(Resources.roboto),
    elevation = 0.dp,
    cornerRadii = CornerRadii.Constant(0.5.rem),
    spacing = 0.75.rem,
    outline = Color.gray(0.8f),
    outlineWidth = 0.px,
    foreground = Color.gray(0.8f),
    background = RadialGradient(
        stops = listOf(
            GradientStop(0f, Color.gray(0.2f)),
            GradientStop(0.4f, Color.gray(0.1f)),
            GradientStop(1f, Color.gray(0.1f)),
        ),
    ),
    bar = { card() },
    dialog = { card() },
    card = {
        copy(background = this.background.closestColor().highlight(0.05f))
    },
    field = {
        copy(
            outlineWidth = 1.px,
            outline = foreground.closestColor().highlight(0.2f),
            cornerRadii = CornerRadii.ForceConstant(0.5.rem),
        )
    },
    nav = {
        copy(
            background = LinearGradient(
                stops = listOf(
                    GradientStop(0f, Color.gray(0.12f).withAlpha(0.5f)),
                    GradientStop(1f, Color.gray(0.1f).withAlpha(0.5f)),
                ),
                angle = Angle.halfTurn
            ),
        )
    }
)

val ferrariLight = Theme(
    id = "ferrariLight",
    title = FontAndStyle(Resources.goldman),
    body = FontAndStyle(Resources.roboto),
    elevation = 0.dp,
    cornerRadii = CornerRadii.Constant(0.5.rem),
    spacing = 0.75.rem,
    outline = Color.gray(0.2f),
    outlineWidth = 0.px,
    foreground = Color.gray(0.2f),
    background = RadialGradient(
        stops = listOf(
            GradientStop(0f, Color.gray(1f)),
            GradientStop(0.4f, Color.gray(0.88f)),
            GradientStop(1f, Color.gray(0.88f)),
        ),
    ),
    field = {
        copy(
            outlineWidth = 1.px,
            cornerRadii = CornerRadii.ForceConstant(0.5.rem),
            outline = foreground.closestColor().highlight(0.2f)
        )
    },
    bar = { copy(background = this.background.closestColor().lighten(0.2f)) },
    dialog = { card() },
    card = {
        copy(background = this.background.closestColor().lighten(0.5f))
    },
    nav = {
        copy(
            background = LinearGradient(
                stops = listOf(
                    GradientStop(0f, Color.gray(0.90f).withAlpha(0.5f)),
                    GradientStop(1f, Color.gray(0.70f).withAlpha(0.5f)),
                ),
                angle = Angle.halfTurn
            ),
        )
    }
)


//val accentHue = Angle(0.4)
val accentHue = Angle(0.56)
val flatDark = Theme.flat("flatDark", accentHue, baseBrightness = 0.1f, saturation = 0.7f).copy(
    title = FontAndStyle(Resources.goldman),
    body = FontAndStyle(Resources.roboto),
)
val flatDark2 = Theme.flat("flatDark2", accentHue, baseBrightness = 0.1f, saturation = 0.7f).copy(
    title = FontAndStyle(Resources.teko, additionalLetterSpacing = 0.3.rem),
    body = FontAndStyle(Resources.roboto),
)

val lightSaturation = 0.2f
val flatLight = Theme.flat("flatLight", accentHue, saturation = lightSaturation, baseBrightness = 0.9f).copy(
    title = FontAndStyle(Resources.goldman),
    body = FontAndStyle(Resources.roboto),
)

fun brandBasedExperimental(id: String, normalBack: Color = Color.gray(0.1f)): Theme {
    val startingBack = normalBack.highlight(0.05f)
//    val dominant = Color.fromHex(0x002b3f).toHSP()
    val dominant = Color.fromHex(0x000000).toHSP()
    val critical = Color.fromHex(0xCC0000).toHSP()
    val secondary = Color.fromHex(0xa2a2a2)
    val tertiary1 = Color.fromHex(0x5f5f5f).also {
        if (normalBack.perceivedBrightness < 0.5) it.toHSP().let { it.copy(brightness = 1f - it.brightness) }
            .toRGB() else it
    }
//    val tertiary2 = Color.fromHex(0x04A7CD).also {
//        if (normalBack.perceivedBrightness < 0.5) it.toHSP().let { it.copy(brightness = 1f - it.brightness) }
//            .toRGB() else it
//    }
    val tertiary2 = Color.fromHex(0xC7C7C7).also {
        if (normalBack.perceivedBrightness < 0.5) it.toHSP().let { it.copy(brightness = 1f - it.brightness) }
            .toRGB() else it
    }
    val tertiary3 = Color.fromHex(0xE3E3E3).also {
        if (normalBack.perceivedBrightness < 0.5) it.toHSP().let { it.copy(brightness = 1f - it.brightness) }
            .toRGB() else it
    }
//    val tertiary3 = Color.fromHex(0x97d0e2).also {
//        if (normalBack.perceivedBrightness < 0.5) it.toHSP().let { it.copy(brightness = 1f - it.brightness) }
//            .toRGB() else it
//    }
//    val tertiary4 = Color.fromHex(0x008EBC).also {
//        if (normalBack.perceivedBrightness < 0.5) it.toHSP().let { it.copy(brightness = 1f - it.brightness) }
//            .toRGB() else it
//    }
    val tertiary4 = Color.fromHex(0xBABABA).also {
        if (normalBack.perceivedBrightness < 0.5) it.toHSP().let { it.copy(brightness = 1f - it.brightness) }
            .toRGB() else it
    }
//    0x04A7CD
//    0x008EBC
    val assurance = if (normalBack.perceivedBrightness > 0.6f) Color.fromHex(0x04A7CD) else Color.fromHex(0x008EBC)
    val importanceRotation = listOf(tertiary3, normalBack, tertiary2, tertiary1, tertiary4).map { it.toHSP() }
    infix fun HSPColor.distanceTo(other: HSPColor): Float {
        return (this.hue - other.hue).absoluteValue.turns + (this.saturation - other.saturation).absoluteValue + (this.brightness - other.brightness).absoluteValue
    }

    fun Paint.outermostColor(): Color = when (this) {
        is Color -> this
        is FadingColor -> this.base
        is LinearGradient -> this.stops.first().color
        is RadialGradient -> this.stops.last().color
    }

    fun Paint.rotateImportance(): Paint {
        val hsp = outermostColor().toHSP()
        if (hsp.saturation > 0.5f) return hsp.toRGB().highlight(0.1f)
        return importanceRotation[
            importanceRotation.indices.minBy { importanceRotation[it] distanceTo hsp }.plus(1) % importanceRotation.size
        ].toRGB()
    }

    fun Paint.rotateBack(): Paint {
        val hsp = outermostColor().toHSP()
        if (hsp.saturation > 0.5f) return outermostColor().highlight(0.1f)
        return when {
            hsp.brightness < 0.99f -> normalBack
            else -> Color.gray(0.95f)
        }
    }

    fun Paint.deriveForeground(): Paint {
        val hsp = outermostColor().toHSP()
        return if (hsp.brightness > 0.6f) {
            if (dominant.brightness < 0.2f)
                dominant.toRGB()
            else
                Color.black
        } else {
            if (dominant.brightness > 0.8f)
                dominant.toRGB()
            else
                Color.white
        }
    }

    return Theme(
        id = id,
        title = FontAndStyle(Resources.stolzl),
        body = FontAndStyle(Resources.poppins),
        background = startingBack,
        elevation = 0.dp,
        transitionDuration = 0.25.seconds,
        cornerRadii = CornerRadii.Constant(0.5.rem),
        spacing = 0.75.rem,
        outlineWidth = 0.px,
        foreground = startingBack.deriveForeground(),
        unselected = {
            val b = background.outermostColor().toHSP()
            if (b.saturation > 0.5 && (b.brightness - 0.5f).absoluteValue < 0.2f)
                copy(
                    id = "uns",
                    background = startingBack.highlight(0.1f),
                    foreground = startingBack.highlight(0.1f).deriveForeground(),
                )
            else null
        },
        selected = { important() },
        field = {
            copy(
                id = "fld",
                outline = background.outermostColor().highlight(0.3f),
                outlineWidth = 0.1.rem,
                cornerRadii = CornerRadii.ForceConstant(0.5.rem),
                background = background.outermostColor()
            )
        },
        focus = {
            copy(
                id = "fcs",
                outline = important().background,
                outlineWidth = 0.3.rem,
            )
        },
        bar = {
            val newBack = dominant.toRGB()
            copy(
                id = "bar",
                background = newBack,
                foreground = newBack.deriveForeground()
            )
        },
        important = {
            val newBack = background.rotateImportance()
            copy(
                id = "imp",
                background = newBack,
                foreground = newBack.deriveForeground()
            )
        },
        critical = {
            val newBack = critical.toRGB()
            copy(
                id = "crt",
                background = newBack,
                foreground = newBack.deriveForeground()
            )
        },
        card = {
            val newBack = background.rotateBack()
            copy(
                id = "crd",
                background = if (background !is Color) newBack.applyAlpha(0.87f) else newBack,
//                backdropFilters = if(background !is Color) listOf(BackdropFilter.Blur(1.rem)) else listOf(),
                foreground = newBack.deriveForeground()
            )
        },
        dialog = {
            copy(
                id = "dlg",
                background = background.outermostColor()
            )
        },
//        danger = {
//            copy(
//                background = Color.fromHex(0xFFB00020.toInt()),
//                outline = Color.fromHex(0xFFB00020.toInt()).highlight(0.1f),
//                foreground = Color.white
//            )
//        },
//        affirmative = {
//            copy(
//                background = Color.fromHex(0xFF20a020.toInt()),
//                outline = Color.fromHex(0xFF20a020.toInt()).highlight(0.1f),
//                foreground = Color.white
//            )
//        },
    )/*.let {
        it.customize(
            newId = it.id + "-start",
            background = startingBack.withStripe(),
            revert = true
        )
    }*/
}

@Serializable
enum class ThemePreference(val title: String, val icon: Icon, val theme: Theme) {
    Brand("Brand Experimental", Icon.lightMode, brandBasedExperimental("brand", Color.white)),
    BrandDark("Brand Experimental Dark", Icon.darkMode, brandBasedExperimental("brand-dark", Color.gray(0.1f))),
    Dark(Strings.dark, Icon.darkMode, flatDark),
    Dark2(Strings.dark2, Icon.darkMode, flatDark2),
    Light(Strings.light, Icon.lightMode, flatLight),
    GrayscaleDark(Strings.grayscaleDark, Icon.darkMode, ferrariInspired),
    GrayscaleLight(Strings.grayscaleLight, Icon.lightMode, ferrariLight),
}

@Serializable
enum class NavPreference(
    val title: String,
    val factory: KFunction2<ViewWriter, AppNav.() -> Unit, Unit>,
    val worksForSmall: Boolean
) {
    Hamburger(Strings.collapsible, ViewWriter::appNavHamburger, true),
    TopAndLeft(Strings.alwaysOpen, ViewWriter::appNavTopAndLeft, false),
    BottomTabs(Strings.bottomTabs, ViewWriter::appNavBottomTabs, true),
    Top(Strings.top, ViewWriter::appNavTop, false),
}

val appNavPreference = PersistentProperty<NavPreference?>("nav", null)

val appThemePreference = Constant<ThemePreference?>(null)

val defaultAppTheme = ThemePreference.values().first().theme

val ViewWriter.tabTheme: ViewWrapper
    get() = card
val ViewWriter.navList: ViewWrapper
    get() = card

@ViewModifierDsl3
val ViewWriter.overimage: ViewWrapper
    get() = themeFromLast {
        it.copy(
            background = it.background.applyAlpha(0.75f),
        )
    }