package com.ilussobsa.views

import com.ilussobsa.Strings
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.KiteUiScreen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.report
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*

fun <T> ViewWriter.multiselect(
    query: ReactiveContext.(input: String) -> List<T>,
    draw: (input: T) -> String,
    items: Writable<Set<T>>,
    enabled: ReactiveContext.() -> Boolean = { true },
    setup: RView.() -> Unit = {}
) {
    scrollsHorizontally - compact - row {
        dynamicTheme { if(enabled()) null else DisabledSemantic }
        row {
            forEachUpdating(shared { items().toList().sortedBy { draw(it) } }) { item ->
                important - row {
                    gravity(Align.Start, Align.Center) - subtext {
                        ::content { item().let(draw) }
                    }
                    gravity(Align.Center, Align.Center) - button {
                        sizeConstraints(width = 1.rem, height = 1.rem) - icon({ Icon.close }, Strings.removeOption)
                        onClick {
                            items set items.await().minus(item.await())
                        }
                    }
                }
            }
        }
        important - row {
            ::exists { items().isEmpty() }
            gravity(Align.Start, Align.Center) - subtext(Strings.all)
        }
        compact - gravity(Align.Center, Align.Center) - button {
            ::exists { enabled() }
            sizeConstraints(width = 1.rem, height = 1.rem) - icon({ Icon.add }, Strings.addItem)
            onClick {
                dialogScreenNavigator.navigate(SelectOptionsDialog(query, draw, items))
            }
        }
        setup()
    }
}

private class SelectOptionsDialog<T>(
    val q: ReactiveContext.(input: String) -> List<T>,
    val draw: (input: T) -> String,
    val items: Writable<Set<T>>
) : KiteUiScreen {

    val search = Property("")
    override fun ViewWriter.render() {
        dismissBackground {
            centered - card - col {
                centered - h2(Strings.select)
                fieldTheme - textField {
                    hint = Strings.search
                    content bind search
                    requestFocus()
                    action = Action("Select", Icon.done) {
                        q(search.value).firstOrNull()?.let { v ->
                            items.modify {
                                if(it.contains(v)) it.minus(v) else it.plus(v)
                            }
                        }
                    }
                }

                sizeConstraints(
                    width = 15.rem,
                    height = 20.rem
                ) - recyclerView {
                    children(
                        shared { q(search.debounce(500)()) }
                    ) { item ->
                        toggleButton {
                            gravity(Align.Start, Align.Center) - text {
                                ::content { item().let(draw) }
                            }
                            checked bind items.containsDynamic { item() }
                        }
                    }

                }
                important - button {
                    centered - h6(Strings.close)
                    onClick {
                        try {
                            navigator.dismiss()
                        } catch (e: Exception) {
                            e.report()
                            alert(
                                Strings.error,
                                Strings.thereWasAnErrorUpdatingThisSearch
                            )
                        }
                    }
                }
            }
        }
    }
}

infix fun <T> Writable<Set<T>>.containsDynamic(value: ReactiveContext.() -> T): Writable<Boolean> =
    shared { value() in this@containsDynamic() }.withWrite { on ->
        if (on) this@containsDynamic.set(this@containsDynamic.await() + value())
        else this@containsDynamic.set(this@containsDynamic.await() - value())
    }