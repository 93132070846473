package com.ilussobsa.views

import com.ilussobsa.sdk.currentSession
import com.ilussobsa.sdk.currentUser
import com.lightningkite.kiteui.ExternalServices
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*

fun ViewWriter.stripePaymentSetupCard() {
    scrolls - col {
        h3("Payment Setup")
        row {
            val onClick = Action("Stripe Payment Setup", Icon.done) {
                currentUser()?.let {
                    val url = currentSession().startStripe()
                    ExternalServices.openTab(url)
                }
            }
            expanding - card - button {
                ::exists{ currentUser()?.paymentSetup == null }
                centered - text("Stripe Payment Setup")
                action = onClick
            }
        }
    }
}