// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareUsAddressFields() {
    val props: Array<SerializableProperty<UsAddress, *>> = arrayOf(UsAddress_street, UsAddress_city, UsAddress_state, UsAddress_zip)
    UsAddress.serializer().properties { props }
}
val <K> DataClassPath<K, UsAddress>.street: DataClassPath<K, String?> get() = this[UsAddress_street]
val <K> DataClassPath<K, UsAddress>.city: DataClassPath<K, String?> get() = this[UsAddress_city]
val <K> DataClassPath<K, UsAddress>.state: DataClassPath<K, UsState?> get() = this[UsAddress_state]
val <K> DataClassPath<K, UsAddress>.zip: DataClassPath<K, String?> get() = this[UsAddress_zip]
inline val UsAddress.Companion.path: DataClassPath<UsAddress, UsAddress> get() = path<UsAddress>()


object UsAddress_street: SerializableProperty<UsAddress, String?> {
    override val name: String = "street"
    override fun get(receiver: UsAddress): String? = receiver.street
    override fun setCopy(receiver: UsAddress, value: String?) = receiver.copy(street = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = UsAddress.serializer().tryFindAnnotations("street")
    override val default: String? = null
}
object UsAddress_city: SerializableProperty<UsAddress, String?> {
    override val name: String = "city"
    override fun get(receiver: UsAddress): String? = receiver.city
    override fun setCopy(receiver: UsAddress, value: String?) = receiver.copy(city = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = UsAddress.serializer().tryFindAnnotations("city")
    override val default: String? = null
}
object UsAddress_state: SerializableProperty<UsAddress, UsState?> {
    override val name: String = "state"
    override fun get(receiver: UsAddress): UsState? = receiver.state
    override fun setCopy(receiver: UsAddress, value: UsState?) = receiver.copy(state = value)
    override val serializer: KSerializer<UsState?> = UsState.serializer().nullable2
    override val annotations: List<Annotation> = UsAddress.serializer().tryFindAnnotations("state")
    override val default: UsState? = null
}
object UsAddress_zip: SerializableProperty<UsAddress, String?> {
    override val name: String = "zip"
    override fun get(receiver: UsAddress): String? = receiver.zip
    override fun setCopy(receiver: UsAddress, value: String?) = receiver.copy(zip = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = UsAddress.serializer().tryFindAnnotations("zip")
    override val default: String? = null
}
