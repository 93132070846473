package com.ilussobsa.views

import com.ilussobsa.*
import com.ilussobsa.Strings
import com.ilussobsa.sdk.currentSession
import com.lightningkite.UUID
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*

@Routable("upload-vehicle/{vehicleId}/images")

class TakeNewVehicleImagesScreen(val vehicleId: UUID = nullUuid) : KiteUiScreen {

    private val vin = Property("")
    override fun ViewWriter.render() {
        stack {
            centered - col {
                h1("VIN:")
                sizeConstraints(width = 30.rem) - fieldTheme - textField {
                    hint = vinFormat.map { it.first() }.joinToString("")
                    content bind vin
                }
                important - button {
                    text(Strings.create)
                    ::enabled { vin().isValidVin() }
                    onClick {
                        try {
                            val result = currentSession().vehicles.insert(
                                Vehicle(
                                    vin = vin(),
                                )
                            )() ?: return@onClick
                            navigator.replace(EditVehicleScreen(result._id))
                        } catch (e: Exception) {
                            alert(Strings.error, Strings.thereWasAnErrorCreatingTheVehicle)
                        }
                    }
                }
            }
        }
    }
}