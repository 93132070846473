// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareCompletionFields() {
    val props: Array<SerializableProperty<Completion, *>> = arrayOf(Completion_at, Completion_bids, Completion_participants, Completion_sold, Completion_price, Completion_bidPrice, Completion_winner, Completion_expirationWarningSent)
    Completion.serializer().properties { props }
}
val <K> DataClassPath<K, Completion>.at: DataClassPath<K, Instant> get() = this[Completion_at]
val <K> DataClassPath<K, Completion>.bids: DataClassPath<K, Int> get() = this[Completion_bids]
val <K> DataClassPath<K, Completion>.participants: DataClassPath<K, Int> get() = this[Completion_participants]
val <K> DataClassPath<K, Completion>.sold: DataClassPath<K, Boolean> get() = this[Completion_sold]
val <K> DataClassPath<K, Completion>.price: DataClassPath<K, PriceInDollars> get() = this[Completion_price]
val <K> DataClassPath<K, Completion>.bidPrice: DataClassPath<K, PriceInDollars> get() = this[Completion_bidPrice]
val <K> DataClassPath<K, Completion>.winner: DataClassPath<K, UUID?> get() = this[Completion_winner]
val <K> DataClassPath<K, Completion>.expirationWarningSent: DataClassPath<K, Boolean> get() = this[Completion_expirationWarningSent]
inline val Completion.Companion.path: DataClassPath<Completion, Completion> get() = path<Completion>()


object Completion_at: SerializableProperty<Completion, Instant> {
    override val name: String = "at"
    override fun get(receiver: Completion): Instant = receiver.at
    override fun setCopy(receiver: Completion, value: Instant) = receiver.copy(at = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = Completion.serializer().tryFindAnnotations("at")
    override val default: Instant = now()
}
object Completion_bids: SerializableProperty<Completion, Int> {
    override val name: String = "bids"
    override fun get(receiver: Completion): Int = receiver.bids
    override fun setCopy(receiver: Completion, value: Int) = receiver.copy(bids = value)
    override val serializer: KSerializer<Int> = Int.serializer()
    override val annotations: List<Annotation> = Completion.serializer().tryFindAnnotations("bids")
}
object Completion_participants: SerializableProperty<Completion, Int> {
    override val name: String = "participants"
    override fun get(receiver: Completion): Int = receiver.participants
    override fun setCopy(receiver: Completion, value: Int) = receiver.copy(participants = value)
    override val serializer: KSerializer<Int> = Int.serializer()
    override val annotations: List<Annotation> = Completion.serializer().tryFindAnnotations("participants")
}
object Completion_sold: SerializableProperty<Completion, Boolean> {
    override val name: String = "sold"
    override fun get(receiver: Completion): Boolean = receiver.sold
    override fun setCopy(receiver: Completion, value: Boolean) = receiver.copy(sold = value)
    override val serializer: KSerializer<Boolean> = Boolean.serializer()
    override val annotations: List<Annotation> = Completion.serializer().tryFindAnnotations("sold")
}
object Completion_price: SerializableProperty<Completion, PriceInDollars> {
    override val name: String = "price"
    override fun get(receiver: Completion): PriceInDollars = receiver.price
    override fun setCopy(receiver: Completion, value: PriceInDollars) = receiver.copy(price = value)
    override val serializer: KSerializer<PriceInDollars> = PriceInDollars.serializer()
    override val annotations: List<Annotation> = Completion.serializer().tryFindAnnotations("price")
}
object Completion_bidPrice: SerializableProperty<Completion, PriceInDollars> {
    override val name: String = "bidPrice"
    override fun get(receiver: Completion): PriceInDollars = receiver.bidPrice
    override fun setCopy(receiver: Completion, value: PriceInDollars) = receiver.copy(bidPrice = value)
    override val serializer: KSerializer<PriceInDollars> = PriceInDollars.serializer()
    override val annotations: List<Annotation> = Completion.serializer().tryFindAnnotations("bidPrice")
}
object Completion_winner: SerializableProperty<Completion, UUID?> {
    override val name: String = "winner"
    override fun get(receiver: Completion): UUID? = receiver.winner
    override fun setCopy(receiver: Completion, value: UUID?) = receiver.copy(winner = value)
    override val serializer: KSerializer<UUID?> = ContextualSerializer(UUID::class).nullable2
    override val annotations: List<Annotation> = Completion.serializer().tryFindAnnotations("winner")
    override val default: UUID? = null
}
object Completion_expirationWarningSent: SerializableProperty<Completion, Boolean> {
    override val name: String = "expirationWarningSent"
    override fun get(receiver: Completion): Boolean = receiver.expirationWarningSent
    override fun setCopy(receiver: Completion, value: Boolean) = receiver.copy(expirationWarningSent = value)
    override val serializer: KSerializer<Boolean> = Boolean.serializer()
    override val annotations: List<Annotation> = Completion.serializer().tryFindAnnotations("expirationWarningSent")
    override val default: Boolean = false
}
