package com.ilussobsa.views.dialogs

import com.ilussobsa.views.LogInScreen

import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.ilussobsa.Strings
import com.ilussobsa.sdk.masqueradeUserId
import com.ilussobsa.views.SettingsScreen
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.l2.icon

fun  ViewWriter.userModal(close: ()->Unit) = sizeConstraints(minWidth = 20.rem) - col {
    button {
        ::exists { masqueradeUserId() != null }
        row {
            centered - icon(Icon.close, "")
            centered - text(Strings.endMasquerade)
        }
        onClick {
            masqueradeUserId set null
        }
    }
    row {
        expanding - link {
            row {
                centered - icon(Icon.settings, "")
                centered - text(Strings.settings)
            }
            to = { SettingsScreen() }
            onNavigate { close() }
        }
        expanding - link {
            row {
                centered - icon(Icon.logout, "")
                centered - text(Strings.logOut)
            }
            to = { LogInScreen() }
            onNavigate { close() }
        }
    }
}