package com.ilussobsa.views

import com.ilussobsa.*
import com.ilussobsa.sdk.*
import com.ilussobsa.utils.*
import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.ilussobsa.Strings
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.navigation.*
import com.lightningkite.kiteui.views.l2.field
import com.lightningkite.kiteui.views.l2.lazyExpanding
import com.lightningkite.serialization.lensPath
import com.lightningkite.serialization.notNull
import kotlinx.coroutines.launch
import kotlinx.serialization.encodeToString

@Routable("/users")
class UsersScreen() : Screen, AdminOnly {

    @QueryParameter("sort")
    val sort = Property<List<SortPart<User>>>(sort { it.name.ascending() })

    @QueryParameter("query")
    val search = Property("")

    @QueryParameter("selectedUser")
    val selectedUser = Property<UUID?>(null)

    private val showOnlyInactive = Property(false)

    override val title: Readable<String> = shared {
        listOfNotNull(
            Strings.users,
        ).joinToString(" ")
    }
    val query = shared {
        Query(
            condition<User> {
                Condition.And(
                    listOfNotNull(
                        search.debounce(500L)().takeUnless { it.isBlank() }
                            ?.let { s ->
                                Condition.And(s.split(' ').filter { it.isNotBlank() }.map { s ->
                                    Condition.Or(
                                        listOf(
                                            it.name.contains(s, true),
                                            it.email.contains(s, true),
                                            it.phoneNumber.notNull.contains(s, true),
                                        )
                                    )
                                })
                            },
                    )
                )
            },
            orderBy = sort(),
            limit = 500,
        )
    }

    override fun ViewWriter.render() {
        col {
            tabTheme - scrollsHorizontally - row {
                select {
                    bind(
                        sort, Constant(
                            listOf(
                                sort<User> { it.name.ascending() },
                                sort<User> { it.lastPresent.descending() },
                            )
                        )
                    ) {
                        when (it) {
                            sort<User> { it.name.ascending() } -> "Name A-Z"
                            sort<User> { it.lastPresent.descending() } -> "Last Present"
                            else -> "Unknown Sort"
                        }
                    }
                }
            }


            val toListen = shared {
                currentSession.awaitNotNull().users.query(query())
            }
            expanding - listDetailV2(
                selected = selectedUser,
                list = { view ->
                    col {
                        card - row {
                            centered - sizeConstraints(
                                width = 1.5.rem,
                                height = 1.5.rem
                            ) - icon(
                                Icon.search,
                                Strings.search
                            )

                            centered - expanding - textField {
                                hint = Strings.search
                                content bind search
                            }
                            centered - compact - button {
                                ::exists { search().isNotEmpty() }
                                sizeConstraints(width = 1.5.rem, height = 1.5.rem) - icon(
                                    Icon.close,
                                    Strings.clearSearch
                                )
                                onClick {
                                    search set ""
                                }
                            }
                        }
                        expanding - navList - stack {
                            val items = shared { toListen()() }
                            recyclerView {
                                children(items) {
                                    psuedoRadioToggleButton(shared { it()._id }, selectedUser, view) {
                                        row {
                                            centered - profilePicture(it)
                                            compact - col {
                                                HeaderSemantic.onNext - text {
                                                    ::content { it().name.takeUnless { it.isBlank() } ?: "<no name provided>" }
                                                }
                                                subtext { ::content { it().email } }
                                                subtext { ::content { it().phoneNumber ?: "No phone number" } }
                                            }
                                        }
                                    }
                                }
                            }
                            centered - text {
                                content = Strings.noUsersMatchedTheseConditions
                                exists = false
                                ::exists { items().isEmpty() }
                            }
                        }
                    }
                },
                detail = {
                    it?.let { UserScreen(it) } ?: object : Screen {
                        override fun ViewWriter.render() {
                            nothingSelected(Strings.pleaseSelectAUserOnTheLeft)
                        }
                    }
                }
            )
        }
    }
}

@Routable("/users/{selectedUser}")
class UserScreen(val selectedUser: UUID) : Screen {
    val user = LateInitProperty<User>()
    override fun ViewWriter.render() {
        val selected = shared { currentSession.awaitNotNull().users.get(selectedUser)() }
        scrolls - stack {
            card - scrolls - col {
                launch {
                    user.value = selected() ?: User(email = "")
                }
                centered - row {
                    centered - button {
                        profilePicture(user)
                        onClick {
                            ExternalServices.requestFile(listOf("image/png", "image/jpeg")) { image ->
                                if (image == null) return@requestFile
                                launch {
                                    val session = currentSessionNullable.await() ?: return@launch
                                    try {
                                        val futureCallToken =
                                            session.uploadFileForRequest().upload(RequestBodyFile(image))
                                        tempImagePointers[futureCallToken] = ImageLocal(image)
                                        user.modify { it.copy(profilePicture = futureCallToken) }
                                    } catch (e: Exception) {
                                        Exception("Error updating profile picture", e).report()
                                        alert(Strings.error, Strings.failedToUploadPicture)
                                    }
                                }
                            }
                        }
                    }
                    centered - button {
                        ::exists {
                            currentUser() != selected() && selected()?.let { currentUser()?.mayMasqueradeAs(it) } == true
                        }
                        text(Strings.masquerade)
                        onClick {
                            masqueradeUserId set selected()?._id?.toString()
                            screenNavigator.reset(DashboardScreen())
                        }
                    }
                }
                field(Strings.name1) {
                    textInput {
                        keyboardHints = KeyboardHints.title
                        hint = "Name"
                        content bind user.lensPath { it.name }
                    }
                }
                field(Strings.email1) {
                    textInput {
                        keyboardHints = KeyboardHints.email
                        hint = "Email"
                        content bind user.lensPath { it.email }
                    }
                }
                field(Strings.phoneNumber) {
                    textInput {
                        keyboardHints = KeyboardHints.phone
                        hint = "Phone Number"
                        content bind user.lensPath { it.phoneNumber }.nullToBlank()
                    }
                }
                field(Strings.accountStatus) {
                    val allowedRoles = listOf(UserRole.Disabled, UserRole.UnverifiedCustomer, UserRole.Customer, UserRole.Manager)
                    select {
                        ::exists { user().role in allowedRoles }
                        bind(
                            edits = user.lensPath { it.role },
                            data = Constant(allowedRoles),
                            render = { Strings.userRoleNames(it) }
                        )
                    }
                    text {
                        ::exists { user().role !in allowedRoles }
                        ::content { Strings.userRoleNames(user().role) }
                    }
                }
                field(Strings.financingSource) {
                    select {
                        bind(
                            edits = user.lensPath { it.financingSource },
                            data = Constant(listOf(null, *FinancingSource.entries.toTypedArray())),
                            render = { Strings.financingSourceName(it) }
                        )
                    }
                }
                lazyExpanding(user.lensPath { it.financingSource }.equalTo(FinancingSource.OwnFinancing)) {
                    val prop = Property(ExternalFinancingForm())
                    reactiveScope {
                        selected()?.currentExternalFinancingForm?.let { currentFinancingFormId ->
                            val existingForm = currentSession().externalFinancingForms[currentFinancingFormId].once()
                                ?: return@reactiveScope
                            launch {
                                prop set existingForm
                            }
                        }
                    }
                    val save = Action(Strings.submit, Icon.done) {
                        val form = prop().copy(user = currentUser()?._id ?: nullUuid)
                        currentSession().externalFinancingForms.upsert(form)
                    }
                    card - col {
                        h3("External Financing Form")
                        externalFinancingForm(prop)
                        important - gravity(Align.Start, Align.Center) - button {
                            text(Strings.save)
                            action = save
                        }
                    }
                }
                centered - important - button {
                    centered - text {
                        val changes = shared {
                            user() != selected()
                        }
                        ::content {
                            if (changes()) Strings.save else Strings.saved
                        }
                        ::enabled { changes() }
                    }
                    onClick {
                        currentSession.awaitNotNull().users.get(selectedUser).set(user())
                    }
                }
            }
        }
    }
}
