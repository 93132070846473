package com.ilussobsa.views

import com.ilussobsa.*
import com.ilussobsa.Strings
import com.ilussobsa.sdk.currentSessionNullable
import com.ilussobsa.sdk.currentSession
import com.ilussobsa.utils.navList
import com.ilussobsa.utils.publish
import com.lightningkite.UUID
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.QueryParameter
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.launchGlobal
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.KiteUiScreen
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningdb.condition
import com.lightningkite.lightningserver.files.*
import com.lightningkite.lightningserver.websocket.*
import com.lightningkite.serialization.*
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.serialization.Serializable
import com.ilussobsa.sdk.currentSession
import com.ilussobsa.utils.publish
import com.lightningkite.kiteui.delay
import com.lightningkite.kiteui.launchGlobal

@Routable("/selling-center")
class SellingCenterScreen() : KiteUiScreen {
    @Serializable
    enum class SellingTab(
        val text: String,
        val priority: Int,
        val filter: ReactiveContext.() -> Condition<Vehicle>,
        val notifyCount: Boolean = false
    ) {
        All(text = Strings.all, priority = 10, filter = { condition { it.rerunStarted.eq(null) and it.archived.eq(null) } }),
        Draft(text = Strings.draft, priority = 5, filter = {
            condition {
                it.submitted.eq(null) and it.completion.eq(null) and it.archived.eq(null) and it.rerunStarted.eq(null)
            }
        }),
        Inventory(text = Strings.inventory, priority = 5, filter = {
            condition {
                it.completion.eq(null) and it.submitted.neq(null) and it.archived.eq(null) and it.rerunStarted.eq(null)
            }
        }),
        NoSale(text = Strings.noSale, priority = 5, filter = {
            condition {
                it.cancelled.neq(null) and it.rerunStarted.eq(null) and it.archived.eq(null)
            }
        }),
        Sold(text = Strings.sold, priority = 5, filter = {
            condition {
                it.completion.notNull.sold.eq(true) and it.paid.eq(null) and it.archived.eq(
                    null
                ) and it.rerunStarted.eq(null) and it.cancelled.eq(null)
            }
        }, notifyCount = true),
        Archived(text = Strings.archived, priority = 5, filter = {
            condition {
                it.archived.neq(null) and it.rerunStarted.eq(null)
            }
        }),
    }

    @QueryParameter("tab")
    val tab = Property<SellingTab>(SellingTab.All)

    @QueryParameter("selectedLot")
    val selectedLot = Property<UUID?>(null)

    @QueryParameter("query")
    val search = Property<String>("")

    @QueryParameter("sort")
    val sort = Property<List<SortPart<Vehicle>>>(sort { it.submitted.notNull.descending() })

    val query = shared {
        Query(
            condition<Vehicle> {
                Condition.And(
                    listOfNotNull(
                        tab().filter(),
                        search.debounce(500L)().takeUnless { it.isBlank() }
                            ?.let { q ->
                                q.split(' ').filter { it.isNotBlank() }.map { p ->
                                    Condition.Or(
                                        listOf(
                                            it.make.notNull.contains(p, true),
                                            it.yearString.notNull.contains(p, true),
                                            it.model.notNull.contains(p, true),
                                            it.trim.notNull.contains(p, true),
                                        )
                                    )
                                }.let { Condition.And(it) }
                            },
                    )
                )
            },
            orderBy = sort(),
            limit = 500,
        )
    }

    override fun ViewWriter.render() {
        col {
            val toListen = shared {
                currentSessionNullable.awaitNotNull().vehicles.watch(query())
            }

            reactiveScope {
                tab()
                launch {
                    delay(1)
                    toListen()().let {
                        if(it.none { it._id == selectedLot.value }) {
                            selectedLot.set(it.firstOrNull()?._id)
                        }
                    }
                }
            }

            fun onCurrentNotInTab(action: suspend () -> Unit) {
                reactiveSuspending {
                    val results = toListen()()
                    delay(2)
                    selectedLot.value?.let {
                        if (results.none { r -> r._id == it }) {
                            println("$it is not in the results ${results.joinToString { it._id.toString() }}")
                            launch { action() }
                        }
                    } ?: run {
                        println("null is not in the results ${results.joinToString { it._id.toString() }}")
                        launch { action() }
                    }
                }
            }
            var lastIndex = 0
            reactiveScope {
                val sel = selectedLot()
                val i = toListen()().indexOfFirst { it._id == sel }
                if (i != -1) {
                    lastIndex = i
                }
            }
            when(tabBehavior.value) {
                TabBehavior.NextInTab -> onCurrentNotInTab {
                    selectedLot.set(toListen()().let { it.getOrNull(lastIndex) ?: it.firstOrNull() }?._id)
                }
                TabBehavior.FollowVehicle -> onCurrentNotInTab {
                    val full = currentSession().vehicles.get(selectedLot.value ?: nullUuid).invoke()
                    if (full == null) selectedLot.set(null)
                    else tab.value = SellingTab.values().sortedBy { it.priority } .find { it.filter()(full) } ?: SellingTab.All
                }
                TabBehavior.Clear -> onCurrentNotInTab { selectedLot.set(null) }
            }

            scrollsHorizontally - compact - card - row {
                for (tab in SellingTab.values()) {
                    centered - radioToggleButton {
                        checked bind this@SellingCenterScreen.tab.equalTo(tab)
                        row {
                            col {
                                text(tab.text)
                            }
                            if (tab.notifyCount) {
                                critical - stack {
                                    spacing = 0.15.rem
                                    val ifBidNum = shared {
                                        currentSession().vehicles.query(Query(tab.filter()))().size
                                    }
                                    ::exists{ ifBidNum.await() > 0 }
                                    subtext {
                                        ::content{
                                            ifBidNum.await().toString()
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                expanding - space()
                important - link {
                    to = { TakeNewVehicleImagesScreen() }
                    row {
                        centered - icon(Icon.publish, "")
                        centered - text(Strings.uploadNew)
                    }
                }
            }

            expanding - listDetailV2(
                selected = selectedLot,
                list = { view ->
                    col {
                        card - row {
                            gravity(Align.Center, Align.Center) - sizeConstraints(
                                width = 1.5.rem,
                                height = 1.5.rem
                            ) - icon(
                                Icon.search,
                                Strings.search
                            )

                            gravity(Align.Start, Align.Center) - sizeConstraints(width = 20.rem) - textField {
                                hint = Strings.search
                                content bind search
                            }
                            gravity(Align.Start, Align.Center) - compact - button {
                                ::exists { search().isNotEmpty() }
                                sizeConstraints(width = 1.5.rem, height = 1.5.rem) - icon(
                                    Icon.close,
                                    Strings.clearSearch
                                )
                                onClick {
                                    search set ""
                                }
                            }
                        }
                        expanding - navList - stack {
                            val items = shared {
                                toListen().also {
                                    println("Meta received. ${it.state.map { "OK" }} $it")
                                    launchGlobal {
                                        delay(5)
                                        println("Meta received delay. ${it.state.map { "OK" }} $it")
                                    }
                                }().also { println("List received ${it.size}") }
                            }
                            recyclerView {
                                children(items) { it ->
                                    compact - psuedoRadioToggleButton(shared { it()._id }, selectedLot, view) {
                                        sellingLotShortDetails(it)
                                    }
                                }
                            }
                            centered - text {
                                exists = false
                                content = Strings.noVehiclesMatchedTheseConditions
                                ::exists { items().isEmpty() }
                            }
                        }
                    }
                },
                detail = {
                    if(it == null) object: Screen {
                        override fun ViewWriter.render() {
                            nothingSelected(Strings.pleaseSelectAVehicleOnTheLeft)
                        }
                    } else VehicleDetailScreen(it).apply { gotoOverride = {
//                        tab.value = SellingTab.Inventory
//                        selectedLot.value = it
                    } }
                }
            )
        }
    }
}