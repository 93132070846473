// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareFinancingApplicationFields() {
    val props: Array<SerializableProperty<FinancingApplication, *>> = arrayOf(FinancingApplication__id, FinancingApplication_user, FinancingApplication_at, FinancingApplication_primary, FinancingApplication_secondary, FinancingApplication_additionalInformation)
    FinancingApplication.serializer().properties { props }
}
val <K> DataClassPath<K, FinancingApplication>._id: DataClassPath<K, UUID> get() = this[FinancingApplication__id]
val <K> DataClassPath<K, FinancingApplication>.user: DataClassPath<K, UUID> get() = this[FinancingApplication_user]
val <K> DataClassPath<K, FinancingApplication>.at: DataClassPath<K, Instant> get() = this[FinancingApplication_at]
val <K> DataClassPath<K, FinancingApplication>.primary: DataClassPath<K, FinancingApplicant> get() = this[FinancingApplication_primary]
val <K> DataClassPath<K, FinancingApplication>.secondary: DataClassPath<K, FinancingApplicant?> get() = this[FinancingApplication_secondary]
val <K> DataClassPath<K, FinancingApplication>.additionalInformation: DataClassPath<K, String?> get() = this[FinancingApplication_additionalInformation]
inline val FinancingApplication.Companion.path: DataClassPath<FinancingApplication, FinancingApplication> get() = path<FinancingApplication>()


object FinancingApplication__id: SerializableProperty<FinancingApplication, UUID> {
    override val name: String = "_id"
    override fun get(receiver: FinancingApplication): UUID = receiver._id
    override fun setCopy(receiver: FinancingApplication, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = FinancingApplication.serializer().tryFindAnnotations("_id")
    override val default: UUID = UUID.random()
}
object FinancingApplication_user: SerializableProperty<FinancingApplication, UUID> {
    override val name: String = "user"
    override fun get(receiver: FinancingApplication): UUID = receiver.user
    override fun setCopy(receiver: FinancingApplication, value: UUID) = receiver.copy(user = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = FinancingApplication.serializer().tryFindAnnotations("user")
}
object FinancingApplication_at: SerializableProperty<FinancingApplication, Instant> {
    override val name: String = "at"
    override fun get(receiver: FinancingApplication): Instant = receiver.at
    override fun setCopy(receiver: FinancingApplication, value: Instant) = receiver.copy(at = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = FinancingApplication.serializer().tryFindAnnotations("at")
    override val default: Instant = now()
}
object FinancingApplication_primary: SerializableProperty<FinancingApplication, FinancingApplicant> {
    override val name: String = "primary"
    override fun get(receiver: FinancingApplication): FinancingApplicant = receiver.primary
    override fun setCopy(receiver: FinancingApplication, value: FinancingApplicant) = receiver.copy(primary = value)
    override val serializer: KSerializer<FinancingApplicant> = FinancingApplicant.serializer()
    override val annotations: List<Annotation> = FinancingApplication.serializer().tryFindAnnotations("primary")
}
object FinancingApplication_secondary: SerializableProperty<FinancingApplication, FinancingApplicant?> {
    override val name: String = "secondary"
    override fun get(receiver: FinancingApplication): FinancingApplicant? = receiver.secondary
    override fun setCopy(receiver: FinancingApplication, value: FinancingApplicant?) = receiver.copy(secondary = value)
    override val serializer: KSerializer<FinancingApplicant?> = FinancingApplicant.serializer().nullable2
    override val annotations: List<Annotation> = FinancingApplication.serializer().tryFindAnnotations("secondary")
    override val default: FinancingApplicant? = null
}
object FinancingApplication_additionalInformation: SerializableProperty<FinancingApplication, String?> {
    override val name: String = "additionalInformation"
    override fun get(receiver: FinancingApplication): String? = receiver.additionalInformation
    override fun setCopy(receiver: FinancingApplication, value: String?) = receiver.copy(additionalInformation = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = FinancingApplication.serializer().tryFindAnnotations("additionalInformation")
    override val default: String? = null
}
