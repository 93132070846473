package com.ilussobsa.views

import com.lightningkite.kiteui.navigation.*
import com.ilussobsa.views.AnimationTestingScreen
import com.ilussobsa.views.AuctionsScreen
import com.ilussobsa.views.AuctionConfigureDialog
import com.ilussobsa.views.BuyingCenterScreen
import com.ilussobsa.views.DashboardScreen
import com.ilussobsa.views.EditVehicleScreen
import com.ilussobsa.views.FinancingFormScreen
import com.ilussobsa.views.LiveAuctionRunlistScreen
import com.ilussobsa.views.LiveAuctionScreen
import com.ilussobsa.views.LogInScreen
import com.ilussobsa.views.NotificationsScreen
import com.ilussobsa.views.NotificationSettingsScreen
import com.ilussobsa.views.PrivacyPolicyScreen
import com.ilussobsa.views.ProposalScreen
import com.ilussobsa.views.SearchScreen
import com.ilussobsa.views.SearchFilterScreen
import com.ilussobsa.views.SellingCenterScreen
import com.ilussobsa.views.SettingsScreen
import com.ilussobsa.views.SimulationScreen
import com.ilussobsa.views.TakeNewVehicleImagesScreen
import com.ilussobsa.views.TermsOfUseScreen
import com.ilussobsa.views.TutorialScreen
import com.ilussobsa.views.TutorialsScreen
import com.ilussobsa.views.UsersScreen
import com.ilussobsa.views.UserScreen
import com.ilussobsa.views.VehicleDetailScreen
import com.lightningkite.kiteui.navigation.Screen.Empty
import com.ilussobsa.SearchParamsOnly
import com.ilussobsa.views.BuyingCenterScreen.BuyingTab
import com.ilussobsa.views.EditVehicleScreen.Tab
import com.ilussobsa.views.LogInScreen.Notice
import com.ilussobsa.views.SellingCenterScreen.SellingTab
import com.ilussobsa.views.TutorialItem
import com.lightningkite.UUID
import com.lightningkite.lightningdb.SortPart


val AutoRoutes = Routes(
    parsers = listOf(
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "animation-test") return@label null
            AnimationTestingScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "auctions") return@label null
            AuctionsScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "auction-configure") return@label null
            AuctionConfigureDialog(
                id = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "buying-center") return@label null
            BuyingCenterScreen(
            ).apply {
                UrlProperties.decodeFromStringMap<kotlin.String>("query", it.parameters)?.let { this.search.value = it }
                UrlProperties.decodeFromStringMap<com.lightningkite.UUID?>("selectedLot", it.parameters)?.let { this.selectedLot.value = it }
                UrlProperties.decodeFromStringMap<kotlin.collections.List<com.lightningkite.lightningdb.SortPart<com.ilussobsa.VehicleRelationship>>>("sort", it.parameters)?.let { this.sort.value = it }
                UrlProperties.decodeFromStringMap<com.ilussobsa.views.BuyingCenterScreen.BuyingTab>("tab", it.parameters)?.let { this.tab.value = it }
            }
        },
        label@{ 
            if (it.segments.size != 0) return@label null
            DashboardScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "edit-vehicle") return@label null
            EditVehicleScreen(
                vehicleId = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
                UrlProperties.decodeFromStringMap<kotlin.Boolean>("flowMode", it.parameters)?.let { this.flowMode.value = it }
                UrlProperties.decodeFromStringMap<com.ilussobsa.views.EditVehicleScreen.Tab>("tab", it.parameters)?.let { this.tab.value = it }
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "financing-form") return@label null
            FinancingFormScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 3) return@label null
            if (it.segments[0] != "live-auction") return@label null
            if (it.segments[2] != "runlist") return@label null
            LiveAuctionRunlistScreen(
                id = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "live-auction") return@label null
            LiveAuctionScreen(
                id = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "login") return@label null
            LogInScreen(
            ).apply {
                UrlProperties.decodeFromStringMap<kotlin.String?>("backend", it.parameters)?.let { this.backend.value = it }
                UrlProperties.decodeFromStringMap<com.ilussobsa.views.LogInScreen.Notice?>("notice", it.parameters)?.let { this.notice.value = it }
                UrlProperties.decodeFromStringMap<kotlin.String?>("proof", it.parameters)?.let { this.proof.value = it }
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "notifications") return@label null
            NotificationsScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "notifications") return@label null
            if (it.segments[1] != "settings") return@label null
            NotificationSettingsScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "privacy") return@label null
            PrivacyPolicyScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "proposal") return@label null
            ProposalScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "search") return@label null
            SearchScreen(
            ).apply {
                UrlProperties.decodeFromStringMap<kotlin.String>("query", it.parameters)?.let { this.query2.value = it }
                UrlProperties.decodeFromStringMap<com.lightningkite.UUID?>("savedSearchId", it.parameters)?.let { this.savedSearchId.value = it }
                UrlProperties.decodeFromStringMap<com.ilussobsa.SearchParamsOnly>("search", it.parameters)?.let { this.search.value = it }
                UrlProperties.decodeFromStringMap<com.lightningkite.UUID?>("selectedLot", it.parameters)?.let { this.selectedLot.value = it }
                UrlProperties.decodeFromStringMap<kotlin.collections.List<com.lightningkite.lightningdb.SortPart<com.ilussobsa.Vehicle>>>("sort", it.parameters)?.let { this.sort.value = it }
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "search-filter") return@label null
            SearchFilterScreen(
            ).apply {
                UrlProperties.decodeFromStringMap<kotlin.String>("query", it.parameters)?.let { this.query2.value = it }
                UrlProperties.decodeFromStringMap<com.lightningkite.UUID?>("savedSearchId", it.parameters)?.let { this.savedSearchId.value = it }
                UrlProperties.decodeFromStringMap<com.ilussobsa.SearchParamsOnly>("search", it.parameters)?.let { this.search.value = it }
                UrlProperties.decodeFromStringMap<com.lightningkite.UUID?>("selectedLot", it.parameters)?.let { this.selectedLot.value = it }
                UrlProperties.decodeFromStringMap<kotlin.collections.List<com.lightningkite.lightningdb.SortPart<com.ilussobsa.Vehicle>>>("sort", it.parameters)?.let { this.sort.value = it }
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "selling-center") return@label null
            SellingCenterScreen(
            ).apply {
                UrlProperties.decodeFromStringMap<kotlin.String>("query", it.parameters)?.let { this.search.value = it }
                UrlProperties.decodeFromStringMap<com.lightningkite.UUID?>("selectedLot", it.parameters)?.let { this.selectedLot.value = it }
                UrlProperties.decodeFromStringMap<kotlin.collections.List<com.lightningkite.lightningdb.SortPart<com.ilussobsa.Vehicle>>>("sort", it.parameters)?.let { this.sort.value = it }
                UrlProperties.decodeFromStringMap<com.ilussobsa.views.SellingCenterScreen.SellingTab>("tab", it.parameters)?.let { this.tab.value = it }
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "settings") return@label null
            SettingsScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "simulation") return@label null
            SimulationScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 3) return@label null
            if (it.segments[0] != "upload-vehicle") return@label null
            if (it.segments[2] != "images") return@label null
            TakeNewVehicleImagesScreen(
                vehicleId = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "terms") return@label null
            TermsOfUseScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "tutorial") return@label null
            TutorialScreen(
                id = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "tutorials") return@label null
            TutorialsScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "users") return@label null
            UsersScreen(
            ).apply {
                UrlProperties.decodeFromStringMap<kotlin.String>("query", it.parameters)?.let { this.search.value = it }
                UrlProperties.decodeFromStringMap<com.lightningkite.UUID?>("selectedUser", it.parameters)?.let { this.selectedUser.value = it }
                UrlProperties.decodeFromStringMap<kotlin.collections.List<com.lightningkite.lightningdb.SortPart<com.ilussobsa.User>>>("sort", it.parameters)?.let { this.sort.value = it }
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "users") return@label null
            UserScreen(
                selectedUser = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "vehicle") return@label null
            VehicleDetailScreen(
                id = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
    ),
    renderers = mapOf(
        AnimationTestingScreen::class to label@{
            if (it !is AnimationTestingScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("animation-test"),
                parameters = p
            ), listOf())
        },
        AuctionsScreen::class to label@{
            if (it !is AuctionsScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("auctions"),
                parameters = p
            ), listOf())
        },
        AuctionConfigureDialog::class to label@{
            if (it !is AuctionConfigureDialog) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("auction-configure", UrlProperties.encodeToString(it.id)),
                parameters = p
            ), listOf())
        },
        BuyingCenterScreen::class to label@{
            if (it !is BuyingCenterScreen) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.search.value, "query", p)
            UrlProperties.encodeToStringMap(it.selectedLot.value, "selectedLot", p)
            UrlProperties.encodeToStringMap(it.sort.value, "sort", p)
            UrlProperties.encodeToStringMap(it.tab.value, "tab", p)
            RouteRendered(UrlLikePath(
                segments = listOf("buying-center"),
                parameters = p
            ), listOf(it.search, it.selectedLot, it.sort, it.tab))
        },
        DashboardScreen::class to label@{
            if (it !is DashboardScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf(),
                parameters = p
            ), listOf())
        },
        EditVehicleScreen::class to label@{
            if (it !is EditVehicleScreen) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.flowMode.value, "flowMode", p)
            UrlProperties.encodeToStringMap(it.tab.value, "tab", p)
            RouteRendered(UrlLikePath(
                segments = listOf("edit-vehicle", UrlProperties.encodeToString(it.vehicleId)),
                parameters = p
            ), listOf(it.flowMode, it.tab))
        },
        FinancingFormScreen::class to label@{
            if (it !is FinancingFormScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("financing-form"),
                parameters = p
            ), listOf())
        },
        LiveAuctionRunlistScreen::class to label@{
            if (it !is LiveAuctionRunlistScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("live-auction", UrlProperties.encodeToString(it.id), "runlist"),
                parameters = p
            ), listOf())
        },
        LiveAuctionScreen::class to label@{
            if (it !is LiveAuctionScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("live-auction", UrlProperties.encodeToString(it.id)),
                parameters = p
            ), listOf())
        },
        LogInScreen::class to label@{
            if (it !is LogInScreen) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.backend.value, "backend", p)
            UrlProperties.encodeToStringMap(it.notice.value, "notice", p)
            UrlProperties.encodeToStringMap(it.proof.value, "proof", p)
            RouteRendered(UrlLikePath(
                segments = listOf("login"),
                parameters = p
            ), listOf(it.backend, it.notice, it.proof))
        },
        NotificationsScreen::class to label@{
            if (it !is NotificationsScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("notifications"),
                parameters = p
            ), listOf())
        },
        NotificationSettingsScreen::class to label@{
            if (it !is NotificationSettingsScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("notifications", "settings"),
                parameters = p
            ), listOf())
        },
        PrivacyPolicyScreen::class to label@{
            if (it !is PrivacyPolicyScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("privacy"),
                parameters = p
            ), listOf())
        },
        ProposalScreen::class to label@{
            if (it !is ProposalScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("proposal"),
                parameters = p
            ), listOf())
        },
        SearchScreen::class to label@{
            if (it !is SearchScreen) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.query2.value, "query", p)
            UrlProperties.encodeToStringMap(it.savedSearchId.value, "savedSearchId", p)
            UrlProperties.encodeToStringMap(it.search.value, "search", p)
            UrlProperties.encodeToStringMap(it.selectedLot.value, "selectedLot", p)
            UrlProperties.encodeToStringMap(it.sort.value, "sort", p)
            RouteRendered(UrlLikePath(
                segments = listOf("search"),
                parameters = p
            ), listOf(it.query2, it.savedSearchId, it.search, it.selectedLot, it.sort))
        },
        SearchFilterScreen::class to label@{
            if (it !is SearchFilterScreen) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.query2.value, "query", p)
            UrlProperties.encodeToStringMap(it.savedSearchId.value, "savedSearchId", p)
            UrlProperties.encodeToStringMap(it.search.value, "search", p)
            UrlProperties.encodeToStringMap(it.selectedLot.value, "selectedLot", p)
            UrlProperties.encodeToStringMap(it.sort.value, "sort", p)
            RouteRendered(UrlLikePath(
                segments = listOf("search-filter"),
                parameters = p
            ), listOf(it.query2, it.savedSearchId, it.search, it.selectedLot, it.sort))
        },
        SellingCenterScreen::class to label@{
            if (it !is SellingCenterScreen) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.search.value, "query", p)
            UrlProperties.encodeToStringMap(it.selectedLot.value, "selectedLot", p)
            UrlProperties.encodeToStringMap(it.sort.value, "sort", p)
            UrlProperties.encodeToStringMap(it.tab.value, "tab", p)
            RouteRendered(UrlLikePath(
                segments = listOf("selling-center"),
                parameters = p
            ), listOf(it.search, it.selectedLot, it.sort, it.tab))
        },
        SettingsScreen::class to label@{
            if (it !is SettingsScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("settings"),
                parameters = p
            ), listOf())
        },
        SimulationScreen::class to label@{
            if (it !is SimulationScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("simulation"),
                parameters = p
            ), listOf())
        },
        TakeNewVehicleImagesScreen::class to label@{
            if (it !is TakeNewVehicleImagesScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("upload-vehicle", UrlProperties.encodeToString(it.vehicleId), "images"),
                parameters = p
            ), listOf())
        },
        TermsOfUseScreen::class to label@{
            if (it !is TermsOfUseScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("terms"),
                parameters = p
            ), listOf())
        },
        TutorialScreen::class to label@{
            if (it !is TutorialScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("tutorial", UrlProperties.encodeToString(it.id)),
                parameters = p
            ), listOf())
        },
        TutorialsScreen::class to label@{
            if (it !is TutorialsScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("tutorials"),
                parameters = p
            ), listOf())
        },
        UsersScreen::class to label@{
            if (it !is UsersScreen) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.search.value, "query", p)
            UrlProperties.encodeToStringMap(it.selectedUser.value, "selectedUser", p)
            UrlProperties.encodeToStringMap(it.sort.value, "sort", p)
            RouteRendered(UrlLikePath(
                segments = listOf("users"),
                parameters = p
            ), listOf(it.search, it.selectedUser, it.sort))
        },
        UserScreen::class to label@{
            if (it !is UserScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("users", UrlProperties.encodeToString(it.selectedUser)),
                parameters = p
            ), listOf())
        },
        VehicleDetailScreen::class to label@{
            if (it !is VehicleDetailScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("vehicle", UrlProperties.encodeToString(it.id)),
                parameters = p
            ), listOf())
        },
    ),
    fallback = Empty
)
