package com.ilussobsa.views.dialogs

import com.ilussobsa.Strings
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.SizeConstraints
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.KiteUiScreen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.card
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.expanding

class GenericConfirmationDialog(val message: String, val confirmMessage: String, val onConfirm: (suspend (Boolean) -> Unit)) :
    KiteUiScreen {
    override fun ViewWriter.render() {
        dismissBackground {
            centered - sizedBox(SizeConstraints(maxWidth = 20.rem, minWidth = 10.rem)) - card - col {
                text {
                    align = Align.Center
                    content = message
                }
                row {
                    card - button {
                        text {
                            align = Align.Center
                            content = Strings.cancel
                        }
                        onClick {
                            onConfirm.invoke(false)
                            dialogScreenNavigator.dismiss()
                        }
                    }
                    expanding - space()
                    card - button {
                        text {
                            align = Align.Center
                            content = confirmMessage
                        }
                        onClick {
                            onConfirm.invoke(true)
                            dialogScreenNavigator.dismiss()
                        }
                    }
                }
            }
        }
    }
}