
package com.ilussobsa.sdk

import com.lightningkite.*
import com.lightningkite.lightningdb.*
import com.lightningkite.kiteui.*
import kotlinx.datetime.*
import com.lightningkite.serialization.*
import com.lightningkite.lightningserver.db.*
import com.lightningkite.lightningserver.auth.*
import kotlin.String
import com.lightningkite.lightningserver.files.UploadInformation
import com.lightningkite.lightningserver.serverhealth.ServerHealth
import com.lightningkite.lightningserver.typed.BulkRequest
import com.lightningkite.lightningserver.typed.BulkResponse
import com.ilussobsa.PaymentMethod
import kotlin.Unit
import com.ilussobsa.FinancingApplication
import com.lightningkite.lightningdb.EntryChange
import com.ilussobsa.User
import kotlin.Boolean
import com.lightningkite.lightningdb.Query
import com.lightningkite.lightningdb.QueryPartial
import com.lightningkite.serialization.Partial
import com.lightningkite.UUID
import com.lightningkite.lightningdb.MassModification
import kotlin.Int
import com.lightningkite.lightningdb.Modification
import com.lightningkite.lightningdb.Condition
import com.lightningkite.lightningdb.GroupCountQuery
import com.lightningkite.lightningdb.AggregateQuery
import kotlin.Double
import com.lightningkite.lightningdb.GroupAggregateQuery
import com.lightningkite.lightningdb.ListChange
import com.lightningkite.lightningserver.auth.proof.FinishProof
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.auth.proof.OtpSecret
import com.lightningkite.lightningserver.auth.proof.EstablishOtp
import com.lightningkite.lightningserver.auth.proof.IdentificationAndPassword
import com.lightningkite.lightningserver.auth.proof.PasswordSecret
import com.lightningkite.lightningserver.auth.proof.EstablishPassword
import com.lightningkite.lightningserver.auth.subject.IdAndAuthMethods
import com.lightningkite.lightningserver.auth.subject.LogInRequest
import com.lightningkite.lightningserver.auth.subject.ProofsCheckResult
import com.lightningkite.lightningserver.auth.subject.SubSessionRequest
import com.lightningkite.lightningserver.auth.oauth.OauthTokenRequest
import com.lightningkite.lightningserver.auth.oauth.OauthResponse
import com.lightningkite.lightningserver.auth.subject.Session
import com.ilussobsa.FcmToken
import com.ilussobsa.VehicleRelationship
import com.ilussobsa.UserVehiclePair
import com.ilussobsa.Vehicle
import com.lightningkite.lightningdb.CollectionUpdates
import com.ilussobsa.AuctionLane
import com.ilussobsa.Bid
import com.ilussobsa.LiveAuctionData
import com.ilussobsa.SellerTalkingPoint
import com.ilussobsa.SavedSearch
import com.ilussobsa.Notification
import com.ilussobsa.ResetDataRequest
import com.ilussobsa.Make
import com.ilussobsa.Model
import com.ilussobsa.EarnestPayment
import com.ilussobsa.ExternalFinancingForm

class LiveApi(val httpUrl: String, val socketUrl: String): Api {
    override val user: Api.UserApi = LiveUserApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val emailProof: Api.EmailProofApi = LiveEmailProofApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val otpSecret: Api.OtpSecretApi = LiveOtpSecretApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val oneTimePasswordProof: Api.OneTimePasswordProofApi = LiveOneTimePasswordProofApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val passwordSecret: Api.PasswordSecretApi = LivePasswordSecretApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val passwordProof: Api.PasswordProofApi = LivePasswordProofApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val userAuth: Api.UserAuthApi = LiveUserAuthApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val userSession: Api.UserSessionApi = LiveUserSessionApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val fcmToken: Api.FcmTokenApi = LiveFcmTokenApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val vehicleRelationship: Api.VehicleRelationshipApi = LiveVehicleRelationshipApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val vehicle: Api.VehicleApi = LiveVehicleApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val auctionLane: Api.AuctionLaneApi = LiveAuctionLaneApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val bid: Api.BidApi = LiveBidApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val liveAuctionData: Api.LiveAuctionDataApi = LiveLiveAuctionDataApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val sellerTalkingPoint: Api.SellerTalkingPointApi = LiveSellerTalkingPointApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val savedSearch: Api.SavedSearchApi = LiveSavedSearchApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val notification: Api.NotificationApi = LiveNotificationApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val faker: Api.FakerApi = LiveFakerApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val make: Api.MakeApi = LiveMakeApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val model: Api.ModelApi = LiveModelApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val earnestPayment: Api.EarnestPaymentApi = LiveEarnestPaymentApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val externalFinancingForm: Api.ExternalFinancingFormApi = LiveExternalFinancingFormApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override suspend fun startStripe(userAccessToken: suspend () -> String, masquerade: String?): String = fetch(
        url = "$httpUrl/users/create-checkout-session",
        method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
    )
    override suspend fun logInViaApple(): String = fetch(
        url = "$httpUrl/proof/oauth/apple/login",
        method = HttpMethod.GET,
    )
    override suspend fun logInViaGoogle(): String = fetch(
        url = "$httpUrl/proof/oauth/google/login",
        method = HttpMethod.GET,
    )
    override suspend fun logInViaMicrosoft(): String = fetch(
        url = "$httpUrl/proof/oauth/microsoft/login",
        method = HttpMethod.GET,
    )
    override suspend fun uploadFileForRequest(): UploadInformation = fetch(
        url = "$httpUrl/upload-early",
        method = HttpMethod.GET,
    )
    override suspend fun getServerHealth(userAccessToken: suspend () -> String, masquerade: String?): ServerHealth = fetch(
        url = "$httpUrl/meta/health",
        method = HttpMethod.GET,
            token = userAccessToken,
            masquerade = masquerade,
    )
    override suspend fun bulkRequest(input: Map<String, BulkRequest>): Map<String, BulkResponse> = fetch(
        url = "$httpUrl/meta/bulk",
        method = HttpMethod.POST,
        body = input
    )
    override suspend fun getPaymentSetupToken(userAccessToken: suspend () -> String, masquerade: String?): String = fetch(
        url = "$httpUrl/payment/payment-setup",
        method = HttpMethod.GET,
            token = userAccessToken,
            masquerade = masquerade,
    )
    override suspend fun getPaymentPublishableKey(userAccessToken: suspend () -> String, masquerade: String?): String = fetch(
        url = "$httpUrl/payment/payment-publishable-key",
        method = HttpMethod.GET,
            token = userAccessToken,
            masquerade = masquerade,
    )
    override suspend fun getPaymentMethods(userAccessToken: suspend () -> String, masquerade: String?): List<PaymentMethod> = fetch(
        url = "$httpUrl/payment",
        method = HttpMethod.GET,
            token = userAccessToken,
            masquerade = masquerade,
    )
    override suspend fun getPaymentMethod(methodId: String, userAccessToken: suspend () -> String, masquerade: String?): PaymentMethod = fetch(
        url = "$httpUrl/payment/${methodId.urlify()}",
        method = HttpMethod.GET,
            token = userAccessToken,
            masquerade = masquerade,
    )
    override suspend fun updatePaymentMethodName(methodId: String, input: String, userAccessToken: suspend () -> String, masquerade: String?): PaymentMethod = fetch(
        url = "$httpUrl/payment/${methodId.urlify()}",
        method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
        body = input
    )
    override suspend fun deletePaymentMethod(methodId: String, userAccessToken: suspend () -> String, masquerade: String?): Unit = fetch(
        url = "$httpUrl/payment/${methodId.urlify()}",
        method = HttpMethod.DELETE,
            token = userAccessToken,
            masquerade = masquerade,
    )
    override suspend fun submitFinancingApplication(input: FinancingApplication, userAccessToken: suspend () -> String, masquerade: String?): EntryChange<User> = fetch(
        url = "$httpUrl/financing-applications",
        method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
        body = input
    )
    override suspend fun checkIfUserExistsByEmail(input: String): Boolean = fetch(
        url = "$httpUrl/find-by-email",
        method = HttpMethod.POST,
        body = input
    )
    class LiveUserApi(val httpUrl: String, val socketUrl: String): Api.UserApi {
        override suspend fun default(userAccessToken: suspend () -> String, masquerade: String?): User = fetch(
            url = "$httpUrl/users/rest/_default_",
            method = HttpMethod.GET,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<User>, userAccessToken: suspend () -> String, masquerade: String?): List<User> = fetch(
            url = "$httpUrl/users/rest/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<User>, userAccessToken: suspend () -> String, masquerade: String?): List<Partial<User>> = fetch(
            url = "$httpUrl/users/rest/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, userAccessToken: suspend () -> String, masquerade: String?): User = fetch(
            url = "$httpUrl/users/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<User>, userAccessToken: suspend () -> String, masquerade: String?): List<User> = fetch(
            url = "$httpUrl/users/rest/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: User, userAccessToken: suspend () -> String, masquerade: String?): User = fetch(
            url = "$httpUrl/users/rest",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: User, userAccessToken: suspend () -> String, masquerade: String?): User = fetch(
            url = "$httpUrl/users/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<User>, userAccessToken: suspend () -> String, masquerade: String?): List<User> = fetch(
            url = "$httpUrl/users/rest",
            method = HttpMethod.PUT,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: User, userAccessToken: suspend () -> String, masquerade: String?): User = fetch(
            url = "$httpUrl/users/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<User>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/users/rest/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<User>, userAccessToken: suspend () -> String, masquerade: String?): EntryChange<User> = fetch(
            url = "$httpUrl/users/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<User>, userAccessToken: suspend () -> String, masquerade: String?): User = fetch(
            url = "$httpUrl/users/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<User>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/users/rest/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, userAccessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/users/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<User>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/users/rest/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<User>, userAccessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/users/rest/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<User>, userAccessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/users/rest/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<User>, userAccessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/users/rest/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override fun watch(userToken: String): TypedWebSocket<Query<User>, ListChange<User>> = multiplexedSocket(
            socketUrl = socketUrl, 
            path = "/users/rest", 
            token = userToken,
        )
    }
    class LiveEmailProofApi(val httpUrl: String, val socketUrl: String): Api.EmailProofApi {
        override suspend fun beginEmailOwnershipProof(input: String): String = fetch(
            url = "$httpUrl/proof/email/start",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun proveEmailOwnership(input: FinishProof): Proof = fetch(
            url = "$httpUrl/proof/email/prove",
            method = HttpMethod.POST,
            body = input
        )
    }
    class LiveOtpSecretApi(val httpUrl: String, val socketUrl: String): Api.OtpSecretApi {
        override suspend fun query(input: Query<OtpSecret>, anyAccessToken: suspend () -> String, masquerade: String?): List<OtpSecret> = fetch(
            url = "$httpUrl/proof/otp/secrets/query",
            method = HttpMethod.POST,
            token = anyAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<OtpSecret>, anyAccessToken: suspend () -> String, masquerade: String?): List<Partial<OtpSecret>> = fetch(
            url = "$httpUrl/proof/otp/secrets/query-partial",
            method = HttpMethod.POST,
            token = anyAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, anyAccessToken: suspend () -> String, masquerade: String?): OtpSecret = fetch(
            url = "$httpUrl/proof/otp/secrets/${id.urlify()}",
            method = HttpMethod.GET,
            token = anyAccessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<OtpSecret>, anyAccessToken: suspend () -> String, masquerade: String?): List<OtpSecret> = fetch(
            url = "$httpUrl/proof/otp/secrets/bulk",
            method = HttpMethod.POST,
            token = anyAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: OtpSecret, anyAccessToken: suspend () -> String, masquerade: String?): OtpSecret = fetch(
            url = "$httpUrl/proof/otp/secrets",
            method = HttpMethod.POST,
            token = anyAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: OtpSecret, anyAccessToken: suspend () -> String, masquerade: String?): OtpSecret = fetch(
            url = "$httpUrl/proof/otp/secrets/${id.urlify()}",
            method = HttpMethod.POST,
            token = anyAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<OtpSecret>, anyAccessToken: suspend () -> String, masquerade: String?): List<OtpSecret> = fetch(
            url = "$httpUrl/proof/otp/secrets",
            method = HttpMethod.PUT,
            token = anyAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: OtpSecret, anyAccessToken: suspend () -> String, masquerade: String?): OtpSecret = fetch(
            url = "$httpUrl/proof/otp/secrets/${id.urlify()}",
            method = HttpMethod.PUT,
            token = anyAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<OtpSecret>, anyAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/proof/otp/secrets/bulk",
            method = HttpMethod.PATCH,
            token = anyAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<OtpSecret>, anyAccessToken: suspend () -> String, masquerade: String?): EntryChange<OtpSecret> = fetch(
            url = "$httpUrl/proof/otp/secrets/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = anyAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<OtpSecret>, anyAccessToken: suspend () -> String, masquerade: String?): OtpSecret = fetch(
            url = "$httpUrl/proof/otp/secrets/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = anyAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<OtpSecret>, anyAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/proof/otp/secrets/bulk-delete",
            method = HttpMethod.POST,
            token = anyAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, anyAccessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/proof/otp/secrets/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = anyAccessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<OtpSecret>, anyAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/proof/otp/secrets/count",
            method = HttpMethod.POST,
            token = anyAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<OtpSecret>, anyAccessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/proof/otp/secrets/group-count",
            method = HttpMethod.POST,
            token = anyAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<OtpSecret>, anyAccessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/proof/otp/secrets/aggregate",
            method = HttpMethod.POST,
            token = anyAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<OtpSecret>, anyAccessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/proof/otp/secrets/group-aggregate",
            method = HttpMethod.POST,
            token = anyAccessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LiveOneTimePasswordProofApi(val httpUrl: String, val socketUrl: String): Api.OneTimePasswordProofApi {
        override suspend fun establishOneTimePassword(input: EstablishOtp, anyAccessToken: suspend () -> String, masquerade: String?): String = fetch(
            url = "$httpUrl/proof/otp/establish",
            method = HttpMethod.POST,
            token = anyAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun proveOTP(input: IdentificationAndPassword): Proof = fetch(
            url = "$httpUrl/proof/otp/prove",
            method = HttpMethod.POST,
            body = input
        )
    }
    class LivePasswordSecretApi(val httpUrl: String, val socketUrl: String): Api.PasswordSecretApi {
        override suspend fun query(input: Query<PasswordSecret>, anyAccessToken: suspend () -> String, masquerade: String?): List<PasswordSecret> = fetch(
            url = "$httpUrl/proof/password/secrets/query",
            method = HttpMethod.POST,
            token = anyAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<PasswordSecret>, anyAccessToken: suspend () -> String, masquerade: String?): List<Partial<PasswordSecret>> = fetch(
            url = "$httpUrl/proof/password/secrets/query-partial",
            method = HttpMethod.POST,
            token = anyAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, anyAccessToken: suspend () -> String, masquerade: String?): PasswordSecret = fetch(
            url = "$httpUrl/proof/password/secrets/${id.urlify()}",
            method = HttpMethod.GET,
            token = anyAccessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<PasswordSecret>, anyAccessToken: suspend () -> String, masquerade: String?): List<PasswordSecret> = fetch(
            url = "$httpUrl/proof/password/secrets/bulk",
            method = HttpMethod.POST,
            token = anyAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: PasswordSecret, anyAccessToken: suspend () -> String, masquerade: String?): PasswordSecret = fetch(
            url = "$httpUrl/proof/password/secrets",
            method = HttpMethod.POST,
            token = anyAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: PasswordSecret, anyAccessToken: suspend () -> String, masquerade: String?): PasswordSecret = fetch(
            url = "$httpUrl/proof/password/secrets/${id.urlify()}",
            method = HttpMethod.POST,
            token = anyAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<PasswordSecret>, anyAccessToken: suspend () -> String, masquerade: String?): List<PasswordSecret> = fetch(
            url = "$httpUrl/proof/password/secrets",
            method = HttpMethod.PUT,
            token = anyAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: PasswordSecret, anyAccessToken: suspend () -> String, masquerade: String?): PasswordSecret = fetch(
            url = "$httpUrl/proof/password/secrets/${id.urlify()}",
            method = HttpMethod.PUT,
            token = anyAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<PasswordSecret>, anyAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/proof/password/secrets/bulk",
            method = HttpMethod.PATCH,
            token = anyAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<PasswordSecret>, anyAccessToken: suspend () -> String, masquerade: String?): EntryChange<PasswordSecret> = fetch(
            url = "$httpUrl/proof/password/secrets/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = anyAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<PasswordSecret>, anyAccessToken: suspend () -> String, masquerade: String?): PasswordSecret = fetch(
            url = "$httpUrl/proof/password/secrets/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = anyAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<PasswordSecret>, anyAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/proof/password/secrets/bulk-delete",
            method = HttpMethod.POST,
            token = anyAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, anyAccessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/proof/password/secrets/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = anyAccessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<PasswordSecret>, anyAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/proof/password/secrets/count",
            method = HttpMethod.POST,
            token = anyAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<PasswordSecret>, anyAccessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/proof/password/secrets/group-count",
            method = HttpMethod.POST,
            token = anyAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<PasswordSecret>, anyAccessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/proof/password/secrets/aggregate",
            method = HttpMethod.POST,
            token = anyAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<PasswordSecret>, anyAccessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/proof/password/secrets/group-aggregate",
            method = HttpMethod.POST,
            token = anyAccessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LivePasswordProofApi(val httpUrl: String, val socketUrl: String): Api.PasswordProofApi {
        override suspend fun establishPassword(input: EstablishPassword, anyAccessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/proof/password/establish",
            method = HttpMethod.POST,
            token = anyAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun provePasswordOwnership(input: IdentificationAndPassword): Proof = fetch(
            url = "$httpUrl/proof/password/prove",
            method = HttpMethod.POST,
            body = input
        )
    }
    class LiveUserAuthApi(val httpUrl: String, val socketUrl: String): Api.UserAuthApi {
        override suspend fun logIn(input: List<Proof>): IdAndAuthMethods<UUID> = fetch(
            url = "$httpUrl/auth/login",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun logInV2(input: LogInRequest): IdAndAuthMethods<UUID> = fetch(
            url = "$httpUrl/auth/login2",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun checkProofs(input: List<Proof>): ProofsCheckResult<UUID> = fetch(
            url = "$httpUrl/auth/proofs-check",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun openSession(input: String): String = fetch(
            url = "$httpUrl/auth/open-session",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun createSubSession(input: SubSessionRequest, userAccessToken: suspend () -> String, masquerade: String?): String = fetch(
            url = "$httpUrl/auth/sub-session",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun getToken(input: OauthTokenRequest): OauthResponse = fetch(
            url = "$httpUrl/auth/token",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun getTokenSimple(input: String): String = fetch(
            url = "$httpUrl/auth/token/simple",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun getSelf(userAccessToken: suspend () -> String, masquerade: String?): User = fetch(
            url = "$httpUrl/auth/self",
            method = HttpMethod.GET,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun terminateSession(userAccessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/auth/terminate",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun terminateOtherSession(sessionId: UUID, userAccessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/auth/${sessionId.urlify()}/terminate",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
        )
    }
    class LiveUserSessionApi(val httpUrl: String, val socketUrl: String): Api.UserSessionApi {
        override suspend fun query(input: Query<Session<User, UUID>>, userAccessToken: suspend () -> String, masquerade: String?): List<Session<User, UUID>> = fetch(
            url = "$httpUrl/auth/sessions/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<Session<User, UUID>>, userAccessToken: suspend () -> String, masquerade: String?): List<Partial<Session<User, UUID>>> = fetch(
            url = "$httpUrl/auth/sessions/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, userAccessToken: suspend () -> String, masquerade: String?): Session<User, UUID> = fetch(
            url = "$httpUrl/auth/sessions/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<Session<User, UUID>>, userAccessToken: suspend () -> String, masquerade: String?): List<Session<User, UUID>> = fetch(
            url = "$httpUrl/auth/sessions/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: Session<User, UUID>, userAccessToken: suspend () -> String, masquerade: String?): Session<User, UUID> = fetch(
            url = "$httpUrl/auth/sessions",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: Session<User, UUID>, userAccessToken: suspend () -> String, masquerade: String?): Session<User, UUID> = fetch(
            url = "$httpUrl/auth/sessions/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<Session<User, UUID>>, userAccessToken: suspend () -> String, masquerade: String?): List<Session<User, UUID>> = fetch(
            url = "$httpUrl/auth/sessions",
            method = HttpMethod.PUT,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: Session<User, UUID>, userAccessToken: suspend () -> String, masquerade: String?): Session<User, UUID> = fetch(
            url = "$httpUrl/auth/sessions/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<Session<User, UUID>>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/auth/sessions/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Session<User, UUID>>, userAccessToken: suspend () -> String, masquerade: String?): EntryChange<Session<User, UUID>> = fetch(
            url = "$httpUrl/auth/sessions/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<Session<User, UUID>>, userAccessToken: suspend () -> String, masquerade: String?): Session<User, UUID> = fetch(
            url = "$httpUrl/auth/sessions/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<Session<User, UUID>>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/auth/sessions/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, userAccessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/auth/sessions/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<Session<User, UUID>>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/auth/sessions/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<Session<User, UUID>>, userAccessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/auth/sessions/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<Session<User, UUID>>, userAccessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/auth/sessions/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<Session<User, UUID>>, userAccessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/auth/sessions/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LiveFcmTokenApi(val httpUrl: String, val socketUrl: String): Api.FcmTokenApi {
        override suspend fun default(userAccessToken: suspend () -> String, masquerade: String?): FcmToken = fetch(
            url = "$httpUrl/fcmTokens/_default_",
            method = HttpMethod.GET,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<FcmToken>, userAccessToken: suspend () -> String, masquerade: String?): List<FcmToken> = fetch(
            url = "$httpUrl/fcmTokens/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<FcmToken>, userAccessToken: suspend () -> String, masquerade: String?): List<Partial<FcmToken>> = fetch(
            url = "$httpUrl/fcmTokens/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: String, userAccessToken: suspend () -> String, masquerade: String?): FcmToken = fetch(
            url = "$httpUrl/fcmTokens/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<FcmToken>, userAccessToken: suspend () -> String, masquerade: String?): List<FcmToken> = fetch(
            url = "$httpUrl/fcmTokens/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: FcmToken, userAccessToken: suspend () -> String, masquerade: String?): FcmToken = fetch(
            url = "$httpUrl/fcmTokens",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: String, input: FcmToken, userAccessToken: suspend () -> String, masquerade: String?): FcmToken = fetch(
            url = "$httpUrl/fcmTokens/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<FcmToken>, userAccessToken: suspend () -> String, masquerade: String?): List<FcmToken> = fetch(
            url = "$httpUrl/fcmTokens",
            method = HttpMethod.PUT,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: String, input: FcmToken, userAccessToken: suspend () -> String, masquerade: String?): FcmToken = fetch(
            url = "$httpUrl/fcmTokens/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<FcmToken>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/fcmTokens/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: String, input: Modification<FcmToken>, userAccessToken: suspend () -> String, masquerade: String?): EntryChange<FcmToken> = fetch(
            url = "$httpUrl/fcmTokens/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: String, input: Modification<FcmToken>, userAccessToken: suspend () -> String, masquerade: String?): FcmToken = fetch(
            url = "$httpUrl/fcmTokens/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<FcmToken>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/fcmTokens/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: String, userAccessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/fcmTokens/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<FcmToken>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/fcmTokens/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<FcmToken>, userAccessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/fcmTokens/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<FcmToken>, userAccessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/fcmTokens/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<FcmToken>, userAccessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/fcmTokens/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun testInAppNotifications(id: String, userAccessToken: suspend () -> String, masquerade: String?): String = fetch(
            url = "$httpUrl/fcmTokens/${id.urlify()}/test",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
        )
    }
    class LiveVehicleRelationshipApi(val httpUrl: String, val socketUrl: String): Api.VehicleRelationshipApi {
        override suspend fun default(userAccessToken: suspend () -> String, masquerade: String?): VehicleRelationship = fetch(
            url = "$httpUrl/vehicle-relationship/_default_",
            method = HttpMethod.GET,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<VehicleRelationship>, userAccessToken: suspend () -> String, masquerade: String?): List<VehicleRelationship> = fetch(
            url = "$httpUrl/vehicle-relationship/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<VehicleRelationship>, userAccessToken: suspend () -> String, masquerade: String?): List<Partial<VehicleRelationship>> = fetch(
            url = "$httpUrl/vehicle-relationship/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UserVehiclePair, userAccessToken: suspend () -> String, masquerade: String?): VehicleRelationship = fetch(
            url = "$httpUrl/vehicle-relationship/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<VehicleRelationship>, userAccessToken: suspend () -> String, masquerade: String?): List<VehicleRelationship> = fetch(
            url = "$httpUrl/vehicle-relationship/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: VehicleRelationship, userAccessToken: suspend () -> String, masquerade: String?): VehicleRelationship = fetch(
            url = "$httpUrl/vehicle-relationship",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UserVehiclePair, input: VehicleRelationship, userAccessToken: suspend () -> String, masquerade: String?): VehicleRelationship = fetch(
            url = "$httpUrl/vehicle-relationship/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<VehicleRelationship>, userAccessToken: suspend () -> String, masquerade: String?): List<VehicleRelationship> = fetch(
            url = "$httpUrl/vehicle-relationship",
            method = HttpMethod.PUT,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UserVehiclePair, input: VehicleRelationship, userAccessToken: suspend () -> String, masquerade: String?): VehicleRelationship = fetch(
            url = "$httpUrl/vehicle-relationship/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<VehicleRelationship>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/vehicle-relationship/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UserVehiclePair, input: Modification<VehicleRelationship>, userAccessToken: suspend () -> String, masquerade: String?): EntryChange<VehicleRelationship> = fetch(
            url = "$httpUrl/vehicle-relationship/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UserVehiclePair, input: Modification<VehicleRelationship>, userAccessToken: suspend () -> String, masquerade: String?): VehicleRelationship = fetch(
            url = "$httpUrl/vehicle-relationship/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<VehicleRelationship>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/vehicle-relationship/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UserVehiclePair, userAccessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/vehicle-relationship/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<VehicleRelationship>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/vehicle-relationship/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<VehicleRelationship>, userAccessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/vehicle-relationship/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<VehicleRelationship>, userAccessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/vehicle-relationship/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<VehicleRelationship>, userAccessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/vehicle-relationship/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LiveVehicleApi(val httpUrl: String, val socketUrl: String): Api.VehicleApi {
        override suspend fun default(userAccessToken: suspend () -> String, masquerade: String?): Vehicle = fetch(
            url = "$httpUrl/vehicle/_default_",
            method = HttpMethod.GET,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<Vehicle>, userAccessToken: suspend () -> String, masquerade: String?): List<Vehicle> = fetch(
            url = "$httpUrl/vehicle/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<Vehicle>, userAccessToken: suspend () -> String, masquerade: String?): List<Partial<Vehicle>> = fetch(
            url = "$httpUrl/vehicle/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, userAccessToken: suspend () -> String, masquerade: String?): Vehicle = fetch(
            url = "$httpUrl/vehicle/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<Vehicle>, userAccessToken: suspend () -> String, masquerade: String?): List<Vehicle> = fetch(
            url = "$httpUrl/vehicle/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: Vehicle, userAccessToken: suspend () -> String, masquerade: String?): Vehicle = fetch(
            url = "$httpUrl/vehicle",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: Vehicle, userAccessToken: suspend () -> String, masquerade: String?): Vehicle = fetch(
            url = "$httpUrl/vehicle/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<Vehicle>, userAccessToken: suspend () -> String, masquerade: String?): List<Vehicle> = fetch(
            url = "$httpUrl/vehicle",
            method = HttpMethod.PUT,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: Vehicle, userAccessToken: suspend () -> String, masquerade: String?): Vehicle = fetch(
            url = "$httpUrl/vehicle/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<Vehicle>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/vehicle/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Vehicle>, userAccessToken: suspend () -> String, masquerade: String?): EntryChange<Vehicle> = fetch(
            url = "$httpUrl/vehicle/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<Vehicle>, userAccessToken: suspend () -> String, masquerade: String?): Vehicle = fetch(
            url = "$httpUrl/vehicle/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<Vehicle>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/vehicle/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, userAccessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/vehicle/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<Vehicle>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/vehicle/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<Vehicle>, userAccessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/vehicle/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<Vehicle>, userAccessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/vehicle/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<Vehicle>, userAccessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/vehicle/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun acceptInitialOffer(id: UUID, userAccessToken: suspend () -> String, masquerade: String?): Vehicle = fetch(
            url = "$httpUrl/vehicle/${id.urlify()}/accept-offer",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override fun updates(userToken: String): TypedWebSocket<Condition<Vehicle>, CollectionUpdates<Vehicle, UUID>> = multiplexedSocket(
            socketUrl = socketUrl, 
            path = "/vehicle", 
            token = userToken,
        )
    }
    class LiveAuctionLaneApi(val httpUrl: String, val socketUrl: String): Api.AuctionLaneApi {
        override suspend fun default(userAccessToken: suspend () -> String, masquerade: String?): AuctionLane = fetch(
            url = "$httpUrl/auction-lane/_default_",
            method = HttpMethod.GET,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<AuctionLane>, userAccessToken: suspend () -> String, masquerade: String?): List<AuctionLane> = fetch(
            url = "$httpUrl/auction-lane/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<AuctionLane>, userAccessToken: suspend () -> String, masquerade: String?): List<Partial<AuctionLane>> = fetch(
            url = "$httpUrl/auction-lane/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, userAccessToken: suspend () -> String, masquerade: String?): AuctionLane = fetch(
            url = "$httpUrl/auction-lane/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<AuctionLane>, userAccessToken: suspend () -> String, masquerade: String?): List<AuctionLane> = fetch(
            url = "$httpUrl/auction-lane/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: AuctionLane, userAccessToken: suspend () -> String, masquerade: String?): AuctionLane = fetch(
            url = "$httpUrl/auction-lane",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: AuctionLane, userAccessToken: suspend () -> String, masquerade: String?): AuctionLane = fetch(
            url = "$httpUrl/auction-lane/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<AuctionLane>, userAccessToken: suspend () -> String, masquerade: String?): List<AuctionLane> = fetch(
            url = "$httpUrl/auction-lane",
            method = HttpMethod.PUT,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: AuctionLane, userAccessToken: suspend () -> String, masquerade: String?): AuctionLane = fetch(
            url = "$httpUrl/auction-lane/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<AuctionLane>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/auction-lane/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<AuctionLane>, userAccessToken: suspend () -> String, masquerade: String?): EntryChange<AuctionLane> = fetch(
            url = "$httpUrl/auction-lane/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<AuctionLane>, userAccessToken: suspend () -> String, masquerade: String?): AuctionLane = fetch(
            url = "$httpUrl/auction-lane/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<AuctionLane>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/auction-lane/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, userAccessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/auction-lane/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<AuctionLane>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/auction-lane/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<AuctionLane>, userAccessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/auction-lane/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<AuctionLane>, userAccessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/auction-lane/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<AuctionLane>, userAccessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/auction-lane/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LiveBidApi(val httpUrl: String, val socketUrl: String): Api.BidApi {
        override suspend fun default(userAccessToken: suspend () -> String, masquerade: String?): Bid = fetch(
            url = "$httpUrl/bid/_default_",
            method = HttpMethod.GET,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<Bid>, userAccessToken: suspend () -> String, masquerade: String?): List<Bid> = fetch(
            url = "$httpUrl/bid/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<Bid>, userAccessToken: suspend () -> String, masquerade: String?): List<Partial<Bid>> = fetch(
            url = "$httpUrl/bid/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, userAccessToken: suspend () -> String, masquerade: String?): Bid = fetch(
            url = "$httpUrl/bid/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<Bid>, userAccessToken: suspend () -> String, masquerade: String?): List<Bid> = fetch(
            url = "$httpUrl/bid/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: Bid, userAccessToken: suspend () -> String, masquerade: String?): Bid = fetch(
            url = "$httpUrl/bid",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: Bid, userAccessToken: suspend () -> String, masquerade: String?): Bid = fetch(
            url = "$httpUrl/bid/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<Bid>, userAccessToken: suspend () -> String, masquerade: String?): List<Bid> = fetch(
            url = "$httpUrl/bid",
            method = HttpMethod.PUT,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: Bid, userAccessToken: suspend () -> String, masquerade: String?): Bid = fetch(
            url = "$httpUrl/bid/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<Bid>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/bid/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Bid>, userAccessToken: suspend () -> String, masquerade: String?): EntryChange<Bid> = fetch(
            url = "$httpUrl/bid/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<Bid>, userAccessToken: suspend () -> String, masquerade: String?): Bid = fetch(
            url = "$httpUrl/bid/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<Bid>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/bid/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, userAccessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/bid/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<Bid>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/bid/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<Bid>, userAccessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/bid/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<Bid>, userAccessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/bid/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<Bid>, userAccessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/bid/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override fun updates(userToken: String): TypedWebSocket<Condition<Bid>, CollectionUpdates<Bid, UUID>> = multiplexedSocket(
            socketUrl = socketUrl, 
            path = "/bid", 
            token = userToken,
        )
    }
    class LiveLiveAuctionDataApi(val httpUrl: String, val socketUrl: String): Api.LiveAuctionDataApi {
        override suspend fun default(userAccessToken: suspend () -> String, masquerade: String?): LiveAuctionData = fetch(
            url = "$httpUrl/live-auction-data/_default_",
            method = HttpMethod.GET,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<LiveAuctionData>, userAccessToken: suspend () -> String, masquerade: String?): List<LiveAuctionData> = fetch(
            url = "$httpUrl/live-auction-data/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<LiveAuctionData>, userAccessToken: suspend () -> String, masquerade: String?): List<Partial<LiveAuctionData>> = fetch(
            url = "$httpUrl/live-auction-data/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, userAccessToken: suspend () -> String, masquerade: String?): LiveAuctionData = fetch(
            url = "$httpUrl/live-auction-data/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<LiveAuctionData>, userAccessToken: suspend () -> String, masquerade: String?): List<LiveAuctionData> = fetch(
            url = "$httpUrl/live-auction-data/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: LiveAuctionData, userAccessToken: suspend () -> String, masquerade: String?): LiveAuctionData = fetch(
            url = "$httpUrl/live-auction-data",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: LiveAuctionData, userAccessToken: suspend () -> String, masquerade: String?): LiveAuctionData = fetch(
            url = "$httpUrl/live-auction-data/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<LiveAuctionData>, userAccessToken: suspend () -> String, masquerade: String?): List<LiveAuctionData> = fetch(
            url = "$httpUrl/live-auction-data",
            method = HttpMethod.PUT,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: LiveAuctionData, userAccessToken: suspend () -> String, masquerade: String?): LiveAuctionData = fetch(
            url = "$httpUrl/live-auction-data/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<LiveAuctionData>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/live-auction-data/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<LiveAuctionData>, userAccessToken: suspend () -> String, masquerade: String?): EntryChange<LiveAuctionData> = fetch(
            url = "$httpUrl/live-auction-data/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<LiveAuctionData>, userAccessToken: suspend () -> String, masquerade: String?): LiveAuctionData = fetch(
            url = "$httpUrl/live-auction-data/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<LiveAuctionData>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/live-auction-data/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, userAccessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/live-auction-data/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<LiveAuctionData>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/live-auction-data/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<LiveAuctionData>, userAccessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/live-auction-data/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<LiveAuctionData>, userAccessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/live-auction-data/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<LiveAuctionData>, userAccessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/live-auction-data/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun start(id: UUID, userAccessToken: suspend () -> String, masquerade: String?): LiveAuctionData? = fetch(
            url = "$httpUrl/live-auction-data/start/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun notifyHover(id: UUID, dealership: UUID, userAccessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/live-auction-data/inform/${id.urlify()}/hover/${dealership.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun notifyUnhover(id: UUID, dealership: UUID, userAccessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/live-auction-data/inform/${id.urlify()}/unhover/${dealership.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun notifyJoin(id: UUID, dealership: UUID, userAccessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/live-auction-data/inform/${id.urlify()}/join/${dealership.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun notifyLeave(id: UUID, dealership: UUID, userAccessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/live-auction-data/inform/${id.urlify()}/leave/${dealership.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override fun updates(userToken: String): TypedWebSocket<Condition<LiveAuctionData>, CollectionUpdates<LiveAuctionData, UUID>> = multiplexedSocket(
            socketUrl = socketUrl, 
            path = "/live-auction-data", 
            token = userToken,
        )
    }
    class LiveSellerTalkingPointApi(val httpUrl: String, val socketUrl: String): Api.SellerTalkingPointApi {
        override suspend fun default(userAccessToken: suspend () -> String, masquerade: String?): SellerTalkingPoint = fetch(
            url = "$httpUrl/seller-talking-points/_default_",
            method = HttpMethod.GET,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<SellerTalkingPoint>, userAccessToken: suspend () -> String, masquerade: String?): List<SellerTalkingPoint> = fetch(
            url = "$httpUrl/seller-talking-points/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<SellerTalkingPoint>, userAccessToken: suspend () -> String, masquerade: String?): List<Partial<SellerTalkingPoint>> = fetch(
            url = "$httpUrl/seller-talking-points/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, userAccessToken: suspend () -> String, masquerade: String?): SellerTalkingPoint = fetch(
            url = "$httpUrl/seller-talking-points/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<SellerTalkingPoint>, userAccessToken: suspend () -> String, masquerade: String?): List<SellerTalkingPoint> = fetch(
            url = "$httpUrl/seller-talking-points/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: SellerTalkingPoint, userAccessToken: suspend () -> String, masquerade: String?): SellerTalkingPoint = fetch(
            url = "$httpUrl/seller-talking-points",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: SellerTalkingPoint, userAccessToken: suspend () -> String, masquerade: String?): SellerTalkingPoint = fetch(
            url = "$httpUrl/seller-talking-points/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<SellerTalkingPoint>, userAccessToken: suspend () -> String, masquerade: String?): List<SellerTalkingPoint> = fetch(
            url = "$httpUrl/seller-talking-points",
            method = HttpMethod.PUT,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: SellerTalkingPoint, userAccessToken: suspend () -> String, masquerade: String?): SellerTalkingPoint = fetch(
            url = "$httpUrl/seller-talking-points/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<SellerTalkingPoint>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/seller-talking-points/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<SellerTalkingPoint>, userAccessToken: suspend () -> String, masquerade: String?): EntryChange<SellerTalkingPoint> = fetch(
            url = "$httpUrl/seller-talking-points/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<SellerTalkingPoint>, userAccessToken: suspend () -> String, masquerade: String?): SellerTalkingPoint = fetch(
            url = "$httpUrl/seller-talking-points/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<SellerTalkingPoint>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/seller-talking-points/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, userAccessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/seller-talking-points/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<SellerTalkingPoint>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/seller-talking-points/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<SellerTalkingPoint>, userAccessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/seller-talking-points/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<SellerTalkingPoint>, userAccessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/seller-talking-points/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<SellerTalkingPoint>, userAccessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/seller-talking-points/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override fun updates(userToken: String): TypedWebSocket<Condition<SellerTalkingPoint>, CollectionUpdates<SellerTalkingPoint, UUID>> = multiplexedSocket(
            socketUrl = socketUrl, 
            path = "/seller-talking-points", 
            token = userToken,
        )
    }
    class LiveSavedSearchApi(val httpUrl: String, val socketUrl: String): Api.SavedSearchApi {
        override suspend fun default(userAccessToken: suspend () -> String, masquerade: String?): SavedSearch = fetch(
            url = "$httpUrl/saved-searches/_default_",
            method = HttpMethod.GET,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<SavedSearch>, userAccessToken: suspend () -> String, masquerade: String?): List<SavedSearch> = fetch(
            url = "$httpUrl/saved-searches/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<SavedSearch>, userAccessToken: suspend () -> String, masquerade: String?): List<Partial<SavedSearch>> = fetch(
            url = "$httpUrl/saved-searches/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, userAccessToken: suspend () -> String, masquerade: String?): SavedSearch = fetch(
            url = "$httpUrl/saved-searches/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<SavedSearch>, userAccessToken: suspend () -> String, masquerade: String?): List<SavedSearch> = fetch(
            url = "$httpUrl/saved-searches/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: SavedSearch, userAccessToken: suspend () -> String, masquerade: String?): SavedSearch = fetch(
            url = "$httpUrl/saved-searches",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: SavedSearch, userAccessToken: suspend () -> String, masquerade: String?): SavedSearch = fetch(
            url = "$httpUrl/saved-searches/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<SavedSearch>, userAccessToken: suspend () -> String, masquerade: String?): List<SavedSearch> = fetch(
            url = "$httpUrl/saved-searches",
            method = HttpMethod.PUT,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: SavedSearch, userAccessToken: suspend () -> String, masquerade: String?): SavedSearch = fetch(
            url = "$httpUrl/saved-searches/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<SavedSearch>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/saved-searches/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<SavedSearch>, userAccessToken: suspend () -> String, masquerade: String?): EntryChange<SavedSearch> = fetch(
            url = "$httpUrl/saved-searches/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<SavedSearch>, userAccessToken: suspend () -> String, masquerade: String?): SavedSearch = fetch(
            url = "$httpUrl/saved-searches/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<SavedSearch>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/saved-searches/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, userAccessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/saved-searches/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<SavedSearch>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/saved-searches/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<SavedSearch>, userAccessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/saved-searches/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<SavedSearch>, userAccessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/saved-searches/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<SavedSearch>, userAccessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/saved-searches/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LiveNotificationApi(val httpUrl: String, val socketUrl: String): Api.NotificationApi {
        override suspend fun default(userAccessToken: suspend () -> String, masquerade: String?): Notification = fetch(
            url = "$httpUrl/notification/_default_",
            method = HttpMethod.GET,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<Notification>, userAccessToken: suspend () -> String, masquerade: String?): List<Notification> = fetch(
            url = "$httpUrl/notification/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<Notification>, userAccessToken: suspend () -> String, masquerade: String?): List<Partial<Notification>> = fetch(
            url = "$httpUrl/notification/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, userAccessToken: suspend () -> String, masquerade: String?): Notification = fetch(
            url = "$httpUrl/notification/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<Notification>, userAccessToken: suspend () -> String, masquerade: String?): List<Notification> = fetch(
            url = "$httpUrl/notification/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: Notification, userAccessToken: suspend () -> String, masquerade: String?): Notification = fetch(
            url = "$httpUrl/notification",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: Notification, userAccessToken: suspend () -> String, masquerade: String?): Notification = fetch(
            url = "$httpUrl/notification/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<Notification>, userAccessToken: suspend () -> String, masquerade: String?): List<Notification> = fetch(
            url = "$httpUrl/notification",
            method = HttpMethod.PUT,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: Notification, userAccessToken: suspend () -> String, masquerade: String?): Notification = fetch(
            url = "$httpUrl/notification/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<Notification>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/notification/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Notification>, userAccessToken: suspend () -> String, masquerade: String?): EntryChange<Notification> = fetch(
            url = "$httpUrl/notification/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<Notification>, userAccessToken: suspend () -> String, masquerade: String?): Notification = fetch(
            url = "$httpUrl/notification/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<Notification>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/notification/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, userAccessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/notification/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<Notification>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/notification/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<Notification>, userAccessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/notification/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<Notification>, userAccessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/notification/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<Notification>, userAccessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/notification/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LiveFakerApi(val httpUrl: String, val socketUrl: String): Api.FakerApi {
        override suspend fun saveState(userAccessToken: suspend () -> String, masquerade: String?): String = fetch(
            url = "$httpUrl/fake-data/save-state",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun loadState(userAccessToken: suspend () -> String, masquerade: String?): String = fetch(
            url = "$httpUrl/fake-data/load-state",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun resetTestData(input: ResetDataRequest, userAccessToken: suspend () -> String, masquerade: String?): String = fetch(
            url = "$httpUrl/fake-data/reset",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LiveMakeApi(val httpUrl: String, val socketUrl: String): Api.MakeApi {
        override suspend fun default(userAccessToken: (suspend () -> String)?, masquerade: String?): Make = fetch(
            url = "$httpUrl/makes/_default_",
            method = HttpMethod.GET,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<Make>, userAccessToken: (suspend () -> String)?, masquerade: String?): List<Make> = fetch(
            url = "$httpUrl/makes/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<Make>, userAccessToken: (suspend () -> String)?, masquerade: String?): List<Partial<Make>> = fetch(
            url = "$httpUrl/makes/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: String, userAccessToken: (suspend () -> String)?, masquerade: String?): Make = fetch(
            url = "$httpUrl/makes/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<Make>, userAccessToken: (suspend () -> String)?, masquerade: String?): List<Make> = fetch(
            url = "$httpUrl/makes/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: Make, userAccessToken: (suspend () -> String)?, masquerade: String?): Make = fetch(
            url = "$httpUrl/makes",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: String, input: Make, userAccessToken: (suspend () -> String)?, masquerade: String?): Make = fetch(
            url = "$httpUrl/makes/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<Make>, userAccessToken: (suspend () -> String)?, masquerade: String?): List<Make> = fetch(
            url = "$httpUrl/makes",
            method = HttpMethod.PUT,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: String, input: Make, userAccessToken: (suspend () -> String)?, masquerade: String?): Make = fetch(
            url = "$httpUrl/makes/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<Make>, userAccessToken: (suspend () -> String)?, masquerade: String?): Int = fetch(
            url = "$httpUrl/makes/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: String, input: Modification<Make>, userAccessToken: (suspend () -> String)?, masquerade: String?): EntryChange<Make> = fetch(
            url = "$httpUrl/makes/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: String, input: Modification<Make>, userAccessToken: (suspend () -> String)?, masquerade: String?): Make = fetch(
            url = "$httpUrl/makes/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<Make>, userAccessToken: (suspend () -> String)?, masquerade: String?): Int = fetch(
            url = "$httpUrl/makes/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: String, userAccessToken: (suspend () -> String)?, masquerade: String?): Unit = fetch(
            url = "$httpUrl/makes/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<Make>, userAccessToken: (suspend () -> String)?, masquerade: String?): Int = fetch(
            url = "$httpUrl/makes/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<Make>, userAccessToken: (suspend () -> String)?, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/makes/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<Make>, userAccessToken: (suspend () -> String)?, masquerade: String?): Double? = fetch(
            url = "$httpUrl/makes/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<Make>, userAccessToken: (suspend () -> String)?, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/makes/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun sync(userAccessToken: suspend () -> String, masquerade: String?): List<Make> = fetch(
            url = "$httpUrl/makes/sync",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun syncAll(userAccessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/makes/syncAll",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
        )
    }
    class LiveModelApi(val httpUrl: String, val socketUrl: String): Api.ModelApi {
        override suspend fun default(userAccessToken: suspend () -> String, masquerade: String?): Model = fetch(
            url = "$httpUrl/models/_default_",
            method = HttpMethod.GET,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<Model>, userAccessToken: suspend () -> String, masquerade: String?): List<Model> = fetch(
            url = "$httpUrl/models/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<Model>, userAccessToken: suspend () -> String, masquerade: String?): List<Partial<Model>> = fetch(
            url = "$httpUrl/models/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: String, userAccessToken: suspend () -> String, masquerade: String?): Model = fetch(
            url = "$httpUrl/models/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<Model>, userAccessToken: suspend () -> String, masquerade: String?): List<Model> = fetch(
            url = "$httpUrl/models/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: Model, userAccessToken: suspend () -> String, masquerade: String?): Model = fetch(
            url = "$httpUrl/models",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: String, input: Model, userAccessToken: suspend () -> String, masquerade: String?): Model = fetch(
            url = "$httpUrl/models/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<Model>, userAccessToken: suspend () -> String, masquerade: String?): List<Model> = fetch(
            url = "$httpUrl/models",
            method = HttpMethod.PUT,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: String, input: Model, userAccessToken: suspend () -> String, masquerade: String?): Model = fetch(
            url = "$httpUrl/models/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<Model>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/models/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: String, input: Modification<Model>, userAccessToken: suspend () -> String, masquerade: String?): EntryChange<Model> = fetch(
            url = "$httpUrl/models/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: String, input: Modification<Model>, userAccessToken: suspend () -> String, masquerade: String?): Model = fetch(
            url = "$httpUrl/models/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<Model>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/models/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: String, userAccessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/models/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<Model>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/models/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<Model>, userAccessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/models/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<Model>, userAccessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/models/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<Model>, userAccessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/models/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun sync(make: String, userAccessToken: suspend () -> String, masquerade: String?): List<Model> = fetch(
            url = "$httpUrl/models/sync/${make.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
        )
    }
    class LiveEarnestPaymentApi(val httpUrl: String, val socketUrl: String): Api.EarnestPaymentApi {
        override suspend fun default(userAccessToken: suspend () -> String, masquerade: String?): EarnestPayment = fetch(
            url = "$httpUrl/subscription-payments/_default_",
            method = HttpMethod.GET,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<EarnestPayment>, userAccessToken: suspend () -> String, masquerade: String?): List<EarnestPayment> = fetch(
            url = "$httpUrl/subscription-payments/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<EarnestPayment>, userAccessToken: suspend () -> String, masquerade: String?): List<Partial<EarnestPayment>> = fetch(
            url = "$httpUrl/subscription-payments/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, userAccessToken: suspend () -> String, masquerade: String?): EarnestPayment = fetch(
            url = "$httpUrl/subscription-payments/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<EarnestPayment>, userAccessToken: suspend () -> String, masquerade: String?): List<EarnestPayment> = fetch(
            url = "$httpUrl/subscription-payments/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: EarnestPayment, userAccessToken: suspend () -> String, masquerade: String?): EarnestPayment = fetch(
            url = "$httpUrl/subscription-payments",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: EarnestPayment, userAccessToken: suspend () -> String, masquerade: String?): EarnestPayment = fetch(
            url = "$httpUrl/subscription-payments/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<EarnestPayment>, userAccessToken: suspend () -> String, masquerade: String?): List<EarnestPayment> = fetch(
            url = "$httpUrl/subscription-payments",
            method = HttpMethod.PUT,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: EarnestPayment, userAccessToken: suspend () -> String, masquerade: String?): EarnestPayment = fetch(
            url = "$httpUrl/subscription-payments/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<EarnestPayment>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/subscription-payments/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<EarnestPayment>, userAccessToken: suspend () -> String, masquerade: String?): EntryChange<EarnestPayment> = fetch(
            url = "$httpUrl/subscription-payments/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<EarnestPayment>, userAccessToken: suspend () -> String, masquerade: String?): EarnestPayment = fetch(
            url = "$httpUrl/subscription-payments/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<EarnestPayment>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/subscription-payments/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, userAccessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/subscription-payments/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<EarnestPayment>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/subscription-payments/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<EarnestPayment>, userAccessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/subscription-payments/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<EarnestPayment>, userAccessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/subscription-payments/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<EarnestPayment>, userAccessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/subscription-payments/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LiveExternalFinancingFormApi(val httpUrl: String, val socketUrl: String): Api.ExternalFinancingFormApi {
        override suspend fun default(userAccessToken: suspend () -> String, masquerade: String?): ExternalFinancingForm = fetch(
            url = "$httpUrl/external-financing-forms/_default_",
            method = HttpMethod.GET,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<ExternalFinancingForm>, userAccessToken: suspend () -> String, masquerade: String?): List<ExternalFinancingForm> = fetch(
            url = "$httpUrl/external-financing-forms/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<ExternalFinancingForm>, userAccessToken: suspend () -> String, masquerade: String?): List<Partial<ExternalFinancingForm>> = fetch(
            url = "$httpUrl/external-financing-forms/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, userAccessToken: suspend () -> String, masquerade: String?): ExternalFinancingForm = fetch(
            url = "$httpUrl/external-financing-forms/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<ExternalFinancingForm>, userAccessToken: suspend () -> String, masquerade: String?): List<ExternalFinancingForm> = fetch(
            url = "$httpUrl/external-financing-forms/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: ExternalFinancingForm, userAccessToken: suspend () -> String, masquerade: String?): ExternalFinancingForm = fetch(
            url = "$httpUrl/external-financing-forms",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: ExternalFinancingForm, userAccessToken: suspend () -> String, masquerade: String?): ExternalFinancingForm = fetch(
            url = "$httpUrl/external-financing-forms/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<ExternalFinancingForm>, userAccessToken: suspend () -> String, masquerade: String?): List<ExternalFinancingForm> = fetch(
            url = "$httpUrl/external-financing-forms",
            method = HttpMethod.PUT,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: ExternalFinancingForm, userAccessToken: suspend () -> String, masquerade: String?): ExternalFinancingForm = fetch(
            url = "$httpUrl/external-financing-forms/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<ExternalFinancingForm>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/external-financing-forms/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<ExternalFinancingForm>, userAccessToken: suspend () -> String, masquerade: String?): EntryChange<ExternalFinancingForm> = fetch(
            url = "$httpUrl/external-financing-forms/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<ExternalFinancingForm>, userAccessToken: suspend () -> String, masquerade: String?): ExternalFinancingForm = fetch(
            url = "$httpUrl/external-financing-forms/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<ExternalFinancingForm>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/external-financing-forms/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, userAccessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/external-financing-forms/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<ExternalFinancingForm>, userAccessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/external-financing-forms/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<ExternalFinancingForm>, userAccessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/external-financing-forms/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<ExternalFinancingForm>, userAccessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/external-financing-forms/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<ExternalFinancingForm>, userAccessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/external-financing-forms/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            masquerade = masquerade,
            body = input
        )
    }
}

