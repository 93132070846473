package com.ilussobsa.views.dialogs

import com.ilussobsa.*
import com.ilussobsa.Strings
import com.ilussobsa.sdk.currentSessionNullable
import com.ilussobsa.sdk.currentSession
import com.ilussobsa.views.EditVehicleScreen
import com.lightningkite.UUID
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.KiteUiScreen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.mainScreenNavigator
import com.lightningkite.kiteui.reactive.shared
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.Modification
import com.lightningkite.lightningdb.modification
import com.ilussobsa.sdk.currentSession
import com.ilussobsa.sdk.currentUser
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.mainScreenNavigator
import com.lightningkite.kiteui.reactive.invoke
import kotlinx.datetime.Instant

class ConfirmUpdateListing(val selectedLot: UUID) : KiteUiScreen {
    val relationship = shared {
        currentUser()?.let { c ->
            currentSessionNullable.awaitNotNull().vehicleRelationships[UserVehiclePair(
                c._id,
                selectedLot
            )]()
        }
    }

    override fun ViewWriter.render() {
        dismissBackground {
            centered - card - sizeConstraints(
                width = 25.rem,
                height = 10.rem
            ) - col {
                centered - h3(Strings.confirmUpdate)

                centered - text(Strings.allProxyBidsOnThisListingWillBe)
                expanding - space()
                row {
                    expanding - col {
                        button {
                            centered - text(Strings.cancel)
                            onClick {
                                navigator.dismiss()
                            }
                        }
                    }

                    expanding - col {
                        important - button {
                            centered - text("OK")
                            onClick {
                                dialogScreenNavigator.clear()
                                mainScreenNavigator.navigate(EditVehicleScreen(selectedLot).apply {
                                    flowMode.value = false
                                })
                            }
                        }
                    }
                }
            }
        }
    }
}