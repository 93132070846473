package com.ilussobsa.sdk

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.LSError
import com.lightningkite.lightningserver.StringArrayFormat
import com.lightningkite.lightningserver.files.*
import com.lightningkite.lightningserver.websocket.*
import com.lightningkite.now
import com.lightningkite.serialization.*
import kotlin.reflect.typeOf
import kotlin.time.Duration.Companion.minutes
import kotlinx.coroutines.*
import kotlinx.datetime.Instant
import kotlinx.serialization.*
import kotlinx.serialization.json.Json
import net.thauvin.erik.urlencoder.UrlEncoderUtil

val json = Json {
    serializersModule = ClientModule
    ignoreUnknownKeys = true
}
val stringArrayFormat = StringArrayFormat(ClientModule)
inline fun <reified T> T.urlify(): String = stringArrayFormat.encodeToString(this)

suspend inline fun <reified T> RequestResponse.readJson() = json.decodeFromString<T>(text())
suspend fun RequestResponse.discard() = Unit
suspend inline fun <reified T> T.toJsonRequestBody() = json.encodeToString(this)

class LsErrorException(val status: Short, val error: LSError): IllegalStateException("$status: ${error.message}")

// 2 problems:
// Retry logic AND batch logic

var recentUrlHits = listOf<Pair<Instant, String>>()

suspend inline fun <reified OUT> fetch(
    url: String,
    method: HttpMethod = HttpMethod.GET,
    noinline token: (suspend () ->String)? = null,
    masquerade: String? = null,
    headers: HttpHeaders = httpHeaders(),
    bodyJson: String?
): OUT {
    val oneMinuteAgo = now() - 1.minutes
    recentUrlHits = recentUrlHits.plus(now() to url).filter { it.first > oneMinuteAgo }
    return batchFetch(url, method, token, masquerade, bodyJson)
}

suspend inline fun <reified IN, reified OUT> fetch(
    url: String,
    method: HttpMethod = HttpMethod.GET,
    noinline token: (suspend () ->String)? = null,
    masquerade: String? = null,
    headers: HttpHeaders = httpHeaders(),
    body: IN
): OUT = fetch(url, method, token, masquerade, headers, json.encodeToString(body))

suspend inline fun <reified OUT> fetch(
    url: String,
    method: HttpMethod = HttpMethod.GET,
    noinline token: (suspend () -> String)? = null,
    masquerade: String? = null,
    headers: HttpHeaders = httpHeaders()
): OUT = fetch(url, method, token, masquerade, headers, null)

inline fun <reified IN, reified OUT> multiplexedSocket(
    socketUrl: String,
    path: String,
    token: String?,
): TypedWebSocket<IN, OUT> = multiplexSocket(
    url = "$socketUrl/?path=multiplex${token?.let { "?jwt=${UrlEncoderUtil.encode(it)}" } ?: ""}",
    path = path,
    params = emptyMap(),
    json = json,
    pingTime = 5_000,
).typed(json, json.serializersModule.serializer(typeOf<IN>()) as KSerializer<IN>, json.serializersModule.serializer(typeOf<OUT>()) as KSerializer<OUT>)
