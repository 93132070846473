package com.ilussobsa.views

import com.ilussobsa.*
import com.ilussobsa.sdk.currentSession
import com.ilussobsa.utils.priceField
import com.lightningkite.PhoneNumber
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.exceptions.PlainTextException
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.DefaultJson
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.field
import com.lightningkite.nowLocal
import com.lightningkite.serialization.lensPath
import com.lightningkite.toEmailAddress
import com.lightningkite.toPhoneNumber
import kotlinx.datetime.LocalDate
import kotlinx.serialization.encodeToString

enum class CurrentOrPrevious { Current, Previous }
enum class ApplicantType { Primary, Secondary }

@Routable("financing-form")
class FinancingFormScreen : Screen {

    override fun ViewWriter.render() {
        val data = LazyProperty {
            val me = currentSession().me.awaitNotNull()
            FinancingApplication(
                user = me._id,
                primary = FinancingApplicant(
                    email = me.email,
                    phoneNumber = me.phoneNumber ?: "",
                    givenName = me.name.substringBefore(' '),
                    middleName = me.name.substringAfter(' ', "").substringBeforeLast(' '),
                    surname = me.name.substringAfterLast(' ', ""),
                )
            )
        }
        val submitAttempted = Property(false)
        pageWithMaxWidth(60.rem) {
            scrolls - col {
                reactive { data() }  // TODO: WTF?

                fun ViewWriter.address(address: Writable<UsAddress>) {
                    col {
                        field("Street Address*") {
                            textInput { content bind address.lensPath { it.street }.nullToBlank() }
                        }

                        rowCollapsingToColumn(50.rem) {
                            weight(1f) - field("City*") {
                                reactive { if(address().city?.isNotBlank() != true && submitAttempted()) throw PlainTextException("Required") }
                                textInput { content bind address.lensPath { it.city }.nullToBlank() }
                            }
                            weight(1f) - field("State*") {
                                reactive { if(address().state == null && submitAttempted()) throw PlainTextException("Required") }
                                select {
                                    bind(
                                        address.lensPath { it.state },
                                        Constant(listOf(null) + UsState.entries.toList())
                                    ) { it?.text ?: "Pick one" }
                                }
                            }
                            weight(1f) - field("Zip Code*") {
                                reactive { if(address().zip?.isNotBlank() != true && submitAttempted()) throw PlainTextException("Required") }
                                textInput { content bind address.lensPath { it.zip }.nullToBlank() }
                            }
                        }
                    }
                }

                fun ViewWriter.residency(residencyInfo: Writable<ResidencyInfo>) {
                    col {
                        address(residencyInfo.lensPath { it.address })

                        rowCollapsingToColumn(50.rem) {
                            weight(1f) - field("Residence Type") {
                                select {
                                    bind(
                                        residencyInfo.lensPath { it.residenceType },
                                        Constant(listOf(null) + ResidenceType.entries.toList())
                                    ) { it?.name ?: "Pick one" }
                                }
                            }
                            weight(1f) - field("Monthly Payment*") {
                                reactive { if(residencyInfo().monthlyPayment == null && submitAttempted()) throw PlainTextException("Required") }
                                row {
                                    text("$")
                                    expanding - numberInput { content bind residencyInfo.lensPath { it.monthlyPayment }.asDouble() }
                                }
                            }
                        }

                        rowCollapsingToColumn(50.rem) {
                            weight(1f) - field("Years at Address*") {
                                reactive { if(residencyInfo().yearsAtAddress == null && submitAttempted()) throw PlainTextException("Required") }
                                numberInput { content bind residencyInfo.lensPath { it.yearsAtAddress }.asDouble() }
                            }
                            weight(1f) - field("Months at Address*") {
                                reactive { if(residencyInfo().monthsAtAddress == null && submitAttempted()) throw PlainTextException("Required") }
                                numberInput {
                                    content bind residencyInfo.lensPath { it.monthsAtAddress }.asDouble()
                                }
                            }
                        }
                    }
                }

                fun ViewWriter.employer(employerInfo: Writable<EmployerInfo>, status: CurrentOrPrevious) {
                    col {
                        field("Employer Name*") {
                            reactive { if(employerInfo().name?.isNotEmpty() != true && submitAttempted()) throw PlainTextException("Required") }
                            textInput { content bind employerInfo.lensPath { it.name }.nullToBlank() }
                        }

                        onlyWhen { status == CurrentOrPrevious.Current } - address(employerInfo.lensPath { it.address }.lens(
                            get = { it ?: UsAddress() },
                            set = { it }
                        ))

                        rowCollapsingToColumn(50.rem) {
                            weight(1f) - field("Office Phone*") {
                                reactive { if(employerInfo().phone == null && submitAttempted()) throw PlainTextException("Required") }
                                textInput { content bind employerInfo.lensPath { it.phone }.nullToBlank() }
                            }
                            weight(1f) - field("Supervisor") { textInput { content bind employerInfo.lensPath { it.supervisor }.nullToBlank() } }
                        }

                        onlyWhen { status == CurrentOrPrevious.Current } - rowCollapsingToColumn(50.rem) {
                            weight(1f) - field("Gross Monthly Income*") {
                                reactive { if(employerInfo().grossMonthlyIncome == null && submitAttempted()) throw PlainTextException("Required") }
                                row {
                                    text("$")
                                    expanding - numberInput {
                                        content bind employerInfo.lensPath { it.grossMonthlyIncome }.asDouble()
                                    }
                                }
                            }
                            weight(1f) - field("Other Monthly Income") {
                                row {
                                    text("$")
                                    expanding - numberInput {
                                        content bind employerInfo.lensPath { it.otherMonthlyIncome }.asDouble()
                                    }
                                }
                            }
                        }

                        rowCollapsingToColumn(50.rem) {
                            weight(1f) - field("Occupation*") {
                                reactive { if(employerInfo().occupation?.isNotEmpty() != true && submitAttempted()) throw PlainTextException("Required") }
                                textInput { content bind employerInfo.lensPath { it.occupation }.nullToBlank() }
                            }

                            onlyWhen { status == CurrentOrPrevious.Current } - weight(1f) - field("Number of Years at Present Job? If less than 2, where did you work previously?*") {
                                reactive { if(employerInfo().yearsEmployed == null && submitAttempted()) throw PlainTextException("Required") }
                                numberInput {
                                    content bind employerInfo.lensPath { it.yearsEmployed }.asDouble()
                                }
                            }
                        }
                    }
                }

                fun ViewWriter.financingFormSectionHeader(applicantType: ApplicantType, headerContent: String) {
                    space()
                    h2 { content = ("${if (applicantType == ApplicantType.Secondary) "Co-Applicant - " else ""}$headerContent")}
                }

                fun ViewWriter.applicant(applicant: Writable<FinancingApplicant>, applicantType: ApplicantType) = col {
                    financingFormSectionHeader(applicantType, "Personal Information")
                    rowCollapsingToColumn(50.rem) {
                        weight(1f) - field("Given Name*") {
                            reactive { if(applicant().givenName?.isNotBlank() != true && submitAttempted()) throw PlainTextException("Required") }
                            textInput {
                                content bind applicant.lensPath { it.givenName }.nullToBlank()
                            }
                        }
                        weight(1f) - field("Middle Name") {
                            textInput {
                                content bind applicant.lensPath { it.middleName }.nullToBlank()
                            }
                        }
                        weight(1f) - field("Surname*") {
                            reactive { if(applicant().surname?.isNotBlank() != true && submitAttempted()) throw PlainTextException("Required") }
                            textInput {
                                content bind applicant.lensPath { it.surname }.nullToBlank()
                            }
                        }
                    }
                    col {
                        rowCollapsingToColumn(50.rem) {
                            weight(1f) - field("Email*") {
                                reactive { if(applicant().email?.isNotBlank() != true && submitAttempted()) throw PlainTextException("Required") }
                                textInput {
                                    content bind applicant.lensPath { it.email }.nullToBlank()
                                }
                            }
                            weight(1f) - field("Phone Number*") {
                                reactive { if(applicant().phoneNumber?.isNotBlank() != true && submitAttempted()) throw PlainTextException("Required") }
                                textInput {
                                    content bind applicant.lensPath { it.phoneNumber }.nullToBlank()
                                }
                            }
                        }
                        rowCollapsingToColumn(50.rem) {
                            weight(1f) - field("Driver's License Number*") {
                                reactive { if(applicant().driversLicenseNumber?.isNotBlank() != true && submitAttempted()) throw PlainTextException("Required") }
                                textInput {
                                    content bind applicant.lensPath { it.driversLicenseNumber }.nullToBlank()
                                }
                            }
                            weight(1f) - field("Driver's License Expiration*") {
                                reactive { if(applicant().driversLicenseExpiration == null && submitAttempted()) throw PlainTextException("Required") }
                                localDateField { content bind applicant.lensPath { it.driversLicenseExpiration } }
                            }
                        }
                        rowCollapsingToColumn(50.rem) {
                            weight(1f) - field("Birthday*") {
                                reactive { if(applicant().birthday == null && submitAttempted()) throw PlainTextException("Required") }
                                localDateField { content bind applicant.lensPath { it.birthday } }
                            }
                            weight(1f) - field("Social Security Number*") {
                                reactive { if(applicant().socialSecurityNumber == null && submitAttempted()) throw PlainTextException("Required") }
                                textInput {
                                    content bind applicant.lensPath { it.socialSecurityNumber }.nullToBlank()
                                }
                            }
                        }
                    }

                    financingFormSectionHeader(applicantType, "Residence Information")
                    if(applicantType == ApplicantType.Secondary) {
                        val coapplicantAddressSameAsPrimary = shared {
                            data().primary.residency == data().secondary?.residency
                        }.withWrite {
                            if (it) {
                                data.value =
                                    data.value.copy(secondary = data.value.secondary!!.copy(residency = data.value.primary.residency))
                            } else {
                                data.value =
                                    data.value.copy(secondary = data.value.secondary!!.copy(residency = ResidencyInfo()))
                            }
                        }
                        row {
                            checkbox {
                                checked bind coapplicantAddressSameAsPrimary
                            }
                            centered - expanding - text("Check if the same as Applicant's")
                        }
                        onlyWhen { !coapplicantAddressSameAsPrimary() }
                    }
                    residency(applicant.lensPath { it.residency })

                    onlyWhen { applicant().residency.yearsAtAddress?.let { years -> years < 2 } ?: false } - col {
                        financingFormSectionHeader(applicantType, "Previous Residence Information")
                        residency(applicant.lensPath { it.previousResidency }.lens(
                            get = { it ?: ResidencyInfo() },
                            set = { it }
                        ))
                    }

                    financingFormSectionHeader(applicantType, "Employment Information")
                    employer(applicant.lensPath { it.employer }, CurrentOrPrevious.Current)

                    onlyWhen { applicant().employer.yearsEmployed?.let { years -> years < 2 } ?: false } - col {
                        financingFormSectionHeader(applicantType, "Previous Employment Information")
                        employer(applicant.lensPath { it.previousEmployer }.lens(
                            get = { it ?: EmployerInfo() },
                            set = { it }
                        ), CurrentOrPrevious.Previous)
                    }
                }

                h1 { content = "Finance Application" }

                applicant(data.lensPath { it.primary }, ApplicantType.Primary)

                space()
                row {
                    checkbox {
                        checked bind data.lensPath { it.secondary }.lens(
                            get = { it != null },
                            modify = { o, it -> if (it) o ?: FinancingApplicant() else null }
                        )
                    }
                    centered - expanding - text("Check if Co-Applicant")
                }
                onlyWhen { data().secondary != null } - applicant(data.lensPath { it.secondary }.lens(
                    get = { it ?: FinancingApplicant() },
                    set = { it }
                ), ApplicantType.Secondary)

                financingFormSectionHeader(ApplicantType.Primary, "Additional Information")
                field("Additional Information") {
                    textArea {
                        content bind data.lensPath { it.additionalInformation }.nullToBlank()
                    }
                }

                financingFormSectionHeader(ApplicantType.Primary, "Terms and Conditions")
                bold - text("ACKNOWLEDGMENT AND CONSENT:")
                text("I certify that the above information is complete and accurate to the best of my knowledge. Creditors receiving this application will retain the application whether or not it is approved. Creditors may rely on this application in deciding whether to grant the requested credit. False statements may subject me to criminal penalties. I authorize the creditors to obtain credit reports about me on an ongoing basis during this credit transaction and to check my credit and employment history on an ongoing basis during the term of the credit transaction. If this application is approved, I authorize the creditor to give credit information about me to its affiliates.")
                text("If you're a resident of California, Maine, Massachusetts, New York, Ohio, Rhode Island, Vermont, Washington, or Wisconsin, please scroll down to read the notice called for under the law of your state. By submitting this application, you represent that you've read the notice required under the law of your state.")

                text("CALIFORNIA RESIDENTS: A married applicant may apply for an individual account.")

                text("MAINE RESIDENTS: Consumer reports (credit reports) may be obtained in connection with this application. If you request, the creditor will inform you:")

                text("1. whether or not consumer reports were obtained, and")
                text("2. if reports were obtained, the names and addresses of the consumer reporting agencies (credit bureaus) that furnished the reports.")
                text("You have the right to choose the agent and insurer for the insurance required by this transaction, but the insurer must be approved by the creditor.")

                val agreed = Property(false)
                space()
                row {
                    checkbox {
                        checked bind agreed
                    }
                    centered - expanding - text("Agree to terms")
                }
                space()
                row {
                    important - button {
                        ::enabled { agreed() }
                        text("Submit")
                        onClick {
                            submitAttempted.value = true
                            val errorText = "Please fill out all required fields"

                            fun validateApplicant(applicant: FinancingApplicant) {
                                if(applicant.givenName?.isNotBlank() != true) throw PlainTextException(errorText)
                                if(applicant.surname?.isNotBlank() != true) throw PlainTextException(errorText)
                                if(applicant.email?.isNotBlank() != true) throw PlainTextException(errorText)
                                if(applicant.phoneNumber?.isNotBlank() != true) throw PlainTextException(errorText)
                                if(applicant.driversLicenseNumber?.isNotBlank() != true) throw PlainTextException(errorText)
                                if(applicant.driversLicenseExpiration == null) throw PlainTextException(errorText)
                                if(applicant.birthday == null) throw PlainTextException(errorText)
                                if(applicant.socialSecurityNumber == null) throw PlainTextException(errorText)

                                if(applicant.residency.address.street?.isNotBlank() != true) throw PlainTextException(errorText)
                                if(applicant.residency.address.city?.isNotBlank() != true) throw PlainTextException(errorText)
                                if(applicant.residency.address.state == null) throw PlainTextException(errorText)
                                if(applicant.residency.address.zip == null) throw PlainTextException(errorText)
                                if(applicant.residency.yearsAtAddress == null) throw PlainTextException(errorText)
                                if(applicant.residency.monthsAtAddress == null) throw PlainTextException(errorText)
                                if(applicant.residency.monthlyPayment == null) throw PlainTextException(errorText)
                                if (applicant.residency.yearsAtAddress?.let { years -> years < 2 } == true) {
                                    if(applicant.previousResidency?.address?.street?.isNotBlank() != true) throw PlainTextException(errorText)
                                    if(applicant.previousResidency?.address?.city?.isNotBlank() != true) throw PlainTextException(errorText)
                                    if(applicant.previousResidency?.address?.state == null) throw PlainTextException(errorText)
                                    if(applicant.previousResidency?.address?.zip == null) throw PlainTextException(errorText)
                                    if(applicant.previousResidency?.yearsAtAddress == null) throw PlainTextException(errorText)
                                    if(applicant.previousResidency?.monthsAtAddress == null) throw PlainTextException(errorText)
                                    if(applicant.previousResidency?.monthlyPayment == null) throw PlainTextException(errorText)
                                }

                                if(applicant.employer.name?.isNotBlank() != true) throw PlainTextException(errorText)
                                if(applicant.employer.address?.street?.isNotBlank() != true) throw PlainTextException(errorText)
                                if(applicant.employer.address?.city?.isNotBlank() != true) throw PlainTextException(errorText)
                                if(applicant.employer.address?.state == null) throw PlainTextException(errorText)
                                if(applicant.employer.address?.zip == null) throw PlainTextException(errorText)
                                if(applicant.employer.phone == null) throw PlainTextException(errorText)
                                if(applicant.employer.grossMonthlyIncome == null) throw PlainTextException(errorText)
                                if(applicant.employer.occupation?.isNotBlank() != true) throw PlainTextException(errorText)
                                if(applicant.employer.yearsEmployed == null) throw PlainTextException(errorText)
                                if (applicant.employer.yearsEmployed?.let { years -> years < 2 } == true) {
                                    if(applicant.employer.name?.isNotBlank() != true) throw PlainTextException(errorText)
                                    if(applicant.employer.phone == null) throw PlainTextException(errorText)
                                    if(applicant.employer.occupation?.isNotBlank() != true) throw PlainTextException(errorText)
                                }
                            }

                            validateApplicant(data().primary)

                            data().secondary?.let {
                                validateApplicant(it)
                            }

                             currentSession().submitFinancingApplication(data())
                             screenNavigator.goBack()
                        }
                    }
                    button {
                        text("Clear Form")
                        onClick {
                            val me = currentSession().me.awaitNotNull()
                            data.value = FinancingApplication(user = me._id, primary = FinancingApplicant())
                        }
                    }
                }
            }
        }
    }
}